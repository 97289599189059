import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import { AiOutlineEdit } from 'react-icons/ai';
import { debounce } from 'lodash';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
} from 'components';
import { useIsGuest, useSessionStorage } from 'hooks';
import { DateConvert, TableNumber } from 'utilities';
import { AnalisaBarangJadiApi } from 'api';
import ModalFilterABJ from './ModalFilterABJ';

const AnalisaBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();

  const paginationKey = `${location.pathname}_pagination`;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    id_kelompok: undefined,
    id_satuan_jual: undefined,
    is_abj: undefined,
    status_approval: undefined,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [dropdown, setDropdown] = useState({
    kelompok: [{ value: null, label: 'Semua' }],
    satuan_jual: [{ value: null, label: 'Semua' }],
    status_analisa: [
      { value: null, label: 'Semua' },
      { value: 'true', label: 'Sudah Dianalisa' },
      { value: 'false', label: 'Belum Dianalisa' },
    ],
    approval: [
      { value: null, label: 'Semua' },
      { value: 'PEN', label: 'PENDING' },
      { value: 'REV', label: 'REVISION' },
      { value: 'VER', label: 'VERIFIED' },
      { value: 'APP', label: 'APPROVED' },
      { value: 'REJ', label: 'REJECTED' },
      { value: 'DRAFT', label: 'DRAFT' },
    ],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    let query = {
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      id_kelompok: pagination.id_kelompok,
      id_satuan_jual: pagination.id_satuan_jual,
      is_abj: pagination.is_abj,
      status_approval: pagination.status_approval,
    };

    AnalisaBarangJadiApi.get(query)
      .then(({ data }) => {
        setDataAnalisaBarangJadi(data.data);
        setPagination({
          ...pagination,
          data_count: data.data_count,
          total_page: data.total_page,
        });
      })
      .catch(() =>
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const fetchDropdownFilter = () => {
    Axios.all([
      AnalisaBarangJadiApi.getDropdownFilter({ tipe: 'kelompok_barang_jadi' }),
      AnalisaBarangJadiApi.getDropdownFilter({ tipe: 'satuan_jual' }),
    ]).then(
      Axios.spread((resKelompok, resSatuan) => {
        setDropdown((prev) => ({
          ...prev,
          kelompok: [...prev.kelompok].concat(
            resKelompok?.data?.data?.map((item) => ({
              value: item.id_kelompok,
              label: item.nama_kelompok,
            }))
          ),
          satuan_jual: [...prev.satuan_jual].concat(
            resSatuan?.data?.data?.map((item) => ({
              value: item.id_satuan,
              label: item.nama_satuan,
            }))
          ),
        }));
      })
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert({
        show: locationState.alert.show,
        text: locationState.alert.text,
        variant: locationState.alert.variant,
      });
    }
  };

  const dismissAlert = () => {
    setAlert({ ...alert, show: false });
    history.replace(location?.pathname, { alert: undefined });
  };

  const getStatus = (is_abj) => {
    if (is_abj) {
      return (
        <span
          className="text-nowrap text-uppercase"
          style={{ color: '#00cc00' }}
        >
          Sudah dianalisa
        </span>
      );
    }
    return (
      <span className="text-nowrap text-uppercase" style={{ color: '#ff0000' }}>
        Belum dianalisa
      </span>
    );
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(() => {
    setNavbarTitle('Analisa Barang Jadi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.id_kelompok,
    pagination.id_satuan_jual,
    pagination.is_abj,
    pagination.status_approval,
  ]);

  useEffect(() => {
    fetchDropdownFilter();

    return () => {
      setDropdown({});
    };
  }, []);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Tgl. Input</ThFixed>
            <Th>Nama Karyawan Pembuat</Th>
            <ThFixed>Kode Barang Jadi</ThFixed>
            <Th>Nama Barang Jadi</Th>
            <Th>Kelompok Barang Jadi</Th>
            <Th>Satuan Jual</Th>
            <ThFixed>Status Analisa</ThFixed>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataAnalisaBarangJadi.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>

              <TdFixed>
                {val.tgl_pengaju
                  ? DateConvert(new Date(val.tgl_pengaju)).defaultDMY
                  : '-'}
              </TdFixed>
              <Td>{val.nama_karyawan_pengaju ?? '-'}</Td>
              <Td>{val.kode_item}</Td>
              <Td>{val.nama_item}</Td>
              <Td>{val.nama_kelompok}</Td>
              <Td>{val.nama_satuan}</Td>
              <Td>{getStatus(val.is_abj)}</Td>
              <Td>
                <ApprovalStatusButton status={val.status_approval} />
              </Td>
              <Td>
                <div className="d-flex">
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/transaksi/analisa-barang-jadi/detail/${val.id_barang_jadi}`,
                          {
                            isApprove:
                              val.status_approval === 'APP' ? true : false,
                          }
                        )
                      }
                    >
                      <IoEyeOutline />
                    </ActionButton>

                    {!isGuest &&
                      val.status_approval !== 'PEN' &&
                      val.status_approval !== 'VER' && (
                        <ActionButton
                          size="sm"
                          variant="success"
                          onClick={() =>
                            history.push(
                              '/transaksi/analisa-barang-jadi/form/' +
                                val.id_barang_jadi
                            )
                          }
                        >
                          <AiOutlineEdit />
                        </ActionButton>
                      )}
                  </ButtonGroup>
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <div className="mb-1">
          <b>List Data Analisa Barang Jadi</b>
        </div>
        <DataTable />

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="d-flex align-items-center mb-2">
            <Col md="10" className="d-flex">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(searchHandler, 1500)}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataAnalisaBarangJadi ? (
        dataAnalisaBarangJadi.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      <ModalFilterABJ
        dropdown={dropdown}
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default AnalisaBarangJadi;
