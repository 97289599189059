import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  DataStatus,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  Th,
  UpdateButton,
} from 'components';
import {
  AlertModal,
  ModalDataMutasiBarangJadi,
  ModalDetailMutasiBarangJadi,
} from './Components';
import { DateConvert, TableNumber } from 'utilities';
import { MutasiBarangJadiApi } from 'api';

export const MutasiBarangJadi = ({ setNavbarTitle }) => {
  const title = 'Mutasi Barang Jadi';
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDropdown, setDataDropdown] = useState({
    job_order: [],
    gudang: [],
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modal, setModal] = useState({
    show: false,
    tipe: '',
    data: {
      id_mutasi_antar_gudang: '',
      tgl_mutasi_antar_gudang: '',
      id_item_buaso: '',
      id_gudang_asal: '',
      id_gudang_tujuan: '',
      qty_mutasi: '',
      catatan_mutasi: '',
      no_ref_transaksi: '',
      message: '',
      id_satuan: '',
      harga_barang_jadi: '',
    },
  });
  const [alertModal, setAlertModal] = useState({
    show: false,
    message: '',
  });

  const tableStyle = {
    tdClassname: 'p-1 px-2 align-middle',
    tdStyle: { width: 'auto' },
  };

  const getInitialData = () => {
    setIsPageLoading(true);

    MutasiBarangJadiApi.page({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  useEffect(() => {
    axios
      .all([
        MutasiBarangJadiApi.dropdown({ tipe: 'job_order' }),
        MutasiBarangJadiApi.dropdown({ tipe: 'gudang' }),
      ])
      .then(
        axios.spread((job_order, gudang) =>
          setDataDropdown({
            job_order: job_order.data.data,
            gudang: gudang.data.data,
          })
        )
      );
  }, []);

  const PageContent = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <Table>
          <thead>
            <tr>
              <Th>No</Th>
              <Th style={{ width: '12px' }}>Aksi</Th>
              <Th style={{ width: '12px' }}>Informasi Mutasi</Th>
              <Th style={{ width: '12px' }}>No. Job Order</Th>
              <Th>Item Barang Jadi</Th>
              <Th>Gudang Asal</Th>
              <Th>Gudang Tujuan</Th>
              <Th style={{ width: '12px' }}>Qty. Mutasi</Th>
              <Th style={{ width: '12px' }}>Status Approval</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td
                  className={`${tableStyle.tdClassname} text-center`}
                  style={tableStyle.tdStyle}
                >
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>

                <Td className={`${tableStyle.tdClassname} text-center`}>
                  <div className="d-flex">
                    <ReadButton
                      onClick={() =>
                        setModal({
                          show: true,
                          tipe: 'detail',
                          data: val,
                        })
                      }
                    />

                    {val.status_approval === 'REV' && (
                      <UpdateButton
                        onClick={() =>
                          setModal({
                            show: true,
                            tipe: 'ubah',
                            data: {
                              ...val,
                              catatan_mutasi: val.catatan,
                            },
                          })
                        }
                      />
                    )}
                  </div>
                </Td>
                <Td
                  className={tableStyle.tdClassname}
                  style={tableStyle.tdStyle}
                >
                  <div>
                    {val.tgl_mutasi_antar_gudang
                      ? DateConvert(new Date(val.tgl_mutasi_antar_gudang))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_mutasi_antar_gudang ?? '-'} </div>
                </Td>

                <Td
                  className={`${tableStyle.tdClassname} text-center`}
                  style={tableStyle.tdStyle}
                >
                  {val.no_ref_transaksi ?? '-'}
                </Td>

                <Td className={tableStyle.tdClassname}>
                  {`${val.kode_item ? `(${val.kode_item}) ` : ''}${
                    val.nama_item ?? '-'
                  }`}
                </Td>

                <Td className={tableStyle.tdClassname}>
                  {val.nama_gudang_asal ?? '-'}
                </Td>

                <Td className={tableStyle.tdClassname}>
                  {val.nama_gudang_tujuan ?? '-'}
                </Td>

                <Td
                  className={`${tableStyle.tdClassname} text-right text-nowrap`}
                >
                  {`${val.qty_mutasi ?? 0} ${val.nama_satuan ?? ''}`}
                </Td>

                <Td className={tableStyle.tdClassname}>
                  <ApprovalStatusButton
                    status={val.status_approval}
                    defaultStatus="PEN"
                  />
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>

        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <>
      <Row className="mb-2 d-flex justify-content-around align-items-end">
        <Col md={4}>
          <InputSearch
            value={searchConfig.key}
            onChange={onInputSearchChange}
          />
        </Col>

        <Col className="d-flex justify-content-end">
          <CreateButton
            onClick={() =>
              setModal({
                show: true,
                tipe: 'tambah',
                data: {
                  id_mutasi_antar_gudang: '',
                  tgl_mutasi_antar_gudang: '',
                  id_item_buaso: '',
                  id_gudang_asal: '',
                  id_gudang_tujuan: '',
                  qty_mutasi: '',
                  catatan_mutasi: '',
                  no_ref_transaksi: '',
                },
              })
            }
          />
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          <ModalDataMutasiBarangJadi
            modal={modal}
            title={title}
            dataDropdown={dataDropdown}
            setAlertConfig={setAlertConfig}
            setModal={setModal}
            setAlertModal={setAlertModal}
            getInitialData={getInitialData}
          />
          <ModalDetailMutasiBarangJadi
            modal={modal}
            setModal={setModal}
            title={title}
          />
          <AlertModal alertModal={alertModal} setAlertModal={setAlertModal} />
        </>
      )}
    </>
  );
};
