import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Card, Nav, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  Approval,
} from 'components';
import { DateConvert } from 'utilities';
import { RAPApi } from 'api';
import { TableBarangJadi, TableListSPK } from '../Section';
import {
  DropdownAnalisaBarangJadiContext,
  DropdownAnalisaBarangJadiContextProvider,
  RAPContext,
  RAPContextProvider,
} from '../RAPContext';
import {
  TabFilePeluang,
  TabGambarPeluang,
  TabInfoPeluang,
} from '../PeluangSection';

const UbahRAP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const UbahRAPContent = () => {
    const { setTriggerFetch } = useContext(DropdownAnalisaBarangJadiContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataSPK, setDataSPK] = useState({});
    const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] =
      useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      nomor: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      Axios.all([RAPApi.getSingle({ id_rap: id }), RAPApi.getDropdownAnalisa()])
        .then(
          Axios.spread((spk, analisa) => {
            setDataSPK(spk.data.data);
            setDataSelectAnalisaBarangJadi(analisa.data.data);
            setTriggerFetch(true);
          })
        )
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Produksi (RAP)');
      getInitialData();

      return () => {
        setIsPageLoading(false);
      };
    }, []);

    const InfoSection = () => {
      const InfoItem = ({ title, value, onClick, link }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className="pl-4 pr-2">:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. SPK"
                  value={
                    dataSPK.tgl_spk
                      ? DateConvert(new Date(dataSPK.tgl_spk)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="No. SPK"
                  value={dataSPK.no_spk ? dataSPK.no_spk : '-'}
                />
                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataSPK.no_peluang ? dataSPK.no_peluang : '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      nomor: dataSPK.no_peluang,
                    })
                  }
                />
                <InfoItem
                  title="Tgl. Selesai Barang Jadi"
                  value={
                    dataSPK.tgl_selesai_spk
                      ? DateConvert(new Date(dataSPK.tgl_selesai_spk)).detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Customer"
                  value={dataSPK.nama_customer ? dataSPK.nama_customer : '-'}
                />
                <InfoItem title="ATT" value={dataSPK.att ? dataSPK.att : '-'} />
                <InfoItem
                  title="Tanggal Realisasi"
                  value={
                    dataSPK.tgl_selesai
                      ? DateConvert(new Date(dataSPK.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItem title="Proyek" value={dataSPK.nama_proyek ?? '-'} />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    const ModalPeluang = () => {
      const [dataInfoPeluang, setDataInfoPeluang] = useState({});
      const [dataTablePeluang, setDataTablePeluang] = useState([]);
      const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
        loading: true,
        success: false,
      });
      const tabsRef = useRef();

      const [defaultTabs, setDefaultTabs] = useState('info');
      const [dataGambar, setDataGambar] = useState([]);
      const [dataFile, setDataFile] = useState([]);

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'info',
          label: 'Informasi Umum',
          component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
        },
        {
          tab: 'gambar',
          label: 'Gambar',
          component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
        },
        {
          tab: 'file',
          label: 'File',
          component: ({ ...rest }) => <TabFilePeluang {...rest} />,
        },
      ];

      // Menangani cek type dari file yang dikirim
      const checkTypeAndStoreFile = (data) => {
        let gambar = [];
        let file = [];

        data.map((val) => {
          const map = {
            link: val.path_gambar,
            id: val.id_peluang_gambar,
            nama: '',
            data: null,
          };

          const generalFileType = map.link.split('.').pop();
          const fileName = map.link.split('/').pop();

          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push({ ...map, nama: fileName });
          }

          return file.push({ ...map, nama: fileName });
        });

        setDataGambar(gambar);
        setDataFile(file);
      };

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, tabs) => {
        e.preventDefault();
        setDefaultTabs(tabs);
      };

      const getDataRAE = (noPeluang) => {
        setFetchPeluangStatus({
          loading: true,
          success: false,
        });

        RAPApi.getDetailPeluangRAP({ no_peluang: noPeluang })
          .then((res) => {
            const dataInfo = res.data.data ?? {};
            const dataTable = res.data.data.detail ?? {};

            setDataInfoPeluang(dataInfo);
            checkTypeAndStoreFile(dataInfo?.gambar ?? []);
            setDataTablePeluang(dataTable);
            setFetchPeluangStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchPeluangStatus({
              loading: false,
              success: false,
            });
          });
      };

      useEffect(() => {
        if (modalPeluangConfig.nomor) {
          getDataRAE(modalPeluangConfig.nomor);
        } else {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        }
      }, []);

      if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
        return (
          <Modal
            size="xl"
            show={modalPeluangConfig.show}
            onHide={() =>
              setModalPeluangConfig({
                ...modalPeluangConfig,
                show: false,
              })
            }
          >
            <Modal.Header closeButton>
              <b>Detail Peluang</b>
            </Modal.Header>
            <Modal.Body>
              {fetchPeluangStatus.loading ? (
                <DataStatus loading text="Memuat data . . ." />
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </Modal.Body>
          </Modal>
        );
      }

      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            <Card.Header>
              {/* Tab Header */}
              <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
                {tabsConfig.map((val, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      ref={tabsRef}
                      href={`#${val.tab}`}
                      onClick={(e) => onTabsChangeHandler(e, val.tab)}
                    >
                      {val.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === defaultTabs && (
                    <Component
                      key={index}
                      type="DETAIL"
                      dataInfoPeluang={dataInfoPeluang}
                      dataTablePeluang={dataTablePeluang}
                      dataGambar={dataGambar}
                      dataFile={dataFile}
                      setDataGambar={setDataGambar}
                      setDataFile={setDataFile}
                    />
                  )
              )}
            </Card.Body>
          </Modal.Body>
        </Modal>
      );
    };

    const TableSection = () => {
      const [dataBarangJadi, setDataBarangJadi] = useState([]);
      const [dataItemSPK, setDataItemSPK] = useState([]);
      const [dataItemPrelim, setDataItemPrelim] = useState([]);
      const { dataAnalisaBarangJadi: contextAnalisa, setType } =
        useContext(RAPContext);

      const mappingHardwood = (data) =>
        data.map((val) => ({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_jenis_kayu: val.id_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          id_part_kayu: val.id_part_kayu,
        }));

      const mappingPlywood = (data) =>
        data.map((val) => ({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_item_buaso: val.id_item_buaso,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
        }));

      const mappingTP = (value) => {
        const final = [];
        value.map((val) => {
          final.push({
            id_barang_jadi: val.id_barang_jadi,
            id_item_buaso: val.id_item_buaso,
            qty: val.qty,
            unit_price: val.unit_price,
            konstanta: val.konstanta,
          });
        });

        return final;
      };

      const mappingFN = (value) => {
        const final = [];
        value.map((val) => {
          final.push({
            id_barang_jadi: val.id_barang_jadi,
            id_item_buaso: val.id_item_bahan,
            qty: val.qty,
            unit_price: val.unit_price,
            konstanta: val.konstanta,
            id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          });
        });

        return final;
      };

      const mappingPrelim = (data) =>
        data.map((val) => ({
          id_item_buaso: val.id_item,
          qty_analisa: val.qty_item,
          unit_price: val.harga,
          qty_durasi: val.qty_durasi,
          id_satuan_durasi: val.id_unit,
          konstanta: val.konstanta,
        }));

      const formInitialValues = {
        id_rap: id,
        id_spk: dataSPK.id_spk,
        id_peluang: dataSPK.id_peluang,
        tgl_rap: dataSPK.tgl_rap,
        no_rap: dataSPK.no_rap,
      };

      const formValidationSchema = Yup.object().shape({
        tgl_rap: Yup.string().required('Pilih tanggal RAP'),
        no_rap: Yup.string().required(
          'Pilih tanggal untuk menentukan nomor RAP'
        ),
      });

      const formSubmitHandler = (values) => {
        const detailAnalisa = [];
        const barangJadi = [];

        contextAnalisa.map((val) =>
          detailAnalisa.push({
            index: val.index,
            analisa_hardwood: mappingHardwood(val.dataHardwood ?? []),
            analisa_plywood: mappingPlywood(val.dataPlywood ?? []),
            analisa_fin_tp_fs: mappingTP(val.dataTPFS ?? []),
            analisa_fin_tp_lc: mappingTP(val.dataTPLC ?? []),
            analisa_fin_tp_mp: mappingTP(val.dataTPMP ?? []),
            analisa_fin_tp_bop: mappingTP(val.dataTPBOP ?? []),
            analisa_fin_fn_bp: mappingFN(val.dataFNBP ?? []),
            analisa_fin_fn_sc: mappingFN(val.dataFNSC ?? []),
            analisa_fin_fn_lc: mappingFN(val.dataFNLC ?? []),
            uraian_labour_cost: val.dataUraianLabourCost ?? [],
            uraian_labour_cost_finishing:
              val.dataUraianLabourCostFinishing ?? [],
            uraian_subkon_finishing: val.dataUraianSubcontFinishing ?? [],
          })
        );

        dataBarangJadi.map((val, idx) => {
          if (val !== null) {
            barangJadi.push({
              is_edit: val.is_edit ?? false,
              id_barang_jadi: val.id_barang_jadi,
              id_rap_detail: val.id_rap_detail,
              qty_rap: val.qty,
              harga_satuan_rap: val.harga_satuan,
              analisa_barang_jadi: detailAnalisa.find(
                (val) => val.index === idx
              )
                ? detailAnalisa.find((val) => val.index === idx)
                : {},
            });
          }
        });

        const finalValues = {
          id_rap: id,
          tgl_rap: values.tgl_rap,
          id_peluang: dataSPK.id_peluang,
          id_spk: dataSPK.id_spk,
          detail_barang_jadi: barangJadi,
        };

        if (Boolean(values.is_draft)) {
          finalValues.is_draft = true;
        }

        RAPApi.save(finalValues)
          .then(() =>
            history.push('/transaksi/rap', {
              alert: {
                show: true,
                variant: 'primary',
                text: 'Data berhasil diubah!',
              },
            })
          )
          .catch(() => {
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal diubah!',
            });
          });
      };

      const formik = useFormik({
        enableReinitialize: true,
        initialValues: formInitialValues,
        validationSchema: formValidationSchema,
        onSubmit: formSubmitHandler,
      });

      const getNomorRAP = (date) => {
        formik.setFieldValue('tgl_rap', date);
      };

      const getDataItemSPK = () => {
        const dataItem = dataSPK.detail_spk ?? [];
        const dataItemPrelim = dataSPK.detail_prelim ?? [];
        setDataItemSPK(dataItem);
        setDataItemPrelim(dataItemPrelim);
      };

      const getDataBarangJadi = () => {
        const barangJadi = dataSPK.detail_barang_jadi
          ? dataSPK.detail_barang_jadi
          : [];
        const filter = barangJadi.map((val) => {
          return {
            is_edit: val.is_edit,
            id_rap_detail: val.id_rap_detail,
            id_barang_jadi: val.id_barang_jadi,
            nama_item: val.nama_item,
            kode_item: val.kode_item,
            qty: val.qty_rap ? val.qty_rap : 0,
            satuan: val.nama_satuan,
            harga_satuan: val.harga_satuan_rap
              ? parseFloat(val.harga_satuan_rap)
              : 0,
          };
        });

        setDataBarangJadi(filter);
      };

      useEffect(() => {
        getDataBarangJadi();
        getDataItemSPK();
        setType('UPDATE');
      }, []);

      const FormRAP = ({ formik }) => {
        const { values, errors, touched, setFieldValue } = formik;

        return (
          <Row>
            <Col sm={6}>
              <DatePicker
                label="Tanggal RAP"
                placeholderText="Pilih tanggal rap"
                selected={values.tgl_rap ? new Date(values.tgl_rap) : ''}
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setFieldValue('tgl_rap', value);
                  getNomorRAP(value);
                }}
                error={errors.tgl_rap && touched.tgl_rap && true}
                errorText={errors.tgl_rap && touched.tgl_rap && errors.tgl_rap}
              />
            </Col>
            <Col sm={6}>
              <Input
                label="Nomor RAP"
                placeholder="Pilih tanggal untuk menentukan nomor RAP"
                value={values.no_rap}
                readOnly={true}
                error={errors.no_rap && touched.no_rap && true}
                errorText={errors.no_rap && touched.no_rap && errors.no_rap}
              />
            </Col>
          </Row>
        );
      };

      const ButtonSection = () => {
        return (
          <div className="d-flex justify-content-end mt-4">
            <ActionButton
              text="Ubah Rencana Anggaran Produksi"
              className="mr-2"
              variant="success"
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
            />

            <ActionButton
              text="Simpan Sebagai Draft"
              onClick={() => {
                formik.setFieldValue('is_draft', true);
                formik.handleSubmit();
              }}
              loading={formik.isSubmitting}
            />
          </div>
        );
      };

      return (
        <>
          <FormRAP formik={formik} />
          <hr />
          <TableListSPK
            dataSPK={dataSPK}
            dataItemSPK={dataItemSPK}
            dataItemPrelim={dataItemPrelim}
          />
          <TableBarangJadi
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            dataSelectAnalisaBarangJadi={dataSelectAnalisaBarangJadi}
            setDataSelectAnalisaBarangJadi={setDataSelectAnalisaBarangJadi}
          />
          <ButtonSection />
        </>
      );
    };

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Ubah Data RAP</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection />
                <hr />
                <TableSection />
              </>
            )}
          </Card.Body>
        </Card>
        {!isPageLoading && <Approval data={dataSPK} />}
        {modalPeluangConfig.show && <ModalPeluang />}
      </>
    );
  };

  return (
    <RAPContextProvider>
      <DropdownAnalisaBarangJadiContextProvider>
        <UbahRAPContent />
      </DropdownAnalisaBarangJadiContextProvider>
    </RAPContextProvider>
  );
};

export default UbahRAP;
