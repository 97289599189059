import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerSF, ContainerSFDetail } from '../SubconFinishing';

export const TabSubcon = ({ readOnly = false, showHistory = true }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="sf">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="sf">
            <NavTab eventKey="sf" title="Subcon Finishing" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="sf"
              component={
                readOnly ? (
                  <ContainerSFDetail />
                ) : (
                  <ContainerSF showHistory={showHistory} />
                )
              }
            />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
