import { Fragment, useEffect, useContext, useState } from 'react';
import { Row, Col, Card, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  ActionButton,
  BackButton,
  DataStatus,
  DatePicker,
  Input,
} from 'components';
import { PermintaanProduksiApi } from 'api';
import { DateConvert } from 'utilities';
import {
  PermintaanProduksiContext,
  withDropdownPermintaanProduksiContextProvider,
  withPermintaanProduksiContextProvider,
} from './Context';
import {
  InfoSection,
  TabRoot,
  TabPane,
  TabBahan,
  TabUpah,
  TabSubcon,
  TabAlatMesin,
  TabOverhead,
  SummarySection,
  ModalAlert,
} from './Component';
import {
  formInitialValues,
  validationSchema,
  mapSubmitValues,
  mapServerValues,
} from './Helpers';

const PermintaanProduksiTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_job_order } = useParams();
  const {
    dataHardwood,
    dataPlywood,
    dataFS,
    dataBPF,
    dataLC,
    dataLCF,
    dataMP,
    dataSF,
    dataBO,
    setDataHardwood,
    setDataPlywood,
    setDataFS,
    setDataBPF,
    setDataLC,
    setDataLCF,
    setDataMP,
    setDataSF,
    setDataBO,
  } = useContext(PermintaanProduksiContext);
  const [dataJO, setDataJO] = useState({});
  const [isFetch, setFetch] = useState({
    loading: false,
    error: false,
  });
  const [modalStatus, setModalStatus] = useState({
    show: false,
    type: 'warning',
    title: 'Konfirmasi Simpan Data',
    message: 'Apakah Anda yakin akan menyimpan data ini?',
  });

  const getInitialData = () => {
    setFetch({ loading: true, error: false });

    PermintaanProduksiApi.getSingleJobOrder({
      id_job_order,
    })
      .then((res) => {
        const {
          analisa_hardwood,
          analisa_plywood,
          analisa_PFS,
          analisa_PPF,
          uraian_labour_cost,
          uraian_labour_cost_finishing,
          analisa_PMC,
          analisa_PSF,
          analisa_POP,
          history,
          ...jobOrder
        } = res.data.data;

        setDataJO(jobOrder);

        setDataHardwood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_hardwood).hardwood,
          history: history?.hardwood ?? [],
        }));

        setDataPlywood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_plywood).hardwood,
          history: history?.plywood ?? [],
        }));

        setDataFS((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PFS).factorySupply,
          history: mapServerValues(history.factory_supply).factorySupply,
        }));

        setDataBPF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PPF).bahanPenunjang,
          history: mapServerValues(history.bahan_penunjang_finishing)
            .bahanPenunjang,
        }));

        setDataLC((prev) => ({
          ...prev,
          jobOrder: uraian_labour_cost,
          history: history?.labour_cost ?? [],
        }));

        setDataLCF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(uraian_labour_cost_finishing)
            .labourCostFinishing,
          history: mapServerValues(history?.labour_cost_finishing)
            .labourCostFinishing,
        }));

        setDataMP((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PMC).machineProcess,
          history: mapServerValues(history?.machine_process).machineProcess,
        }));

        setDataSF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PSF).subconFinishing,
          history: mapServerValues(history?.subcon_finishing).subconFinishing,
        }));

        setDataBO((prev) => ({
          ...prev,
          jobOrder: analisa_POP,
          history: history?.biaya_overhead ?? [],
        }));

        setFetch({ loading: false, error: false });
      })
      .catch(() => {
        window.alert('Data gagal dimuat!');
        setFetch({ loading: false, error: true });
      });
  };

  const closeModalAlert = () =>
    setModalStatus((prev) => ({ ...prev, show: false }));

  useEffect(() => {
    setNavbarTitle('Permintaan Produksi');
    getInitialData();
  }, []);

  const getNoPermintaanProduksi = async (tanggal) =>
    await PermintaanProduksiApi.getNoBaru({ tanggal }).then(
      (res) => res.data.data
    );

  const onSubmit = (values, { setSubmitting }) => {
    const finalValues = mapSubmitValues({
      ...values,
      dataHardwood,
      dataPlywood,
      dataFS,
      dataBPF,
      dataLC,
      dataLCF,
      dataMP,
      dataSF,
      dataBO,
    });

    PermintaanProduksiApi.create(finalValues)
      .then(() =>
        setModalStatus((prev) => ({
          ...prev,
          show: true,
          type: 'success',
          title: 'Berhasil',
          message: 'Data Permintaan Produksi berhasil disimpan!',
        }))
      )
      .catch((err) =>
        setModalStatus((prev) => ({
          ...prev,
          show: true,
          type: 'error',
          title: 'Gagal',
          message: `Data Permintaan Produksi gagal disimpan! ${
            err.message ?? ''
          }`,
        }))
      )
      .finally(() => setSubmitting(false));
  };

  if (isFetch.loading || isFetch.error) {
    return (
      <Fragment>
        <Row className="d-flex justify-space-between align-items-end mb-1">
          <Col>
            <div>Detail Data Job Order</div>
          </Col>
          <Col className="d-flex justify-content-end align-items-end">
            <BackButton onClick={() => history.goBack()} />
          </Col>
        </Row>
        <DataStatus
          loading={isFetch.loading}
          text={isFetch.loading ? 'Memuat data' : 'Data gagal dimuat!'}
        />
      </Fragment>
    );
  }

  return (
    <Formik
      enableReinitializee
      initialValues={formInitialValues({ id_job_order })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Fragment>
          <Row className="d-flex justify-space-between align-items-end mb-2">
            <Col>
              <div>Detail Data Job Order</div>
            </Col>
            <Col className="d-flex justify-content-end align-items-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <InfoSection dataJO={dataJO} />
              <hr />
              <Row>
                <Col>
                  <DatePicker
                    label="Tgl. Permintaan Produksi"
                    selected={formik.values.tgl_permintaan_produksi}
                    onChange={async (value) => {
                      const no = await getNoPermintaanProduksi(
                        DateConvert(value).default
                      );

                      formik.setValues((prev) => ({
                        ...prev,
                        tgl_permintaan_produksi: value,
                        no_permintaan_produksi: no,
                      }));
                    }}
                    error={
                      formik.errors.tgl_permintaan_produksi &&
                      formik.touched.tgl_permintaan_produksi
                    }
                    errorText={formik.errors.tgl_permintaan_produksi}
                  />
                </Col>

                <Col>
                  <Input
                    readOnly
                    label="No. Permintaan Produksi"
                    value={formik.values.no_permintaan_produksi}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <TabRoot>
            <Tab.Content>
              <TabPane eventKey="bahan" component={<TabBahan />} />
              <TabPane eventKey="upah" component={<TabUpah />} />
              <TabPane eventKey="alat" component={<TabAlatMesin />} />
              <TabPane eventKey="subkon" component={<TabSubcon />} />
              <TabPane eventKey="overhead" component={<TabOverhead />} />
            </Tab.Content>
          </TabRoot>

          <SummarySection />
          <Row className="my-2">
            <Col className="d-flex flex-row justify-content-end align-items-end">
              <ActionButton
                text="Simpan"
                onClick={() =>
                  setModalStatus({
                    show: true,
                    type: 'warning',
                    title: 'Konfirmasi Simpan Data',
                    message: 'Apakah Anda yakin akan menyimpan data ini?',
                  })
                }
              />
            </Col>
          </Row>

          <ModalAlert
            modalStatus={modalStatus}
            onSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            onSuccessClose={() => {
              closeModalAlert();
              history.push('/transaksi/permintaan-produksi', {
                alert: {
                  text: 'Data berhasil disimpan!',
                  variant: 'primary',
                },
              });
            }}
            onErrorClose={closeModalAlert}
            onModalClose={closeModalAlert}
          />
        </Fragment>
      )}
    </Formik>
  );
};

export default withDropdownPermintaanProduksiContextProvider(
  withPermintaanProduksiContextProvider(PermintaanProduksiTambah)
);
