import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  DataStatus,
  DeleteModal,
  Alert,
  Pagination,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DeleteButton,
  ReadButton,
  ExportButton,
} from 'components';
import { RegistrasiBarangJadiApi } from 'api';
import { PageNumber } from 'utilities';
import FileSaver from 'file-saver';

const RegistrasiBarangJadi = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const title = 'Registrasi Barang Jadi';

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);

  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis
  const [data, setData] = useState([]);

  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });

  const { REACT_APP_FILE_BASE_URL } = process.env;

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    Axios.all([
      RegistrasiBarangJadiApi.getPage(
        paginationConfig.page,
        paginationConfig.dataLength,
        searchConfig.key
      ),
    ])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: res.data.data_count,
            totalPage: res.data.total_page,
          });
        })
      )
      .catch((err) =>
        alert(err?.response?.data?.message ?? 'Tidak dapat memuat data')
      )
      .finally(() => setIsLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  // fungsi show/ hide
  const changeDataStatus = (status, id_item_buaso) => {
    setIsLoading(true);
    setAlertConfig({
      ...alertConfig,
      show: false,
    });

    const value = { id_item_buaso };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
    };

    status === true
      ? RegistrasiBarangJadiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : RegistrasiBarangJadiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig({
        ...registerAlertConfig,
        show: true,
      });
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  // Modal Hapus
  const HapusModal = () => {
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      RegistrasiBarangJadiApi.delete(deleteData.id_item_buaso)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: `Hapus data gagal! ${err.response.data.message}`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Item : {deleteData.kode_item}</div>
        <div>Nama Barang Jadi : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode Item</ThFixed>
            <Th>Nama Barang Jadi</Th>
            <Th>Kelompok Barang Jadi</Th>
            <Th>Satuan Jual</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <Td textCenter>
                {PageNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        `/master/registrasi-barang-jadi/detail/${val.id_item_buaso}`,
                        {}
                      )
                    }
                  />
                  <UpdateButton
                    onClick={() =>
                      history.push(
                        `/master/registrasi-barang-jadi/update/${val.id_item_buaso}`,
                        {}
                      )
                    }
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() =>
                      changeDataStatus(val.is_hidden, val.id_item_buaso)
                    }
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.kode_item}</TdFixed>
              <Td>{val.nama_item}</Td>
              <Td>{val.barang.nama_kelompok}</Td>
              <Td>{val.nama_satuan_pakai}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <ExportButton
            onClick={() => {
              setIsLoading(true);
              RegistrasiBarangJadiApi.export()
                .then((val) => {
                  const urlFile = REACT_APP_FILE_BASE_URL + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
          <CreateButton
            onClick={() =>
              history.push(`/master/registrasi-barang-jadi/tambah`, {})
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR HISTORY LOCATION
          history.replace('/master/registrasi-barang-jadi', {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length !== 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}

      {/* Modal */}
      <HapusModal />
      <DeleteModal />
    </CRUDLayout>
  );
};

export default RegistrasiBarangJadi;
