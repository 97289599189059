export const calculationHandler = {
  total: (qty, harga) => {
    const newQty = qty ?? 0;
    const newHarga = harga ?? 0;

    return parseInt(newQty * newHarga);
  },

  totalAllPeriod: (data) => {
    const pastPeriod = calculationHandler.afterPercent(
      data.persentase_periode_lalu,
      calculationHandler.total(data.qty, data.unit_price)
    );
    const currentPeriod = parseInt(
      calculationHandler.afterPercent(
        data.persentase_periode_ini,
        calculationHandler.total(data.qty, data.unit_price)
      )
    );

    return pastPeriod + currentPeriod;
  },

  afterPercent: (percent, price) => {
    const newPercent = percent ?? 0;
    const newPrice = price ?? 0;

    return parseInt((newPercent / 100) * newPrice);
  },

  grandTotal: (data) =>
    data.reduce(
      (prev, current) =>
        parseInt(prev) +
        parseInt(parseFloat(current.unit_price) * parseFloat(current.qty)),
      0
    ),

  grandTotalPastPeriod: (data) =>
    data.reduce((prev, current) => {
      const total = parseInt(
        calculationHandler.afterPercent(
          current.persentase_periode_lalu,
          calculationHandler.total(current.qty, current.unit_price)
        )
      );

      return prev + total;
    }, 0),

  grandTotalCurrentPeriod: (data) =>
    data.reduce((prev, current) => {
      const total = parseInt(
        calculationHandler.afterPercent(
          current.persentase_periode_ini,
          calculationHandler.total(current.qty, current.unit_price)
        )
      );

      return prev + total;
    }, 0),

  grandTotalAllPeriod: (data) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);

    return parseInt(pastPeriod + currentPeriod);
  },

  // dp
  grandTotalDPPastPeriod: (data, dp) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);

    return parseInt(parseFloat(dp / 100) * pastPeriod);
  },

  grandTotalDPCurrentPeriod: (data, dp) => {
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);

    return parseInt(parseFloat(dp / 100) * currentPeriod);
  },

  grandTotalDPAllPeriod: (data, dp) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);
    const totalAllPeriod = pastPeriod + currentPeriod;

    return parseInt(parseFloat(dp / 100) * totalAllPeriod);
  },

  // retensi
  grandTotalRetensiPastPeriod: (data, retensi) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);

    return parseInt(parseFloat(retensi / 100) * pastPeriod);
  },

  grandTotalRetensiCurrentPeriod: (data, retensi) => {
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);

    return parseInt(parseFloat(retensi / 100) * currentPeriod);
  },

  grandTotalRetensiAllPeriod: (data, retensi) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);
    const totalAllPeriod = pastPeriod + currentPeriod;

    return parseInt(parseFloat(retensi / 100) * totalAllPeriod);
  },

  // Invoice
  grandTotalInvoicePastPeriode: (data, dp, retensi) => {
    const pastPeriod = calculationHandler.grandTotalPastPeriod(data);
    const totalDP = calculationHandler.grandTotalDPPastPeriod(data, dp);
    const totalRetensi = calculationHandler.grandTotalRetensiPastPeriod(
      data,
      retensi
    );

    return parseInt(pastPeriod - (totalDP + totalRetensi));
  },

  grandTotalInvoiceCurrentPeriode: (data, dp, retensi) => {
    const currentPeriod = calculationHandler.grandTotalCurrentPeriod(data);
    const totalDP = calculationHandler.grandTotalDPCurrentPeriod(data, dp);
    const totalRetensi = calculationHandler.grandTotalRetensiCurrentPeriod(
      data,
      retensi
    );

    return parseInt(currentPeriod - (totalDP + totalRetensi));
  },

  grandTotalInvoiceAllPeriode: (data, dp, retensi) => {
    const pastPeriod = calculationHandler.grandTotalInvoicePastPeriode(
      data,
      dp,
      retensi
    );
    const currentPeriod = calculationHandler.grandTotalInvoiceCurrentPeriode(
      data,
      dp,
      retensi
    );

    return parseInt(currentPeriod + pastPeriod);
  },

  // PPN
  grandTotalPPN: (data, ppn) => {
    console.log(ppn);
    const grandTotal = calculationHandler.grandTotal(data);

    return parseInt(parseFloat(ppn / 100) * grandTotal);
  },

  grandTotalPPNPastPeriod: (data, dp, retensi, ppn) => {
    const pastPeriod = calculationHandler.grandTotalInvoicePastPeriode(
      data,
      dp,
      retensi
    );
    return parseInt(parseFloat(ppn / 100) * pastPeriod);
  },

  grandTotalPPNCurrentPeriod: (data, dp, retensi, ppn) => {
    const currentPeriod = calculationHandler.grandTotalInvoiceCurrentPeriode(
      data,
      dp,
      retensi
    );
    return parseInt(parseFloat(ppn / 100) * currentPeriod);
  },

  grandTotalPPNAllPeriod: (data, dp, retensi, ppn) => {
    const allPeriod = calculationHandler.grandTotalInvoiceAllPeriode(
      data,
      dp,
      retensi
    );
    return parseInt(parseFloat(ppn / 100) * allPeriod);
  },

  // Invoice + PPN
  grandTotalPPNWithInvoice: (data, ppn) => {
    const grandTotal = calculationHandler.grandTotal(data);
    const totalPPN = calculationHandler.grandTotalPPN(data, ppn);

    return parseInt(grandTotal + totalPPN);
  },

  grandTotalPPNWithInvoicePastPeriod: (data, dp, retensi, ppn) => {
    const pastPeriod = calculationHandler.grandTotalInvoicePastPeriode(
      data,
      dp,
      retensi
    );
    const totalPPN = calculationHandler.grandTotalPPNPastPeriod(
      data,
      dp,
      retensi,
      ppn
    );

    return parseInt(totalPPN + pastPeriod);
  },

  grandTotalPPNWithInvoiceCurrentPeriod: (data, dp, retensi, ppn) => {
    const currentPeriod = calculationHandler.grandTotalInvoiceCurrentPeriode(
      data,
      dp,
      retensi
    );
    const totalPPN = calculationHandler.grandTotalPPNCurrentPeriod(
      data,
      dp,
      retensi,
      ppn
    );

    return parseInt(totalPPN + currentPeriod);
  },

  grandTotalPPNWithInvoiceAllPeriod: (data, dp, retensi, ppn) => {
    const allPeriod = calculationHandler.grandTotalInvoiceAllPeriode(
      data,
      dp,
      retensi
    );
    const totalPPN = calculationHandler.grandTotalPPNAllPeriod(
      data,
      dp,
      retensi,
      ppn
    );

    return parseInt(totalPPN + allPeriod);
  },
};
