import React, { useState } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import {
  Table,
  Th,
  Td,
  DataStatus,
  ActionButton,
  InfoItemHorizontal,
} from 'components';
import { DateConvert } from 'utilities';

const ModalDetail = ({ show, onClose, data, title, withGudang }) => {
  const width = 200;

  return (
    <Modal size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <b>Detail Data Realisasi {title ?? ''}</b>
      </Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal
          width={width}
          label="Tgl. Transfer"
          text={
            data?.tgl_transfer
              ? DateConvert(new Date(data?.tgl_transfer)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          width={width}
          label="No. Transfer"
          text={data?.no_transfer ?? '-'}
        />
        <InfoItemHorizontal
          width={width}
          label="Item Bahan"
          text={data?.nama_item ?? '-'}
        />
        <InfoItemHorizontal
          width={width}
          label="Satuan"
          text={data?.satuan ?? '-'}
        />
        <InfoItemHorizontal
          width={width}
          label="Qty. Transfer"
          text={data?.qty_transfer ?? '-'}
        />
        {withGudang && (
          <>
            <InfoItemHorizontal
              width={width}
              label="Gudang Asal Transfer"
              text={data?.gudang_asal ?? '-'}
            />
            <InfoItemHorizontal
              width={width}
              label="Gudang Tujuan Transfer"
              text={data?.gudang_tujuan ?? '-'}
            />
          </>
        )}
        <InfoItemHorizontal
          width={width}
          label="Qty. Realisasi Periode Lalu"
          text={`${data?.qty_realisasi_lalu ?? '-'} ${data?.satuan ?? ''}`}
        />
        <InfoItemHorizontal
          width={width}
          label="Qty. Realisasi Periode Ini"
          text={`${data?.qty_realisasi ?? '-'} ${data?.satuan ?? ''}`}
        />
        <InfoItemHorizontal
          width={width}
          label="Qty. Realisasi S/D Periode Ini"
          text={`${
            parseFloat(data?.qty_realisasi_lalu ?? 0) +
            parseFloat(data?.qty_realisasi ?? 0)
          } ${data?.satuan ?? ''}`}
        />
      </Modal.Body>
      <ModalFooter>
        <ActionButton
          text="Tutup"
          variant="outline-secondary"
          onClick={onClose}
        />
      </ModalFooter>
    </Modal>
  );
};

export const TableDetailProduksi = ({ title, dataRealisasi, withGudang }) => {
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });

  return (
    <>
      <div className="mt-3 pb-2">
        <b className="p-1">List Realisasi {title ?? ''}</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th className="py-2 text-center" style={{ width: 30 }}>
              No.
            </Th>
            <Th className="p-2 text-center" style={{ minWidth: 300 }}>
              Item Bahan
            </Th>
            <Th className="p-2 text-center" style={{ width: 150 }}>
              Satuan
            </Th>
            <Th className="p-1 text-center" style={{ width: 100 }}>
              Qty. Realisasi
            </Th>
            <Th className="p-1 text-center" style={{ width: 50 }}>
              {' '}
              Aksi
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataRealisasi.length > 0 ? (
            dataRealisasi.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan}</Td>
                <Td className="text-right">{val.qty_realisasi}</Td>
                <Td className="text-center">
                  <ActionButton
                    size="sm"
                    text={<IoEyeOutline />}
                    onClick={() => setModal({ show: true, data: val })}
                  />
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <ModalDetail
        title={title}
        data={modal.data}
        show={modal.show}
        withGudang={withGudang}
        onClose={() => setModal({ show: false, data: {} })}
      />
    </>
  );
};
