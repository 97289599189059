import { createContext, useState } from 'react';

export const TransferSisaProduksiContext = createContext();

export const TransferSisaProduksiContextProvider = ({ children }) => {
  const [listTransferSisa, setListTransferSisa] = useState([]);

  return (
    <TransferSisaProduksiContext.Provider
      value={{
        listTransferSisa,
        setListTransferSisa,
      }}
    >
      {children}
    </TransferSisaProduksiContext.Provider>
  );
};

export const withTransferSisaProduksiContextProvider =
  (Component) => (props) => {
    return (
      <TransferSisaProduksiContextProvider>
        <Component {...props} />
      </TransferSisaProduksiContextProvider>
    );
  };
