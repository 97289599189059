import { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  TextArea,
  Input,
  SelectSearch,
  DatePicker,
} from 'components';
import { DateConvert } from 'utilities';
import { MutasiBarangJadiApi } from 'api';

export const ModalDataMutasiBarangJadi = ({
  modal,
  dataDropdown,
  title,
  setAlertModal,
  setAlertConfig,
  setModal,
  getInitialData,
}) => {
  const [dataInput, setDataInput] = useState({
    no_baru: '',
    qty: 0,
    kode_barang_jadi: '',
    nama_barang_jadi: '',
    id_barang_jadi: '',
    qty_terkirim: 0,
    qty_sisa: 0,
  });
  const [loadingInput, setLoadingInput] = useState({
    no_baru: false,
    qty: false,
    kode_barang_jadi: false,
    nama_barang_jadi: false,
    qty_terkirim: false,
    gudang_asal: false,
    qty_sisa: false,
  });

  const getNomorBaru = (tanggal) => {
    setLoadingInput({
      ...loadingInput,
      no_baru: true,
    });

    MutasiBarangJadiApi.no_baru({ tanggal })
      .then((res) =>
        setDataInput({
          ...dataInput,
          no_baru: res.data.data,
        })
      )
      .finally(() =>
        setLoadingInput({
          ...loadingInput,
          no_baru: false,
        })
      );
  };

  const getMutasiBarang = (id_barang) => {
    setLoadingInput({
      ...loadingInput,
      qty_terkirim: true,
      qty_sisa: true,
    });
    MutasiBarangJadiApi.mutasi_barang({ id_barang })
      .then((res) =>
        setDataInput({
          ...dataInput,
          qty_terkirim: res.data.data,
          qty_sisa: dataInput.qty - res.data.data,
        })
      )
      .catch(() =>
        setDataInput({
          ...dataInput,
          qty_terkirim: 0,
          qty_sisa: dataInput.qty,
        })
      )
      .finally(() =>
        setLoadingInput({
          ...loadingInput,
          qty_terkirim: false,
          qty_sisa: false,
        })
      );
  };

  useEffect(() => {
    if (modal.tipe === 'ubah') {
      const data_job_order = dataDropdown?.job_order?.find(
        (val) => val.no_job_order === modal.data.no_ref_transaksi
      );

      setDataInput({
        no_baru: modal.data.no_mutasi_antar_gudang,
        qty: data_job_order.qty,
        id_barang_jadi: data_job_order.id_barang_jadi,
        kode_barang_jadi: data_job_order.kode_barang_jadi,
        nama_barang_jadi: data_job_order.nama_barang_jadi,
        qty_terkirim: 0,
        qty_sisa: 0,
      });
    } else if (modal.tipe === 'tambah') {
      getNomorBaru(DateConvert(new Date()).default);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show]);

  useEffect(() => {
    dataInput.id_barang_jadi !== '' &&
      getMutasiBarang(dataInput.id_barang_jadi);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInput.id_barang_jadi]);

  const formInitialValues = {
    id_mutasi_antar_gudang: modal?.data?.id_mutasi_antar_gudang,
    tgl_mutasi_antar_gudang:
      modal?.data?.tgl_mutasi_antar_gudang !== ''
        ? DateConvert(new Date(modal.data.tgl_mutasi_antar_gudang)).default
        : DateConvert(new Date()).default,
    id_item_buaso: modal?.data?.id_item_buaso,
    id_gudang_asal: modal?.data?.id_gudang_asal
      ? modal?.data?.id_gudang_asal
      : '62',
    nama_gudang_asal: modal?.data?.nama_gudang_asal
      ? modal?.data?.nama_gudang_asal
      : 'Gudang Produksi',
    id_gudang_tujuan: modal?.data?.id_gudang_tujuan,
    nama_gudang_tujuan: modal?.data?.nama_gudang_tujuan
      ? modal?.data?.nama_gudang_tujuan
      : 'Pilih Gudang',
    qty_mutasi: modal?.data?.qty_mutasi,
    catatan_mutasi: modal?.data?.catatan_mutasi,
    no_ref_transaksi: modal?.data?.no_ref_transaksi,
    id_satuan: modal?.data?.id_satuan,
    harga_barang_jadi: modal?.data?.harga_barang_jadi,
  };

  const formValidationSchema = Yup.object().shape({
    tgl_mutasi_antar_gudang: Yup.date('Format Tanggal salah').required(
      'Tanggal Mutasi Antar Gudang wajib diisi'
    ),
    id_item_buaso: Yup.string().required('Barang Jadi tidak boleh kosong'),
    id_gudang_asal: Yup.string().required('Gudang Asal tidak boleh kosong'),
    id_gudang_tujuan: Yup.string().required('Gudang Tujuan wajib diisi'),
    qty_mutasi: Yup.number()
      .typeError('Qty Mutasi wajib diisi dengan angka')
      .required('Qty Mutasi wajib diisi'),
    no_ref_transaksi: Yup.string().required('No. Job Order wajib diisi'),
  });

  const formSubmitHandler = (values) => {
    const errorMessage = (message) => {
      setAlertModal({
        show: true,
        message,
      });
    };

    if (modal.tipe === 'tambah') {
      MutasiBarangJadiApi.create({
        tgl_mutasi_antar_gudang: DateConvert(
          new Date(values.tgl_mutasi_antar_gudang)
        ).default,
        id_item_buaso: values.id_item_buaso,
        id_gudang_asal: values.id_gudang_asal,
        id_gudang_tujuan: values.id_gudang_tujuan,
        qty_mutasi: values.qty_mutasi,
        catatan_mutasi: values.catatan_mutasi,
        no_ref_transaksi: values.no_ref_transaksi,
        id_satuan: values.id_satuan,
        harga_barang_jadi: values.harga_barang_jadi,
      })
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Tambah data berhasil!',
          })
        )
        .catch((err) => errorMessage(err.response.data.message))
        .finally(() => {
          setModal({
            ...modal,
            show: false,
            tipe: '',
          });
          getInitialData();
        });
    } else {
      MutasiBarangJadiApi.update({
        id_mutasi_antar_gudang: values.id_mutasi_antar_gudang,
        tgl_mutasi_antar_gudang: DateConvert(
          new Date(values.tgl_mutasi_antar_gudang)
        ).default,
        id_item_buaso: values.id_item_buaso,
        id_gudang_asal: values.id_gudang_asal,
        id_gudang_tujuan: values.id_gudang_tujuan,
        qty_mutasi: values.qty_mutasi,
        catatan_mutasi: values.catatan_mutasi,
        no_ref_transaksi: values.no_ref_transaksi,
        id_satuan: values.id_satuan,
        harga_barang_jadi: values.harga_barang_jadi,
      })
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Tambah data berhasil!',
          })
        )
        .catch((err) => errorMessage(err.response.data.message))
        .finally(() => {
          setModal({
            ...modal,
            show: false,
            tipe: '',
          });
          getInitialData();
        });
    }
  };

  return (
    <Modal
      show={modal.show && modal.tipe !== 'detail'}
      onHide={() => {
        setModal({
          ...modal,
          show: false,
          tipe: '',
        });

        setDataInput({
          no_baru: '',
          qty: 0,
          kode_barang_jadi: '',
          nama_barang_jadi: '',
          id_barang_jadi: '',
          qty_terkirim: 0,
          qty_sisa: 0,
        });
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="mb-0 text-capitalize">
            {modal.tipe} Data {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row>
                <Col md>
                  <DatePicker
                    label="Tgl. Mutasi"
                    placeholderText="Tgl. Mutasi"
                    selected={new Date(values.tgl_mutasi_antar_gudang)}
                    onChange={(val) => {
                      const value = DateConvert(new Date(val)).default;
                      setValues({
                        ...values,
                        tgl_mutasi_antar_gudang: value,
                      });

                      modal.tipe === 'tambah' && getNomorBaru(value);
                    }}
                    error={
                      errors.tgl_mutasi_antar_gudang &&
                      touched.tgl_mutasi_antar_gudang &&
                      true
                    }
                    errorText={
                      errors.tgl_mutasi_antar_gudang &&
                      touched.tgl_mutasi_antar_gudang &&
                      errors.tgl_mutasi_antar_gudang
                    }
                  />
                </Col>

                <Col md>
                  <Input
                    label="No. Mutasi"
                    type="text"
                    value={
                      loadingInput.no_baru ? 'Menunggu...' : dataInput.no_baru
                    }
                    readOnly={true}
                    loading={loadingInput.no_baru}
                    error={
                      errors.no_ref_transaksi &&
                      touched.no_ref_transaksi &&
                      true
                    }
                    errorText={errors.no_ref_transaksi}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <SelectSearch
                    label="No. Job Order"
                    onChange={(val) => {
                      setValues({
                        ...values,
                        no_ref_transaksi: val.no_job_order,
                        id_item_buaso: val.id_barang_jadi,
                        id_satuan: val.id_satuan,
                        harga_barang_jadi: val.harga_barang_jadi,
                        no_spk: val.no_spk,
                        nama_proyek: val.nama_proyek,
                        nama_customer: val.nama_customer,
                      });

                      setDataInput({
                        ...dataInput,
                        qty: val.qty,
                        nama_barang_jadi: val.nama_barang_jadi,
                        kode_barang_jadi: val.kode_barang_jadi,
                        id_barang_jadi: val.id_barang_jadi,
                        id_satuan: val.id_satuan,
                        harga_barang_jadi: val.harga_barang_jadi,
                      });
                    }}
                    option={dataDropdown.job_order.map((val) => {
                      return {
                        ...val,
                        value: val.no_job_order,
                        label: val.no_job_order,
                        no_job_order: val.no_job_order,
                        qty: val.qty,
                        nama_barang_jadi: val.nama_barang_jadi,
                        id_barang_jadi: val.id_barang_jadi,
                        kode_barang_jadi: val.kode_barang_jadi,
                        id_satuan: val.id_satuan,
                        harga_barang_jadi: val.harga_barang_jadi,
                      };
                    })} //option harus berupa value dan label
                    defaultValue={{
                      value: values.no_ref_transaksi,
                      label:
                        values.no_ref_transaksi === ''
                          ? 'Pilih No. Job Order'
                          : values.no_ref_transaksi,
                    }}
                    error={
                      errors.no_ref_transaksi &&
                      touched.no_ref_transaksi &&
                      true
                    }
                    errorText={errors.no_ref_transaksi}
                  />
                </Col>

                <Col md="4">
                  <Input
                    label="Qty. Job Order"
                    type="text"
                    value={dataInput.qty}
                    placeholder="0"
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <Input
                    label="Barang Jadi"
                    type="text"
                    value={`${
                      dataInput.kode_barang_jadi !== ''
                        ? `(${dataInput.kode_barang_jadi}) `
                        : ''
                    }${dataInput.nama_barang_jadi}`}
                    readOnly={true}
                    error={
                      errors.id_item_buaso && touched.id_item_buaso && true
                    }
                    errorText={errors.id_item_buaso}
                  />
                </Col>

                <Col md="4">
                  <Input
                    label="Qty. Terkirim"
                    type="text"
                    value={
                      loadingInput.qty_terkirim
                        ? 'Menunggu...'
                        : dataInput.qty_terkirim
                    }
                    placeholder="0"
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <Input
                    readOnly
                    label="No. SPK"
                    type="text"
                    value={values.no_spk}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <Input
                    readOnly
                    label="Nama Proyek"
                    type="text"
                    value={values.nama_proyek}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <Input
                    readOnly
                    label="Customer"
                    type="text"
                    value={values.nama_customer}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <Input
                    label="Gudang Asal"
                    type="text"
                    value={values.nama_gudang_asal}
                    readOnly={true}
                  />
                </Col>

                <Col md="4">
                  <Input
                    label="Qty. Sisa"
                    type="text"
                    value={
                      loadingInput.qty_sisa ? 'Menunggu...' : dataInput.qty_sisa
                    }
                    placeholder="0"
                    readOnly={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col md="8">
                  <SelectSearch
                    label="Gudang Tujuan"
                    onChange={(val) => {
                      setValues({
                        ...values,
                        id_gudang_tujuan: val.value,
                      });
                    }}
                    option={dataDropdown.gudang.map((val) => {
                      return {
                        value: val.id_gudang,
                        label: val.nama_gudang,
                      };
                    })}
                    defaultValue={{
                      value: values.id_gudang_tujuan,
                      label: values.nama_gudang_tujuan,
                    }}
                    error={
                      errors.id_gudang_tujuan &&
                      touched.id_gudang_tujuan &&
                      true
                    }
                    errorText={
                      touched.id_gudang_tujuan && errors.id_gudang_tujuan
                    }
                  />
                </Col>

                <Col md="4">
                  <Input
                    label="Qty. Mutasi"
                    type="text"
                    name="qty_mutasi"
                    value={dataInput.qty_mutasi}
                    placeholder="0"
                    onChange={handleChange}
                    error={errors.qty_mutasi && touched.qty_mutasi && true}
                    errorText={
                      errors.qty_mutasi &&
                      touched.qty_mutasi &&
                      errors.qty_mutasi
                    }
                  />
                </Col>
              </Row>

              <TextArea
                label="Catatan Mutasi"
                placeholder="Catatan Mutasi"
                name="catatan_mutasi"
                value={values.catatan_mutasi}
                onChange={handleChange}
                error={errors.catatan_mutasi && touched.catatan_mutasi && true}
                errorText={
                  errors.catatan_mutasi &&
                  touched.catatan_mutasi &&
                  errors.catatan_mutasi
                }
                rows="3"
              />
            </Modal.Body>

            <Modal.Footer>
              <ActionButton
                type="submit"
                variant="primary"
                text="Simpan"
                loading={isSubmitting}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
