import { Services } from '../../../services';

class RealisasiProduksiApi {
  get(params) {
    return Services.get('/realisasi_produksi/front_page', { params }); //get page realisasi produksi
  }

  getJo(params) {
    return Services.get('/realisasi_produksi/page', { params }); //get page realisasi produksi
  }

  getNomor(params) {
    return Services.get('/realisasi_produksi/no_baru', { params });
  }

  save(data) {
    return Services.post('/realisasi_produksi', data);
  }

  // Realisasi bahan
  getRealisasiBahan(params) {
    return Services.get('/realisasi_produksi_bahan/single', { params });
  }

  getHistoryRealisasiBahan(params) {
    return Services.get('/realisasi_produksi_bahan/history', { params });
  }

  getNomorBahan(params) {
    return Services.get('/realisasi_produksi_bahan/no_baru', { params });
  }

  saveRealisasiBahan(data) {
    return Services.post('/realisasi_produksi_bahan', data);
  }

  updateRealisasiBahan(data) {
    return Services.put('/realisasi_produksi_bahan', data);
  }

  deleteRealisasiBahan(data) {
    return Services.post('/realisasi_produksi_bahan/delete', data);
  }

  // Realisasi upah
  getRealisasiUpah(params) {
    return Services.get('/realisasi_produksi_upah/single', { params });
  }

  getHistoryRealisasiUpah(params) {
    return Services.get('/realisasi_produksi_upah/history', { params });
  }

  getNomorUpah(params) {
    return Services.get('/realisasi_produksi_upah/no_baru', { params });
  }

  saveRealisasiUpah(data) {
    return Services.post('/realisasi_produksi_upah', data);
  }

  updateRealisasiUpah(data) {
    return Services.put('/realisasi_produksi_upah', data);
  }

  deleteRealisasiUpah(data) {
    return Services.post('/realisasi_produksi_upah/delete', data);
  }

  // Realisasi subkon
  getRealisasiSubkon(params) {
    return Services.get('/realisasi_produksi_subkon/single', { params });
  }

  getHistoryRealisasiSubkon(params) {
    return Services.get('/realisasi_produksi_subkon/history', { params });
  }

  getNomorSubkon(params) {
    return Services.get('/realisasi_produksi_subkon/no_baru', { params });
  }

  saveRealisasiSubkon(data) {
    return Services.post('/realisasi_produksi_subkon', data);
  }

  updateRealisasiSubkon(data) {
    return Services.put('/realisasi_produksi_subkon', data);
  }

  deleteRealisasiSubkon(data) {
    return Services.post('/realisasi_produksi_subkon/delete', data);
  }

  // Realisasi Alat Mesin
  getRealisasiAlatMesin(params) {
    return Services.get('/realisasi_produksi_alat_mesin/single', { params });
  }

  getHistoryRealisasiAlatMesin(params) {
    return Services.get('/realisasi_produksi_alat_mesin/history', { params });
  }

  getNomorAlatMesin(params) {
    return Services.get('/realisasi_produksi_alat_mesin/no_baru', { params });
  }

  saveRealisasiAlatMesin(data) {
    return Services.post('/realisasi_produksi_alat_mesin', data);
  }

  updateRealisasiAlatMesin(data) {
    return Services.put('/realisasi_produksi_alat_mesin', data);
  }

  deleteRealisasiAlatMesin(data) {
    return Services.post('/realisasi_produksi_alat_mesin/delete', data);
  }

  // Realisasi Overhead
  getRealisasiOverhead(params) {
    return Services.get('/realisasi_produksi_overhead/single', { params });
  }

  getHistoryRealisasiOverhead(params) {
    return Services.get('/realisasi_produksi_overhead/history', { params });
  }

  getNomorOverhead(params) {
    return Services.get('/realisasi_produksi_overhead/no_baru', { params });
  }

  saveRealisasiOverhead(data) {
    return Services.post('/realisasi_produksi_overhead', data);
  }

  updateRealisasiOverhead(data) {
    return Services.put('/realisasi_produksi_overhead', data);
  }

  deleteRealisasiOverhead(data) {
    return Services.post('/realisasi_produksi_overhead/delete', data);
  }

  // Opname Upah
  getOpnameUpah(params) {
    return Services.get('/opname_upah/single', { params });
  }

  // Opname Subkon
  getOpnameSubkon(params) {
    return Services.get('/opname_subkon/single', { params });
  }

  getDropdown(params) {
    return Services.get('/realisasi_produksi/dropdown', { params });
  }
}

export default new RealisasiProduksiApi();
