import { Services } from '../../../services';

class CloseJobOrderApi {
  get(params) {
    return Services.get('/job_order/page', { params });
  }

  getSingle(params) {
    return Services.get('/job_order/single', { params });
  }

  getListBDP(params) {
    return Services.get('job_order_close/list_bdp', { params });
  }

  getNo(params) {
    return Services.get('/job_order_close/no_baru', { params });
  }

  save(data) {
    return Services.put('/job_order_close', data);
  }
}

export default new CloseJobOrderApi();
