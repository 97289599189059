export const mappingDataBahan = (rawData) =>
  rawData.map((val) => {
    return {
      id_transfer: val.id_transfer_produksi_bahan ?? '',
      tgl_transfer: val.tgl_transfer_produksi_bahan ?? '',
      no_transfer: val.no_transfer_produksi_bahan ?? '-',
      nama_item: val.nama_item_buaso ?? '-',
      satuan: val.nama_satuan ?? '-',
      qty_transfer: parseFloat(val.qty_transfer ?? 0),
      qty_realisasi: parseFloat(val.qty_realisasi ?? 0),
      qty_realisasi_lalu: parseFloat(val?.qty_realisasi_lalu ?? 0),
      gudang_asal: val.nama_gudang_asal ?? '-',
      gudang_tujuan: val.nama_gudang_tujuan ?? '-',
    };
  });

export const mappingDataHistoryBahan = (rawData) =>
  rawData.map((val) => {
    return {
      id_transfer: val.id_transfer_produksi_bahan ?? '',
      id_realisasi: val.id_realisasi_produksi_bahan ?? '',
      tgl_realisasi: val.tgl_realisasi_produksi_bahan ?? '',
      no_realisasi: val.no_realisasi_produksi ?? '-',
      tgl_transfer: val.tgl_transfer_produksi_bahan ?? '',
      no_transfer: val.no_transfer_produksi_bahan ?? '-',
      nama_item: val.nama_item ?? '-',
      satuan: val.nama_satuan ?? '-',
      qty_transfer: val.qty_transfer ? parseFloat(val.qty_transfer) : 0,
      qty_realisasi: val.qty_realisasi_produksi_bahan
        ? parseFloat(val.qty_realisasi_produksi_bahan)
        : 0,
      qty_realisasi_lalu: val.qty_realisasi_produksi_bahan_lalu
        ? parseFloat(val.qty_realisasi_produksi_bahan_lalu)
        : 0,
      gudang_asal: val.nama_gudang_asal ?? '-',
      gudang_tujuan: val.nama_gudang_tujuan ?? '-',
    };
  });

export const mappingDataAlatMesin = (rawData) =>
  rawData.map((val) => {
    return {
      id_transfer: val.id_transfer_produksi_alat_mesin,
      tgl_transfer: val.tgl_transfer_produksi_alat_mesin ?? '-',
      no_transfer: val.no_transfer_produksi_alat_mesin ?? '-',
      nama_item: val.nama_item_aset ?? '-',
      satuan: val.nama_satuan_pakai ?? '-',
      qty_transfer: val.qty_transfer_produksi_alat_mesin
        ? parseFloat(val.qty_transfer_produksi_alat_mesin)
        : 0,
      qty_realisasi_lalu: parseFloat(val?.qty_realisasi_lalu ?? 0),
      qty_realisasi: 0,
    };
  });

export const mappingDataHistoryAlatMesin = (rawData) =>
  rawData.map((val) => {
    return {
      id_realisasi: val.id_realisasi_produksi_alat_mesin,
      id_transfer: val.id_transfer_produksi_alat_mesin,
      tgl_realisasi: val.tgl_realisasi_produksi_alat_mesin ?? '-',
      no_realisasi: val.no_realisasi_produksi ?? '-',
      tgl_transfer: val.tgl_transfer_produksi_alat_mesin ?? '-',
      no_transfer: val.no_transfer_produksi_alat_mesin ?? '-',
      nama_item: val.nama_item_aset ?? '-',
      satuan: val.nama_satuan ?? '-',
      qty_transfer: val.qty_transfer_produksi_alat_mesin
        ? parseFloat(val.qty_transfer_produksi_alat_mesin)
        : 0,
      qty_realisasi: val.qty_realisasi_produksi_alat_mesin
        ? parseFloat(val.qty_realisasi_produksi_alat_mesin)
        : 0,
      qty_realisasi_lalu: val.qty_realisasi_produksi_alat_mesin_lalu
        ? parseFloat(val.qty_realisasi_produksi_alat_mesin_lalu)
        : 0,
    };
  });

export const mappingDataOverhead = (rawData) =>
  rawData.map((val) => {
    return {
      id_transfer: val.id_transfer_produksi_overhead,
      tgl_transfer: val.tgl_transfer_produksi_overhead ?? '-',
      no_transfer: val.no_transfer_produksi_overhead ?? '-',
      nama_item: val.nama_item ?? '-',
      satuan: val.nama_satuan ?? '-',
      qty_transfer: val.qty_transfer_produksi_overhead
        ? parseFloat(val.qty_transfer_produksi_overhead)
        : 0,
      qty_realisasi_lalu: parseFloat(val?.qty_realisasi_lalu ?? 0),
      qty_realisasi: 0,
    };
  });

export const mappingDataHistoryOverhead = (rawData) =>
  rawData.map((val) => {
    return {
      id_realisasi: val.id_realisasi_produksi_overhead,
      id_transfer: val.id_transfer_produksi_overhead,
      tgl_realisasi: val.tgl_realisasi_produksi_overhead ?? '-',
      no_realisasi: val.no_realisasi_produksi ?? '-',
      tgl_transfer: val.tgl_transfer_produksi_overhead ?? '-',
      no_transfer: val.no_transfer_produksi_overhead ?? '-',
      nama_item: val.nama_item ?? '-',
      satuan: val.nama_satuan ?? '-',
      qty_transfer: val.qty_transfer_produksi_overhead
        ? parseFloat(val.qty_transfer_produksi_overhead)
        : 0,
      qty_realisasi: val.qty_realisasi_produksi_overhead
        ? parseFloat(val.qty_realisasi_produksi_overhead)
        : 0,
      qty_realisasi_lalu: val.qty_realisasi_produksi_overhead_lalu
        ? parseFloat(val.qty_realisasi_produksi_overhead_lalu)
        : 0,
    };
  });
