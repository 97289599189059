import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerLC, ContainerLCDetail } from '../LabourCost';
import { ContainerLCF, ContainerLCFDetail } from '../LabourCostFinishing';

export const TabUpah = ({ readOnly = false, showHistory = true }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="lc">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="lc">
            <NavTab eventKey="lc" title="Labour Cost" />
            <NavTab eventKey="lcf" title="Labour Cost Finishing" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="lc"
              component={
                readOnly ? (
                  <ContainerLCDetail />
                ) : (
                  <ContainerLC showHistory={showHistory} />
                )
              }
            />
            <TabPane
              eventKey="lcf"
              component={
                readOnly ? (
                  <ContainerLCFDetail />
                ) : (
                  <ContainerLCF showHistory={showHistory} />
                )
              }
            />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
