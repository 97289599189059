import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { RealisasiProduksiApi } from 'api';
import { ModalFilterJobOrder } from './Section';

const ListJobOrderRealisasiProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJO, setDataJO] = useState([]);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_jo_awal: undefined,
    tgl_jo_akhir: undefined,
    nama_customer: undefined,
    no_spk: undefined,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RealisasiProduksiApi.getJo({
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_jo_awal: pagination.tgl_jo_awal,
      tgl_jo_akhir: pagination.tgl_jo_akhir,
      nama_customer: pagination.nama_customer,
      no_spk: pagination.no_spk,
    })
      .then(({ data }) => {
        setDataJO(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch(() =>
        setAlertConfig({ variant: 'danger', text: 'Data gagal dimuat!' })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState?.alert);
    }
  };

  useEffect(() => {
    setNavbarTitle('Realisasi Produksi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_jo_awal,
    pagination.tgl_jo_akhir,
    pagination.nama_customer,
    pagination.no_spk,
  ]);

  const PageContent = () => {
    return (
      <>
        <div className="p-2">
          <b>List Job Order Yang Siap Direalisasi</b>
        </div>

        {dataJO?.length > 0 ? (
          <Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Informasi SPK</ThFixed>
                <ThFixed>Informasi RAP</ThFixed>
                <ThFixed>Informasi Job Order</ThFixed>
                <Th style={{ minWidth: '200px' }}>Customer</Th>
                <Th style={{ minWidth: '400px' }}>Barang Jadi</Th>
                <Th style={{ minWidth: '100px' }}>Qty Job Order</Th>
                <Th className="p-0" style={{ width: 120 }}>
                  Qty. yang Telah Terealisasi
                </Th>
                <Th>Aksi</Th>
              </Tr>
            </THead>
            <TBody>
              {dataJO.map((val, index) => (
                <Tr key={index}>
                  <TdFixed textCenter>
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_spk
                        ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val?.no_spk ?? '-'}</div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_rap
                        ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val?.no_rap ?? '-'}</div>
                  </TdFixed>
                  <TdFixed>
                    <div>
                      {val.tgl_job_order
                        ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val?.no_job_order ?? '-'}</div>
                  </TdFixed>
                  <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
                  <Td>{val.nama_barang_jadi ? val.nama_barang_jadi : '-'}</Td>
                  <Td>
                    {val?.qty_rap
                      ? `${parseFloat(val.qty_rap)} ${val.kode_satuan}`
                      : '-'}
                  </Td>
                  <Td>
                    {val?.qty_realisasi_sum
                      ? `${parseFloat(val.qty_realisasi_sum)} ${
                          val.kode_satuan
                        }`
                      : '-'}
                  </Td>
                  <Td>
                    <ActionButton
                      size="sm"
                      text="Realisasi"
                      className="col"
                      onClick={() =>
                        history.push({
                          pathname:
                            '/transaksi/realisasi-produksi/realisasi/' +
                            val.id_job_order,
                          state: {
                            dataJo: val,
                          },
                        })
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        ) : (
          <DataStatus text="Tidak ada data" />
        )}

        {dataJO?.length > 0 && (
          <Pagination
            dataLength={pagination.per_page}
            dataNumber={
              pagination.page * pagination.per_page - pagination.per_page + 1
            }
            dataPage={
              pagination.data_count < pagination.per_page
                ? pagination.data_count
                : pagination.page * pagination.per_page
            }
            dataCount={pagination.data_count}
            currentPage={pagination.page}
            totalPage={pagination.total_page}
            onPaginationChange={({ selected }) =>
              setPagination({
                ...pagination,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPagination({
                ...pagination,
                page: 1,
                per_page: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-1">
            <Col className="d-flex flex-row justify-content-start align-items-center">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(
                  (e) =>
                    setPagination((prv) => ({
                      ...prv,
                      q: e.target.value,
                      page: 1,
                    })),
                  1500
                )}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {showFilter && (
        <ModalFilterJobOrder
          show={showFilter}
          setShow={setShowFilter}
          filter={pagination}
          setFilter={setPagination}
        />
      )}
    </CRUDLayout>
  );
};

export default ListJobOrderRealisasiProduksi;
