import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  InputSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  ReadButton,
  CRUDLayout,
  DataStatus,
  Pagination,
  TextArea,
  TdFixed,
  ThFixed,
  THead,
  Input,
  TBody,
  Alert,
  Tr,
  Th,
  Td,
} from 'components';
import { TableNumber } from 'utilities';
import { DeskripsiBahanBakuApi } from 'api';

const PartKayu = ({ setNavbarTitle }) => {
  const title = 'Deskripsi Bahan Baku';
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    DeskripsiBahanBakuApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };
  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
      setData([]);
    };
  }, [
    setNavbarTitle,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const formInitialValues = {
    id_deskripsi_bahan_baku: processedData?.id_deskripsi_bahan_baku ?? '',
    nama_deskripsi_bahan_baku: processedData?.nama_deskripsi_bahan_baku ?? '',
    keterangan: processedData?.keterangan ?? '',
  };
  const formValidationSchema = Yup.object().shape({
    nama_deskripsi_bahan_baku: Yup.string().required(
      'Masukkan Nama Deskripsi Bahan Baku'
    ),
  });
  const formSubmitHandler = (values, { setSubmitting }) => {
    const create = () => {
      const finalValues = {
        nama_deskripsi_bahan_baku: values.nama_deskripsi_bahan_baku,
        keterangan: values.keterangan,
      };

      DeskripsiBahanBakuApi.create(finalValues)
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambahkan!',
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Gagal menambah data!',
          })
        )
        .finally(() => {
          setSubmitting(false);
          setProcessedData({});
          setModalConfig({ ...modalConfig, show: false });
          getInitialData();
        });
    };

    const update = () => {
      DeskripsiBahanBakuApi.update(values)
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Gagal menyimpan data!',
          })
        )
        .finally(() => {
          setSubmitting(false);
          setProcessedData({});
          setModalConfig({ ...modalConfig, show: false });
          getInitialData();
        });
    };

    if (modalConfig.type === 'tambah') {
      create();
    } else if (modalConfig.type === 'update') {
      update();
    }
  };

  const PageModal = () => {
    // MODAL TITLE
    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === 'detail' ? (
        <span className="text-primary">Detail Deskripsi Bahan Baku</span>
      ) : modalConfig.type.toLowerCase() === 'update' ? (
        <span className="text-success">Ubah Deskripsi Bahan Baku</span>
      ) : modalConfig.type.toLowerCase() === 'delete' ? (
        <span className="text-danger">Hapus Deskripsi Bahan Baku</span>
      ) : (
        <span className="text-primary">Tambah Deskripsi Bahan Baku</span>
      );

    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);
      const deleteDataHandler = () => {
        setIsDeleting(true);

        DeskripsiBahanBakuApi.delete({
          id_deskripsi_bahan_baku: processedData.id_deskripsi_bahan_baku,
        })
          .then(() =>
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: 'Data berhasil dihapus!',
            })
          )
          .catch(() =>
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal dihapus!',
            })
          )
          .finally(() => {
            setIsDeleting(false);
            setProcessedData({});
            setModalConfig({ ...modalConfig, show: false });
            getInitialData();
          });
      };
      return (
        <Modal.Body className="text-center">
          <h6>
            <span>{`Hapus Data ${title}:`}</span>
            <br />
            <b>{processedData.nama_deskripsi_bahan_baku}</b>
          </h6>
          <small className="text-danger">
            Data yang dihapus tidak dapat dikembalikan!
          </small>
          <div className="d-flex justify-content-center mt-3">
            <ActionButton
              variant="outline-secondary"
              className="m-1"
              text="Batal"
              onClick={() => {
                setModalConfig({ ...modalConfig, show: false });
                setProcessedData({});
              }}
            />
            <ActionButton
              variant="danger"
              className="m-1"
              text="Hapus Data"
              loading={isDeleting}
              onClick={deleteDataHandler}
            />
          </div>
        </Modal.Body>
      );
    };

    // BODY MODAL DETAIL
    const ModalDetail = () => {
      const ShowData = ({ title, value, line }) => (
        <div>
          <small
            className="text-secondary"
            style={{ opacity: 0.8, textTransform: 'capitalize' }}
          >
            {title}
          </small>
          <div className="text-dark">
            <small>{value ?? '-'}</small>
          </div>
          {line && <hr className="my-2" />}
        </div>
      );

      return (
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <ShowData
                title="Nama Deskripsi Bahan Baku"
                value={processedData.nama_deskripsi_bahan_baku}
                line={true}
              />
              <ShowData
                title="Keterangan"
                value={processedData.keterangan}
                line={true}
              />
            </Col>
          </Row>
        </Modal.Body>
      );
    };

    // BODY MODAL FORM
    const ModalForm = ({ formik }) => {
      const {
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleSubmit,
      } = formik;

      return (
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md>
                <Input
                  label="Nama Deskripsi Bahan Baku"
                  placeholder="Masukkan Nama Deskripsi Bahan Baku"
                  value={values.nama_deskripsi_bahan_baku}
                  onChange={(e) =>
                    setFieldValue('nama_deskripsi_bahan_baku', e.target.value)
                  }
                  error={
                    errors.nama_deskripsi_bahan_baku &&
                    touched.nama_deskripsi_bahan_baku &&
                    true
                  }
                  errorText={errors.nama_deskripsi_bahan_baku}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  label="Keterangan"
                  placeholder="Masukkan Keterangan"
                  value={values.keterangan}
                  onChange={(e) => setFieldValue('keterangan', e.target.value)}
                />
              </Col>
            </Row>
            <div className="mt-2 d-flex justify-content-end">
              <ActionButton
                onClick={handleSubmit}
                loading={isSubmitting}
                text={
                  modalConfig.type.toLowerCase() === 'update'
                    ? 'Ubah'
                    : 'Tambah'
                }
                variant={
                  modalConfig.type.toLowerCase() === 'update'
                    ? 'success'
                    : 'primary'
                }
              />
            </div>
          </form>
        </Modal.Body>
      );
    };

    return (
      <Modal
        size="md"
        show={modalConfig.show}
        onHide={() => {
          setModalConfig({ ...modalConfig, show: false });
          setProcessedData({});
        }}
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        {modalConfig.type === 'detail' ? (
          <ModalDetail />
        ) : modalConfig.type === 'delete' ? (
          <ModalDelete />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {(formik) => <ModalForm formik={formik} />}
          </Formik>
        )}
      </Modal>
    );
  };

  const DataTable = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Nama Deskripsi Bahan Baku</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => {
            return (
              <Tr key={val.id_item_buaso}>
                <TdFixed>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start align-items-center">
                    <ReadButton
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({ show: true, type: 'detail' });
                      }}
                    />
                    <UpdateButton
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({ show: true, type: 'update' });
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setModalConfig({ show: true, type: 'delete' });
                        setProcessedData(val);
                      }}
                    />
                  </div>
                </TdFixed>
                <Td>{val.nama_deskripsi_bahan_baku ?? '-'}</Td>
                <Td>{val.keterangan ?? '-'}</Td>
              </Tr>
            );
          })}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={paginationConfig.dataLength}
        dataNumber={
          paginationConfig.page * paginationConfig.dataLength -
          paginationConfig.dataLength +
          1
        }
        dataPage={
          paginationConfig.dataCount < paginationConfig.dataLength
            ? paginationConfig.dataCount
            : paginationConfig.page * paginationConfig.dataLength
        }
        dataCount={paginationConfig.dataCount}
        currentPage={paginationConfig.page}
        totalPage={paginationConfig.totalPage}
        onPaginationChange={({ selected }) =>
          setPaginationConfig({
            ...paginationConfig,
            page: selected + 1,
          })
        }
        onDataLengthChange={(e) =>
          setPaginationConfig({
            ...paginationConfig,
            page: 1,
            dataLength: e.target.value,
          })
        }
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: 'primary',
                    text: 'Hasil dari pencarian: ' + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => setModalConfig({ show: true, type: 'tambah' })}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {isPageLoading ? (
        <DataStatus loading={isPageLoading} text="Memuat Data" />
      ) : data.length > 0 ? (
        <DataTable />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  );
};

export default PartKayu;
