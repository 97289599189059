import { Services } from '../../../services';

class TipeBarangJadiApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/tipe_barang_jadi/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getSingle(id_tipe_barang_jadi) {
    return Services.get(
      `/tipe_barang_jadi/single?id_tipe_barang_jadi=${id_tipe_barang_jadi}`
    );
  }
  // GET DROPDOWN ITEM PERLU PARAMS 'tipe' dengan value 'bop / fs / lc / mp'
  getDropdownItem(tipe = 'fs') {
    return Services.get(`/tipe_barang_jadi/dropdown?tipe=${tipe}`);
  }
  getDropdownKelompokTipeBarangJadi() {
    return Services.get('/kelompok_tipe_barang_jadi/dropdown');
  }
  create(data) {
    return Services.post('/tipe_barang_jadi', data);
  }
  // API UNTUK UPDATE MASTER DATA TIPE BARANG JADI
  update(data) {
    return Services.put('/tipe_barang_jadi', data);
  }
  delete(id_tipe_barang_jadi) {
    return Services.post('/tipe_barang_jadi/delete', id_tipe_barang_jadi);
  }
  search(key) {
    return Services.get(`/tipe_barang_jadi/page/?q=${key}`);
  }
  show(id_tipe_barang_jadi) {
    return Services.put('/tipe_barang_jadi/show', id_tipe_barang_jadi);
  }
  hide(id_tipe_barang_jadi) {
    return Services.put('/tipe_barang_jadi/hide', id_tipe_barang_jadi);
  }
  simpanDetailTipeBarangjadi(data) {
    return Services.post('/tipe_barang_jadi/detail', data);
  }
  // UPDATE QTY PADA ITEM DETAIL TIPE BARANG JADI
  simpanQtyDetailTipeBarangjadi(data) {
    return Services.put('/tipe_barang_jadi/detail', data);
  }
  // HAPUS ITEM DETAIL TIPE BARANG JADI
  deleteDetailTipeBarangJadi(data) {
    return Services.post('/tipe_barang_jadi/detail_delete', data);
  }
}

export default new TipeBarangJadiApi();
