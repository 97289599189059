import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';
import htmlParser from 'html-react-parser';

const InfoSection = ({ dataSPK }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{value ? value : '-'}</td>
    </tr>
  );

  const TextEditor = ({ title, value }) => (
    <div className="mt-3" style={{ fontSize: 14 }}>
      <div>{title}</div>
      <div>{value}</div>
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  dataSPK.tgl_penawaran
                    ? DateConvert(new Date(dataSPK.tgl_penawaran)).detail
                    : '-'
                }
              />
              <InfoItem title="No. Penawaran" value={dataSPK.no_penawaran} />
              <InfoItem title="Customer" value={dataSPK.nama_customer} />
              <InfoItem title="ATT" value={dataSPK.att} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Selesai"
                value={
                  dataSPK.tgl_selesai_spk
                    ? DateConvert(new Date(dataSPK.tgl_selesai_spk)).detail
                    : '-'
                }
              />
              <InfoItem
                title="Tgl. SPK"
                value={
                  dataSPK.tgl_spk
                    ? DateConvert(new Date(dataSPK.tgl_spk)).detail
                    : '-'
                }
              />
              <InfoItem title="No. SPK" value={dataSPK.no_spk} />
              <InfoItem title="Proyek" value={dataSPK.nama_proyek} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <TextEditor
        title="Catatan :"
        value={dataSPK.catatan ? htmlParser(dataSPK.catatan) : '-'}
      />
      <hr />
    </>
  );
};

export default InfoSection;
