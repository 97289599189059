import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableSF } from './TableSF';

export const ContainerSF = ({ showHistory }) => {
  const { dataSF } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableSF
        jobOrder
        showTotalPrice
        title="List Subcon Finishing Dari Job Order"
        data={dataSF.jobOrder}
      />
      <TableSF
        showInputRow
        showTotalPrice
        title="List Subcon Finishing"
        data={dataSF.permintaan}
      />
      {showHistory && (
        <TableSF
          readOnly
          title="History Permintaan Subcon Finishing"
          data={dataSF.history}
        />
      )}
    </Row>
  );
};
