import { Services } from '../../../services';

class JobOrderApi {
  get(params) {
    return Services.get('/job_order/page', { params });
  }

  getRap(params) {
    return Services.get('/job_order/rap', { params });
  }

  getSingle(params) {
    return Services.get('/job_order/single', { params });
  }

  getSingleSPK(params) {
    return Services.get('/job_order/spk', { params });
  }

  getSingleRap(params) {
    return Services.get('/job_order/single_rap', { params });
  }

  getNo(params) {
    return Services.get('/job_order/no_baru', { params });
  }

  delete(data) {
    return Services.post('/job_order/delete', data);
  }

  save(data) {
    return Services.post('/job_order', data);
  }

  update(data) {
    return Services.put('/job_order', data);
  }

  // Barang Jadi
  getDropdownTipeFinishing() {
    return Services.get('/analisa_barang_jadi/d_finishing');
  }
}

export default new JobOrderApi();
