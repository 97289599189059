import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableBPF } from './TableBPF';

export const ContainerBPF = ({ showHistory }) => {
  const { dataBPF } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableBPF
        jobOrder
        showTotalPrice
        title="List Bahan Penunjang Finishing Dari Job Order"
        data={dataBPF.jobOrder}
      />
      <TableBPF
        showInputRow
        showTotalPrice
        title="List Bahan Penunjang Finishing"
        data={dataBPF.permintaan}
      />
      {showHistory && (
        <TableBPF
          readOnly
          title="History Permintaan Bahan Penunjang Finishing"
          data={dataBPF.history}
        />
      )}
    </Row>
  );
};
