import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  Input,
  InputCurrency,
  SelectSearch,
  Td,
  Tr,
} from 'components';
import { DropdownPermintaanProduksiContext } from '../../Context';

export const InputMP = ({ type = 'create', data, onSubmit, onCancel }) => {
  const { dropdownTPMP } = useContext(DropdownPermintaanProduksiContext);
  const isCreate = type === 'create';

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        setValues,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Tr>
            <Td></Td>

            <Td colSpan={2}>
              <SelectSearch
                placeholder="Pilih Item Machine Process"
                name="id_item_buaso"
                option={dropdownTPMP?.data ?? []}
                loading={dropdownTPMP.isLoading}
                defaultValue={dropdownTPMP?.data?.find(
                  ({ id_item_buaso }) => values.id_item_buaso === id_item_buaso
                )}
                onChange={(val) =>
                  setValues((prev) => ({
                    ...prev,
                    id_item_buaso: val.id_item_buaso,
                    nama_item: val.nama_item,
                    kode_item_bahan: val.kode_item_bahan,
                    nama_satuan: val.nama_satuan,
                    unit_price: parseFloat(val.harga_satuan),
                  }))
                }
                error={errors.id_item_buaso && touched.id_item_buaso}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                className="text-right"
                name="qty"
                value={values.qty}
                onChange={(value) => setFieldValue('qty', value)}
                error={errors.qty && touched.qty}
              />
            </Td>

            <Td>
              <Input readOnly name="nama_satuan" value={values.nama_satuan} />
            </Td>

            <Td>
              <InputCurrency
                className="text-right"
                name="unit_price"
                value={parseFloat(values.unit_price)}
                onChange={(value) => setFieldValue('unit_price', value)}
                error={errors.unit_price && touched.unit_price}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                className="text-right"
                name="konstanta"
                decimalScale={6}
                value={values.konstanta}
                onChange={(value) => setFieldValue('konstanta', value)}
                error={errors.konstanta && touched.konstanta}
              />
            </Td>

            <Td>
              <InputCurrency
                readOnly
                className="text-right"
                name="subtotal"
                value={calcSubtotalMP(values)}
              />
            </Td>

            <Td>
              {isCreate ? (
                <ActionButton
                  size="sm"
                  variant="primary"
                  className="btn-block"
                  text={<IoAddOutline />}
                  onClick={handleSubmit}
                />
              ) : (
                <ButtonGroup>
                  <ActionButton
                    size="sm"
                    variant="outline-success"
                    text={<IoCheckmarkOutline />}
                    onClick={handleSubmit}
                  />
                  <ActionButton
                    size="sm"
                    variant="outline-danger"
                    text={<IoCloseOutline />}
                    onClick={onCancel}
                  />
                </ButtonGroup>
              )}
            </Td>
          </Tr>
        );
      }}
    </Formik>
  );
};

const formInitialValues = (data) => ({
  id_item_buaso: data?.id_item_buaso ?? '',
  kode_item_bahan: data?.kode_item_bahan ?? '',
  nama_item: data?.nama_item ?? '',
  qty: parseFloat(data?.qty ?? 0),
  nama_satuan: data?.nama_satuan ?? '',
  unit_price: data?.unit_price ?? 0,
  konstanta: parseFloat(data?.konstanta ?? 1),
});

const validationSchema = yup.object().shape({
  id_item_buaso: yup.string().required(),
  qty: yup.string().required(),
  unit_price: yup.string().required(),
  konstanta: yup.string().required(),
});

export const calcSubtotalMP = (data) => {
  return Math.round(
    parseFloat(data.qty).toFixed(6) *
      parseFloat(data.unit_price) *
      parseFloat(data.konstanta).toFixed(6)
  );
};
