import React, { useState, useEffect } from 'react';
import { Modal, ModalFooter, Col, Row } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import {
  Table,
  Th,
  Td,
  DataStatus,
  ActionButton,
  InfoItemHorizontal,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { RealisasiProduksiApi } from 'api';
import { calculationHandler } from '../Utils';

const ModalDetail = ({ show, onClose, data, title, type }) => {
  const [dataDetail, setDataDetail] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [fetchStatus, setFetchStatus] = useState({
    loading: true,
    success: false,
  });

  const getInitialData = () => {
    const fetch = async () =>
      type === 'upah'
        ? await RealisasiProduksiApi.getOpnameUpah({
            id_kontrak_upah: data?.id_kontrak_upah,
            id_realisasi_produksi_upah: data?.id_realisasi_produksi_upah,
          })
        : await RealisasiProduksiApi.getOpnameSubkon({
            id_kontrak_subkon: data?.id_kontrak_subkon,
            id_realisasi_produksi_subkon: data?.id_realisasi_produksi_subkon,
          });

    return fetch()
      .then((res) => {
        const data = res?.data?.data ?? {};
        setDataDetail(data);
        setDataTable(data?.detail ?? []);
        setFetchStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    show && getInitialData();

    return () => {
      setDataDetail({});
      setDataTable([]);
      setFetchStatus({ loading: true, success: false });
    };
  }, [show]);

  if (fetchStatus.loading && !fetchStatus.success) {
    return (
      <Modal size="xl" show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <b>Detail Data Realisasi {title ?? ''}</b>
        </Modal.Header>
        <Modal.Body>
          <DataStatus
            loading={fetchStatus.loading}
            text={
              fetchStatus.loading ? 'Memuat data . . .' : 'Data gagal dimuat'
            }
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal size="xl" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <b>Detail Data Realisasi {title ?? ''}</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <InfoItemHorizontal
              label="Vendor"
              text={dataDetail?.nama_vendor ?? '-'}
            />
            <InfoItemHorizontal
              label="Tgl. Realisasi Produksi"
              text={
                dataDetail?.tgl_realisasi
                  ? DateConvert(new Date(dataDetail.tgl_realisasi)).detail
                  : '-'
              }
            />
          </Col>
          <Col>
            <InfoItemHorizontal
              label="Proyek"
              text={dataDetail?.nama_proyek ?? '-'}
            />
            <InfoItemHorizontal
              label="Item Barang Jadi"
              text={dataDetail?.nama_barang_jadi ?? '-'}
            />
          </Col>
        </Row>
        <hr />
        <div className="pb-2">
          <b className="p-1">Detail Opname</b>
        </div>
        <Table responsive>
          <thead className="bg-light">
            <tr>
              <Th
                rowSpan={2}
                className="py-2 text-center"
                style={{ width: 30 }}
              >
                No.
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ minWidth: 150 }}
              >
                Uraian
              </Th>
              <Th rowSpan={2} className="p-2 text-center" style={{ width: 80 }}>
                Volume
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ width: 150 }}
              >
                Harga Satuan
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ width: 150 }}
              >
                Jumlah
              </Th>
              <Th colSpan={6} className="p-0 text-center">
                Realisasi Pembayaran
              </Th>
            </tr>
            <tr>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                Periode Lalu
              </Th>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                Periode Ini
              </Th>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                S/D Periode Ini
              </Th>
            </tr>
          </thead>
          <tbody>
            {!fetchStatus.loading &&
            fetchStatus.success &&
            dataTable?.length > 0 ? (
              <>
                {dataTable.map((val, index) => (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>{val?.uraian ?? ''}</Td>
                    <Td className="text-right">
                      {val?.qty ?? ''} {val?.kode_satuan ?? ''}
                    </Td>
                    <Td className="text-right">
                      {val?.unit_price
                        ? RupiahConvert(String(parseInt(val.unit_price))).detail
                        : ''}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          parseInt(
                            calculationHandler.total(val.qty, val.unit_price)
                          ).toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {val.persentase_periode_lalu ?? 0}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler
                            .afterPercent(
                              val.persentase_periode_lalu,
                              calculationHandler.total(val.qty, val.unit_price)
                            )
                            .toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">{val.persentase_periode_ini}</Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler
                            .afterPercent(
                              val.persentase_periode_ini,
                              calculationHandler.total(val.qty, val.unit_price)
                            )
                            .toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {parseInt(val?.persentase_periode_lalu ?? 0) +
                        parseInt(val?.persentase_periode_ini ?? 0)}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler.totalAllPeriod(val).toString()
                        ).detail
                      }
                    </Td>
                  </tr>
                ))}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>GRAND TOTAL</b>
                  </Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler.grandTotal(dataTable).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalPastPeriod(dataTable)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalCurrentPeriod(dataTable)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalAllPeriod(dataTable)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                </tr>
                {/* total dp */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>DP ({parseFloat(dataDetail.dp ?? 0).toPrecision()} %)</b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPPastPeriod(dataTable, dataDetail.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPCurrentPeriod(dataTable, dataDetail.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPAllPeriod(dataTable, dataDetail.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                </tr>

                {/* total Retensi */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>
                      RETENSI (
                      {parseFloat(dataDetail.retensi ?? 0).toPrecision()} %)
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiPastPeriod(
                              dataTable,
                              dataDetail.retensi
                            )
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiCurrentPeriod(
                              dataTable,
                              dataDetail.retensi
                            )
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {dataTable
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiAllPeriod(
                              dataTable,
                              dataDetail.retensi
                            )
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                </tr>

                {/* total Invoice */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>INVOICE</b>
                  </Td>
                  <Td className="text-right">
                    {/* {dataTable
                      ? RupiahConvert(calculationHandler.grandTotal(dataTable).toString()).detail
                      : RupiahConvert("0").detail} */}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoicePastPeriode(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoiceCurrentPeriode(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoiceAllPeriode(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                </tr>

                {/* total PPN */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>
                      PPN ({parseFloat(dataDetail.ppn ?? 0).toPrecision()} %)
                    </b>
                  </Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPN(
                              dataTable,
                              dataDetail.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNPastPeriod(
                              dataTable,
                              dataDetail.dp,
                              dataDetail.retensi,
                              dataDetail.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNCurrentPeriod(
                              dataTable,
                              dataDetail.dp,
                              dataDetail.retensi,
                              dataDetail.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataDetail.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {dataTable
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNAllPeriod(
                              dataTable,
                              dataDetail.dp,
                              dataDetail.retensi,
                              dataDetail.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                </tr>

                {/* PPN + INVOICE */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>
                      INVOICE + PPN (
                      {parseFloat(dataDetail.ppn ?? 0).toPrecision()} %)
                    </b>
                  </Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoice(
                                dataTable,
                                dataDetail.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoicePastPeriod(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi,
                                dataDetail.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoiceCurrentPeriod(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi,
                                dataDetail.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {dataTable
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoiceAllPeriod(
                                dataTable,
                                dataDetail.dp,
                                dataDetail.retensi,
                                dataDetail.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                </tr>
              </>
            ) : (
              <tr>
                <Td colSpan={11}>
                  <DataStatus
                    loading={fetchStatus.loading}
                    text={
                      fetchStatus.loading
                        ? 'Memuat data . . .'
                        : fetchStatus.success
                        ? 'Tidak ada data'
                        : 'Data gagal dimuat'
                    }
                  />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <ModalFooter>
        <ActionButton
          text="Tutup"
          variant="outline-secondary"
          onClick={onClose}
        />
      </ModalFooter>
    </Modal>
  );
};

export const TableDetailOpname = ({ title, dataRealisasi, type }) => {
  const [modal, setModal] = useState({
    shwo: '',
    data: {},
  });

  return (
    <>
      <div className="pb-2">
        <b className="p-1">Opname {title ?? ''} </b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th className="py-2 text-center" style={{ width: 30 }}>
              No.
            </Th>
            <Th className="text-center" style={{ minWidth: 200 }}>
              Vendor
            </Th>
            <Th className="text-center" style={{ minWidth: 200 }}>
              Rincian Pekerjaan
            </Th>
            <Th className="text-center" style={{ width: 160 }}>
              Total Upah Kerja
            </Th>
            <Th className="text-center" style={{ width: 160 }}>
              Pembayaran Periode Ini
            </Th>
            <Th className="p-1 text-center" style={{ width: 40 }}>
              Aksi
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataRealisasi && dataRealisasi?.length > 0 ? (
            dataRealisasi.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>{val?.nama_vendor ?? ''}</Td>
                <Td>
                  {val?.rincian?.map((val, i) => (
                    <span className="d-block" key={i}>
                      - {val?.uraian ?? ''}
                    </span>
                  ))}
                </Td>
                <Td className="text-right">
                  {val?.rincian
                    ? RupiahConvert(
                        calculationHandler.grandTotal(val.rincian).toString()
                      ).detail
                    : RupiahConvert('0').detail}
                </Td>
                <Td className="text-right">
                  {val?.rincian
                    ? RupiahConvert(
                        calculationHandler
                          .grandTotalCurrentPeriod(val.rincian)
                          .toString()
                      ).detail
                    : RupiahConvert('0').detail}
                </Td>
                <Td className="text-center">
                  <ActionButton
                    size="sm"
                    text={<IoEyeOutline />}
                    onClick={() => setModal({ show: true, data: val })}
                  />
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <ModalDetail
        title={title}
        data={modal.data}
        show={modal.show}
        type={type}
        onClose={() => setModal({ show: false, data: {} })}
      />
    </>
  );
};
