import { Services } from '../../../services';

class TemplatePenunjangProduksiApi {
  get(params) {
    return Services.get('/template_penunjang_produksi/page/', { params });
  }
  getDropdown(params) {
    return Services.get('/template_penunjang_produksi/dropdown', { params });
  }
  getSingle(params) {
    return Services.get('/template_penunjang_produksi/single', { params });
  }
  create(data) {
    return Services.post('/template_penunjang_produksi', data);
  }
  update(data) {
    return Services.put('/template_penunjang_produksi', data);
  }
  delete(id_deskripsi_bahan_baku) {
    return Services.post(
      '/template_penunjang_produksi/delete',
      id_deskripsi_bahan_baku
    );
  }
}

export default new TemplatePenunjangProduksiApi();
