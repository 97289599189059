import { useContext } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ActionButton, Input, InputCurrency, SelectSearch } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TransferSisaProduksiApi } from 'api';
import {
  DropdownTransferSisaProduksiContext,
  TransferSisaProduksiContext,
} from '../Contexts';

export const ModalFormTransferSisaProduksi = ({
  data,
  action,
  show,
  onHide,
  onClickShowFormBarang,
}) => {
  const { setListTransferSisa } = useContext(TransferSisaProduksiContext);
  const { dropdownKelompok, dropdownJenis, dropdownGudang } = useContext(
    DropdownTransferSisaProduksiContext
  );

  const initialValues = {
    id_kelompok: data?.id_kelompok ?? '',
    id_jenis: data?.id_jenis ?? '',
    id_item_buaso: data?.id_item_buaso ?? '',
    nama_item: data?.nama_item ?? '',
    nama_satuan: data?.nama_satuan ?? '',
    qty_transfer: data?.qty_transfer ?? '',
    id_gudang_asal: '62',
    id_gudang_tujuan: data?.id_gudang_tujuan ?? '',
    nama_gudang_tujuan: data?.nama_gudang_tujuan ?? '',
    nilai_persediaan: data?.nilai_persediaan ?? '',
  };

  const validationSchema = yup.object().shape({
    id_kelompok: yup.string().required('Pilih Kelompok'),
    id_jenis: yup.string().required('Pilih Jenis'),
    id_item_buaso: yup.string().required('Pilih Barang Jadi'),
    id_gudang_tujuan: yup.string().required('Pilih Gudang Tujuan'),
    qty_transfer: yup.string().required('Masukkan Qty. Transfer Sisa Produksi'),
    nilai_persediaan: yup
      .string()
      .required('Masukkan Nilai Persediaan Per Satuan'),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    if (action === 'create') {
      setListTransferSisa((prev) => prev.toSpliced(prev.length, 0, values));
    }

    if (action === 'update') {
      setListTransferSisa((prev) => prev.toSpliced(data.index, 1, values));
    }

    resetForm();
    onHide();
  };

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const optJenis =
    dropdownJenis?.data?.filter((j) => j.id_kelompok === values.id_kelompok) ??
    [];

  const dropdownItemBahan = useQuery(
    ['dropdown_bahan', 'transfer_sisa_produksi', values.id_jenis],
    () =>
      TransferSisaProduksiApi.dropdown({
        tipe: 'bahan',
        id_jenis: values.id_jenis,
      }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_item_buaso,
            label: v.nama_item ?? '',
            nama_satuan: v.satuan_pakai ?? '',
          })) ?? []
      )
  );

  return (
    <Modal
      show={show}
      size="md"
      onHide={() => {
        onHide();
        resetForm();
      }}
    >
      <Modal.Header closeButton>Tambah Transfer Sisa Produksi</Modal.Header>
      <Modal.Body>
        <SelectSearch
          label="Kelompok Bahan"
          placeholder="Pilih Kelompok Bahan"
          option={dropdownKelompok?.data ?? []}
          loading={dropdownKelompok.isLoading}
          defaultValue={
            values.id_kelompok
              ? dropdownKelompok.data.find(
                  (k) => k.value === values.id_kelompok
                )
              : null
          }
          onChange={({ value, label }) =>
            setValues((prev) => ({
              ...prev,
              id_kelompok: value,
              nama_kelompok: label,
              id_jenis: '',
              nama_jenis: '',
              id_item_buaso: '',
              nama_item: '',
              nama_satuan: '',
            }))
          }
          error={errors.id_kelompok && touched.id_kelompok}
          errorText={errors.id_kelompok}
        />

        <SelectSearch
          key={values.id_kelompok}
          isDisabled={!values.id_kelompok}
          label="Jenis Bahan"
          loading={dropdownJenis.isLoading}
          defaultValue={
            values.id_jenis
              ? dropdownJenis.data.find((j) => j.value === values.id_jenis)
              : null
          }
          placeholder="Pilih Jenis Bahan"
          option={optJenis}
          onChange={({ value, label }) =>
            setValues((prev) => ({
              ...prev,
              id_jenis: value,
              nama_jenis: label,
              id_item_buaso: '',
              nama_item: '',
              nama_satuan: '',
            }))
          }
          error={errors.id_jenis && touched.id_jenis}
          errorText={errors.id_jenis}
        />

        <SelectSearch
          label="Item Bahan"
          isDisabled={!values.id_jenis}
          placeholder="Pilih Item Bahan"
          loading={dropdownItemBahan.isLoading}
          defaultValue={
            values.id_item_buaso
              ? dropdownItemBahan.data.find(
                  (i) => i.value === values.id_item_buaso
                )
              : null
          }
          option={dropdownItemBahan?.data ?? []}
          onChange={({ value, label, nama_satuan }) =>
            setValues((prev) => ({
              ...prev,
              id_item_buaso: value,
              nama_item: label,
              nama_satuan,
            }))
          }
          error={errors.id_item_buaso && touched.id_item_buaso}
          errorText={errors.id_item_buaso}
        />

        <Row className="d-flex justify-space-between align-items-center">
          <Col>
            <Input disabled label="Satuan" value={values?.nama_satuan} />
          </Col>
          <Col className="d-flex justify-content-end">
            <ActionButton
              className="mt-3 btn-block"
              text="Register Item Baru"
              onClick={onClickShowFormBarang}
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <Input
              disabled
              label="Gudang Asal"
              value={
                dropdownGudang?.data?.find(
                  ({ value }) => value === values.id_gudang_asal
                )?.label
              }
            />
          </Col>
          <Col>
            <SelectSearch
              label="Gudang Tujuan"
              placeholder="Pilih gudang tujuan"
              option={dropdownGudang?.data ?? []}
              loading={dropdownGudang.isLoading}
              defaultValue={
                values.id_gudang_tujuan
                  ? dropdownGudang.data.find(
                      (g) => g.value === values.id_gudang_tujuan
                    )
                  : null
              }
              onChange={({ value, label }) =>
                setValues((prev) => ({
                  ...prev,
                  id_gudang_tujuan: value,
                  nama_gudang_tujuan: label,
                }))
              }
              error={errors.id_gudang_tujuan && touched.id_gudang_tujuan}
              errorText={errors.id_gudang_tujuan}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InputCurrency
              prefix=""
              className="text-right"
              label="Qty. Transfer Sisa Produksi"
              decimalScale={2}
              value={values?.qty_transfer}
              onChange={(value) => setFieldValue('qty_transfer', value)}
              error={errors.qty_transfer && touched.qty_transfer}
              errorText={errors.qty_transfer}
            />
          </Col>
          <Col>
            <InputCurrency
              className="text-right"
              label="Nilai Persediaan Per Satuan"
              value={values?.nilai_persediaan}
              onChange={(value) => setFieldValue('nilai_persediaan', value)}
              error={errors.nilai_persediaan && touched.nilai_persediaan}
              errorText={errors.nilai_persediaan}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <ActionButton text="Simpan" onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
};
