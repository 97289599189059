import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';

const ModalFilterRAP = ({ dropdown, show, setShow, filter, setFilter }) => {
  const initialValues = {
    tgl_rap_awal: filter.tgl_rap_awal,
    tgl_rap_akhir: filter.tgl_rap_akhir,
    tgl_selesai_start: filter.tgl_selesai_start,
    tgl_selesai_end: filter.tgl_selesai_end,
    id_customer: filter.id_customer,
    baseline: filter.baseline,
    status_approval: filter.status_approval,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...values, ...prev, filter: false }));
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_rap_awal: undefined,
      tgl_rap_akhir: undefined,
      tgl_selesai_start: undefined,
      tgl_selesai_end: undefined,
      id_customer: undefined,
      baseline: undefined,
      status_approval: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ values, setValues, handleSubmit }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. RAP"
            monthsShown={2}
            placeholderText="Pilih tanggal RAP"
            startDate={values.tgl_rap_awal ? new Date(values.tgl_rap_awal) : ''}
            endDate={values.tgl_rap_akhir ? new Date(values.tgl_rap_akhir) : ''}
            onChange={(dates) => {
              const [start, end] = dates;
              const tgl_rap_awal = start ? DateConvert(start).default : null;
              const tgl_rap_akhir = end ? DateConvert(end).default : null;

              setValues((prev) => ({
                ...prev,
                tgl_rap_awal,
                tgl_rap_akhir,
              }));
            }}
          />

          <DatePicker
            selectsRange
            label="Tgl. Selesai"
            placeholderText="Pilih tanggal selesai"
            monthsShown={2}
            startDate={
              values.tgl_selesai_start ? new Date(values.tgl_selesai_start) : ''
            }
            endDate={
              values.tgl_selesai_end ? new Date(values.tgl_selesai_end) : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;

              const tgl_selesai_start = start
                ? DateConvert(start).default
                : null;
              const tgl_selesai_end = end ? DateConvert(end).default : null;

              setValues((prev) => ({
                ...prev,
                tgl_selesai_start,
                tgl_selesai_end,
              }));
            }}
          />

          <SelectSearch
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown?.customer?.find(
              (item) => item.value === values.id_customer
            )}
            option={dropdown?.customer}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, id_customer: e.value }))
            }
          />
          <SelectSearch
            label="Baseline"
            placeholder="Pilih baseline"
            defaultValue={dropdown.baseline.find(
              (item) => item.value === values.baseline
            )}
            option={dropdown.baseline}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, baseline: e.value }))
            }
          />
          <SelectSearch
            label="Status Approval"
            placeholder="Pilih status approval"
            defaultValue={dropdown.approval.find(
              (item) => item.value === values.status_approval
            )}
            option={dropdown.approval}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, status_approval: e.value }))
            }
          />
        </FilterModal>
      )}
    </Formik>
  );
};

export default ModalFilterRAP;
