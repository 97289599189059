// React
import React, { useState, useEffect } from 'react';

// Component
import {
  CRUDLayout,
  Input,
  InputSearch,
  Tr,
  CreateButton,
  ActionButton,
  DataStatus,
  DeleteModal,
  Switch,
  Th,
  Td,
  UpdateButton,
  DeleteButton,
  Alert,
  ThFixed,
  Pagination,
  THead,
  TBody,
  TdFixed,
  TextArea,
} from '../../../components';
import { PageNumber } from '../../../utilities';
import { Col, ModalBody, ModalFooter, Modal } from 'react-bootstrap';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// API
import { BarangJadiTipeProduksiApi } from '../../../api';
import { useIsGuest } from 'hooks';

const BarangJadiTipeProduksi = ({ setNavbarTitle }) => {
  const title = 'Barang Jadi Tipe Produksi';
  const isGuest = useIsGuest();
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE SEARCHING
  const [searchKey, setSearchKey] = useState('');

  // MODALS SHOW STATE
  const [modal, setModal] = useState({
    show: false,
    tipe: '',
    data: {},
  });

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    BarangJadiTipeProduksiApi.page({
      page,
      per_page: dataLength,
      q: searchConfig.key,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch(() => {
        setShowAlert(true);
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  // FUNCTION SWITCH HIDDEN DATA
  // TODO: CHECK SWITCH SHOW HIDDEN BUG API
  const changeDataStatus = (status, id_barang_jadi_tipe_produksi) => {
    setIsLoading(true);
    setShowAlert(false);

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? BarangJadiTipeProduksiApi.show({ id_barang_jadi_tipe_produksi })
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : BarangJadiTipeProduksiApi.hide({ id_barang_jadi_tipe_produksi })
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    // setShowAlert(false)
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchConfig.key]);

  // MODAL TAMBAH COMPONENT
  const DataModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      nama_barang_jadi_tipe_produksi:
        modal?.tipe === 'ubah'
          ? modal?.data?.nama_barang_jadi_tipe_produksi
          : '',
      keterangan: modal?.tipe === 'ubah' ? modal?.data?.keterangan : '',
    };

    // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
    const formValidationSchema = Yup.object().shape({
      nama_barang_jadi_tipe_produksi: Yup.string()
        .test(
          'check_nama',
          'Nama Barang Jadi Tipe Produksi telah digunakan',
          (nama_barang_jadi_tipe_produksi) =>
            modal?.data?.nama_barang_jadi_tipe_produksi ===
            nama_barang_jadi_tipe_produksi
              ? true
              : BarangJadiTipeProduksiApi.single({
                  nama_barang_jadi_tipe_produksi,
                })
                  .then(() => false)
                  .catch(() => true)
        )
        .required('Nama Barang Jadi Tipe Produksi wajib diisi'),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      if (modal?.tipe === 'tambah') {
        BarangJadiTipeProduksiApi.create(values)
          .then(() => {
            // SUCCESS ALERT
            setShowAlert(true);
            setAlertConfig({
              variant: 'primary',
              text: 'Tambah data berhasil!',
            });
          })
          .catch(() => {
            // ERROR ALERT
            setShowAlert(true);
            setAlertConfig({
              variant: 'danger',
              text: `Tambah data gagal!`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false,
              data: {},
            });
            // TAMPILKAN ALERT
            // setShowAlert(true)
            // FETCH DATA DARI SERVER
            getData();
          });
      } else {
        values.id_barang_jadi_tipe_produksi =
          modal?.data?.id_barang_jadi_tipe_produksi;

        BarangJadiTipeProduksiApi.update(values)
          .then(() => {
            // SUCCESS ALERT
            setShowAlert(true);
            setAlertConfig({
              variant: 'primary',
              text: 'Ubah data berhasil!',
            });
          })
          .catch(() => {
            // ERROR ALERT
            setShowAlert(true);
            setAlertConfig({
              variant: 'danger',
              text: `Ubah data gagal!`,
            });
          })
          .finally(() => {
            // CLOSE MODAL
            setModal({
              ...modal,
              show: false,
              data: {},
            });
            // TAMPILKAN ALERT
            // setShowAlert(true)
            // FETCH DATA DARI SERVER
            getData();
          });
      }
    };

    return (
      <Modal
        show={modal?.show && modal?.tipe !== 'hapus'}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
            data: {},
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="mb-0 text-capitalize">
              {modal?.tipe} Data {title}
            </h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <Input
                  label="Nama Barang Jadi Tipe Produksi"
                  placeholder="Nama Barang Jadi Tipe Produksi"
                  type="text"
                  name="nama_barang_jadi_tipe_produksi"
                  value={values.nama_barang_jadi_tipe_produksi}
                  onChange={handleChange}
                  error={
                    errors.nama_barang_jadi_tipe_produksi &&
                    touched.nama_barang_jadi_tipe_produksi &&
                    true
                  }
                  errorText={errors.nama_barang_jadi_tipe_produksi}
                />

                <TextArea
                  label="Keterangan"
                  placeholder="Keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  rows={3}
                />
              </ModalBody>

              <ModalFooter>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      BarangJadiTipeProduksiApi.delete({
        id_barang_jadi_tipe_produksi: modal?.data?.id_barang_jadi_tipe_produksi,
      })
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal!`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setModal({
            ...modal,
            show: false,
            data: {},
          });
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={modal?.show && modal?.tipe === 'hapus'}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
            data: {},
          })
        }
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>
          Nama Barang Jadi Tipe Produksi :{' '}
          {modal?.data?.nama_barang_jadi_tipe_produksi}
        </div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {!isGuest && <ThFixed>Aksi</ThFixed>}
              <Th>Nama Barang Jadi Tipe Produksi</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_kayu}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  {!isGuest && (
                    <TdFixed>
                      <div className="d-flex justify-content-center">
                        <UpdateButton
                          onClick={() =>
                            setModal({
                              show: true,
                              tipe: 'ubah',
                              data: val,
                            })
                          }
                        />
                        <DeleteButton
                          onClick={() =>
                            setModal({
                              show: true,
                              tipe: 'hapus',
                              data: val,
                            })
                          }
                        />
                        <Switch
                          id={val.id_barang_jadi_tipe_produksi}
                          checked={val.is_hidden ? false : true}
                          onChange={() =>
                            changeDataStatus(
                              val.is_hidden,
                              val.id_barang_jadi_tipe_produksi
                            )
                          }
                        />
                      </div>
                    </TdFixed>
                  )}
                  <Td>{val.nama_barang_jadi_tipe_produksi}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                // onChange={(e) => {
                //   setSearchKey(e.target.value)
                //   setPage(1)
                // }}
                // onSubmit={(e) => e.preventDefault()}
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  key ? setShowAlert(true) : setShowAlert(false);
                  setAlertConfig({
                    variant: 'primary',
                    text: 'Hasil dari pencarian: ' + key,
                  });
                }}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Tambah */}
          <CreateButton
            onClick={() =>
              setModal({
                show: true,
                tipe: 'tambah',
                data: {},
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <DataModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default BarangJadiTipeProduksi;
