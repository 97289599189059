export const mapDataPeluang = (data) =>
  data.map((peluang) =>
    Object({
      ...peluang,
      value: peluang.id_peluang,
      label: `${peluang.jumlah_barang_jadi} - ${peluang.no_peluang} - ${peluang.nama_customer}
              ${peluang.nama_proyek}`,

      color: peluang.jumlah_barang_jadi > 0 ? '#22bb33' : '#bb2124',
    })
  );

export const getStatusAnalisa = (is_abj) => {
  if (String(is_abj).toLowerCase() === 't') {
    return (
      <span className="text-nowrap text-uppercase" style={{ color: '#00cc00' }}>
        Sudah dianalisa
      </span>
    );
  }

  return (
    <span className="text-nowrap text-uppercase" style={{ color: '#ff0000' }}>
      Belum dianalisa
    </span>
  );
};
