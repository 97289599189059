import { Services, UploadServices } from '../../../services';

class AnalisaBarangJadi {
  get(params) {
    return Services.get('/analisa_barang_jadi/list_barang_jadi', { params });
  }

  // Dropdown untuk filter on/off page
  getDropdownFilter = (params) => {
    return Services.get('/analisa_barang_jadi/dropdown', { params });
  };

  getSingle(params) {
    return Services.get('/analisa_barang_jadi/single', { params });
  }

  getSingleTemplatePenunjang(params) {
    return Services.get('/analisa_barang_jadi/template', { params });
  }

  // Probably Unused
  // getKayu(params) {
  //   return Services.get("/kayu/dropdown", { params });
  // }

  // Probably Unused
  // getKelompokTipeBarangJadi(params) {
  //   return Services.get("/analisa_barang_jadi/dropdown?tipe=tipe_produksi");
  // }

  // Probably Unused
  // getTipeBarangJadi(params) {
  //   return Services.get("/analisa_barang_jadi/dropdown?tipe=sub_tipe_produksi");
  // }

  // Probably Unused
  // getSingleTipeBarangJadi(params) {
  //   return Services.get("/tipe_barang_jadi/single", { params });
  // }

  getDropdownTipeBarangJadi(params) {
    return Services.get('/tipe_barang_jadi/dropdown', { params });
  }

  getDropdownAnalisaPenunjang(params) {
    return Services.get('/analisa_barang_jadi/dropdown', { params });
  }

  getFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/page', { params });
  }

  getDropdownFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/dropdown', { params });
  }

  getDropdownTipeSisi() {
    return Services.get('/analisa_barang_jadi/d_tipe_sisi');
  }

  getDropdownTipeFinishing() {
    return Services.get('/analisa_barang_jadi/d_finishing');
  }

  getDropdownSpekKayu() {
    return Services.get('/analisa_barang_jadi/d_jenis_kayu');
  }

  getDropdownSpekPlywood() {
    return Services.get('/analisa_barang_jadi/d_plywood');
  }

  getDropdownPart() {
    return Services.get('/analisa_barang_jadi/d_part_kayu');
  }

  getDropdownDeskripsi() {
    return Services.get('/deskripsi_bahan_baku');
  }

  getDropdownBarangJadi() {
    return Services.get(`/registrasi_barang_jadi`);
  }

  getHargaKubikasiKayu(params) {
    return Services.get('/analisa_barang_jadi/harga_kubikasi', { params });
  }

  getSingleFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/single', { params });
  }

  save(data) {
    return Services.post('/analisa_barang_jadi', data);
  }

  // API MENU FILTER BARANG JADI
  getFilterPage(params) {
    return Services.get('/analisa_barang_jadi/filter_barang_jadi', { params });
  }

  getDropdownPeluang(params) {
    return Services.get('/analisa_barang_jadi/d_peluang', { params });
  }

  exportAnalisaBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_export', { params });
  }
}
export default new AnalisaBarangJadi();
