import { useEffect, useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useSessionStorage } from 'hooks';
import {
  TableRealisasiHardwood,
  TableRealisasiPlywood,
  TableRealisasiBahanPenunjang,
  TableRealisasiFactorySupply,
} from './Table';
import { TabPane, NavTab } from '../../Components';
import { RealisasiProduksiContext } from '../../Contexts';

const TabRealisasiBahan = () => {
  const { dataItem, dataRealisasi, dataHistory } = useContext(
    RealisasiProduksiContext
  );

  const [tab, setTab] = useSessionStorage('tab_realisasi_bahan', 'hardwood');

  const tabsConfig = [
    {
      tab: 'hardwood',
      label: 'Hardwood',
      component: () => (
        <TableRealisasiHardwood
          dataHardwood={dataItem.hardwood}
          dataRealisasiHardwood={dataRealisasi.hardwood}
          dataHistoryHardwood={dataHistory.hardwood}
        />
      ),
    },
    {
      tab: 'plywood',
      label: 'Plywood',
      component: () => (
        <TableRealisasiPlywood
          dataPlywood={dataItem.plywood}
          dataRealisasiPlywood={dataRealisasi.plywood}
          dataHistoryPlywood={dataHistory.plywood}
        />
      ),
    },
    {
      tab: 'fs',
      label: 'Factory Supply',
      component: () => (
        <TableRealisasiFactorySupply
          dataFactorySupply={dataItem.fs}
          dataRealisasiFactorySupply={dataRealisasi.fs}
          dataHistoryFactorySupply={dataHistory.fs}
        />
      ),
    },
    {
      tab: 'bp',
      label: 'Bahan Penunjang',
      component: () => (
        <TableRealisasiBahanPenunjang
          dataBahanPenunjang={dataItem.bp}
          dataRealisasiBahanPenunjang={dataRealisasi.bp}
          dataHistoryBahanPenunjang={dataHistory.bp}
        />
      ),
    },
  ];

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('tab_realisasi_bahan');
    };
  }, []);

  return (
    <Card>
      <Tab.Container defaultActiveKey={tab}>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={tab}>
            {tabsConfig.map((val) => (
              <NavTab
                eventKey={val.tab}
                title={val.label}
                onClick={() => setTab(val.tab)}
              />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            {tabsConfig.map(({ tab, component: Component }) => (
              <TabPane eventKey={tab} component={<Component />} />
            ))}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

export default TabRealisasiBahan;
