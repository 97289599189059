import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, Row, Col, Nav, Modal, ModalBody } from 'react-bootstrap';
import { IoAlertCircleOutline } from 'react-icons/io5';
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  DatePicker,
} from 'components';
import { DateConvert } from 'utilities';
import {
  TabBahan,
  TabUpah,
  TabAlatMesin,
  TabSubcont,
  TabOverhead,
  TabSisaProduksi,
} from './Tabs';
import { CloseJobOrderApi } from 'api';

const AddCloseJobOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { header } = location.state || {}; // MENYIMPAN DATA ITEM DARI GET PAGE UNTUK INFO SECTION
  const { id_job_order } = useParams();
  const [listBDP, setListBDP] = useState({});
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  console.log({ header });

  // Menangani fetching data ketika halaman di render
  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    CloseJobOrderApi.getListBDP({
      id_job_order,
      no_job_order: header.no_job_order,
    })
      .then((listBDP) => {
        setListBDP(listBDP?.data?.data ?? {});
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch((e) =>
        setFetchingStatus({
          loading: false,
          success: false,
        })
      );
  };

  const formInitialValues = {
    tgl_close_job_order: '',
    no_close_job_order: '',
    qty_close_job_order: header?.qty_realisasi_sum ?? 0,
  };

  const formValidationSchema = Yup.object().shape({
    tgl_close_job_order: Yup.string().required('Pilih tanggal Close Job Order'),
    no_close_job_order: Yup.string().required(
      'Pilih tgl. Close Job Order untuk menentukan nomor'
    ),
    qty_close_job_order: Yup.string().required('Masukkan Qty. Close Job Order'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValues = {
      ...values,
      id_job_order,
      status_job_order: 'close',
    };
    setModalConfirmConfig({
      show: true,
      data: finalValues,
    });
  };

  useEffect(() => {
    setNavbarTitle('Close Job Order');
    getInitialData();

    return () => {};
  }, []);

  const Input = ({
    label,
    type,
    name,
    placeholder,
    value,
    readOnly,
    error,
    errorText,
    onChange,
    onBlur,
    onFocus,
    autoComplete,
    maxLength,
    className,
    classNameInput,
    style,
  }) => {
    return (
      <div className={`mb-2 ${className}`} style={style}>
        <small>{label}</small>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`form-control form-control-sm ${
            error && 'is-invalid'
          } ${classNameInput}`}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        <div className="invalid-feedback">{errorText}</div>
      </div>
    );
  };

  const InfoSection = () => {
    const InfoItems = ({ label, value }) => (
      <tr style={{ fontSize: 14 }}>
        <td className="text-nowrap">{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Row>
        <Col md>
          <table>
            <tbody>
              <InfoItems
                label="Tgl. Job Order"
                value={
                  header?.tgl_job_order
                    ? DateConvert(new Date(header.tgl_job_order)).detail
                    : '-'
                }
              />
              <InfoItems
                label="No. Job Order"
                value={header?.no_job_order ?? '-'}
              />
              <InfoItems label="No. RAP" value={header?.no_rap ?? '-'} />
              <InfoItems label="No. SPK" value={header?.no_spk ?? '-'} />
            </tbody>
          </table>
        </Col>
        <Col md>
          <table>
            <tbody>
              <InfoItems
                label="Customer"
                value={header?.nama_customer ?? '-'}
              />
              <InfoItems
                label="Barang Jadi"
                value={header?.nama_barang_jadi ?? '-'}
              />
              <InfoItems
                label="Qty. Job Order"
                value={`${parseFloat(header?.qty_rap ?? 0).toPrecision()} ${
                  header?.nama_satuan ?? ''
                }`}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState('bahan'); //State untuk menampung tabs yang aktif

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan',
        label: 'Bahan',
        component: () => <TabBahan dataBahan={listBDP?.bahan ?? []} />,
      },
      {
        tab: 'upah',
        label: 'Upah',
        component: () => <TabUpah dataUpah={listBDP?.upah ?? []} />,
      },
      {
        tab: 'alatMesin',
        label: 'Alat & Mesin',
        component: () => (
          <TabAlatMesin dataAlatMesin={listBDP?.alat_mesin ?? []} />
        ),
      },
      {
        tab: 'subcont',
        label: 'Subcont',
        component: () => <TabSubcont dataSubcont={listBDP?.subkon ?? []} />,
      },
      {
        tab: 'overhead',
        label: 'Overhead',
        component: () => <TabOverhead dataOverhead={listBDP?.overhead ?? []} />,
      },
      {
        tab: 'sisaProduksi',
        label: 'Sisa Produksi',
        component: () => (
          <TabSisaProduksi dataSisaProduksi={listBDP?.sisa_produksi ?? []} />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <TabsNav key={index} tab={val.tab} label={val.label} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            ({ tab, component: Component }, index) =>
              tab === tabs && <Component key={index} />
          )}
        </Card.Body>
      </Card>
    );
  };

  const FormSection = ({ formik }) => {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      setValues,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = formik;
    // Generate nomor ke server
    const getNoHandler = (tanggal = DateConvert(new Date()).default) => {
      CloseJobOrderApi.getNo({ tanggal }).then((res) => {
        setValues({
          ...values,
          tgl_close_job_order: tanggal,
          no_close_job_order: res.data.data,
        });
      });
    };

    useEffect(() => {
      getNoHandler();
      return () => {};
    }, []);

    return (
      <form onSubmit={handleSubmit}>
        <InfoSection />
        <hr />
        <Row className="mb-2">
          <Col>
            <DatePicker
              label="Tgl. Close Job Order"
              selected={
                values.tgl_close_job_order
                  ? new Date(values.tgl_close_job_order)
                  : ''
              }
              onChange={(date) => {
                const newDate = DateConvert(date).default;
                getNoHandler(newDate);
              }}
              error={
                Boolean(errors.tgl_close_job_order) &&
                touched.tgl_close_job_order
              }
              errorText={errors.tgl_close_job_order}
            />
          </Col>
          <Col>
            <Input
              readOnly
              label="No. Close Job Order"
              placeholder="Pilih tgl. Close Job Order"
              value={values.no_close_job_order}
              error={
                Boolean(errors.no_close_job_order) && touched.no_close_job_order
              }
              errorText={errors.no_close_job_order}
            />
          </Col>
          <Col>
            <Input
              readOnly
              label="Qty. Barang Jadi Yang Telah Selesai Di Produksi"
              value={parseFloat(values.qty_close_job_order).toPrecision()}
              classNameInput="text-right"
              error={
                Boolean(errors.qty_close_job_order) &&
                touched.qty_close_job_order
              }
              errorText={errors.qty_close_job_order}
            />
          </Col>
        </Row>
        <TableSection />
        <div className="mt-2 d-flex justify-content-end">
          <ActionButton type="submit" text="Close Job Order" />
        </div>
      </form>
    );
  };

  const ModalConfirm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = () => {
      setIsSubmitting(true);

      CloseJobOrderApi.save(modalConfirmConfig.data)
        .then(() => {
          history.push('/transaksi/close-job-order/', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil ditambah!',
            },
          });
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal ditambah!',
          });
          setIsSubmitting(false);
        });
    };

    return (
      <Modal
        closeButton
        show={modalConfirmConfig.show}
        onHide={() => {
          setModalConfirmConfig({
            show: false,
            data: {},
          });
        }}
        title="Konfirmasi Close Job Order"
      >
        <ModalBody className="text-center">
          <IoAlertCircleOutline size={80} className="text-danger mb-3" />
          <p>
            <b>Apa Anda yakin akan meng-close Job Order ini?</b>
            <br />
            <small className="text-danger">
              Job Order yang telah di CLOSE tidak bisa dikembalikan menjadi
              OPEN!
            </small>
          </p>
          <div className="d-flex justify-content-center">
            <ActionButton
              variant="outline-danger"
              text="Batal"
              className="m-1 px-4"
              onClick={() => {
                setModalConfirmConfig({
                  show: false,
                  data: {},
                });
              }}
            />
            <ActionButton
              variant="primary"
              text="Close Job Order"
              className="m-1 px-4"
              loading={isSubmitting}
              onClick={onSubmitHandler}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Close Job Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : fetchingStatus.success ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {(formik) => <FormSection formik={formik} />}
            </Formik>
          ) : (
            <DataStatus text="Data gagal dimuat!" />
          )}
        </Card.Body>
      </Card>
      {modalConfirmConfig.show && <ModalConfirm />}
    </>
  );
};

export default AddCloseJobOrder;
