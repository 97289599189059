import { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Select,
  Input,
  SelectSearch,
  InputBackLabel,
  ActionButton,
} from 'components';
import { DropdownTransferSisaProduksiContext } from '../Contexts';
import { TransferSisaProduksiApi } from 'api';

export const ModalFormItemBarang = ({ show, onHide }) => {
  const {
    dropdownBuaso,
    dropdownKelompok,
    dropdownPabrikan,
    dropdownSatuan,
    dropdownDimensi,
    dropdownJenis,
  } = useContext(DropdownTransferSisaProduksiContext);

  const initialValues = {
    id_buaso: '2', // default buaso bahan
    kode_item: '',
    nama_item: '',
    keterangan: '',
    id_satuan: '',
    id_satuan_pakai: '',
    id_kelompok: '',
    id_jenis: '',
    tipe: ' ',
    id_satuan_beli: '',
    id_pabrikan: '',
    dimensi: '',
    pj: 0,
    lb: 0,
    tb: 0,
  };

  const validationSchema = yup.object().shape({
    id_satuan_pakai: yup.string().required('Pilih Satuan Pakai'),
    id_kelompok: yup.string().required('Pilih Kelompok'),
    id_jenis: yup.string().required('Pilih Jenis'),
    id_satuan_beli: yup.string().required('Pilih Satuan Beli'),
    id_pabrikan: yup.string().required('Pilih Pabrikan'),
    dimensi: yup.string().required('Masukkan Dimensi'),
  });

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    TransferSisaProduksiApi.create_bahan(values)
      .then(() => {
        window.alert('Data berhasil ditambahkan!');
        resetForm();
        onHide();
      })
      .catch((err) => {
        window.alert(
          `Gagal menyimpan data! ${err?.response?.data?.message ?? ''}`
        );
        setSubmitting(false);
      });
  };

  const {
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setValues,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const optJenis = dropdownJenis?.data?.filter(
    (item) => item.id_kelompok === values.id_kelompok
  );

  const getNomorItemBahan = () =>
    TransferSisaProduksiApi.bahan_no_baru()
      .then((res) => res.data.data)
      .catch((err) =>
        window.alert(
          `Gagal memuat nomor bahan ${err?.response?.data?.message ?? ''}`
        )
      );

  useEffect(
    () =>
      (async () => {
        const kode_item = await getNomorItemBahan();
        setFieldValue('kode_item', kode_item);
      })(),
    []
  );

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>Tambah Data Item Barang</Modal.Header>
      <Modal.Body>
        <div className="row ">
          <div className="col-md-6 col-sm-12">
            <Select
              label="BUASO"
              name="id_buaso"
              defaultValue={values.id_buaso}
              disabled={true}
            >
              {dropdownBuaso?.data?.map((e) => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </Select>

            <Input
              label="Kode Bahan"
              type="text"
              name="kode_item"
              placeholder="Kode Item"
              value={values.kode_item}
              onChange={handleChange}
            />

            <Input
              label="Nama Item"
              type="text"
              name="nama_item"
              placeholder="Nama Item"
              value={values.nama_item}
              onChange={handleChange}
              error={errors.nama_item && touched.nama_item && true}
              errorText={errors.nama_item}
            />

            <SelectSearch
              label="Kelompok Barang"
              name="id_kelompok"
              placeholder="Pilih Kelompok"
              onChange={(val) => setFieldValue('id_kelompok', val.value)}
              option={dropdownKelompok?.data ?? []}
              error={errors.id_kelompok && touched.id_kelompok && true}
              errorText={
                errors.id_kelompok && touched.id_kelompok && errors.id_kelompok
              }
            />

            <SelectSearch
              key={values.id_kelompok}
              loading={false}
              isDisabled={values.id_kelompok ? false : true}
              label="Jenis Barang"
              name="id_jenis"
              placeholder={
                values.id_kelompok
                  ? 'Pilih Jenis'
                  : 'Pilih Kelompok Terlebih Dahulu'
              }
              onChange={(val) => setFieldValue('id_jenis', val.value)}
              option={optJenis ?? []}
              error={errors.id_jenis && touched.id_jenis && true}
              errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
            />

            <Input
              label="Keterangan"
              type="text"
              name="keterangan"
              placeholder="Keterangan"
              value={values.keterangan}
              onChange={handleChange}
              error={errors.keterangan && touched.keterangan && true}
              errorText={errors.keterangan}
            />
          </div>

          <div className="col-md-6 col-sm-12">
            <SelectSearch
              label="Pabrikan"
              name="id_pabrikan"
              placeholder="Pilih Pabrikan"
              onChange={(val) => setFieldValue('id_pabrikan', val.value)}
              option={dropdownPabrikan?.data ?? []}
              defaultValue=""
              error={errors.id_pabrikan && touched.id_pabrikan && true}
              errorText={
                errors.id_pabrikan && touched.id_pabrikan && errors.id_pabrikan
              }
            />

            <SelectSearch
              label="Satuan Pakai"
              name="id_satuan_pakai"
              placeholder="Pilih Satuan Pakai"
              onChange={(val) => {
                setValues((prev) => ({
                  ...prev,
                  id_satuan_pakai: val.value,
                  id_satuan: val.value,
                }));
              }}
              option={dropdownSatuan?.data ?? []}
              defaultValue=""
              error={errors.id_satuan_pakai && touched.id_satuan_pakai && true}
              errorText={
                errors.id_satuan_pakai &&
                touched.id_satuan_pakai &&
                errors.id_satuan_pakai
              }
            />

            <SelectSearch
              label="Satuan Beli"
              name="id_satuan_beli"
              placeholder="Pilih Satuan Beli"
              onChange={(val) => setFieldValue('id_satuan_beli', val.value)}
              option={dropdownSatuan?.data ?? []}
              defaultValue=""
              error={errors.id_satuan_beli && touched.id_satuan_beli && true}
              errorText={
                errors.id_satuan_beli &&
                touched.id_satuan_beli &&
                errors.id_satuan_beli
              }
            />

            <SelectSearch
              label="Dimensi"
              name="dimensi"
              placeholder="Pilih Dimensi"
              onChange={(val) => setFieldValue('dimensi', val.value)}
              option={dropdownDimensi ?? []}
              error={errors.dimensi && touched.dimensi && true}
              errorText={errors.dimensi && touched.dimensi && errors.dimensi}
            />

            <div className="row">
              <div className="col-md-4">
                <InputBackLabel
                  readOnly={parseInt(values.dimensi) > 0 ? false : true}
                  type="text"
                  label="Panjang"
                  name="pj"
                  backLabel="cm"
                  min={0}
                  value={values.dimensi ? values.pj : 0}
                  onChange={(val) => {
                    const value = decimalConvert(val.target.value);
                    setFieldValue('pj', value);
                  }}
                  error={errors.pj && touched.pj && true}
                  errorText={errors.pj}
                />
              </div>
              <div className="col-md-4">
                <InputBackLabel
                  readOnly={parseInt(values.dimensi) > 1 ? false : true}
                  type="text"
                  label="Lebar"
                  name="lb"
                  backLabel="cm"
                  min={0}
                  value={parseInt(values.dimensi) > 1 ? values.lb : 0}
                  onChange={(val) => {
                    const value = decimalConvert(val.target.value);
                    setFieldValue('lb', value);
                  }}
                  error={errors.lb && touched.lb && true}
                  errorText={errors.lb}
                />
              </div>
              <div className="col-md-4">
                <InputBackLabel
                  readOnly={parseInt(values.dimensi) > 2 ? false : true}
                  type="text"
                  step="1"
                  label="Tebal"
                  name="tb"
                  backLabel="cm"
                  min={0}
                  value={parseInt(values.dimensi) > 2 ? values.tb : 0}
                  onChange={(val) => {
                    const value = decimalConvert(val.target.value);
                    setFieldValue('tb', value);
                  }}
                  error={errors.tb && touched.tb && true}
                  errorText={errors.tb}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          loading={isSubmitting}
          text="Simpan"
          onClick={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};

export const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, '');
  const convert = newValue.substring(0, 7);

  return convert;
};
