import React from 'react';
import { DataStatus, Table, ThFixed } from 'components';
import { DateConvert } from 'utilities';

const TabSisaProduksi = ({ dataSisaProduksi }) => {
  const TableSisaProduksi = () => {
    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          padding: 0,
          fontSize: '14px',
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={`${props.className} p-1`}
        style={{
          ...props.style,
          fontSize: '13px',
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <div className="pb-2">
          <b className="p-1">List BDP Sisa Produksi</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed className="py-2 text-center">No.</ThFixed>
              <ThFixed className="p-2 text-center">Tgl. BDP</ThFixed>
              <ThFixed className="p-2 text-center">No. BDP</ThFixed>
              <Th className="p-2 text-center" style={{ width: 400 }}>
                Item Sisa Produksi
              </Th>
              <Th className="p-2 text-center">Satuan</Th>
              <Th className="p-1 text-center" style={{ width: 70 }}>
                Qty.
              </Th>
            </tr>
          </thead>
          <tbody>
            {dataSisaProduksi.length > 0 ? (
              dataSisaProduksi.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      {val.tgl_bdp_sisa_produksi
                        ? DateConvert(new Date(val.tgl_bdp_sisa_produksi))
                            .defaultDMY
                        : '-'}
                    </Td>
                    <Td>{val?.no_bdp_sisa_produksi ?? '-'}</Td>
                    <Td>{`${val.kode_item ? `(${val.kode_item})` : ''} ${
                      val?.nama_item ?? '-'
                    }`}</Td>
                    <Td>{val?.nama_satuan ?? '-'}</Td>
                    <Td className="text-right">
                      {parseFloat(val?.qty_realisasi).toPrecision() ?? 0}
                    </Td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <Td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  // Menampilkan konten halaman saat data berhasil diperoleh
  return <TableSisaProduksi />;
};

export default TabSisaProduksi;
