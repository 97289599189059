import { Fragment, useEffect, useContext, useState } from 'react';
import { Row, Col, Card, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  ActionButton,
  BackButton,
  DataStatus,
  DatePicker,
  Input,
} from 'components';
import { PermintaanProduksiApi } from 'api';
import {
  PermintaanProduksiContext,
  withDropdownPermintaanProduksiContextProvider,
  withPermintaanProduksiContextProvider,
} from './Context';
import {
  InfoSection,
  TabRoot,
  TabPane,
  TabBahan,
  TabUpah,
  TabSubcon,
  TabAlatMesin,
  TabOverhead,
  SummarySection,
  ModalAlert,
} from './Component';
import {
  formInitialValues,
  validationSchema,
  mapSubmitValues,
  mapServerValues,
} from './Helpers';

const PermintaanProduksiUpdate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_permintaan_produksi } = useParams();
  const {
    dataHardwood,
    dataPlywood,
    dataFS,
    dataBPF,
    dataLC,
    dataLCF,
    dataMP,
    dataSF,
    dataBO,
    setDataHardwood,
    setDataPlywood,
    setDataFS,
    setDataBPF,
    setDataLC,
    setDataLCF,
    setDataMP,
    setDataSF,
    setDataBO,
  } = useContext(PermintaanProduksiContext);
  const [dataJO, setDataJO] = useState({});
  const [isFetch, setFetch] = useState({
    loading: false,
    error: false,
  });
  const [modalStatus, setModalStatus] = useState({
    show: false,
    type: 'warning',
    title: 'Konfirmasi Simpan Data',
    message: 'Apakah Anda yakin akan menyimpan data ini?',
  });

  const getInitialData = () => {
    setFetch({ loading: true, error: false });

    PermintaanProduksiApi.getSingle({ id_permintaan_produksi })
      .then((res) => {
        const {
          hardwood,
          plywood,
          factory_supply,
          bahan_penunjang,
          labour_cost,
          labour_cost_finishing,
          machine_process,
          subcon_finishing,
          biaya_overhead,
          analisa_hardwood,
          analisa_plywood,
          analisa_PFS,
          analisa_PPF,
          analisa_PLC,
          analisa_PLF,
          analisa_PMC,
          analisa_PSF,
          analisa_POP,
          ...jobOrder
        } = res?.data?.data ?? {};

        setDataJO(jobOrder);

        setDataHardwood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_hardwood).hardwood,
          permintaan: mapServerValues(hardwood).hardwood,
        }));

        setDataPlywood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_plywood).plywood,
          permintaan: mapServerValues(plywood).plywood,
        }));

        setDataFS((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PFS).factorySupply,
          permintaan: mapServerValues(factory_supply).factorySupply,
        }));

        setDataBPF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PPF).bahanPenunjang,
          permintaan: mapServerValues(bahan_penunjang).bahanPenunjang,
        }));

        setDataLC((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PLC).labourCost,
          permintaan: mapServerValues(labour_cost).labourCost,
        }));

        setDataLCF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PPF).labourCostFinishing,
          permintaan: mapServerValues(labour_cost_finishing)
            .labourCostFinishing,
        }));

        setDataMP((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PMC).machineProcess,
          permintaan: mapServerValues(machine_process).machineProcess,
        }));

        setDataSF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PSF).subconFinishing,
          permintaan: mapServerValues(subcon_finishing).subconFinishing,
        }));

        setDataBO((prev) => ({
          ...prev,
          jobOrder: analisa_POP,
          permintaan: biaya_overhead,
        }));

        setFetch({ loading: false, error: false });
      })
      .catch(() => {
        window.alert('Data gagal dimuat!');
        setFetch({ loading: false, error: true });
      });
  };

  const closeModalAlert = () =>
    setModalStatus((prev) => ({ ...prev, show: false }));

  useEffect(() => {
    setNavbarTitle('Permintaan Produksi');
    getInitialData();
  }, []);

  const onSubmit = (values) => {
    const finalValues = mapSubmitValues({
      ...values,
      dataHardwood,
      dataPlywood,
      dataFS,
      dataBPF,
      dataLC,
      dataLCF,
      dataMP,
      dataSF,
      dataBO,
    });

    PermintaanProduksiApi.update(finalValues)
      .then(() =>
        setModalStatus((prev) => ({
          ...prev,
          show: true,
          type: 'success',
          title: 'Berhasil',
          message: 'Data Permintaan Produksi berhasil disimpan!',
        }))
      )
      .catch((err) =>
        setModalStatus((prev) => ({
          ...prev,
          show: true,
          type: 'error',
          title: 'Gagal',
          message: `Data Permintaan Produksi gagal disimpan! ${
            err.message ?? ''
          }`,
        }))
      );
  };

  if (isFetch.loading || isFetch.error) {
    return (
      <Fragment>
        <Row className="d-flex justify-space-between align-items-end mb-1">
          <Col>
            <div>Detail Data Job Order</div>
          </Col>
          <Col className="d-flex justify-content-end align-items-end">
            <BackButton onClick={() => history.goBack()} />
          </Col>
        </Row>
        <DataStatus
          loading={isFetch.loading}
          text={isFetch.loading ? 'Memuat data' : 'Data gagal dimuat!'}
        />
      </Fragment>
    );
  }

  return (
    <Formik
      enableReinitializee
      initialValues={formInitialValues(dataJO)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Fragment>
          <Row className="d-flex justify-space-between align-items-end mb-1">
            <Col>
              <div>Detail Data Job Order</div>
            </Col>
            <Col className="d-flex justify-content-end align-items-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>

          <Card>
            <Card.Body>
              <InfoSection dataJO={dataJO} />
              <hr />
              <Row>
                <Col>
                  <DatePicker
                    label="Tgl. Permintaan Produksi"
                    selected={formik.values.tgl_permintaan_produksi}
                    onChange={async (value) => {
                      formik.setValues((prev) => ({
                        ...prev,
                        tgl_permintaan_produksi: value,
                      }));
                    }}
                    error={
                      formik.errors.tgl_permintaan_produksi &&
                      formik.touched.tgl_permintaan_produksi
                    }
                    errorText={formik.errors.tgl_permintaan_produksi}
                  />
                </Col>

                <Col>
                  <Input
                    readOnly
                    label="No. Permintaan Produksi"
                    value={formik.values.no_permintaan_produksi}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <TabRoot>
            <Tab.Content>
              <TabPane
                eventKey="bahan"
                component={<TabBahan showHistory={false} />}
              />
              <TabPane
                eventKey="upah"
                component={<TabUpah showHistory={false} />}
              />
              <TabPane
                eventKey="alat"
                component={<TabAlatMesin showHistory={false} />}
              />
              <TabPane
                eventKey="subkon"
                component={<TabSubcon showHistory={false} />}
              />
              <TabPane
                eventKey="overhead"
                component={<TabOverhead showHistory={false} />}
              />
            </Tab.Content>
          </TabRoot>

          <SummarySection />
          <Row className="my-2">
            <Col className="d-flex flex-row justify-content-end align-items-end">
              <ActionButton
                text="Simpan"
                loading={formik.isSubmitting}
                onClick={formik.handleSubmit}
              />
            </Col>
          </Row>

          <ModalAlert
            modalStatus={modalStatus}
            onSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            onSuccessClose={() => {
              closeModalAlert();
              history.push('/transaksi/permintaan-produksi', {
                alert: {
                  text: 'Data berhasil disimpan!',
                  variant: 'primary',
                },
              });
            }}
            onErrorClose={closeModalAlert}
            onModalClose={closeModalAlert}
          />
        </Fragment>
      )}
    </Formik>
  );
};

export default withDropdownPermintaanProduksiContextProvider(
  withPermintaanProduksiContextProvider(PermintaanProduksiUpdate)
);
