import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlParser from 'html-react-parser';
import {
  Input,
  SelectSearch,
  TextEditor,
  InfoItemHorizontal,
  InfoItemVertical,
} from 'components';

const TabInfo = ({
  type: TYPE,
  formik,
  dataTipeProduksi,
  dataBentuk,
  dataBahan,
  dataUkuran,
  dataSeri,
  dataKelompok,
  dataJenis,
  dataSatuan,
}) => {
  const { values, errors, touched, setFieldValue, setValues } = formik;
  const [filteredDataJenis, setFilteredDataJenis] = useState([]);
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(values.uraian ?? '<p></p>')
      )
    )
  );
  const [formLoading, setFormLoading] = useState({
    jenis: false,
  });

  // Filter data jenis berdasarkan id_kelompok
  const filterDataJenisHandler = (kelompok) => {
    setFormLoading({ ...formLoading, jenis: true });
    setFilteredDataJenis(
      dataJenis.filter((val) => val.id_kelompok === kelompok)
    );

    setTimeout(() => {
      setFormLoading({ ...formLoading, jenis: false });
    }, 300);
  };

  // Menangani saat SelectSearch untuk nama barang jadi diubah
  const onOptionBarangJadiHandler = (field, id, nama) => {
    const newValues = values;

    newValues[`id_${field}`] = id;
    newValues[`nama_${field}`] = nama;

    // Check spasi agar tidak double pada saat penggabungan nama barang jadi
    const getNamaTipeProduksi = newValues.id_tipe_produksi
      ? newValues.nama_tipe_produksi
        ? `${newValues.nama_tipe_produksi}`
        : ''
      : '';
    const getNamaBentuk = newValues.id_bentuk
      ? newValues.nama_bentuk
        ? ` ${newValues.nama_bentuk}`
        : ''
      : '';
    const getNamaBahan = newValues.id_bahan
      ? newValues.nama_bahan
        ? ` ${newValues.nama_bahan}`
        : ''
      : '';
    const getNamaUkuran = newValues.id_ukuran
      ? newValues.nama_ukuran
        ? ` ${newValues.nama_ukuran}`
        : ''
      : '';
    const getNamaSeri = newValues.id_seri
      ? newValues.nama_seri
        ? ` ${newValues.nama_seri}`
        : ''
      : '';

    const generateName =
      getNamaTipeProduksi +
      getNamaBentuk +
      getNamaBahan +
      getNamaUkuran +
      getNamaSeri;

    newValues['nama_item'] = generateName.trim();

    setValues(newValues);
  };

  // Menangani saat select kelompok diubah
  const onKelompokChange = (value) => {
    filterDataJenisHandler(value);

    setValues({
      ...values,
      id_kelompok: value,
      id_jenis: '',
    });
  };

  // Menangani saat text editor uraian berubah
  const onUraianChangeHandler = (value) => {
    const convertData = convertToRaw(value.getCurrentContent());

    setTextEditorState(value);
    setFieldValue('uraian', draftToHtml(convertData));
  };

  useEffect(() => {
    values.id_kelompok && filterDataJenisHandler(values.id_kelompok);
  }, []);

  // Tampilan jika type DETAIL
  if (TYPE === 'DETAIL') {
    return (
      <>
        <Row>
          <Col lg>
            <InfoItemVertical label="Kode Item" text={values.kode_item} />
            <InfoItemVertical
              label="Nama Barang Jadi"
              text={values.nama_item}
            />
            <InfoItemVertical
              label="Sumber Barang Jadi"
              text={
                values.id_kelompok
                  ? dataKelompok.find(
                      (val) => val.value === values.id_kelompok
                    )['label']
                  : ''
              }
            />
            <InfoItemVertical label="Pabrikan" text={values.nama_pabrikan} />
            <InfoItemVertical
              label="Satuan Pakai"
              text={
                values.id_satuan
                  ? dataSatuan.find((val) => val.value === values.id_satuan)[
                      'label'
                    ]
                  : ''
              }
            />
            <InfoItemVertical
              label="Satuan Jual"
              text={
                values.id_satuan_jual
                  ? dataSatuan.find(
                      (val) => val.value === values.id_satuan_jual
                    )['label']
                  : ''
              }
            />
          </Col>
          <Col lg>
            <small>Uraian</small>
            <br />
            <div
              className="border rounded p-3 overflow-auto"
              style={{ maxHeight: '60vh' }}
            >
              {values.uraian ? htmlParser(values.uraian) : '-'}
            </div>
          </Col>
        </Row>
      </>
    );
  }

  // Tampilan jika type TAMBAH / UBAH
  return (
    <div>
      <Row>
        <Col lg>
          <Row>
            {/* Kode Barang Jadi */}
            <Col sm>
              <Input
                readOnly
                label="Kode Barang Jadi"
                placeholder="Masukan kode barang jadi"
                value={values.kode_item}
                error={Boolean(errors.kode_item && touched.kode_item)}
                errorText={
                  Boolean(errors.kode_item && touched.kode_item) &&
                  errors.kode_item
                }
              />
            </Col>

            {/* Tipe Produksi */}
            <Col sm>
              <SelectSearch
                label="Tipe Produksi"
                placeholder="Pilih tipe produksi"
                option={dataTipeProduksi}
                defaultValue={
                  values.id_tipe_produksi
                    ? dataTipeProduksi.find(
                        (val) => val.value === values.id_tipe_produksi
                      )
                    : ''
                }
                onChange={(val) =>
                  onOptionBarangJadiHandler(
                    'tipe_produksi',
                    val.value,
                    val.label
                  )
                }
                error={Boolean(
                  errors.id_tipe_produksi && touched.id_tipe_produksi
                )}
                errorText={
                  Boolean(
                    errors.id_tipe_produksi && touched.id_tipe_produksi
                  ) && errors.id_tipe_produksi
                }
              />
            </Col>
          </Row>
          <Row>
            {/* Bentuk */}
            <Col sm>
              <SelectSearch
                label="Bentuk"
                placeholder="Pilih bentuk"
                option={dataBentuk}
                defaultValue={
                  values.id_bentuk
                    ? dataBentuk.find((val) => val.value === values.id_bentuk)
                    : ''
                }
                onChange={(val) =>
                  onOptionBarangJadiHandler('bentuk', val.value, val.label)
                }
                error={Boolean(errors.id_bentuk && touched.id_bentuk)}
                errorText={
                  Boolean(errors.id_bentuk && touched.id_bentuk) &&
                  errors.id_bentuk
                }
              />
            </Col>

            {/* Bahan */}
            <Col sm>
              <SelectSearch
                label="Bahan"
                placeholder="Pilih bahan"
                option={dataBahan}
                defaultValue={
                  values.id_bahan
                    ? dataBahan.find((val) => val.value === values.id_bahan)
                    : ''
                }
                onChange={(val) =>
                  onOptionBarangJadiHandler('bahan', val.value, val.label)
                }
                error={Boolean(errors.id_bahan && touched.id_bahan)}
                errorText={
                  Boolean(errors.id_bahan && touched.id_bahan) &&
                  errors.id_bahan
                }
              />
            </Col>
          </Row>
          <Row>
            {/* Ukuran */}
            <Col sm>
              <SelectSearch
                label="Ukuran"
                placeholder="Pilih ukuran"
                option={dataUkuran}
                defaultValue={
                  values.id_ukuran
                    ? dataUkuran.find((val) => val.value === values.id_ukuran)
                    : ''
                }
                onChange={(val) =>
                  onOptionBarangJadiHandler('ukuran', val.value, val.label)
                }
                error={Boolean(errors.id_ukuran && touched.id_ukuran)}
                errorText={
                  Boolean(errors.id_ukuran && touched.id_ukuran) &&
                  errors.id_ukuran
                }
              />
            </Col>

            {/* Seri */}
            <Col sm>
              <SelectSearch
                label="Seri"
                placeholder="Pilih seri"
                option={dataSeri}
                defaultValue={
                  values.id_seri
                    ? dataSeri.find((val) => val.value === values.id_seri)
                    : ''
                }
                onChange={(val) =>
                  onOptionBarangJadiHandler('seri', val.value, val.label)
                }
                error={Boolean(errors.id_seri && touched.id_seri)}
                errorText={
                  Boolean(errors.id_seri && touched.id_seri) && errors.id_seri
                }
              />
            </Col>
          </Row>

          {/* Nama Barang Jadi */}
          <Input
            label="Nama Barang Jadi"
            placeholder="Masukan nama barang jadi"
            onChange={(e) => setFieldValue('nama_item', e.target.value)}
            value={values.nama_item}
            error={Boolean(errors.nama_item && touched.nama_item)}
            errorText={
              Boolean(errors.nama_item && touched.nama_item) && errors.nama_item
            }
          />

          {/* Sumber Barang Jadi */}
          <SelectSearch
            label="Sumber Barang Jadi"
            placeholder="Pilih kelompok barang jadi"
            option={dataKelompok}
            defaultValue={
              values.id_kelompok
                ? dataKelompok.find((val) => val.value === values.id_kelompok)
                : ''
            }
            onChange={(val) => setFieldValue('id_kelompok', val.value)}
            error={Boolean(errors.id_kelompok && touched.id_kelompok)}
            errorText={
              Boolean(errors.id_kelompok && touched.id_kelompok) &&
              errors.id_kelompok
            }
          />

          {/* Pabrikan */}
          <Input
            readOnly
            label="Pabrikan"
            placeholder="Masukan pabrikan"
            value={values.nama_pabrikan}
          />

          <Row>
            {/* Satuan Beli */}
            <Col sm>
              <SelectSearch
                label="Satuan Beli"
                placeholder="Pilih satuan beli"
                option={dataSatuan}
                defaultValue={
                  values.id_satuan_beli
                    ? dataSatuan.find(
                        (val) => val.value === values.id_satuan_beli
                      )
                    : ''
                }
                onChange={(val) => setFieldValue('id_satuan_beli', val.value)}
                error={Boolean(errors.id_satuan_beli && touched.id_satuan_beli)}
                errorText={
                  Boolean(errors.id_satuan_beli && touched.id_satuan_beli) &&
                  errors.id_satuan_beli
                }
              />
            </Col>

            {/* Satuan Pakai*/}
            <Col sm>
              <SelectSearch
                label="Satuan Pakai"
                placeholder="Pilih satuan pakai"
                option={dataSatuan}
                defaultValue={
                  values.id_satuan
                    ? dataSatuan.find((val) => val.value === values.id_satuan)
                    : ''
                }
                onChange={(val) => setFieldValue('id_satuan', val.value)}
                error={Boolean(errors.id_satuan && touched.id_satuan)}
                errorText={
                  Boolean(errors.id_satuan && touched.id_satuan) &&
                  errors.id_satuan
                }
              />
            </Col>

            {/* Satuan Jual */}
            <Col sm>
              <SelectSearch
                label="Satuan Jual"
                placeholder="Pilih satuan jual"
                option={dataSatuan}
                defaultValue={
                  values.id_satuan_jual
                    ? dataSatuan.find(
                        (val) => val.value === values.id_satuan_jual
                      )
                    : ''
                }
                onChange={(val) => setFieldValue('id_satuan_jual', val.value)}
                error={Boolean(errors.id_satuan_jual && touched.id_satuan_jual)}
                errorText={
                  Boolean(errors.id_satuan_jual && touched.id_satuan_jual) &&
                  errors.id_satuan_jual
                }
              />
            </Col>
          </Row>

          {/* Link Referensi */}
          <Input
            label="Link Referensi"
            placeholder="https://"
            onChange={(e) => setFieldValue('link_referensi', e.target.value)}
            value={values.link_referensi}
            error={Boolean(errors.link_referensi && touched.link_referensi)}
            errorText={
              Boolean(errors.link_referensi && touched.link_referensi) &&
              errors.link_referensi
            }
          />
        </Col>

        {/* Uraian Bahan jadi */}
        <Col lg>
          <TextEditor
            label="Uraian Barang Jadi"
            placeholder="Masukan uraian"
            editorState={textEditorState}
            onEditorStateChange={(val) => onUraianChangeHandler(val)}
            editorHeight={440}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TabInfo;
