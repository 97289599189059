import React from 'react';
import { DataStatus, Table, ThFixed } from 'components';
import { DateConvert } from 'utilities';

const TabAlatMesin = ({ dataAlatMesin }) => {
  const TableAlatMesin = () => {
    const getGrup = (ref) => {
      if (ref === 'RHW') return 'Hardwood';
      if (ref === 'RPW') return 'Plywood';
      if (ref === 'RFS') return 'Factory Supply';
      if (ref === 'RPF') return 'Penunjang Finishing';
      if (ref === 'RLC') return 'Labour Cost';
      if (ref === 'RLF') return 'Labour Cost Finishing';
      if (ref === 'RSF') return 'Subkon Finishing';
      if (ref === 'RMP') return 'Machine Process';
      if (ref === 'ROP') return 'Biaya Overhead';
      return '-';
    };

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          padding: 0,
          fontSize: '14px',
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={`${props.className} p-1`}
        style={{
          ...props.style,
          fontSize: '13px',
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <div className="pb-2">
          <b className="p-1">List BDP Alat & Mesin</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed className="py-2 text-center">No.</ThFixed>
              <ThFixed className="p-2 text-center">Tgl. BDP</ThFixed>
              <ThFixed className="p-2 text-center">No. BDP</ThFixed>
              <Th className="p-2 text-center">Grup</Th>
              <Th className="p-2 text-center" style={{ width: 400 }}>
                Item Alat & Mesin
              </Th>
              <Th className="p-2 text-center">Satuan</Th>
              <Th className="p-1 text-center" style={{ width: 70 }}>
                Qty.
              </Th>
            </tr>
          </thead>
          <tbody>
            {dataAlatMesin.length > 0 ? (
              dataAlatMesin.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      {val.tgl_bdp_alat_mesin
                        ? DateConvert(new Date(val.tgl_bdp_alat_mesin))
                            .defaultDMY
                        : '-'}
                    </Td>
                    <Td>{val?.no_bdp_alat_mesin ?? '-'}</Td>
                    <Td>{getGrup(val?.ref ?? '-')}</Td>
                    <Td>{`${
                      val.kode_item_aset ? `(${val.kode_item_aset})` : ''
                    } ${val?.nama_item_aset ?? '-'}`}</Td>
                    <Td>{val?.nama_satuan ?? '-'}</Td>
                    <Td className="text-right">
                      {parseFloat(
                        val?.qty_realisasi_produksi_alat_mesin
                      ).toPrecision() ?? 0}
                    </Td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <Td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  // Menampilkan konten halaman saat data berhasil diperoleh
  return <TableAlatMesin />;
};

export default TabAlatMesin;
