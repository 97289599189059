import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { IoCheckmarkDone } from 'react-icons/io5';

export const TickDone = () => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>Sudah dibuatkan permintaan</Tooltip>}
    >
      <div
        className="bg-success d-flex justify-content-center align-items-center"
        style={{
          margin: '0.5px 5px',
          borderRadius: '20%',
          minWidth: 15,
          maxHeight: 15,
        }}
      >
        <IoCheckmarkDone />
      </div>
    </OverlayTrigger>
  );
};
