import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DeleteModal,
  AddButton,
  BackButton,
} from '../../../../components';
import { TableNumber, DateConvert } from '../../../../utilities';
import { JobOrderApi } from '../../../../api';
import { Col } from 'react-bootstrap';

const ListRap = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // GET PAGE LIST RAP
  const getInitialData = () => {
    setIsPageLoading(true);

    JobOrderApi.getRap({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        if (searchConfig.key !== '') {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchConfig.key}`,
          });
        } else {
          setAlertConfig({
            show: false,
          });
        }
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  // Modal Hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const values = { id_job_order: deleteData.id_job_order };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      JobOrderApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! ${err.response.data.message}`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setAlertConfig({ show: true });
          // request data baru ke server
          getInitialData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Job Order"
      >
        <div>No. Job Order : {deleteData.no_job_order}</div>
      </DeleteModal>
    );
  };

  useEffect(() => {
    setNavbarTitle('List RAP');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <Th>Tgl. RAP</Th>
            <Th>No. RAP</Th>
            <Th>Customer</Th>
            <Th>ATT</Th>
            <Th>Tgl. Selesai</Th>
            <Th>Barang Jadi</Th>
            <Th>Qty.</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>
                {val.tgl_rap
                  ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                  : ''}
              </Td>
              <Td>{val.no_rap ? val.no_rap : '-'}</Td>
              <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
              <Td>{val.att ? val.att : '-'}</Td>
              <Td>
                {val.tgl_selesai
                  ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                  : '-'}
              </Td>
              <Td>{val.nama_barang_jadi ? val.nama_barang_jadi : '-'}</Td>
              <Td>{val.qty_rap ? val.qty_rap : '-'}</Td>
              <TdFixed>
                <AddButton
                  onClick={() => {
                    history.push(
                      `/transaksi/job-order/tambah/${val.id_rap_detail}`
                    );
                  }}
                />
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchConfig({
                      ...searchConfig,
                      key: e.target.value,
                    });
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data ? (
        data.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      <HapusModal />
    </CRUDLayout>
  );
};

export default ListRap;
