import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
  ActionButton,
  SelectSearch,
  CRUDLayout,
  DatePicker,
  Pagination,
  DataStatus,
  TdFixed,
  ThFixed,
  Alert,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
} from 'components';
import { TableNumber } from 'utilities';
import { AnalisaBarangJadiApi } from 'api';
import { useIsGuest, useSessionStorage } from 'hooks';
import { selectSearchStyle } from './Contants';
import { getStatusAnalisa, mapDataPeluang } from './Helpers';
import ModalFormPeluang from './ModalFormPeluang';
import {
  DropdownAnalisaBarangJadiFilterContext,
  withDropdownAnalisaBarangJadiFilterContextProvider,
} from './Context/DropdownAnalisaBarangJadiFilterContext';

const AnalisaBarangJadiFilter = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const dateFilterKey = `${location.pathname}_date_filter`;
  const peluangKey = `${location.pathname}_peluang_filter`;

  const { loading: loadingDropdownBarangJadi } = useContext(
    DropdownAnalisaBarangJadiFilterContext
  );
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dateFilter, setDateFilter] = useSessionStorage(
    dateFilterKey,
    new Date()
  );
  const [dataPeluang, setDataPeluang] = useState([]);
  const [selectedPeluang, setSelectedPeluang] = useSessionStorage(peluangKey, {
    value: null,
    label: null,
  });
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);
  const [modalConfigPeluang, setModalConfigPeluang] = useState({ show: false });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getDropdownPeluang = (date) => {
    setIsDropdownLoading(true);

    const bulan = date.getMonth() + 1;
    const tahun = date.getFullYear();

    AnalisaBarangJadiApi.getDropdownPeluang({ bulan, tahun })
      .then(({ data }) => {
        setDataPeluang(mapDataPeluang(data?.data ?? []));
        setDateFilter(date);
      })
      .catch(() => alert('Gagal mengambil data peluang!'))
      .finally(() => setIsDropdownLoading(false));
  };

  const getFilteredAnalisaBarangJadi = () => {
    setIsPageLoading(true);

    let query = {
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      id_peluang: selectedPeluang.value ?? '',
    };

    AnalisaBarangJadiApi.getFilterPage(query)
      .then(({ data }) => {
        setDataAnalisaBarangJadi(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState.alert);
    }
  };

  useEffect(() => {
    selectedPeluang.value && getFilteredAnalisaBarangJadi();
  }, [
    setNavbarTitle,
    paginationConfig.page,
    paginationConfig.dataLength,
    selectedPeluang.value,
  ]);

  useEffect(() => {
    setNavbarTitle('Filter Analisa Barang Jadi');
    getDropdownPeluang(new Date(dateFilter));
    checkAlert();
  }, []);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>
              <span className="text-nowrap">Kode Barang Jadi</span>
            </ThFixed>
            <Th>Nama Barang Jadi</Th>
            <ThFixed>Nomor Peluang</ThFixed>
            <Th>Nama Customer</Th>
            <ThFixed>Status</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataAnalisaBarangJadi?.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>{val.kode_item}</Td>
              <Td>{val.nama_item}</Td>
              <Td>{val.no_peluang}</Td>
              <Td>{val.nama_customer}</Td>
              <Td>{getStatusAnalisa(val.is_abj)}</Td>
              <Td>
                <ActionButton
                  size="sm"
                  text="Analisa"
                  className="col"
                  onClick={() =>
                    history.push(
                      '/transaksi/analisa-barang-jadi/form/' +
                        val.id_barang_jadi
                    )
                  }
                />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <div className="mt-2">
        <DataTable />
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </div>
    );
  };

  return (
    <CRUDLayout>
      <Row>
        <Col md={{ span: 10, offset: 2 }}>
          <div className="d-flex justify-content-end align-items-center">
            <Col md="3">
              <DatePicker
                label="Filter Nomor Peluang"
                selected={new Date(dateFilter)}
                onChange={(date) => getDropdownPeluang(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </Col>
            <Col md="6">
              <SelectSearch
                label="Nomor Peluang"
                placeholder={
                  isDropdownLoading ? 'Memuat data . . ' : 'Pilih Nomor Peluang'
                }
                loading={isDropdownLoading}
                defaultValue={
                  dataPeluang?.find(
                    (item) => item.value === selectedPeluang.value
                  ) ?? null
                }
                value={selectedPeluang.value ? selectedPeluang : null}
                options={dataPeluang}
                onChange={(e) =>
                  setSelectedPeluang({ value: e.value, label: e.label })
                }
                styles={selectSearchStyle}
              />
            </Col>

            {!isGuest && (
              <Col md="auto" className="pr-0 mt-3">
                <ActionButton
                  loading={loadingDropdownBarangJadi}
                  disable={Boolean(!selectedPeluang.value)}
                  className="btn-block"
                  size="sm"
                  text="Tambah Data Barang Jadi"
                  onClick={() =>
                    setModalConfigPeluang({ ...modalConfigPeluang, show: true })
                  }
                />
              </Col>
            )}
          </div>
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataAnalisaBarangJadi ? (
        dataAnalisaBarangJadi?.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      <ModalFormPeluang
        id_peluang={selectedPeluang.value}
        modalConfig={modalConfigPeluang}
        setModalConfig={setModalConfigPeluang}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        getFilteredAnalisaBarangJadi={getFilteredAnalisaBarangJadi}
      />
    </CRUDLayout>
  );
};

export default withDropdownAnalisaBarangJadiFilterContextProvider(
  AnalisaBarangJadiFilter
);
