import { useEffect, useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useSessionStorage } from 'hooks';
import { RealisasiProduksiContext } from '../../Contexts';
import {
  TableRealisasiLabourCost,
  TableRealisasiLabourCostFinishing,
} from './Table';
import { NavTab, TabPane } from '../../Components';

const TabRealisasiUpah = () => {
  const { dataItem, dataRealisasi, dataHistory } = useContext(
    RealisasiProduksiContext
  );

  const [tab, setTab] = useSessionStorage('tab_realisasi_upah', 'lc');

  const tabsConfig = [
    {
      tab: 'lc',
      label: 'Labour Cost',
      component: () => (
        <TableRealisasiLabourCost
          dataLabourCost={dataItem.lc}
          dataRealisasiLabourCost={dataRealisasi.lc}
          dataHistoryLabourCost={dataHistory.lc}
        />
      ),
    },
    {
      tab: 'lc_fin',
      label: 'Labour Cost Finishing',
      component: () => (
        <TableRealisasiLabourCostFinishing
          dataLabourCostFinishing={dataItem.lcf}
          dataRealisasiLabourCostFinishing={dataRealisasi.lcf}
          dataHistoryLabourCostFinishing={dataHistory.lcf}
        />
      ),
    },
  ];

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('tab_realisasi_upah');
    };
  }, []);

  return (
    <Card>
      <Tab.Container defaultActiveKey={tab}>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={tab}>
            {tabsConfig.map((val) => (
              <NavTab
                eventKey={val.tab}
                title={val.label}
                onClick={() => setTab(val.tab)}
              />
            ))}
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            {tabsConfig.map(({ tab, component: Component }) => (
              <TabPane eventKey={tab} component={<Component />} />
            ))}
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};

export default TabRealisasiUpah;
