import React, { useState, useEffect } from 'react';
import { DataStatus, Alert } from 'components';
import { RAPApi } from 'api';
import {
  InfoSection,
  TableSPK,
  TableLabourCost,
  TableMachineProcess,
} from './Table';

const DetailSPK = ({ idSPK }) => {
  const [dataSPK, setDataSPK] = useState({});
  const [dataListSPK, setDataListSPK] = useState([]);
  const [dataLabourCost, setDataLabourCost] = useState([]);
  const [dataMachineProcess, setDataMachineProcess] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    success: true,
    loading: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Fetching data pada saat halaman dikunjungi
  const getInitialData = () => {
    setFetchingStatus({
      ...fetchingStatus,
      loading: true,
    });

    RAPApi.getSingleSPK({ id_spk: idSPK })
      .then((spk) => {
        const dataAwalSPK = spk.data.data.detail_spk;
        const dataAwalLC = spk.data.data.detail_upah;
        const dataAwalMP = spk.data.data.detail_alat_mesin;

        setDataSPK(spk.data.data);
        setDataListSPK(dataAwalSPK ?? []);
        setDataLabourCost(dataAwalLC ?? []);
        setDataMachineProcess(dataAwalMP ?? []);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() =>
        setFetchingStatus({
          ...fetchingStatus,
          loading: false,
        })
      );
  };

  useEffect(() => {
    getInitialData();

    return () => {};
  }, []);

  // Menampilkan loading pada saat fetching data
  if (fetchingStatus.loading) {
    return <DataStatus loading={true} text="Memuat data . . ." />;
  }

  // Menampilkan status ketika data gagal diperoleh
  if (!fetchingStatus.success) {
    return <DataStatus text="Data gagal dimuat" />;
  }

  // Menampilkan semua isi dari halaman
  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <InfoSection dataSPK={dataSPK} />
      <TableSPK dataSPK={dataSPK} dataListSPK={dataListSPK} />
      {/* <TableLabourCost 
        dataLabourCost={dataLabourCost}
      />
      <TableMachineProcess 
        dataMachineProcess={dataMachineProcess}
      /> */}
    </>
  );
};

export default DetailSPK;
