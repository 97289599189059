import { useContext } from 'react';
import {
  RealisasiProduksiContext,
  withRealisasiProduksiProvider,
} from '../../Contexts';
import { TableRealisasiAlatMesin } from './Table';

const TabRealisasiAlatMesin = () => {
  const { dataItem, dataRealisasi, dataHistory } = useContext(
    RealisasiProduksiContext
  );

  return (
    <div>
      <TableRealisasiAlatMesin
        dataAlatMesin={dataItem.alat_mesin}
        dataHistoryAlatMesin={dataHistory.alat_mesin}
        dataRealisasiAlatMesin={dataRealisasi.alat_mesin}
      />
    </div>
  );
};

export default TabRealisasiAlatMesin;
