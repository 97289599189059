import { Row, Col, Modal } from 'react-bootstrap';
import {
  InfoItemHorizontal,
  Input,
  ActionButton,
  DataStatus,
  Table,
} from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { calculationHandler } from '../Utils';

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, '');
  const convert = newValue.substring(0, 3);

  return convert;
};

const Th = (props) => (
  <th
    {...props}
    className={`${props.className} align-middle`}
    style={{
      ...props.style,
      padding: 0,
      fontSize: '14px',
    }}
  >
    {props.children}
  </th>
);

const Td = (props) => (
  <td
    {...props}
    className={`${props.className} p-1`}
    style={{
      ...props.style,
      fontSize: '13px',
    }}
  >
    {props.children}
  </td>
);

export const ModalOpname = ({
  formik,
  modalConfig,
  data,
  setData,
  dataInfo,
  fetchStatus,
}) => {
  const { values, isSubmitting, handleSubmit } = formik;

  const onPercentChangeHandler = (e, i) => {
    const persen = decimalConvert(e.target.value)
      ? parseInt(decimalConvert(e.target.value))
      : 0;
    let newData = [...data];
    const persenPeriodeLalu = parseInt(
      newData?.[i]?.persentase_periode_lalu ?? 0
    );
    const persentasePeriodeIni =
      persenPeriodeLalu >= 100
        ? 0
        : persenPeriodeLalu + persen >= 100
        ? 100 - persenPeriodeLalu
        : persen;
    const nominalPeriodeIni = calculationHandler.afterPercent(
      persen,
      calculationHandler.total(newData[i].qty, newData[i].unit_price)
    );
    newData[i] = {
      ...newData[i],
      persentase_periode_ini: persentasePeriodeIni,
      nominal_periode_ini: nominalPeriodeIni,
    };

    setData(newData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Col>
            <InfoItemHorizontal
              label="Vendor"
              text={values?.nama_vendor ?? '-'}
            />
            <InfoItemHorizontal
              label="Tgl. Realisasi Produksi"
              text={
                values?.tgl_realisasi
                  ? DateConvert(new Date(values.tgl_realisasi)).detail
                  : '-'
              }
            />
          </Col>
          <Col>
            <InfoItemHorizontal
              label="Proyek"
              text={values?.nama_proyek ?? '-'}
            />
            <InfoItemHorizontal
              label="Item Barang Jadi"
              text={values?.nama_barang_jadi ?? '-'}
            />
          </Col>
        </Row>
        <hr />
        <div className="pb-2">
          <b className="p-1">Detail Opname</b>
        </div>
        <Table responsive>
          <thead className="bg-light">
            <tr>
              <Th
                rowSpan={2}
                className="py-2 text-center"
                style={{ width: 30 }}
              >
                No.
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ minWidth: 150 }}
              >
                Uraian
              </Th>
              <Th rowSpan={2} className="p-2 text-center" style={{ width: 80 }}>
                Volume
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ width: 150 }}
              >
                Harga Satuan
              </Th>
              <Th
                rowSpan={2}
                className="p-2 text-center"
                style={{ width: 150 }}
              >
                Jumlah
              </Th>
              <Th colSpan={6} className="p-0 text-center">
                Realisasi Pembayaran
              </Th>
            </tr>
            <tr>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                Periode Lalu
              </Th>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                Periode Ini
              </Th>
              <Th className="p-0 text-center" style={{ width: 60 }}>
                %
              </Th>
              <Th className="p-0 text-center" style={{ width: 150 }}>
                S/D Periode Ini
              </Th>
            </tr>
          </thead>
          <tbody>
            {!fetchStatus.loading && fetchStatus.success && data?.length > 0 ? (
              <>
                {data.map((val, index) => (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>{val?.uraian ?? ''}</Td>
                    <Td className="text-right">
                      {val?.qty ?? ''} {val?.kode_satuan ?? ''}
                    </Td>
                    <Td className="text-right">
                      {val?.unit_price
                        ? RupiahConvert(String(parseInt(val.unit_price))).detail
                        : ''}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          parseInt(
                            calculationHandler.total(val.qty, val.unit_price)
                          ).toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {val.persentase_periode_lalu ?? 0}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler
                            .afterPercent(
                              val.persentase_periode_lalu,
                              calculationHandler.total(val.qty, val.unit_price)
                            )
                            .toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {modalConfig.type !== 'detail' ? (
                        <Input
                          noMargin
                          value={val.persentase_periode_ini ?? 0}
                          onChange={(e) => onPercentChangeHandler(e, index)}
                        />
                      ) : (
                        val.persentase_periode_ini
                      )}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler
                            .afterPercent(
                              val.persentase_periode_ini,
                              calculationHandler.total(val.qty, val.unit_price)
                            )
                            .toString()
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {parseInt(val?.persentase_periode_lalu ?? 0) +
                        parseInt(val?.persentase_periode_ini ?? 0)}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          calculationHandler.totalAllPeriod(val).toString()
                        ).detail
                      }
                    </Td>
                  </tr>
                ))}
                {/* total */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>GRAND TOTAL</b>
                  </Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          calculationHandler.grandTotal(data).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalPastPeriod(data)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalCurrentPeriod(data)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalAllPeriod(data)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                </tr>

                {/* total dp */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>DP ({parseFloat(dataInfo.dp ?? 0).toPrecision()} %)</b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPPastPeriod(data, dataInfo.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPCurrentPeriod(data, dataInfo.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.dp ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalDPAllPeriod(data, dataInfo.dp)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                </tr>

                {/* total Retensi */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>
                      RETENSI ({parseFloat(dataInfo.retensi ?? 0).toPrecision()}{' '}
                      %)
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiPastPeriod(data, dataInfo.retensi)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiCurrentPeriod(
                              data,
                              dataInfo.retensi
                            )
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.retensi ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    (
                    {data
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalRetensiAllPeriod(data, dataInfo.retensi)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                    )
                  </Td>
                </tr>

                {/* total Invoice */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>INVOICE</b>
                  </Td>
                  <Td className="text-right">
                    {/* {data
                      ? RupiahConvert(calculationHandler.grandTotal(data).toString()).detail
                      : RupiahConvert("0").detail} */}
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoicePastPeriode(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoiceCurrentPeriode(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            calculationHandler
                              .grandTotalInvoiceAllPeriode(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi
                              )
                              .toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                </tr>

                {/* total PPN */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>PPN ({parseFloat(dataInfo.ppn ?? 0).toPrecision()} %)</b>
                  </Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPN(data, dataInfo.ppn)
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNPastPeriod(
                              data,
                              dataInfo.dp,
                              dataInfo.retensi,
                              dataInfo.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNCurrentPeriod(
                              data,
                              dataInfo.dp,
                              dataInfo.retensi,
                              dataInfo.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {parseFloat(dataInfo.ppn ?? 0).toPrecision()} %
                  </Td>
                  <Td className="text-right">
                    {data
                      ? RupiahConvert(
                          parseInt(
                            calculationHandler.grandTotalPPNAllPeriod(
                              data,
                              dataInfo.dp,
                              dataInfo.retensi,
                              dataInfo.ppn
                            )
                          ).toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                </tr>

                {/* PPN + INVOICE */}
                <tr>
                  <Td colSpan={4} className="text-right">
                    <b>
                      INVOICE + PPN (
                      {parseFloat(dataInfo.ppn ?? 0).toPrecision()} %)
                    </b>
                  </Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoice(
                                data,
                                dataInfo.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoicePastPeriod(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi,
                                dataInfo.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoiceCurrentPeriod(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi,
                                dataInfo.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                  <Td className="text-right"></Td>
                  <Td className="text-right">
                    <b>
                      {data
                        ? RupiahConvert(
                            parseInt(
                              calculationHandler.grandTotalPPNWithInvoiceAllPeriod(
                                data,
                                dataInfo.dp,
                                dataInfo.retensi,
                                dataInfo.ppn
                              )
                            ).toString()
                          ).detail
                        : RupiahConvert('0').detail}
                    </b>
                  </Td>
                </tr>
              </>
            ) : (
              <tr>
                <Td colSpan={11}>
                  <DataStatus
                    loading={fetchStatus.loading}
                    text={
                      fetchStatus.loading
                        ? 'Memuat data . . .'
                        : fetchStatus.success
                        ? 'Tidak ada data'
                        : 'Data gagal dimuat'
                    }
                  />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* <div className="d-flex justify-content-end p-1">
          <table>
            <body>
              <tr>
                <td className="bg-white" style={{ fontSize: 14 }}>
                  TOTAL UPAH KERJA
                </td>
                <td className="bg-white pl-5 pr-4" style={{ fontSize: 14 }}>
                  :
                </td>
                <td className="bg-white text-right" style={{ fontSize: 14 }}>
                  {data
                    ? RupiahConvert(calculationHandler.grandTotal(data).toString()).detail
                    : RupiahConvert("0").detail}
                </td>
              </tr>
              <tr>
                <td className="bg-white" style={{ fontSize: 14 }}>
                  PEMBAYARAN PERIODE INI
                </td>
                <td className="bg-white pl-5 pr-4" style={{ fontSize: 14 }}>
                  :
                </td>
                <td className="bg-white text-right" style={{ fontSize: 14 }}>
                  {data
                    ? RupiahConvert(calculationHandler.grandTotalCurrentPeriod(data).toString())
                        .detail
                    : RupiahConvert("0").detail}
                </td>
              </tr>
              <tr>
                <td className="bg-white" style={{ fontSize: 14 }}>
                  RETENSI
                </td>
                <td className="bg-white pl-5 pr-4" style={{ fontSize: 14 }}>
                  :
                </td>
                <td className="bg-white text-right" style={{ fontSize: 14 }}>
                  {data
                    ? RupiahConvert(
                        String(
                          calculationHandler.grandTotal(data) -
                            calculationHandler.grandTotalPastPeriod(data)
                        )
                      ).detail
                    : RupiahConvert("0").detail}
                </td>
              </tr>
            </body>
          </table>
        </div> */}
      </Modal.Body>
      {modalConfig.type !== 'detail' && (
        <Modal.Footer>
          <ActionButton
            type="submit"
            text={modalConfig.type === 'update' ? 'Ubah' : 'Simpan'}
            variant={modalConfig.type === 'update' ? 'success' : 'primary'}
            className="px-4"
            loading={isSubmitting}
          />
        </Modal.Footer>
      )}
    </form>
  );
};
