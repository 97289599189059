import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Nav, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import Axios from 'axios';
import { BackButton, DataStatus } from 'components';
import { RealisasiProduksiApi } from 'api';
import {
  TabRealisasiBahan,
  TabRealisasiUpah,
  TabRealisasiSubkon,
  TabRealisasiAlatMesin,
  TabRealisasiOverhead,
} from '../RealisasiProduksi/Tabs';
import {
  mappingDataAlatMesin,
  mappingDataOverhead,
  responseFilterBahan,
  responseFilterUpah,
} from '../RealisasiProduksi/Utils';
import {
  RealisasiProduksiContext,
  withRealisasiProduksiProvider,
} from '../RealisasiProduksi/Contexts';
import { useSessionStorage } from 'hooks';
import { NavTab, TabPane, InfoSection } from '../RealisasiProduksi/Components';

const DetailRealisasiProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { setDataRealisasi } = useContext(RealisasiProduksiContext);

  const [tab, setTab] = useSessionStorage('tab_realisasi_root_detail', 'bahan');
  const [rawDataRealisasi, setRawDataRealisasi] = useState({});
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  const formInitialValues = {
    id_job_order: rawDataRealisasi?.id_job_order,
    no_realisasi_produksi: rawDataRealisasi?.no_realisasi_produksi,
    tgl_realisasi_produksi: rawDataRealisasi?.tgl_realisasi_produksi,
    nama_proyek: rawDataRealisasi?.nama_proyek ?? '',
    nama_barang_jadi: rawDataRealisasi?.nama_barang_jadi,
    isReadOnly: true,
  };

  const checkData = new Promise((resolve, reject) => {
    const data = location?.state?.dataJo;
    if (data) {
      return resolve(data);
    }

    return reject('Tidak ada data');
  });

  const getDataRealisasi = (id_job_order) => {
    Axios.all([
      RealisasiProduksiApi.getRealisasiBahan({ id_job_order }),
      RealisasiProduksiApi.getRealisasiUpah({ id_job_order }),
      RealisasiProduksiApi.getRealisasiSubkon({ id_job_order }),
      RealisasiProduksiApi.getRealisasiAlatMesin({
        id_job_order,
      }),
      RealisasiProduksiApi.getRealisasiOverhead({ id_job_order }),
    ])
      .then(
        Axios.spread((bahanRes, upahRes, subkonRes, alatRes, overheadRes) => {
          // bahan
          const { hw, pw, fs, bpf } = responseFilterBahan(
            bahanRes.data.data ?? []
          );

          // upah
          const { lc, lcf } = responseFilterUpah(upahRes.data.data ?? []);

          // subkon
          const subkon = subkonRes.data?.data;

          // alat mesin
          const alat_mesin = mappingDataAlatMesin(alatRes?.data?.data ?? []);

          // overhead
          const overhead = mappingDataOverhead(overheadRes?.data?.data ?? []);

          setDataRealisasi((prev) => ({
            ...prev,
            hardwood: hw,
            plywood: pw,
            bp: bpf,
            fs,
            lc,
            lcf,
            subkon,
            alat_mesin,
            overhead,
          }));

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  // Menangani fetching data ketika halaman di render
  const getInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    checkData
      .then((data) => {
        setRawDataRealisasi(data);
        getDataRealisasi(data.id_job_order, data.no_realisasi_produksi);
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Realisasi Produksi');
    getInitialData();

    return () => {
      sessionStorage.removeItem('tab_realisasi_root_detail');
    };
  }, []);

  const tabsConfig = [
    {
      tab: 'bahan',
      label: 'Realisasi Bahan',
      component: () => <TabRealisasiBahan />,
    },
    {
      tab: 'upah',
      label: 'Realisasi Upah',
      component: () => <TabRealisasiUpah />,
    },
    {
      tab: 'subkon',
      label: 'Realisasi Subkon',
      component: () => <TabRealisasiSubkon />,
    },
    {
      tab: 'alatMesin',
      label: 'Realisasi Alat Mesin',
      component: () => <TabRealisasiAlatMesin />,
    },
    {
      tab: 'overhead',
      label: 'Realisasi Overhead',
      component: () => <TabRealisasiOverhead />,
    },
  ];

  return (
    <Formik enableReinitialize initialValues={formInitialValues}>
      {() => (
        <>
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <b>Realisasi Produksi</b>
              <BackButton onClick={() => history.goBack()} />
            </Card.Header>
            <Card.Body>
              {fetchingStatus.loading ? (
                <DataStatus loading={true} text="Memuat data . . ." />
              ) : fetchingStatus.success ? (
                <InfoSection readOnly dataInfo={rawDataRealisasi} />
              ) : (
                <DataStatus text="Data gagal dimuat!" />
              )}
            </Card.Body>
          </Card>
          {!fetchingStatus.loading && fetchingStatus.success && (
            <Card>
              <Tab.Container defaultActiveKey={tab}>
                <Card.Header>
                  <Nav variant="tabs" defaultActiveKey={tab}>
                    {tabsConfig.map((val) => (
                      <NavTab
                        eventKey={val.tab}
                        title={val.label}
                        onClick={() => setTab(val.tab)}
                      />
                    ))}
                  </Nav>
                </Card.Header>

                <Card.Body>
                  <Tab.Content>
                    {tabsConfig.map(({ tab, component: Component }) => (
                      <TabPane eventKey={tab} component={<Component />} />
                    ))}
                  </Tab.Content>
                </Card.Body>
              </Tab.Container>
            </Card>
          )}
        </>
      )}
    </Formik>
  );
};

export default withRealisasiProduksiProvider(DetailRealisasiProduksi);
