import { Services } from '../../../services';

class KayuApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/kayu/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  create(data) {
    return Services.post('/kayu', data);
  }
  update(data) {
    return Services.put('/kayu', data);
  }
  delete(id_kondisi_aset) {
    return Services.post('/kayu/delete', id_kondisi_aset);
  }
  search(key) {
    return Services.get(`/kayu/page?q=${key}`);
  }
  show(id_kayu) {
    return Services.put('/kayu/show', id_kayu);
  }
  hide(id_kayu) {
    return Services.put('/kayu/hide', id_kayu);
  }
  getSatuan() {
    return Services.get('/kayu/satuan');
  }
}

export default new KayuApi();
