import { Services } from '../../../services';

class AnalisaRAPApi {
  get(params) {
    return Services.get('/analisa_barang_jadi/list_barang_jadi', { params });
  }

  getSingle(params) {
    return Services.get('/analisa_barang_jadi/single', { params });
  }

  getKayu(params) {
    return Services.get('/kayu/dropdown', { params });
  }

  getKelompokTipeBarangJadi(params) {
    return Services.get('/kelompok_tipe_barang_jadi/dropdown', { params });
  }

  getTipeBarangJadi(params) {
    return Services.get('/tipe_barang_jadi/page', { params });
  }

  getDropdownTipeBarangJadi(params) {
    return Services.get('/tipe_barang_jadi/dropdown', { params });
  }

  getSingleTipeBarangJadi(params) {
    return Services.get('/tipe_barang_jadi/single', { params });
  }

  getFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/page', { params });
  }

  getDropdownFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/dropdown', { params });
  }

  getSingleFinishingBarangJadi(params) {
    return Services.get('/finishing_barang_jadi/single', { params });
  }

  save(data) {
    return Services.post('/analisa_barang_jadi', data);
  }
}

export default new AnalisaRAPApi();
