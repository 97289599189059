import { Formik } from 'formik';
import { FilterModal, SelectSearch } from 'components';

const ModalFilterABJ = ({ dropdown, show, setShow, filter, setFilter }) => {
  const initialValues = {
    id_kelompok: filter.id_kelompok,
    id_satuan_jual: filter.id_satuan_jual,
    is_abj: filter.is_abj,
    status_approval: filter.status_approval,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...values, ...prev, filter: false }));
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      id_kelompok: undefined,
      id_satuan_jual: undefined,
      is_abj: undefined,
      status_approval: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ values, setFieldValue, setValues, handleSubmit }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            label="Kelompok Barang Jadi"
            placeholder="Pilih kelompok barang jadi"
            defaultValue={dropdown?.kelompok.find(
              (item) => item.value === values.id_kelompok
            )}
            option={dropdown?.kelompok ?? []}
            onChange={(e) => setFieldValue('id_kelompok', e.value)}
          />
          <SelectSearch
            label="Satuan Jual"
            placeholder="Pilih satuan jual"
            defaultValue={dropdown?.satuan_jual?.find(
              (item) => item.value === values.id_satuan_jual
            )}
            option={dropdown?.satuan_jual ?? []}
            onChange={(e) => setFieldValue('id_satuan_jual', e.value)}
          />
          <SelectSearch
            label="Status Analisa"
            placeholder="Pilih status analisa"
            defaultValue={dropdown?.status_analisa?.find(
              (item) => item.value === values.is_abj
            )}
            option={dropdown.status_analisa}
            onChange={(e) => setFieldValue('is_abj', e.value)}
          />
          <SelectSearch
            label="Status Approval"
            placeholder="Pilih status approval"
            defaultValue={dropdown.approval.find(
              (item) => item.value === values.status_approval
            )}
            option={dropdown.approval}
            onChange={(e) => setFieldValue('status_approval', e.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};

export default ModalFilterABJ;
