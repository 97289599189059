import React, { useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CRUDLayout,
  Input,
  DataStatus,
  ActionButton,
  SelectSearch,
  THead,
  TBody,
  Tr,
  Th,
  ThFixed,
  DeleteButton,
  Td,
  TdFixed,
} from 'components';
import { DropdownAnalisaBarangJadiFilterContext } from '../Context/DropdownAnalisaBarangJadiFilterContext';

const TableBarangJadi = ({ type, listBarangJadi, setListBarangJadi }) => {
  const formInitialValues = {
    id_item_buaso: '',
    kode_item: '',
    nama_item: '',
    nama_jenis: '',
    nama_satuan: '',
    qty: '',
  };

  const { dropdown } = useContext(DropdownAnalisaBarangJadiFilterContext);

  const formValidationSchema = Yup.object().shape({
    id_item_buaso: Yup.string().required('Pilih Barang Jadi'),
    qty: Yup.string().required('Qty. harus diisi'),
  });

  const formSubmitHandler = (values, { resetForm }) => {
    setListBarangJadi([...listBarangJadi, values]);
    resetForm();
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);
    return convert;
  };

  const hapusBarangJadi = (index) =>
    setListBarangJadi(listBarangJadi.filter((item, i) => i !== index));

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <>
            {type !== 'DETAIL' && (
              <div className="d-flex justify-content-end align-items-end mb-2">
                <div className="col-md-6 pl-0">
                  <SelectSearch
                    label="Barang Jadi"
                    name="id_item_buaso"
                    placeholder="Pilih Barang Jadi"
                    onChange={(val) => {
                      setValues({
                        ...values,
                        id_item_buaso: val.value,
                        nama_item: val.label,
                        kode_item: val.kode_item,
                        nama_jenis: val.nama_jenis,
                        nama_satuan: val.nama_satuan,
                      });
                    }}
                    option={dropdown.barangjadi_peluang ?? []}
                    defaultValue={
                      values.id_item_buaso
                        ? dropdown?.barangjadi_peluang?.find(
                            (item) => item.value === values.id_item_buaso
                          )
                        : ''
                    }
                    error={
                      errors.id_item_buaso && touched.id_item_buaso && true
                    }
                    errorText={
                      errors.id_item_buaso &&
                      touched.id_item_buaso &&
                      errors.id_item_buaso
                    }
                  />
                </div>

                <div className="col-md-3">
                  <Input
                    label="Qty"
                    type="text"
                    name="qty"
                    value={values.qty}
                    onChange={(e) => {
                      const value = decimalConvert(e.target.value);
                      setFieldValue('qty', value);
                    }}
                    error={errors.qty && touched.qty && true}
                    errorText={errors.qty && touched.qty && errors.qty}
                  />
                </div>

                <div className="col-md-3">
                  <ActionButton
                    type="button"
                    className="btn btn-primary mb-2"
                    size="md"
                    text="Tambah Data"
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Formik>

      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{ width: 100 }}>Kode Barang Jadi</Th>
            <Th>Barang Jadi</Th>

            <Th>Satuan</Th>
            <Th>Qty</Th>
            {type !== 'DETAIL' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>
        <TBody>
          {listBarangJadi.length !== 0 ? (
            listBarangJadi.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <Td>{val.nama_item}</Td>
                <Td>{val.nama_satuan}</Td>
                <Td>
                  <div className="text-right">{val.qty}</div>
                </Td>
                {type !== 'DETAIL' && (
                  <TdFixed>
                    <DeleteButton onClick={() => hapusBarangJadi(index)} />
                  </TdFixed>
                )}
              </Tr>
            ))
          ) : (
            <tr>
              <td colSpan={type !== 'DETAIL' ? 7 : 6} className="text-center">
                <DataStatus text="Tidak Ada Data" />
              </td>
            </tr>
          )}
        </TBody>
      </CRUDLayout.Table>
    </>
  );
};

export default TableBarangJadi;
