import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Card, Nav, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Approval, BackButton, DataStatus } from 'components';
import { DateConvert } from 'utilities';
import { RAPApi } from 'api';
import { TableBarangJadi } from '../Section';
import {
  DropdownAnalisaBarangJadiContext,
  DropdownAnalisaBarangJadiContextProvider,
  RAPContext,
  RAPContextProvider,
} from '../RAPContext';
import {
  TabInfoPeluang,
  TabFilePeluang,
  TabGambarPeluang,
} from '../PeluangSection';

const DetailRAP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const DetailRAPContent = () => {
    const { setType } = useContext(RAPContext);
    const { setTriggerFetch } = useContext(DropdownAnalisaBarangJadiContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataRAP, setDataRAP] = useState({});
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      nomor: '',
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      RAPApi.getSingle({ id_rap: id })
        .then(({ data }) => {
          setDataRAP(data.data);
          setTriggerFetch(true);
        })
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Produksi (RAP)');
      getInitialData();

      return () => {
        setIsPageLoading(false);
      };
    }, []);

    const ModalPeluang = () => {
      const [dataInfoPeluang, setDataInfoPeluang] = useState({});
      const [dataTablePeluang, setDataTablePeluang] = useState([]);
      const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
        loading: true,
        success: false,
      });
      const tabsRef = useRef();

      const [defaultTabs, setDefaultTabs] = useState('info');
      const [dataGambar, setDataGambar] = useState([]);
      const [dataFile, setDataFile] = useState([]);

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'info',
          label: 'Informasi Umum',
          component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
        },
        {
          tab: 'gambar',
          label: 'Gambar',
          component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
        },
        {
          tab: 'file',
          label: 'File',
          component: ({ ...rest }) => <TabFilePeluang {...rest} />,
        },
      ];

      // Menangani cek type dari file yang dikirim
      const checkTypeAndStoreFile = (data) => {
        let gambar = [];
        let file = [];

        data.map((val) => {
          const map = {
            link: val.path_gambar,
            id: val.id_peluang_gambar,
            nama: '',
            data: null,
          };

          const generalFileType = map.link.split('.').pop();
          const fileName = map.link.split('/').pop();

          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push({ ...map, nama: fileName });
          }

          return file.push({ ...map, nama: fileName });
        });

        setDataGambar(gambar);
        setDataFile(file);
      };

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, tabs) => {
        e.preventDefault();
        setDefaultTabs(tabs);
      };

      const getDataRAE = (noPeluang) => {
        setFetchPeluangStatus({
          loading: true,
          success: false,
        });

        RAPApi.getDetailPeluangRAP({ no_peluang: noPeluang })
          .then((res) => {
            const dataInfo = res.data.data ?? {};
            const dataTable = res.data.data.detail ?? {};

            setDataInfoPeluang(dataInfo);
            checkTypeAndStoreFile(dataInfo?.gambar ?? []);
            setDataTablePeluang(dataTable);
            setFetchPeluangStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchPeluangStatus({
              loading: false,
              success: false,
            });
          });
      };

      useEffect(() => {
        if (modalPeluangConfig.nomor) {
          getDataRAE(modalPeluangConfig.nomor);
        } else {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        }
      }, []);

      if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
        return (
          <Modal
            size="xl"
            show={modalPeluangConfig.show}
            onHide={() =>
              setModalPeluangConfig({
                ...modalPeluangConfig,
                show: false,
              })
            }
          >
            <Modal.Header closeButton>
              <b>Detail Peluang</b>
            </Modal.Header>
            <Modal.Body>
              {fetchPeluangStatus.loading ? (
                <DataStatus loading text="Memuat data . . ." />
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </Modal.Body>
          </Modal>
        );
      }

      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            <Card.Header>
              {/* Tab Header */}
              <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
                {tabsConfig.map((val, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      ref={tabsRef}
                      href={`#${val.tab}`}
                      onClick={(e) => onTabsChangeHandler(e, val.tab)}
                    >
                      {val.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === defaultTabs && (
                    <Component
                      key={index}
                      type="DETAIL"
                      dataInfoPeluang={dataInfoPeluang}
                      dataTablePeluang={dataTablePeluang}
                      dataGambar={dataGambar}
                      dataFile={dataFile}
                      setDataGambar={setDataGambar}
                      setDataFile={setDataFile}
                    />
                  )
              )}
            </Card.Body>
          </Modal.Body>
        </Modal>
      );
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value, onClick, link }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className="pl-4 pr-2">:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row className="d-flex">
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. RAP"
                  value={
                    dataRAP.tgl_rap
                      ? DateConvert(new Date(dataRAP.tgl_rap)).detail
                      : '-'
                  }
                />

                <InfoItem
                  title="No. RAP"
                  value={dataRAP.no_rap ? dataRAP.no_rap : '-'}
                />

                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataRAP.no_peluang ? dataRAP.no_peluang : '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      nomor: dataRAP.no_peluang,
                    })
                  }
                />

                <InfoItem
                  title="Customer"
                  value={dataRAP.nama_customer ? dataRAP.nama_customer : '-'}
                />

                <InfoItem title="ATT" value={dataRAP.att ? dataRAP.att : '-'} />

                <InfoItem
                  title="Tgl. Selesai Barang Jadi"
                  value={
                    dataRAP.tgl_selesai_spk
                      ? DateConvert(new Date(dataRAP.tgl_selesai_spk)).detail
                      : '-'
                  }
                />

                <InfoItem
                  title="Tgl. SPK"
                  value={
                    dataRAP.tgl_spk
                      ? DateConvert(new Date(dataRAP.tgl_spk)).detail
                      : '-'
                  }
                />

                <InfoItem title="No. SPK" value={dataRAP.no_spk ?? '-'} />
              </tbody>
            </table>
          </Col>

          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem title="Proyek" value={dataRAP.nama_proyek} />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    const TableSection = () => {
      const [dataBarangJadi, setDataBarangJadi] = useState([]);

      const getDataBarangJadi = () => {
        const barangJadi = dataRAP.detail_barang_jadi
          ? dataRAP.detail_barang_jadi
          : [];
        const filter = barangJadi.map((val) => {
          return {
            is_edit: val.is_edit,
            id_rap_detail: val.id_rap_detail,
            id_barang_jadi: val.id_barang_jadi,
            nama_item: val.nama_item,
            kode_item: val.kode_item,
            qty: val.qty_rap ? val.qty_rap : 0,
            satuan: val.nama_satuan,
            harga_satuan: val.harga_satuan_rap
              ? parseFloat(val.harga_satuan_rap)
              : 0,
          };
        });

        setDataBarangJadi(filter);
      };

      useEffect(() => {
        setType('DETAIL');
        getDataBarangJadi();
      }, []);

      return (
        <TableBarangJadi
          type="DETAIL"
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      );
    };

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data RAP</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection />
                <hr />
                <TableSection />
              </>
            )}
          </Card.Body>
        </Card>
        {!isPageLoading && <Approval data={dataRAP} />}
        {modalPeluangConfig.show && <ModalPeluang />}
      </>
    );
  };

  return (
    <RAPContextProvider>
      <DropdownAnalisaBarangJadiContextProvider>
        <DetailRAPContent />
      </DropdownAnalisaBarangJadiContextProvider>
    </RAPContextProvider>
  );
};

export default DetailRAP;
