import React, { useEffect } from 'react';
import { DataStatus, Table, ThFixed } from 'components';
import { DateConvert } from 'utilities';

const TabUpah = ({ dataUpah }) => {
  const TableUpah = () => {
    const getGrup = (ref) => {
      if (ref === 'RHW') return 'Hardwood';
      if (ref === 'RPW') return 'Plywood';
      if (ref === 'RFS') return 'Factory Supply';
      if (ref === 'RPF') return 'Penunjang Finishing';
      if (ref === 'RLC') return 'Labour Cost';
      if (ref === 'RLF') return 'Labour Cost Finishing';
      if (ref === 'RSF') return 'Subkon Finishing';
      if (ref === 'RMP') return 'Machine Process';
      if (ref === 'ROP') return 'Biaya Overhead';
      return '-';
    };

    // const mapDataUpah = () => {
    //   const dataRincian = [];
    //   const dataDetail = dataUpah.map((val) => {
    //     if (val.rincian) {
    //       val.rincian.map((item) => dataRincian.push(item));
    //     }
    //     return {
    //       id_kontrak_upah: val.id_kontrak_upah,
    //       tgl_bdp_upah: val.tgl_bdp_upah ?? "-",
    //       no_bdp_upah: val.no_bdp_upah ?? "-",
    //       grup: getGrup(val?.ref ?? "-"),
    //       nama_vendor: val.nama_vendor ?? "-",
    //     };
    //   });

    //   const mergeData = dataDetail.map((val) => ({
    //     ...dataRincian.find((item) => item.id_kontrak_upah === val.id_kontrak_upah && item),
    //     ...val,
    //   }));

    //   console.log(mergeData);
    // };

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          padding: 0,
          fontSize: '14px',
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={`${props.className} p-1`}
        style={{
          ...props.style,
          fontSize: '13px',
        }}
      >
        {props.children}
      </td>
    );

    // useEffect(() => {
    //   mapDataUpah();
    // }, []);

    return (
      <>
        <div className="pb-2">
          <b className="p-1">List BDP Upah</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed className="py-2 text-center">No.</ThFixed>
              <ThFixed className="p-2 text-center">Tgl. BDP</ThFixed>
              <ThFixed className="p-2 text-center">No. BDP</ThFixed>
              <Th className="p-2 text-center">Grup</Th>
              <Th className="p-2 text-center">Vendor</Th>
              <Th className="p-2 text-center" style={{ width: 400 }}>
                Item Upah
              </Th>
              <Th className="p-2 text-center">Satuan</Th>
              <Th className="p-1 text-center" style={{ width: 70 }}>
                Qty.
              </Th>
              <Th className="p-1 text-center" style={{ width: 70 }}>
                Persentase Periode Ini
              </Th>
            </tr>
          </thead>
          <tbody>
            {dataUpah.length > 0 ? (
              dataUpah.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      {val.tgl_bdp_upah
                        ? DateConvert(new Date(val.tgl_bdp_upah)).defaultDMY
                        : '-'}
                    </Td>
                    <Td>{val?.no_bdp_upah ?? '-'}</Td>
                    <Td>{getGrup(val?.ref ?? '-')}</Td>
                    <Td>{val?.nama_vendor ?? '-'}</Td>
                    <Td>
                      {val.rincian
                        ? val.rincian.map((item) => (
                            <tr>
                              <td width="800">{item.uraian}</td>
                            </tr>
                          ))
                        : ''}
                    </Td>
                    <Td>
                      {val.rincian
                        ? val.rincian.map((item) => (
                            <tr>
                              <td width="200">{item.nama_satuan}</td>
                            </tr>
                          ))
                        : ''}
                    </Td>
                    <Td>
                      {val.rincian
                        ? val.rincian.map((item) => (
                            <tr>
                              <td className="text-right" width="200">
                                {parseFloat(item.qty).toPrecision()}
                              </td>
                            </tr>
                          ))
                        : ''}
                    </Td>
                    <Td>
                      {val.rincian
                        ? val.rincian.map((item) => (
                            <tr>
                              <td className="text-right" width="200">
                                {parseFloat(
                                  item.persentase_periode_ini
                                ).toPrecision() + ' %'}
                              </td>
                            </tr>
                          ))
                        : ''}
                    </Td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <Td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  // Menampilkan konten halaman saat data berhasil diperoleh
  return <TableUpah />;
};

export default TabUpah;
