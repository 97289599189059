import { Services } from '../../../services';

class FinishingBarangJadiApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/finishing_barang_jadi/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getItemBahanPenunjang() {
    return Services.get('/finishing_barang_jadi/dropdown?tipe=bp');
  }
  getItemSubkon() {
    return Services.get('/finishing_barang_jadi/dropdown?tipe=sc');
  }
  getItemLabourCost() {
    return Services.get('/finishing_barang_jadi/dropdown?tipe=lc');
  }
  getDetail(id) {
    return Services.get(
      `/finishing_barang_jadi/single?id_finishing_barang_jadi=${id}`
    );
  }
  create(data) {
    return Services.post('/finishing_barang_jadi', data);
  }
  simpanDetail(data) {
    return Services.post('/finishing_barang_jadi/detail', data);
  }
  editDetail(data) {
    return Services.put('/finishing_barang_jadi/detail', data);
  }
  update(data) {
    return Services.put('/finishing_barang_jadi', data);
  }
  delete(id) {
    return Services.post('/finishing_barang_jadi/delete', id);
  }
  deleteDetail(data) {
    return Services.post('/finishing_barang_jadi/detail_delete/', data);
  }
  search(key) {
    return Services.get(`/finishing_barang_jadi/page/?q=${key}`);
  }
  show(id) {
    return Services.put('/finishing_barang_jadi/show', id);
  }
  hide(id) {
    return Services.put('/finishing_barang_jadi/hide', id);
  }
}

export default new FinishingBarangJadiApi();
