import * as yup from 'yup';

export const formInitialValues = (data) => ({
  id_permintaan_produksi: data.id_permintaan_produksi,
  id_job_order: data.id_job_order,
  tgl_permintaan_produksi: data.tgl_permintaan_produksi
    ? new Date(data.tgl_permintaan_produksi)
    : '',
  no_permintaan_produksi: data?.no_permintaan_produksi ?? '',
});

export const validationSchema = yup.object().shape({
  tgl_permintaan_produksi: yup
    .string()
    .required('Tgl. Permintaan Produksi tidak boleh kosong'),
});
