import { useContext } from 'react';
import { RealisasiProduksiContext } from '../../Contexts';
import { TableRealisasiOverhead } from './Table';

const TabRealisasiOverhead = () => {
  const { dataItem, dataRealisasi, dataHistory } = useContext(
    RealisasiProduksiContext
  );
  return (
    <div>
      <TableRealisasiOverhead
        dataOverhead={dataItem.overhead}
        dataHistoryOverhead={dataHistory.overhead}
        dataRealisasiOverhead={dataRealisasi.overhead}
      />
    </div>
  );
};

export default TabRealisasiOverhead;
