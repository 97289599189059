import React, { useContext, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, Input, InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import { RealisasiProduksiContext } from '../Contexts';

export const ModalRealisasiProduksi = ({
  tabKey, //  key pada state dataRealisasi di RealisasiProduksiContext : hardwood, plywood, lc, ...etc
  title,
  withGudang,
  processedData,
  modalConfig,
  setModalConfig,
  setAlertConfig,
  setProcessedData,
}) => {
  const { setDataRealisasi } = useContext(RealisasiProduksiContext);

  const ModalForm = () => {
    const formInitialValues = {
      id_transfer: processedData.id_transfer ?? '',
      id_realisasi: processedData.id_realisasi ?? '',
      tgl_realisasi: processedData.tgl_realisasi,
      no_realisasi: processedData.no_realisasi,
      tgl_transfer: processedData.tgl_transfer,
      no_transfer: processedData.no_transfer,
      nama_item: processedData.nama_item,
      satuan: processedData.satuan,
      qty_transfer: processedData.qty_transfer ?? 0,
      qty_realisasi: processedData.qty_realisasi ?? 0,
      qty_realisasi_lalu: processedData.qty_realisasi_lalu ?? 0,
      gudang_asal: processedData.gudang_asal,
      gudang_tujuan: processedData.gudang_tujuan,
    };

    const formValidationSchema = Yup.object().shape({
      qty_realisasi: Yup.string()
        .required('Masukan qty realisasi')
        .test({
          test: (val, parent) =>
            parseFloat(
              parseFloat(val) + parseFloat(parent?.parent?.qty_realisasi_lalu)
            ) <= parent?.parent?.qty_transfer,
          message:
            'Qty realisasi s/d periode ini tidak boleh lebih dari qty. transfer',
        })
        .test({
          test: (val) => parseFloat(val) > 0,
          message: 'Minimal qty. adalah 0',
        }),
    });

    const formSubmitHandler = (values) => {
      const realisasiDataProduksi = () => {
        setDataRealisasi((prev) => ({
          ...prev,
          [tabKey]: [...prev[tabKey], values],
        }));

        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil direalisasi!',
        });

        setModalConfig({
          ...modalConfig,
          show: false,
        });

        setProcessedData({});
      };

      const updateDataProduksi = () => {
        setDataRealisasi((prev) => ({
          ...prev,
          [tabKey]: prev[tabKey].toSpliced(processedData.index, 1, values),
        }));

        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });

        setModalConfig({
          ...modalConfig,
          show: false,
        });

        setProcessedData({});
      };

      modalConfig.type === 'create'
        ? realisasiDataProduksi()
        : updateDataProduksi();
    };

    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {modalConfig.type === 'detail' && (
                  <>
                    <InfoItemHorizontal
                      label="Tgl. Realisasi"
                      text={
                        values?.tgl_realisasi
                          ? DateConvert(new Date(values.tgl_realisasi)).detail
                          : '-'
                      }
                    />
                    <InfoItemHorizontal
                      label="No. Realisasi"
                      text={values.no_realisasi}
                    />
                  </>
                )}
                <InfoItemHorizontal
                  label="Tgl. Transfer"
                  text={
                    values?.tgl_transfer
                      ? DateConvert(new Date(values.tgl_transfer)).detail
                      : '-'
                  }
                />
                <InfoItemHorizontal
                  label="No. Transfer"
                  text={values.no_transfer}
                />
                <InfoItemHorizontal
                  label="Item Bahan"
                  text={values.nama_item}
                />
                <InfoItemHorizontal label="Satuan" text={values.satuan} />
                <InfoItemHorizontal
                  label="Qty. Transfer"
                  text={values.qty_transfer}
                />
                {withGudang && (
                  <>
                    <InfoItemHorizontal
                      label="Gudang Asal Transfer"
                      text={values.gudang_asal}
                    />
                    <InfoItemHorizontal
                      label="Gudang Tujuan Tranfer"
                      text={values.gudang_tujuan}
                    />
                  </>
                )}
                <InfoItemHorizontal
                  label="Qty. Realisasi Periode Lalu"
                  text={`${values?.qty_realisasi_lalu ?? '-'} ${
                    values.satuan ?? ''
                  }`}
                />
                <Row className="mt-2">
                  <Col>
                    <Input
                      label="Qty. Realisasi"
                      name="qty_realisasi"
                      placeholder="Masukan qty. realisasi"
                      value={values.qty_realisasi}
                      onChange={handleChange}
                      error={
                        Boolean(errors.qty_realisasi) && touched.qty_realisasi
                      }
                      errorText={errors.qty_realisasi}
                    />
                  </Col>
                  <Col>
                    <Input
                      label="Qty. Realisasi S/D Periode Ini"
                      value={
                        parseFloat(
                          values.qty_realisasi < 0.0001
                            ? 0
                            : values.qty_realisasi
                        ) + parseFloat(values.qty_realisasi_lalu ?? 0)
                      }
                      readOnly
                    />
                  </Col>
                </Row>
              </Modal.Body>
              {modalConfig.type !== 'detail' && (
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    variant={
                      modalConfig.type === 'update' ? 'success' : 'primary'
                    }
                    text={modalConfig.type === 'update' ? 'Ubah' : 'Simpan'}
                    className="px-4"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              )}
            </form>
          );
        }}
      </Formik>
    );
  };

  const ModalDelete = () => {
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteDataHandler = () => {
      setIsDeleting(true);

      setDataRealisasi((prev) => ({
        ...prev,
        [tabKey]: prev[tabKey].toSpliced(processedData.index, 1),
      }));

      setAlertConfig({
        show: true,
        variant: 'primary',
        text: 'Data berhasil dihapus!',
      });

      setIsDeleting(false);

      setProcessedData({});

      setModalConfig({
        ...modalConfig,
        show: false,
      });
    };

    return (
      <Modal.Body className="text-center">
        <p>
          Apakah anda yaking menghapus data ini?
          <br />
          <small className="text-danger">
            Data yang dihapus tidak dapat dikembalikan!
          </small>
        </p>
        <div>
          <ActionButton
            variant="outline-dark"
            text="Batal"
            className="m-1"
            onClick={() => {
              setModalConfig({
                ...modalConfig,
                show: false,
              });
              setProcessedData({});
            }}
          />
          <ActionButton
            variant="danger"
            text="Hapus Data"
            className="m-1"
            onClick={deleteDataHandler}
            loading={isDeleting}
          />
        </div>
      </Modal.Body>
    );
  };

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        setProcessedData({});
      }}
    >
      <Modal.Header closeButton>
        <b>
          {modalConfig.type === 'detail'
            ? 'Detail ' + title
            : modalConfig.type === 'create'
            ? 'Tambah ' + title
            : modalConfig.type === 'update'
            ? 'Ubah ' + title
            : modalConfig.type === 'delete' && 'Hapus ' + title}
        </b>
      </Modal.Header>
      {modalConfig.type === 'delete' ? <ModalDelete /> : <ModalForm />}
    </Modal>
  );
};
