import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  Input,
  InputCurrency,
  SelectSearch,
  Td,
  Tr,
} from 'components';
import { DropdownPermintaanProduksiContext } from '../../Context';

export const InputLC = ({ type = 'create', data, onSubmit, onCancel }) => {
  const { dropdownSatuan } = useContext(DropdownPermintaanProduksiContext);
  const isCreate = type === 'create';

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        setValues,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Tr>
            <Td></Td>

            <Td>
              <Input
                placeholder="Tuliskan Uraian Pekerjaan"
                name="uraian"
                defaultValue={values.uraian}
                onChange={({ target }) =>
                  setValues((prev) => ({
                    ...prev,
                    uraian: target.value,
                  }))
                }
                error={errors.uraian && touched.uraian}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                className="text-right"
                name="qty"
                value={values.qty}
                onChange={(value) => setFieldValue('qty', value)}
                error={errors.qty && touched.qty}
              />
            </Td>

            <Td>
              <SelectSearch
                placeholder="Pilih Satuan"
                name="id_satuan"
                option={dropdownSatuan?.data ?? []}
                loading={dropdownSatuan?.isLoading}
                defaultValue={dropdownSatuan?.data?.find(
                  ({ id_satuan }) => values.id_satuan === id_satuan
                )}
                onChange={(val) =>
                  setValues((prev) => ({
                    ...prev,
                    id_satuan: val.id_satuan,
                    nama_satuan: val.nama_satuan,
                  }))
                }
                error={errors.id_satuan && touched.id_satuan}
              />
            </Td>

            <Td>
              <InputCurrency
                allowLeadingZeros
                className="text-right"
                name="unit_price"
                value={parseFloat(values.unit_price)}
                onChange={(value) => setFieldValue('unit_price', value)}
                error={errors.unit_price && touched.unit_price}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                className="text-right"
                name="konstanta"
                decimalScale={6}
                value={parseFloat(values.konstanta)}
                onChange={(value) => setFieldValue('konstanta', value)}
                error={errors.konstanta && touched.konstanta}
              />
            </Td>

            <Td>
              <InputCurrency
                readOnly
                className="text-right"
                name="subtotal"
                value={calcSubtotalLC(values)}
              />
            </Td>

            <Td>
              {isCreate ? (
                <ActionButton
                  size="sm"
                  variant="primary"
                  className="btn-block"
                  text={<IoAddOutline />}
                  onClick={handleSubmit}
                />
              ) : (
                <ButtonGroup>
                  <ActionButton
                    size="sm"
                    variant="outline-success"
                    text={<IoCheckmarkOutline />}
                    onClick={handleSubmit}
                  />
                  <ActionButton
                    size="sm"
                    variant="outline-danger"
                    text={<IoCloseOutline />}
                    onClick={onCancel}
                  />
                </ButtonGroup>
              )}
            </Td>
          </Tr>
        );
      }}
    </Formik>
  );
};

const formInitialValues = (data) => ({
  uraian: data?.uraian ?? '',
  qty: parseFloat(data?.qty ?? 0),
  id_satuan: data?.id_satuan ?? '',
  nama_satuan: data?.nama_satuan ?? '',
  unit_price: data?.unit_price ?? 0,
  konstanta: parseFloat(data?.konstanta ?? 1),
});

const validationSchema = yup.object().shape({
  uraian: yup.string().required(),
  qty: yup.string().required(),
  id_satuan: yup.string().required(),
  unit_price: yup.string().required(),
  konstanta: yup.string().required(),
});

export const calcSubtotalLC = (data) => {
  return Math.round(
    parseFloat(data.qty).toFixed(6) *
      parseFloat(data.unit_price) *
      parseFloat(data.konstanta).toFixed(6)
  );
};
