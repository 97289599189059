import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Card, Row, Col, Nav } from 'react-bootstrap';
import { Alert, BackButton, DataStatus } from 'components';
import { DateConvert } from 'utilities';
import {
  TabBahan,
  TabUpah,
  TabAlatMesin,
  TabSubcont,
  TabOverhead,
  TabSisaProduksi,
} from './Tabs';
import { CloseJobOrderApi } from 'api';

const DetailCloseJobOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { header } = location.state || {}; // MENYIMPAN DATA ITEM DARI GET PAGE UNTUK INFO SECTION
  const { id_job_order } = useParams();
  const [listBDP, setListBDP] = useState({});
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  // Menangani fetching data ketika halaman di render
  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    CloseJobOrderApi.getListBDP({
      id_job_order,
      no_job_order: header.no_job_order,
    })
      .then((listBDP) => {
        setListBDP(listBDP?.data?.data ?? {});
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch((e) =>
        setFetchingStatus({
          loading: false,
          success: false,
        })
      );
  };

  useEffect(() => {
    setNavbarTitle('Close Job Order');
    getInitialData();

    return () => {};
  }, []);

  const InfoSection = () => {
    const InfoItems = ({ label, value }) => (
      <tr style={{ fontSize: 14 }}>
        <td className="text-nowrap">{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );
    const ShowData = ({ label, value }) => (
      <div className="mb-2">
        <small
          className="text-secondary"
          style={{ opacity: 0.8, textTransform: 'capitalize' }}
        >
          {label}
        </small>
        <div className="text-dark" style={{ fontWeight: 500 }}>
          {value ? value : '-'}
        </div>
      </div>
    );

    return (
      <div>
        <Row>
          <Col md>
            <table>
              <tbody>
                <InfoItems
                  label="Tgl. Job Order"
                  value={
                    header?.tgl_job_order
                      ? DateConvert(new Date(header.tgl_job_order)).detail
                      : '-'
                  }
                />
                <InfoItems
                  label="No. Job Order"
                  value={header?.no_job_order ?? '-'}
                />
                <InfoItems label="No. RAP" value={header?.no_rap ?? '-'} />
                <InfoItems label="No. SPK" value={header?.no_spk ?? '-'} />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table>
              <tbody>
                <InfoItems
                  label="Customer"
                  value={header?.nama_customer ?? '-'}
                />
                <InfoItems
                  label="Barang Jadi"
                  value={header?.nama_barang_jadi ?? '-'}
                />
                <InfoItems
                  label="Qty. Job Order "
                  value={`${parseFloat(header?.qty_rap ?? 0).toPrecision()} ${
                    header?.nama_satuan ?? ''
                  }`}
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row className="mb-2">
          <Col>
            <ShowData
              label="Tgl. Close Job Order"
              value={
                header?.tgl_close_job_order
                  ? DateConvert(new Date(header?.tgl_close_job_order)).detail
                  : '-'
              }
            />
          </Col>
          <Col>
            <ShowData
              label="No. Close Job Order"
              value={header?.no_close_job_order ?? '-'}
            />
          </Col>
          <Col>
            <ShowData
              label="Qty. Barang Jadi Yang Telah Selesai Di Produksi"
              value={`${parseFloat(
                header?.qty_realisasi_sum ?? 0
              ).toPrecision()} ${header?.nama_satuan ?? ''}`}
            />
          </Col>
        </Row>
      </div>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState('bahan'); //State untuk menampung tabs yang aktif

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan',
        label: 'Bahan',
        component: () => <TabBahan dataBahan={listBDP?.bahan ?? []} />,
      },
      {
        tab: 'upah',
        label: 'Upah',
        component: () => <TabUpah dataUpah={listBDP?.upah ?? []} />,
      },
      {
        tab: 'alatMesin',
        label: 'Alat & Mesin',
        component: () => (
          <TabAlatMesin dataAlatMesin={listBDP?.alat_mesin ?? []} />
        ),
      },
      {
        tab: 'subcont',
        label: 'Subcont',
        component: () => <TabSubcont dataSubcont={listBDP?.subkon ?? []} />,
      },
      {
        tab: 'overhead',
        label: 'Overhead',
        component: () => <TabOverhead dataOverhead={listBDP?.overhead ?? []} />,
      },
      {
        tab: 'sisaProduksi',
        label: 'Sisa Produksi',
        component: () => (
          <TabSisaProduksi dataSisaProduksi={listBDP?.sisa_produksi ?? []} />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <Card>
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <TabsNav key={index} tab={val.tab} label={val.label} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            ({ tab, component: Component }, index) =>
              tab === tabs && <Component key={index} />
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Close Job Order</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        {fetchingStatus.loading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : fetchingStatus.success ? (
          <>
            <InfoSection />
            <TableSection />
          </>
        ) : (
          <DataStatus text="Data gagal dimuat!" />
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailCloseJobOrder;
