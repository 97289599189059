import { createContext } from 'react';
import { useQuery } from 'react-query';
import { RealisasiProduksiApi } from 'api';
import { useQueryDropdown } from 'config/useQueryDropdown';

export const DropdownRealisasiProduksiContext = createContext();

export const DropdownRealisasiProduksiProvider = ({ children }) => {
  const dropdownCustomer = useQuery(
    ['dropdown_customer', 'realisasi_produksi'],
    () =>
      RealisasiProduksiApi.getDropdown({ tipe: 'nama_customer' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.nama_customer,
            label: v.nama_customer,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownSPK = useQuery(
    ['dropdown_spk', 'realisasi_produksi'],
    () =>
      RealisasiProduksiApi.getDropdown({ tipe: 'no_spk' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.no_spk,
            label: v.no_spk,
          })) ?? []
      ),
    useQueryDropdown
  );

  return (
    <DropdownRealisasiProduksiContext.Provider
      value={{
        dropdownSPK,
        dropdownCustomer,
      }}
    >
      {children}
    </DropdownRealisasiProduksiContext.Provider>
  );
};

export const withDropdownRealisasiProduksiProvider = (Component) => (props) => {
  return (
    <DropdownRealisasiProduksiProvider>
      <Component {...props} />
    </DropdownRealisasiProduksiProvider>
  );
};
