import { useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownTransferSisaProduksiContext } from '../Contexts';

export const ModalFilterTransferSisaProduksi = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const defaultDropdown = { value: null, label: 'Semua' };
  const { dropdownJobOrder } = useContext(DropdownTransferSisaProduksiContext);
  const optItems = [defaultDropdown].concat(dropdownJobOrder?.data ?? []);

  const initialValues = {
    tgl_realisasi_produksi_start: filter?.tgl_realisasi_produksi_start,
    tgl_realisasi_produksi_end: filter?.tgl_realisasi_produksi_end,
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    id_barang_jadi: filter?.id_barang_jadi,
    tgl_transfer_sisa_produksi_start: filter?.tgl_transfer_sisa_produksi_start,
    tgl_transfer_sisa_produksi_end: filter?.tgl_transfer_sisa_produksi_end,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      tgl_realisasi_produksi_start: undefined,
      tgl_realisasi_produksi_end: undefined,
      tgl_transfer_sisa_produksi_start: undefined,
      tgl_transfer_sisa_produksi_end: undefined,
      id_barang_jadi: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Job Order"
            placeholderText="Pilih Tanggal Job Order"
            startDate={
              values.tgl_job_order_start
                ? new Date(values.tgl_job_order_start)
                : ''
            }
            endDate={
              values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_job_order_start', startDate);
              setFieldValue('tgl_job_order_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Job Order"
            placeholder="Pilih Item Job Order"
            loading={dropdownJobOrder?.isLoading}
            option={optItems}
            defaultValue={
              values.id_barang_jadi
                ? [].find((v) => values.id_barang_jadi === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_barang_jadi', value)}
          />

          <DatePicker
            selectsRange
            label="Tgl. Realisasi Produksi"
            placeholderText="Pilih Tanggal Realisasi Produksi"
            startDate={
              values.tgl_realisasi_produksi_start
                ? new Date(values.tgl_realisasi_produksi_start)
                : ''
            }
            endDate={
              values.tgl_realisasi_produksi_end
                ? new Date(values.tgl_realisasi_produksi_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_realisasi_produksi_start', startDate);
              setFieldValue('tgl_realisasi_produksi_end', endDate);
            }}
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Transfer Sisa Produksi"
            placeholderText="Pilih Tanggal Transfer Sisa Produksi"
            startDate={
              values.tgl_transfer_start
                ? new Date(values.tgl_transfer_sisa_produksi_start)
                : ''
            }
            endDate={
              values.tgl_transfer_sisa_produksi_end
                ? new Date(values.tgl_transfer_sisa_produksi_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_transfer_sisa_produksi_start', startDate);
              setFieldValue('tgl_transfer_sisa_produksi_end', endDate);
            }}
            monthsShown={2}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
