import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  IoAddOutline,
  IoTrashOutline,
  IoPencilOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import ReactSelect from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, Alert } from 'components';
import { RupiahConvert } from 'utilities';

const TableListTPLC = ({
  dataSelectTPLC,
  dataTPLC,
  setDataTPLC,
  defaultKonst,
}) => {
  const { id } = useParams();
  const [processedData, setProcessedData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'danger',
  });

  let lastUrutanItem = 1;
  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const formInitialValues = {
    id_barang_jadi: id,
    id_item_buaso: selectedData.id_item_buaso ? selectedData.id_item_buaso : '',
    qty: '0',
    unit_price: selectedData.harga_satuan
      ? parseInt(selectedData.harga_satuan)
      : 0,
    konstanta: '1',
    kode_item_bahan: selectedData.kode_item_bahan
      ? selectedData.kode_item_bahan
      : '',
    nama_item: selectedData.nama_item ? selectedData.nama_item : '',
    nama_satuan: selectedData.nama_satuan ? selectedData.nama_satuan : '',
  };

  const formValidationSchema = Yup.object().shape({
    id_item_buaso: Yup.string().required(),
    qty: Yup.string().required(),
    unit_price: Yup.string().required(),
    konstanta: Yup.string().required(),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      konstanta: parseFloat(values.konstanta),
      urutan_item: lastUrutanItem,
      ref: 'lc',
    };

    setDataTPLC([...dataTPLC, finalValues]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    formik;
  const formTotal = parseInt(
    parseFloat(values.qty).toFixed(6) *
      parseInt(values.unit_price) *
      parseFloat(values.konstanta).toFixed(6)
  );
  let subTotal = 0;

  // const decimalConvert = value => {

  //   if(value.length > 1) {
  //     const remove = value.replace(/[^\w\s]/gi, '')
  //     const char1 = remove.substring(0, 1)
  //     const char2 = remove.substring(1,2)

  //     return (char1 + '.' + char2)
  //   }

  //   return value
  // }

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 10);

    return convert;
  };

  useEffect(() => {
    setSelectedData({});
    return () => {};
  }, [dataSelectTPLC]);

  const SelectSearch = (props) => (
    <ReactSelect
      {...props}
      classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
      noOptionsMessage={() => 'Tidak ada data'}
      menuShouldBlockScroll={true}
      menuPosition="fixed"
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
        }),
      }}
    />
  );

  const PageModal = () => {
    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);
        setTimeout(() => {
          const newData = dataTPLC.filter(
            (val) => val.urutan_item !== processedData.urutan_item
          );
          setDataTPLC(newData);
          setIsDeleting(false);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil dihapus!',
          });
        }, 200);
      };

      useEffect(() => {
        return () => {
          setIsDeleting(false);
        };
      }, []);

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan nama item bahan: </span>
            <br />
            <b>{processedData.nama_item}</b>
          </h5>
          <small className="text-danger">
            Data yang dihapus tidak dapat dikembalikan!
          </small>
        </>
      );

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      );

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <span className="text-danger">Hapus Data Labour Cost</span>
        </Modal.Header>
        <ModalDelete />
      </Modal>
    );
  };

  const ListDataTable = ({
    index,
    val,
    total,
    setProcessedData,
    setModalConfig,
  }) => {
    return (
      <tr key={index}>
        <td style={tableStyling} className="px-2">
          {index + 1}
        </td>
        <td style={tableStyling} className="px-2">
          {val.kode_item_bahan}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_item}
        </td>
        <td style={tableStyling} className="text-right">
          {val.qty ? parseFloat(val.qty).toPrecision() : '0'}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_satuan}
        </td>
        <td style={tableStyling} className="text-right">
          {val.unit_price &&
            RupiahConvert(parseInt(val.unit_price).toString()).detail}
        </td>
        <td style={tableStyling} className="text-right">
          {val.konstanta ? parseFloat(val.konstanta).toPrecision() : '0'}
        </td>
        <td style={tableStyling} className="text-right">
          {RupiahConvert(total.toString()).detail}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="px-2 pb-2 mt-3">
        <b>List Item Labour Cost</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <div className="table-responsive">
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center">
            <tr>
              <th className="align-middle p-2" style={tableStyling}>
                No.
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Kode Item
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Item Labour Cost
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Qty
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit Price (Rp)
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Konst.
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Total Price (Rp)
              </th>
            </tr>
          </thead>
          <tbody>
            {dataTPLC.map((val, index) => {
              const total = Math.round(
                parseFloat(val.qty).toFixed(6) *
                  parseInt(val.unit_price) *
                  parseFloat(val.konstanta).toFixed(6)
              );
              const lastItem = parseInt(val.urutan_item)
                ? parseInt(val.urutan_item) + 1
                : 1;
              lastUrutanItem = lastItem;
              subTotal = parseInt(subTotal) + parseInt(total);

              return (
                <ListDataTable
                  index={index}
                  val={val}
                  total={total}
                  editedData={editedData}
                  setEditedData={setEditedData}
                  setProcessedData={setProcessedData}
                  setModalConfig={setModalConfig}
                />
              );
            })}
            <tr>
              <td colSpan={7} className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td className="text-right" style={tableStyling}>
                <b>{RupiahConvert(subTotal.toString()).detail}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PageModal />
    </>
  );
};

export default TableListTPLC;
