import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, Col, Row } from 'react-bootstrap';
import {
  Input,
  ActionButton,
  BackButton,
  DataStatus,
  Alert,
  SelectSearch,
  TextArea,
} from 'components';
import Axios from 'axios';
import {
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
} from './Table';
import { JobOrderApi, TemplatePenunjangProduksiApi } from 'api';
import { DateConvert, RupiahConvert } from 'utilities';

const TambahTemplatePenunjangProduksi = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);

  // STATE DATA SATUAN
  const [detail, setDetail] = useState({});
  const [dataTPFS, setDataTPFS] = useState([]);
  const [dataTPLC, setDataTPLC] = useState([]);
  const [dataTPMP, setDataTPMP] = useState([]);
  const [dataTPBOP, setDataTPBOP] = useState([]);

  const [dataDropdown, setDataDropdown] = useState({
    tipe_prd: [],
    bentuk: [],
    ukuran: [],
    jenis_kayu: [],
    fs: [],
    lc: [],
    mp: [],
    bo: [],
    check: false,
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const history = useHistory();
  const { id } = useParams();

  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([
      TemplatePenunjangProduksiApi.getSingle({
        id_barang_jadi_template_penunjang_produksi: id,
      }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'tipe_prd' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'bentuk' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'ukuran' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'jenis_kayu' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'fs' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'lc' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'mp' }),
      TemplatePenunjangProduksiApi.getDropdown({ tipe: 'bo' }),
    ])
      .then(
        Axios.spread(
          (detail, tipe, bentuk, ukuran, jenis_kayu, fs, lc, mp, bo) => {
            const detailData = detail.data.data.detail ?? [];
            let detailFS = detailData.filter((val) => val.ref === 'fs');
            let detailLC = detailData.filter((val) => val.ref === 'lc');
            let detailMP = detail.data.data.mp ?? [];
            let detailBO = detailData.filter((val) => val.ref === 'bo');

            const Maping = (value) => {
              const final = [];
              value.map((val) => {
                final.push({
                  id_item_buaso: val.id_item_buaso,
                  kode_item_bahan: val.kode_item,
                  nama_satuan: val.nama_satuan,
                  nama_item: val.nama_item,
                  qty: val.qty,
                  unit_price: val.harga_satuan,
                  konstanta: val.konstanta,
                  ref: val.ref,
                });
              });

              return final;
            };

            setDetail(detail.data.data);
            setDataTPFS(Maping(detailFS));
            setDataTPLC(Maping(detailLC));
            setDataTPMP(Maping(detailMP));
            setDataTPBOP(Maping(detailBO));
            setDataDropdown({
              ...dataDropdown,
              tipe_prd: tipe.data.data,
              bentuk: bentuk.data.data,
              ukuran: ukuran.data.data,
              jenis_kayu: jenis_kayu.data.data,
              fs: fs.data.data,
              lc: lc.data.data,
              mp: mp.data.data,
              bo: bo.data.data,
              check: true,
            });
          }
        )
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
        setDataDropdown({ ...dataDropdown, check: false });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle('Tambah Template Penunjang Produksi');
    return () => {};
  }, []);

  const getGrandTotal = () => {
    let totalTPFS = 0;
    let totalTPLC = 0;
    let totalTPMP = 0;
    let totalTPBOP = 0;

    dataTPFS.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPFS = parseInt(totalTPFS) + parseInt(total);
    });

    dataTPLC.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPLC = parseInt(totalTPLC) + parseInt(total);
    });

    dataTPMP.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPMP = parseInt(totalTPMP) + parseInt(total);
    });

    dataTPBOP.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
    });

    const grandTotal =
      parseInt(totalTPFS) +
      parseInt(totalTPLC) +
      parseInt(totalTPMP) +
      parseInt(totalTPBOP);

    return RupiahConvert(grandTotal.toString()).detail;
  };

  const GrandTotalSection = () => (
    <div className="p-2 bg-white text-right border">
      <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
        Grand Total : {getGrandTotal()}
      </b>
    </div>
  );

  const mappingTP = (value) => {
    const final = [];
    value.map((val) => {
      final.push({
        id_item_buaso: val.id_item_buaso,
        qty: val.qty,
        harga_satuan: val.unit_price,
        konstanta: val.konstanta,
        ref: val.ref ?? 'mp',
      });
    });

    return final;
  };

  const formInitialValues = {
    id_barang_jadi_template_penunjang_produksi: id,
    id_barang_jadi_tipe_produksi: detail.id_barang_jadi_tipe_produksi ?? '-',
    nama_barang_jadi_tipe_produksi:
      detail.nama_barang_jadi_tipe_produksi ?? '-',
    id_barang_jadi_bentuk: detail.id_barang_jadi_bentuk ?? '-',
    nama_barang_jadi_bentuk: detail.nama_barang_jadi_bentuk ?? '-',
    id_barang_jadi_ukuran: detail.id_barang_jadi_ukuran ?? '-',
    nama_barang_jadi_ukuran: detail.nama_barang_jadi_ukuran ?? '-',
    id_jenis_kayu: detail.id_jenis_kayu ?? '-',
    nama_jenis_kayu: detail.nama_jenis_kayu ?? '-',
    keterangan: detail.keterangan ?? '-',
  };

  const formValidationSchema = Yup.object().shape({
    id_barang_jadi_tipe_produksi: Yup.string().required(
      'Masukan Tipe Produksi'
    ),
    id_barang_jadi_bentuk: Yup.string().required('Masukan Bentuk'),
    id_barang_jadi_ukuran: Yup.string().required('Masukan Ukuran'),
    id_jenis_kayu: Yup.string().required('Masukan Bahan'),
  });

  const formSubmitHandler = (values) => {
    const analisaFinTpFs = mappingTP(dataTPFS);
    const analisaFinTpLc = mappingTP(dataTPLC);
    const analisaFinTpMp = mappingTP(dataTPMP);
    const analisaFinTpBop = mappingTP(dataTPBOP);
    const finalValues = {
      ...values,
      detail: [
        ...analisaFinTpFs,
        ...analisaFinTpLc,
        ...analisaFinTpMp,
        ...analisaFinTpBop,
      ],
    };

    TemplatePenunjangProduksiApi.create(finalValues)
      .then((res) => {
        setAlertConfig({
          variant: 'primary',
          text: `Tambah data berhasil! `,
        });
        history.push('/master/template-penunjang-produksi', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Tambah Data Berhasil',
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: 'danger',
          text: err.response.data.message,
          show: true,
        });
      })
      .finally(() => {});
  };

  return (
    <>
      {isLoading ? (
        <DataStatus text="Memuat Data" loading={true} />
      ) : dataDropdown.check === true ? (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between align-items-center ml-2 mb-2">
                <b>Detail Data Template Penunjang Produksi</b>
                <BackButton onClick={() => history.goBack()} />
              </div>
              <Card>
                <Card.Body>
                  <Alert
                    show={alertConfig.show}
                    variant={alertConfig.variant}
                    text={alertConfig.text}
                    showCloseButton={true}
                    onClose={() => {
                      setAlertConfig({
                        ...alertConfig,
                        show: false,
                      });
                    }}
                  />
                  <Row className="mb-2">
                    <Col lg="6">
                      <SelectSearch
                        label="Tipe Produksi"
                        name="id_barang_jadi_tipe_produksi"
                        placeholder="Pilih Tipe Produksi"
                        option={dataDropdown.tipe_prd.map((val) => {
                          return {
                            value: val.id_barang_jadi_tipe_produksi,
                            label: val.nama_barang_jadi_tipe_produksi,
                          };
                        })}
                        onChange={(e) => {
                          setFieldValue(
                            'id_barang_jadi_tipe_produksi',
                            e.value
                          );
                        }}
                        defaultValue={{
                          value: values.id_barang_jadi_tipe_produksi,
                          label: values.nama_barang_jadi_tipe_produksi,
                        }}
                        error={
                          errors.id_barang_jadi_tipe_produksi &&
                          touched.id_barang_jadi_tipe_produksi &&
                          true
                        }
                        errorText={
                          errors.id_barang_jadi_tipe_produksi &&
                          touched.id_barang_jadi_tipe_produksi &&
                          errors.id_barang_jadi_tipe_produksi
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <SelectSearch
                        label="Bentuk"
                        name="id_barang_jadi_bentuk"
                        placeholder="Pilih Bentuk"
                        option={dataDropdown.bentuk.map((val) => {
                          return {
                            value: val.id_barang_jadi_bentuk,
                            label: val.nama_barang_jadi_bentuk,
                          };
                        })}
                        onChange={(e) => {
                          setFieldValue('id_barang_jadi_bentuk', e.value);
                        }}
                        defaultValue={{
                          value: values.id_barang_jadi_bentuk,
                          label: values.nama_barang_jadi_bentuk,
                        }}
                        error={
                          errors.id_barang_jadi_bentuk &&
                          touched.id_barang_jadi_bentuk &&
                          true
                        }
                        errorText={
                          errors.id_barang_jadi_bentuk &&
                          touched.id_barang_jadi_bentuk &&
                          errors.id_barang_jadi_bentuk
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <SelectSearch
                        label="Ukuran"
                        name="id_barang_jadi_ukuran"
                        placeholder="Pilih Ukuran"
                        option={dataDropdown.ukuran.map((val) => {
                          return {
                            value: val.id_barang_jadi_ukuran,
                            label: val.nama_barang_jadi_ukuran,
                          };
                        })}
                        onChange={(e) => {
                          setFieldValue('id_barang_jadi_ukuran', e.value);
                        }}
                        defaultValue={{
                          value: values.id_barang_jadi_ukuran,
                          label: values.nama_barang_jadi_ukuran,
                        }}
                        error={
                          errors.id_barang_jadi_ukuran &&
                          touched.id_barang_jadi_ukuran &&
                          true
                        }
                        errorText={
                          errors.id_barang_jadi_ukuran &&
                          touched.id_barang_jadi_ukuran &&
                          errors.id_barang_jadi_ukuran
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <SelectSearch
                        label="Bahan"
                        name="id_jenis_kayu"
                        placeholder="Pilih Ukuran"
                        option={dataDropdown.jenis_kayu.map((val) => {
                          return {
                            value: val.id_jenis_kayu,
                            label: val.nama_jenis_kayu,
                          };
                        })}
                        onChange={(e) => {
                          setFieldValue('id_jenis_kayu', e.value);
                        }}
                        defaultValue={{
                          value: values.id_jenis_kayu,
                          label: values.nama_jenis_kayu,
                        }}
                        error={
                          errors.id_jenis_kayu && touched.id_jenis_kayu && true
                        }
                        errorText={
                          errors.id_jenis_kayu &&
                          touched.id_jenis_kayu &&
                          errors.id_jenis_kayu
                        }
                      />
                    </Col>
                  </Row>
                  <TextArea
                    label="Keterangan"
                    placeholder="Masukan Keterangan"
                    name="keterangan"
                    value={values.keterangan}
                    rows={3}
                    onChange={handleChange}
                  />
                </Card.Body>
              </Card>
              <div className="d-flex justify-content-between align-items-center ml-2 mb-2 mt-4">
                <b>Data Analisa Penunjang Produksi</b>
              </div>
              <Card>
                <Card.Body>
                  <TableListTPFS
                    dataSelectTPFS={dataDropdown.fs}
                    dataTPFS={dataTPFS}
                    setDataTPFS={setDataTPFS}
                  />
                  <TableListTPLC
                    dataSelectTPLC={dataDropdown.lc}
                    dataTPLC={dataTPLC}
                    setDataTPLC={setDataTPLC}
                  />
                  <TableListTPMP
                    dataSelectTPMP={dataDropdown.mp}
                    dataTPMP={dataTPMP}
                    setDataTPMP={setDataTPMP}
                  />
                  <TableListTPBOP
                    dataSelectTPBOP={dataDropdown.bo}
                    dataTPBOP={dataTPBOP}
                    setDataTPBOP={setDataTPBOP}
                  />
                  <GrandTotalSection />
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton text="Simpan" type="submit" />
                  </div>
                </Card.Footer>
              </Card>
            </form>
          )}
        </Formik>
      ) : (
        <DataStatus text="Data Tidak Ada" />
      )}
    </>
  );
};

export default TambahTemplatePenunjangProduksi;
