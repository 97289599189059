import { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card, Tab } from 'react-bootstrap';
import { BackButton, DataStatus } from 'components';
import { PermintaanProduksiApi } from 'api';
import {
  withPermintaanProduksiContextProvider,
  PermintaanProduksiContext,
} from './Context';
import {
  InfoSection,
  TabRoot,
  TabPane,
  TabBahan,
  TabUpah,
  TabSubcon,
  TabAlatMesin,
  TabOverhead,
  SummarySection,
} from './Component';
import { mapServerValues } from './Helpers';

const PermintaanProduksiDetail = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_permintaan_produksi } = useParams();
  const {
    setDataHardwood,
    setDataPlywood,
    setDataFS,
    setDataBPF,
    setDataLC,
    setDataLCF,
    setDataMP,
    setDataSF,
    setDataBO,
  } = useContext(PermintaanProduksiContext);

  const [isFetch, setFetch] = useState({
    loading: false,
    error: false,
  });
  const [data, setData] = useState({});

  const getInitialData = () => {
    setFetch({ loading: true, error: false });

    PermintaanProduksiApi.getSingle({ id_permintaan_produksi })
      .then((res) => {
        const {
          hardwood,
          plywood,
          factory_supply,
          bahan_penunjang,
          labour_cost,
          labour_cost_finishing,
          machine_process,
          subcon_finishing,
          biaya_overhead,
          analisa_hardwood,
          analisa_plywood,
          analisa_PFS,
          analisa_PPF,
          analisa_PLC,
          analisa_PLF,
          analisa_PMC,
          analisa_PSF,
          analisa_POP,
          ...jobOrder
        } = res?.data?.data ?? {};

        setData(jobOrder);

        setDataHardwood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_hardwood).hardwood,
          permintaan: mapServerValues(hardwood).hardwood,
        }));

        setDataPlywood((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_plywood).plywood,
          permintaan: mapServerValues(plywood).plywood,
        }));

        setDataFS((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PFS).factorySupply,
          permintaan: mapServerValues(factory_supply).factorySupply,
        }));

        setDataBPF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PPF).bahanPenunjang,
          permintaan: mapServerValues(bahan_penunjang).bahanPenunjang,
        }));

        setDataLC((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PLC).labourCost,
          permintaan: mapServerValues(labour_cost).labourCost,
        }));

        setDataLCF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PPF).labourCostFinishing,
          permintaan: mapServerValues(labour_cost_finishing)
            .labourCostFinishing,
        }));

        setDataMP((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PMC).machineProcess,
          permintaan: mapServerValues(machine_process).machineProcess,
        }));

        setDataSF((prev) => ({
          ...prev,
          jobOrder: mapServerValues(analisa_PSF).subconFinishing,
          permintaan: mapServerValues(subcon_finishing).subconFinishing,
        }));

        setDataBO((prev) => ({
          ...prev,
          jobOrder: analisa_POP,
          permintaan: biaya_overhead,
        }));

        setFetch({ loading: false, error: false });
      })
      .catch(() => {
        window.alert('Data gagal dimuat!');
        setFetch({ loading: false, error: true });
      });
  };
  useEffect(() => {
    setNavbarTitle('Permintaan Produksi');
    getInitialData();
  }, []);

  if (isFetch.loading || isFetch.error) {
    return (
      <Fragment>
        <Row className="d-flex justify-space-between align-items-end mb-1">
          <Col>
            <div>Detail Data Job Order</div>
          </Col>
          <Col className="d-flex justify-content-end align-items-end">
            <BackButton onClick={() => history.goBack()} />
          </Col>
        </Row>
        <DataStatus
          loading={isFetch.loading}
          text={isFetch.loading ? 'Memuat data' : 'Data gagal dimuat!'}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row className="d-flex justify-space-between align-items-end mb-1">
        <Col>
          <div>Detail Data Job Order</div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Card>
        <Card.Body>
          <InfoSection showPermintaanProduksi showApproval dataJO={data} />
        </Card.Body>
      </Card>

      <TabRoot>
        <Tab.Content>
          <TabPane eventKey="bahan" component={<TabBahan readOnly />} />
          <TabPane eventKey="upah" component={<TabUpah readOnly />} />
          <TabPane eventKey="alat" component={<TabAlatMesin readOnly />} />
          <TabPane eventKey="subkon" component={<TabSubcon readOnly />} />
          <TabPane eventKey="overhead" component={<TabOverhead readOnly />} />
        </Tab.Content>
      </TabRoot>

      <SummarySection />
    </Fragment>
  );
};

export default withPermintaanProduksiContextProvider(PermintaanProduksiDetail);
