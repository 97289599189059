import { Modal } from 'react-bootstrap';
import { IoCloseCircle } from 'react-icons/io5';
import { ActionButton } from 'components';

export const ModalAlertEmptyRealisasi = ({ show, message, onModalClose }) => {
  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header closeButton>Peringatan!</Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <IoCloseCircle size={80} color="#c93636" />
        </div>
        <div className="text-center font-weight-bold">
          Tidak ada data Realisasi!
        </div>
        <small className="text-center font-weight-bold mt-2">
          Periksa kembali Tabel Realisasi pada Tab{' '}
          <b className="font-weight-bold">{message}</b>!
        </small>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-center align-items-center">
        <ActionButton
          text="Kembali"
          className="px-4"
          variant="light"
          onClick={onModalClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
