import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectMonth,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { CloseJobOrderApi } from 'api';
import { useIsGuest } from 'hooks';

const CloseJobOrder = ({ setNavbarTitle }) => {
  const today = new Date();
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataCloseJobOrder, setDataCloseJobOrder] = useState([]);
  const [filterKey, setFilterKey] = useState(''); // STATE MENYIMPAN FILTER STATUS JOB ORDER
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    const date = `${dateSelected.year}-${dateSelected.month}-01`;
    const query =
      dateSelected.month.toString() === '13'
        ? {
            q: searchConfig.key,
            page: paginationConfig.page,
            per_page: paginationConfig.dataLength,
            tahun: dateSelected.year,
          }
        : {
            q: searchConfig.key,
            page: paginationConfig.page,
            per_page: paginationConfig.dataLength,
            tanggal: date,
          };
    if (filterKey !== '') query.status_job_order = filterKey;

    setIsPageLoading(true);

    CloseJobOrderApi.get(query)
      .then(({ data }) => {
        setDataCloseJobOrder(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  const getStatus = (val) => {
    if (val.status_job_order === 'close') {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span style={{ color: '#ff0000' }}> CLOSED </span>
          {val?.no_close_job_order ?? '-'}
        </div>
      );
    }
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span style={{ color: '#00cc00' }}> OPEN </span>
      </div>
    );
  };

  useEffect(() => {
    setNavbarTitle('Close Job Order');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    filterKey,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dateSelected.month,
    dateSelected.year,
  ]);

  const Select = (props) => (
    <select
      {...props}
      className={`custom-select custom-select ${props.className}`}
    >
      {props.children}
    </select>
  );

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th>Customer</Th>
            <Th>Barang Jadi</Th>
            <Th>Qty. Job Order</Th>
            <Th style={{ width: 120 }}>Qty. yang Telah Terealisasi</Th>
            <ThFixed>Status</ThFixed>
            <Th>Aksi</Th>
          </tr>
        </thead>
        <tbody>
          {dataCloseJobOrder.map((val, index) => (
            <tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>
                <div>
                  {' '}
                  {val.tgl_spk
                    ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                    : '-'}{' '}
                </div>
                <div>{val?.no_spk ?? '-'}</div>
              </TdFixed>
              <TdFixed>
                <div>
                  {' '}
                  {val.tgl_job_order
                    ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                    : '-'}{' '}
                </div>
                <div>{val?.no_job_order ?? '-'}</div>
              </TdFixed>
              <Td className="text-uppercase">{val?.nama_customer ?? '-'}</Td>
              <Td>{val?.nama_barang_jadi ?? '-'}</Td>
              <Td style={{ maxWidth: '10px' }}>{`${
                parseFloat(val?.qty_rap).toPrecision() ?? '0'
              } ${val?.nama_satuan ?? ''}`}</Td>
              <Td style={{ maxWidth: '10px' }}>{`${
                parseFloat(val?.qty_realisasi_sum).toPrecision() ?? '0'
              } ${val?.nama_satuan ?? ''}`}</Td>
              <Td>{getStatus(val)}</Td>
              <Td>
                {val.status_job_order === 'close' ? (
                  <ActionButton
                    size="sm"
                    text="Detail"
                    className="col"
                    onClick={() =>
                      history.push({
                        pathname:
                          '/transaksi/close-job-order/detail/' +
                          val.id_job_order,
                        state: {
                          header: val,
                        },
                      })
                    }
                  />
                ) : (
                  <>
                    {!isGuest && (
                      <ActionButton
                        variant="danger"
                        size="sm"
                        text="CLOSE"
                        className="col"
                        onClick={() =>
                          history.push({
                            pathname:
                              '/transaksi/close-job-order/tambah/' +
                              val.id_job_order,
                            state: {
                              header: val,
                            },
                          })
                        }
                      />
                    )}
                  </>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataCloseJobOrder || dataCloseJobOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            <Select
              className="m-1"
              style={{ width: 200 }}
              defaultValue={filterKey}
              onChange={(e) => {
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                });
                setFilterKey(e.target.value);
              }}
            >
              <option key="" value="">
                Semua Job Order
              </option>
              <option key="open" value="open">
                Hanya Status OPEN
              </option>
              <option key="close" value="close">
                Hanya Status CLOSED
              </option>
            </Select>

            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                });
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });
              }}
            >
              <option value={13}>Semua Bulan</option>
            </SelectMonth>
            <Select
              className="m-1"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                });
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });
              }}
            >
              {_.range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          history.replace('/transaksi/close-job-order', { alert: undefined });
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default CloseJobOrder;
