import { Services } from '../../../services';

class BarangJadiTipeProduksiApi {
  page(params) {
    return Services.get('/barang_jadi_tipe_produksi/page', { params });
  }
  single(params) {
    return Services.get('/barang_jadi_tipe_produksi/single', { params });
  }
  create(data) {
    return Services.post('/barang_jadi_tipe_produksi', data);
  }
  update(data) {
    return Services.put('/barang_jadi_tipe_produksi', data);
  }
  delete(data) {
    return Services.post('/barang_jadi_tipe_produksi/delete', data);
  }
  show(data) {
    return Services.put('/barang_jadi_tipe_produksi/show', data);
  }
  hide(data) {
    return Services.put('/barang_jadi_tipe_produksi/hide', data);
  }
}

export default new BarangJadiTipeProduksiApi();
