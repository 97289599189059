import React, { useState, useEffect } from 'react';
import { Table, Spinner, Modal, ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import ReactSelect from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, ActionButton } from 'components';
import { RupiahConvert } from 'utilities';
// import { SuratPerjanjianKerjaApi } from "api";

const TableSPK = ({
  formik,
  dataSPK,
  setDataSPK,
  dataPrelim,
  processedData,
  setModalPrelim,
  setProcessedData,
  modalConfig,
  setModalConfig,
  ppn,
}) => {
  // const { values, submitCount, setValues, setFieldValue } = formik;
  const [ppnSPK, setPpnSPK] = useState(dataSPK.ppn);
  const [isFetching, setIsFetching] = useState(true);
  const [editedData, setEditedData] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [deletedData, setDeletedData] = useState({
    status: false,
    index: '',
    data: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Fetch data awal server
  const getInitialData = () => {
    setIsFetching(true);

    // SuratPerjanjianKerjaApi.getDropdownBarangJadi()
    //   .then(({ data }) => {
    //     setDataBarangJadi(data.data);
    //   })
    //   .catch(() => {
    //     setAlertConfig({
    //       show: true,
    //       variant: "danger",
    //       text: "Data item barang jadi",
    //     });
    //   });
  };

  // Menangani konversi menjadi desimal
  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);

    return convert;
  };

  console.log(dataPrelim);

  const getGrandTotalPrelim = dataPrelim.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    const newQtyDurasi = current.qty_durasi
      ? parseFloat(current.qty_durasi)
      : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    const total = subTotal * newQtyDurasi * newKonts;

    return prev + total;
  }, 0);

  // Menangani perhitungan grand total tabel
  const getGrandTotal = () => {
    const grandTotal = dataSPK.detail_barang_jadi.reduce((prev, current) => {
      const count =
        parseInt(current.harga_satuan_rab) * parseFloat(current.qty_rab);
      const total = prev + count;

      return total;
    }, 0);

    return grandTotal + getGrandTotalPrelim;
  };

  const getGrandTotalAfterDiskon = () => {
    const total = getGrandTotal();
    const diskon = dataSPK.diskon ?? 0;
    const countDiskon = (diskon * total) / 100;
    const afterDiskon = parseInt(total) - parseInt(countDiskon);

    return afterDiskon;
  };

  // Menangani perhitungan grand total setelah ppn
  const getGrandTotalAfterPPN = () => {
    const total = getGrandTotalAfterDiskon();
    const ppn = ppnSPK ?? 0;
    const countPPN = (ppn * total) / 100;
    const afterPPN = parseInt(total) + parseInt(countPPN);

    return afterPPN;
  };

  useEffect(() => {
    getInitialData();
    // setFieldValue("ppn_spk", ppnSPK);

    return () => {};
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  // Menampilkan data pada tabel
  const TableContent = ({ index, val }) => {
    // Menangani perhitungan total
    const getTotal = () => {
      const qty = val.qty_rab ? val.qty_rab : 0;
      const price = val.harga_satuan_rab ? val.harga_satuan_rab : 0;
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr>
        <Td className="text-center pb-2">{index + 1}</Td>
        <Td className="px-1">{val.kode_item}</Td>
        <Td className="px-1">{val.nama_item}</Td>
        <Td className="px-1 text-right">{val.qty_rab}</Td>
        <Td className="px-1">{val.nama_satuan}</Td>
        <Td className="px-1">{val.uraian}</Td>
        <Td className="px-1 text-right">
          {RupiahConvert(parseInt(val.harga_satuan_rab).toString()).detail}
        </Td>
        <Td className="px-1 text-right">{getTotal()}</Td>
      </tr>
    );
  };

  // Menampilkan keseluruhan data & fitur tabel
  return (
    <>
      <div className="px-1 py-2">
        <b>List Item SPK</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <Table bordered>
        <thead className="bg-light">
          <tr>
            <Th rowSpan={2} className="text-center" style={{ width: 30 }}>
              No
            </Th>
            <Th rowSpan={2} className="text-center" style={{ width: 80 }}>
              Kode Item Barang Jadi
            </Th>
            <Th rowSpan={2} className="text-center">
              Nama Item Barang Jadi
            </Th>
            <Th colSpan={2} className="text-center">
              Volume
            </Th>
            <Th rowSpan={2} className="text-center">
              Uraian
            </Th>
            <Th rowSpan={2} className="text-center">
              Harga Rate
            </Th>
            <Th rowSpan={2} className="text-center">
              Sub Total Rate
            </Th>
          </tr>
          <tr>
            <Th className="text-center">Qty</Th>
            <Th className="text-center">Unit</Th>
          </tr>
        </thead>
        <tbody>
          {dataSPK.detail_barang_jadi.map((val, index) => {
            return <TableContent key={index} index={index} val={val} />;
          })}

          <tr>
            <Td className="text-center pb-2">
              {dataSPK && dataSPK.length > 0 ? dataSPK.length + 1 : 1}
            </Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">PRELIM</Td>
            <Td className="px-1 text-right">1</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseFloat(getGrandTotalPrelim).toString()).detail}
            </Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseFloat(getGrandTotalPrelim).toString()).detail}
            </Td>
          </tr>

          {/* Menampilkan & menangani form tambah */}
          {/* <CreateFormTable
            dataSPK={dataSPK}
            setDataSPK={setDataSPK}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
          /> */}
        </tbody>
        <tfoot>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total
            </Th>
            <Th className="bg-light text-right">
              {RupiahConvert(getGrandTotal().toString()).detail}
            </Th>
            <Th className="bg-light text-right"></Th>
          </tr>
          <tr>
            <Th colSpan={7} className="pr-2 text-right">
              Diskon (Dalam %)
            </Th>
            <Th className=" text-right">
              {dataSPK.diskon ? parseFloat(dataSPK.diskon).toPrecision() : 0}
            </Th>
            <Th></Th>
          </tr>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total Setelah Pajak
            </Th>
            <Th className="bg-light text-right">
              {RupiahConvert(getGrandTotalAfterDiskon().toString()).detail}
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="pr-2 text-right">
              PPN (Dalam %)
            </Th>
            <Th className=" text-right">{ppnSPK}</Th>
            <Th></Th>
          </tr>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total Setelah Pajak
            </Th>
            <Th className="bg-light text-right">
              {RupiahConvert(getGrandTotalAfterPPN().toString()).detail}
            </Th>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default TableSPK;
