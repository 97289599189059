export const mapDataSubmitBahan = (data, ref) =>
  data.map((val) => ({
    id_transfer_produksi_bahan: val?.id_transfer,
    qty_realisasi_produksi_bahan: val?.qty_realisasi,
    ref: ref,
  }));

export const mapDataSubmitUpah = (data, ref) =>
  data.map((val) => ({
    id_kontrak_upah: val?.id_kontrak_upah,
    ref: ref,
    opname: val?.rincian?.map((val) => ({
      id_kontrak_upah_detail: val?.id_kontrak_upah_detail,
      persentase_periode_ini: val?.persentase_periode_ini,
      nominal_periode_ini: val?.nominal_periode_ini,
    })),
  }));

export const mapDataSubmitAlatMesin = (data, ref, tgl) =>
  data.map((val) => ({
    id_transfer_produksi_alat_mesin: val?.id_transfer,
    tgl_realisasi_produksi_alat_mesin: tgl,
    qty_realisasi_produksi_alat_mesin: val?.qty_realisasi,
    ref: ref,
  }));

export const mapDataSubmitSubkon = (data, ref) =>
  data.map((val) => ({
    id_kontrak_subkon: val?.id_kontrak_subkon,
    ref: ref,
    opname: val?.rincian?.map((val) => ({
      id_kontrak_subkon_detail: val?.id_kontrak_subkon_detail,
      persentase_periode_ini: val?.persentase_periode_ini,
      nominal_periode_ini: val?.nominal_periode_ini,
    })),
  }));

export const mapDataSubmitOverhead = (data, ref, tgl) =>
  data.map((val) => ({
    id_transfer_produksi_overhead: val?.id_transfer,
    tgl_realisasi_produksi_overhead: tgl,
    qty_realisasi_produksi_overhead: val?.qty_realisasi,
    ref: ref,
  }));
