import { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ActionButton, Alert, BackButton, DataStatus } from 'components';
import { TransferSisaProduksiApi } from 'api';
import { InfoSection, TableDataTransferSisaProduksi } from './Components';
import {
  TransferSisaProduksiContext,
  withTransferSisaProduksiContextProvider,
} from './Contexts';

const TambahTransferSisaProduksi = ({ setNavbarTitle }) => {
  const { id: id_realisasi_produksi } = useParams();
  const history = useHistory();
  const { listTransferSisa } = useContext(TransferSisaProduksiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsLoading(true);

    TransferSisaProduksiApi.singleRealisasi({ id_realisasi_produksi })
      .then((res) => {
        setData(res.data.data);
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    tgl_transfer_sisa_produksi: data?.tgl_transfer_sisa_produksi ?? '',
    no_transfer_sisa_produksi: data?.no_transfer_sisa_produksi ?? '',
    id_job_order: data?.id_job_order ?? '',
    tgl_job_order: data?.tgl_job_order ?? '',
    no_job_order: data?.no_job_order ?? '',
    no_rap: data?.no_rap ?? '',
    no_spk: data?.no_spk ?? '',
    nama_customer: data?.nama_customer ?? '',
    nama_proyek: data?.nama_proyek ?? '',
    nama_barang_jadi: data?.nama_barang_jadi ?? '',
    qty_rap: data?.qty_rap ?? '',
    nama_satuan: data?.nama_satuan ?? '',
    id_realisasi_produksi: data?.id_realisasi_produksi ?? '',
    tgl_realisasi_produksi: data?.tgl_realisasi_produksi ?? '',
    no_realisasi_produksi: data?.no_realisasi_produksi ?? '',
  };

  const validationSchema = yup.object().shape({
    tgl_transfer_sisa_produksi: yup
      .string()
      .required('Tanggal transfer sisa produksi diperlukan'),
  });

  const onSubmit = (values, { setSubmitting }) => {
    console.log(values);
    const finalValues = {
      ...values,
    };

    finalValues.detail = listTransferSisa?.map((e) => ({
      id_item_buaso: e.id_item_buaso,
      id_gudang_asal: e.id_gudang_asal,
      id_gudang_tujuan: e.id_gudang_tujuan,
      qty_transfer: e.qty_transfer,
      nilai_persediaan: e.nilai_persediaan,
      id_satuan: e.id_satuan,
    }));

    TransferSisaProduksiApi.create(finalValues)
      .then(() => {
        setSubmitting(false);
        history.push('/transaksi/transfer-sisa-produksi', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Berhasil simpan data',
          },
        });
      })
      .catch(() => {
        setSubmitting(false);
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Gagal simpan data',
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Transfer Sisa Produksi');
    getInitialData();
  }, [setNavbarTitle]);

  return (
    <>
      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      <Row className="d-flex justify-content-end align-items-end">
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <InfoSection action="form" />
              <TableDataTransferSisaProduksi action="form" />
              <Row className="mt-3 d-flex justify-content-end">
                <Col className="d-flex justify-content-end">
                  <ActionButton text="Simpan " onClick={handleSubmit} />
                </Col>
              </Row>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default withTransferSisaProduksiContextProvider(
  TambahTransferSisaProduksi
);
