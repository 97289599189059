import { useRef, useState, useEffect } from 'react';
import { Modal, Card, Nav } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { ActionButton, Alert, DataStatus } from 'components';
import { DateConvert } from 'utilities';
import { RegistrasiBarangJadiApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Section';

const ModalFormBarangJadi = ({
  ID,
  modalConfig,
  setModalConfig,
  submitDataHandler,
}) => {
  const tabsRef = useRef();
  const [dataInfo, setDataInfo] = useState({});
  const [kodeItem, setKodeItem] = useState('');
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataTipeProduksi, setDataTipeProduksi] = useState([]);
  const [dataBentuk, setDataBentuk] = useState([]);
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUkuran, setDataUkuran] = useState([]);
  const [dataSeri, setDataSeri] = useState([]);
  const [dataKelompok, setDataKelompok] = useState([]);
  const [dataJenis, setDataJenis] = useState([]);
  const [dataSatuan, setDataSatuan] = useState([]);
  const [defaultTabs, setDefaultTabs] = useState('info');
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} />,
    },
  ];

  // Nilai awal pada form
  const formInitialValues = {
    id_buaso: dataInfo?.buaso?.id_buaso ?? null,
    id_tipe_produksi: '',
    id_bentuk: '',
    id_bahan: '',
    id_ukuran: '',
    id_seri: '',
    id_kelompok: dataInfo.id_kelompok ?? null,
    id_jenis: dataInfo.id_jenis ?? null,
    id_pabrikan: dataInfo.id_pabrikan ?? null,
    id_satuan: dataInfo.id_satuan_pakai ?? null,
    id_satuan_jual: dataInfo.id_satuan_jual,
    id_satuan_beli: dataInfo.id_satuan_beli ?? null,
    kode_item: kodeItem,
    kode_buaso: dataInfo?.kode_buaso ?? null,
    nama_item: dataInfo.nama_item ?? null,
    nama_buaso: dataInfo?.nama_buaso ?? null,
    nama_pabrikan: dataInfo?.nama_pabrikan ?? null,
    nama_tipe_produksi: '',
    nama_bentuk: '',
    nama_bahan: '',
    nama_ukuran: '',
    link_referensi: dataInfo.link_referensi ?? null,
    uraian: dataInfo.uraian ?? null,
    tgl_input: DateConvert(new Date()).default,
  };

  // Validasi pada form
  const formValidationSchema = Yup.object().shape({
    id_kelompok: Yup.string().required('Pilih kelompok barang jadi'),
    id_jenis: Yup.string().required('Pilih jenis barang jadi'),
    id_satuan: Yup.string().required('Pilih satuan pakai'),
    id_satuan_beli: Yup.string().required('Pilih satuan beli'),
    id_satuan_jual: Yup.string().required('Pilih satuan jual'),
    link_referensi: Yup.string().url('Masukan link yang valid'),
    nama_item: Yup.string()
      .required('Masukan nama item')
      .test({
        name: 'check_nama',
        test: (val) =>
          val === dataInfo.nama_item
            ? true
            : RegistrasiBarangJadiApi.checkNama({ nama_item: val })
                .then(() => true)
                .catch(() => false),
        message: 'Nama tidak dapat digunakan karena telah diregister',
      }),
  });

  // Menangani submit form
  const formSubmitHandler = async (values, { setSubmitting }) => {
    const { REACT_APP_UPLOAD_BASE_URL } = process.env;
    const uploadLink = `${REACT_APP_UPLOAD_BASE_URL}/registrasi_barang_jadi_multiple`;

    const mergeFile = [
      ...dataGambar.filter((val) => val.data),
      ...dataFile.filter((val) => val.data),
    ];

    const prevGambar = dataGambar
      .filter((val) => !val.data)
      .map((val) =>
        val.link
          .split('/')
          .filter((val, index) => index > 2)
          .join('/')
      );

    const prevFile = dataFile
      .filter((val) => !val.data)
      .map((val) =>
        val.link
          .split('/')
          .filter((val, index) => index > 2)
          .join('/')
      );

    const mergePrevFile = [...prevFile, ...prevGambar]; // Gabungan data dari state gambar dan file yang telah di filter menjadi link untuk disimpan ke database
    const fileData = new FormData();

    // Check if new image exist, then upload
    const uploadImage = async () => {
      if (mergeFile.length > 0) {
        mergeFile.map((val, index) =>
          fileData.append(`file_${index}`, val.data)
        );

        return Axios.post(uploadLink, fileData, {
          headers: {
            'Api-Key': 'TUdQQmFuZ3NhdFBhbnRla0FzdQ==',
            'Content-Type': 'application/json',
          },
        })
          .then((res) => {
            const fileResponse = res.data.data;
            const filePath = Object.values(fileResponse);
            return filePath;
          })
          .catch(({ response }) => {
            window.alert(response?.data?.message ?? 'Gagal upload gambar!');
            return [];
          });
      }
      return [];
    };

    const uploadedImage = await uploadImage();

    const finalValues = {
      id_kelompok: values.id_kelompok,
      id_pabrikan: values.id_pabrikan,
      nama_pabrikan: values.nama_pabrikan,
      tgl_input: values.tgl_input,
      nama_item: values.nama_item,
      id_satuan: values.id_satuan,
      id_satuan_jual: values.id_satuan_jual,
      id_satuan_beli: values.id_satuan_beli,
      uraian: values.uraian,
      link_referensi: values.link_referensi,
      id_tipe_produksi: values.id_tipe_produksi,
      id_bentuk: values.id_bentuk,
      id_bahan: values.id_bahan,
      id_ukuran: values.id_ukuran,
      id_seri: values.id_seri,
      path_gambar: [...uploadedImage, ...mergePrevFile],
    };

    // Submit Barang Jadi
    RegistrasiBarangJadiApi.create(finalValues)
      .then((res) => {
        const id_barang_jadi = res?.data?.data?.id_item_buaso;
        const isSaveAs = true;
        submitDataHandler({ id_barang_jadi, isSaveAs });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data Barang Jadi sudah terdaftar!',
        });
      })
      .finally(() => setSubmitting(false));
  };

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  // Mapping data attribut yang akan digunakan oleh SelectSearch
  const mappingOptionAttributBarangJadi = (type, data) => {
    const nameOfObject = `nama_${type}`;
    const idOfObject = `id_${type}`;

    return data.map((val) => {
      return {
        label: val[nameOfObject],
        value: val[idOfObject],
        id_kelompok: val.id_kelompok,
      };
    });
  };

  // Mapping data untuk memperoleh nama barang jadi yang akan digunakan oleh SelectSearch
  const mappingOptionNamaBarangJadi = (type, data) => {
    let newData = [];

    if (type === 'bahan') {
      newData = data.map((val) => {
        return {
          label: val.nama_jenis_kayu,
          value: val.id_jenis_kayu,
        };
      });
    } else {
      const nameOfObject = `nama_barang_jadi_${type}`;
      const idOfObject = `id_barang_jadi_${type}`;

      newData = data.map((val) => {
        return {
          label: val[nameOfObject],
          value: val[idOfObject],
        };
      });
    }

    return [{ label: 'Tidak menggunakan', value: null }, ...newData];
  };

  // Menangani cek type dari file yang dikirim
  const checkTypeAndStoreFile = (data) => {
    let gambar = [];
    let file = [];

    data.map((val) => {
      const map = {
        link: val.path_gambar,
        id: val.id_item_buaso_gambar_barang_jadi,
        nama: '',
        data: null,
      };

      const generalFileType = map.link.split('.').pop();
      const fileName = map.link.split('/').pop();

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName });
      }

      return file.push({ ...map, nama: fileName });
    });

    setDataGambar(gambar);
    setDataFile(file);
  };

  // Fetch data pada saat page pertama kali dibuka
  const fetchInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await Axios.all([
      RegistrasiBarangJadiApi.getSingle(ID),
      RegistrasiBarangJadiApi.getKode(),
      RegistrasiBarangJadiApi.getKelompok(),
      RegistrasiBarangJadiApi.getJenis(),
      RegistrasiBarangJadiApi.getSatuan(),
      RegistrasiBarangJadiApi.getDropdown({ tipe: 'tipe_produksi' }),
      RegistrasiBarangJadiApi.getDropdown({ tipe: 'seri' }),
      RegistrasiBarangJadiApi.getDropdown({ tipe: 'bentuk' }),
      RegistrasiBarangJadiApi.getDropdown({ tipe: 'ukuran' }),
      RegistrasiBarangJadiApi.getDropdown({ tipe: 'bahan' }),
    ])
      .then(
        Axios.spread(
          (
            single,
            kode,
            kelompok,
            jenis,
            satuan,
            tipe,
            seri,
            bentuk,
            ukuran,
            bahan
          ) => {
            const rawDataInfo = single.data.data ?? {};
            const rawDataKode = kode.data.data ?? '';
            const rawDataKelompok = kelompok?.data?.data ?? [];
            const rawDataJenis = jenis?.data?.data ?? [];
            const rawDataSatuan = satuan?.data?.data ?? [];
            const rawDataTipe = tipe?.data?.data ?? [];
            const rawDataSeri = seri?.data?.data ?? [];
            const rawDataBentuk = bentuk?.data?.data ?? [];
            const rawDataUkuran = ukuran?.data?.data ?? [];
            const rawDataBahan = bahan?.data?.data ?? [];
            const mapDataKelompok = mappingOptionAttributBarangJadi(
              'kelompok',
              rawDataKelompok
            );
            const mapDataJenis = mappingOptionAttributBarangJadi(
              'jenis',
              rawDataJenis
            );
            const mapDataSatuan = mappingOptionAttributBarangJadi(
              'satuan',
              rawDataSatuan
            );
            const mapDataTipe = mappingOptionNamaBarangJadi(
              'tipe_produksi',
              rawDataTipe
            );
            const mapDataSeri = mappingOptionNamaBarangJadi(
              'seri',
              rawDataSeri
            );
            const mapDataBentuk = mappingOptionNamaBarangJadi(
              'bentuk',
              rawDataBentuk
            );
            const mapDataUkuran = mappingOptionNamaBarangJadi(
              'ukuran',
              rawDataUkuran
            );
            const mapDataBahan = mappingOptionNamaBarangJadi(
              'bahan',
              rawDataBahan
            );

            checkTypeAndStoreFile(rawDataInfo.gambar ?? []);
            setDataInfo(rawDataInfo);
            setKodeItem(rawDataKode);
            setDataKelompok(mapDataKelompok);
            setDataJenis(mapDataJenis);
            setDataSatuan(mapDataSatuan);
            setDataTipeProduksi(mapDataTipe);
            setDataSeri(mapDataSeri);
            setDataBentuk(mapDataBentuk);
            setDataUkuran(mapDataUkuran);
            setDataBahan(mapDataBahan);
            setFetchingStatus({
              loading: false,
              success: true,
            });
          }
        )
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(async () => {
    await fetchInitialData();

    return () => {
      setFetchingStatus({ loading: false, success: false });
    };
  }, []);

  return (
    <Modal
      size="xl"
      scrollable={true}
      show={modalConfig.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Header className="pb-0" closeButton>
        <div className="d-flex justify-content-between align-items-center pb-2">
          <h6 className="text-warning">Tambah Barang Jadi (Save As)</h6>
        </div>
      </Modal.Header>

      <Modal.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          text={alertConfig.text}
          variant={alertConfig.variant}
          onClose={() =>
            setAlertConfig({
              show: false,
              variant: '',
              text: '',
            })
          }
        />

        <Card>
          <Card.Header>
            {/* Tab Header */}
            <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
              {tabsConfig.map((val, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    ref={tabsRef}
                    href={`#${val.tab}`}
                    onClick={(e) => onTabsChangeHandler(e, val.tab)}
                  >
                    {val.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {fetchingStatus.loading ? (
              <DataStatus loading text="Memuat data . . ." />
            ) : !fetchingStatus.success ? (
              <DataStatus text="Data gagal dimuat!" />
            ) : (
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    {/* Tab Body */}
                    {tabsConfig.map(
                      ({ tab, component: Component }, index) =>
                        tab === defaultTabs && (
                          <Component
                            key={index}
                            formik={formik}
                            dataTipeProduksi={dataTipeProduksi}
                            dataBentuk={dataBentuk}
                            dataBahan={dataBahan}
                            dataUkuran={dataUkuran}
                            dataSeri={dataSeri}
                            dataKelompok={dataKelompok}
                            dataJenis={dataJenis}
                            dataSatuan={dataSatuan}
                            dataGambar={dataGambar}
                            dataFile={dataFile}
                            setDataGambar={setDataGambar}
                            setDataFile={setDataFile}
                          />
                        )
                    )}
                    <hr />

                    {/* Submit Button */}
                    <div className="text-right">
                      <ActionButton
                        type="submit"
                        variant="warning"
                        className="text-white"
                        text="Simpan Barang Jadi"
                        loading={formik.isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFormBarangJadi;
