import { Nav, Tab } from 'react-bootstrap';

export const NavTab = ({ eventKey, title, onClick }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey} onClick={onClick}>
      {title}{' '}
    </Nav.Link>
  </Nav.Item>
);

export const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
