// HALAMAN UPDATE Sub Tipe Produksi
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Td,
  Th,
  BackButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  SelectSearch,
  TextArea,
  UpdateModal,
} from '../../../../components';
import { RupiahConvert, DateConvert } from '../../../../utilities';
import { TipeBarangJadiApi } from '../../../../api';
const UpdateTipeBarangJadi = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  let { id_tipe_barang_jadi } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [updateData, setUpdateData] = useState([]);

  // STATE MENAMPUNG DATA
  const [data, setData] = useState({});

  const [listTipeBarangJadi, setListTipeBarangJadi] = useState([]);
  const [listFactorySupply, setListFactorySupply] = useState([]);
  const [listLabourCost, setListLabourCost] = useState([]);
  const [listMachineProcess, setListMachineProcess] = useState([]);
  const [listOverheadPabrik, setListOverheadPabrik] = useState([]);

  const [dropdownFS, setDropdownFS] = useState([]);
  const [dropdownLC, setDropdownLC] = useState([]);
  const [dropdownMP, setDropdownMP] = useState([]);
  const [dropdownBOP, setDropdownBOP] = useState([]);

  const [grandTotal, setGrandTotal] = useState(0);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = (loading = true) => {
    setIsLoading(loading);
    Axios.all([
      TipeBarangJadiApi.getSingle(id_tipe_barang_jadi),
      TipeBarangJadiApi.getDropdownKelompokTipeBarangJadi(),
      TipeBarangJadiApi.getDropdownItem('fs'),
      TipeBarangJadiApi.getDropdownItem('lc'),
      TipeBarangJadiApi.getDropdownItem('mp'),
      TipeBarangJadiApi.getDropdownItem('bop'),
    ])
      .then(
        Axios.spread((res, kelompok_dropdown, fs, lc, mp, bop) => {
          setData(res.data.data);
          setDropdownFS(fs.data.data);
          setDropdownLC(lc.data.data);
          setDropdownMP(mp.data.data);
          setDropdownBOP(bop.data.data);

          setListFactorySupply(res.data.data.detail.fs);
          setListLabourCost(res.data.data.detail.lc);
          setListMachineProcess(res.data.data.detail.mp);
          setListOverheadPabrik(res.data.data.detail.bop);
          setListTipeBarangJadi(kelompok_dropdown.data.data.data);
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(true);
    setNavbarTitle('Detail Data Sub Tipe Produksi'); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // TABLE COMPONENT
  const Table = (props) => {
    const toCommas = (val) => {
      return val.toString().replace(/\./g, ',');
    };

    useEffect(() => {
      hitungGrandTotal();
    }, []);

    return (
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Item</ThFixed>
            <Th>Nama Item</Th>
            <ThFixed>Qty</ThFixed>
            <ThFixed>Unit</ThFixed>
            <Th>Unit Price</Th>
            <ThFixed>Konstanta</ThFixed>
            <Th>Sub Total Price</Th>
          </Tr>
        </THead>
        <TBody>
          {props.data.length > 0 ? (
            props.data?.map((val, index) => {
              // TAMBAH KEY sub_total PADA ELEMENT BARANG
              let totalOrder =
                parseFloat(val.harga_satuan) * parseFloat(val.qty);
              let sub_total = totalOrder * parseFloat(val.koefisien ?? 0);
              val.sub_total = parseInt(sub_total);
              return (
                <Tr key={props.kode + index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item_bahan}</TdFixed>
                  <Td>{val.nama_item_bahan}</Td>
                  <Td textRight>{toCommas(parseFloat(val.qty))}</Td>
                  <TdFixed>{val.nama_satuan}</TdFixed>
                  <Td width={150} textRight>
                    {RupiahConvert(String(parseInt(val.harga_satuan))).detail}
                  </Td>
                  <TdFixed>{toCommas(parseFloat(val.koefisien ?? 0))}</TdFixed>
                  <Td width={150} textRight>
                    {RupiahConvert(String(val.sub_total)).detail}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {props.data.length > 0 ? (
            <Tr>
              <Td textRight colSpan="7">
                <strong>Total {props.title}</strong>
              </Td>
              <Td textRight>
                <strong>
                  {
                    RupiahConvert(
                      String(
                        props.data.reduce(
                          (sum, { sub_total }) => sum + parseInt(sub_total),
                          0
                        )
                      )
                    ).detail
                  }
                </strong>
              </Td>
            </Tr>
          ) : (
            <div />
          )}
        </TBody>
      </CRUDLayout.Table>
    );
  };

  // TEXT DETAIL COMPONENT
  const TextDetail = ({ title, text, line }) => (
    <div className="mb-2">
      <small
        className="text-secondary"
        style={{ opacity: 0.8, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : '-'}
      </div>
      {line && <hr className="my-2" />}
    </div>
  );

  const formInitialValues = {
    id_kelompok_tipe_barang_jadi: data.id_kelompok_tipe_barang_jadi,
    nama_kelompok_tipe_barang_jadi: data.nama_kelompok_tipe_barang_jadi,
    nama_tipe_barang_jadi: data.nama_tipe_barang_jadi,
    deskripsi: data.deskripsi,
  };

  // FORMIK VALIDATION SCHEMA Sub Tipe Produksi DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_kelompok_tipe_barang_jadi: Yup.string().required(
      'Pilih Kelompok Tipe Barang'
    ),
    nama_tipe_barang_jadi: Yup.string().required(
      'Masukkan nama sub tipe produksi'
    ),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    // SEND UPDATED DATA TO SERVER
    const finalValues = {
      id_tipe_barang_jadi: data.id_tipe_barang_jadi,
      ...values,
    };
    TipeBarangJadiApi.update(finalValues)
      .then((res) => {
        setAlertConfig({
          variant: 'primary',
          text: `Update data berhasil!`,
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: 'danger',
          text: `Update data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        // SHOW ALERT
        setShowAlert(true);
      });
  };

  const hitungGrandTotal = () => {
    let totalFS =
      listFactorySupply.length > 0
        ? listFactorySupply.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalMP =
      listMachineProcess.length > 0
        ? listMachineProcess.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalLC =
      listLabourCost.length > 0
        ? listLabourCost.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalBOP =
      listOverheadPabrik.length > 0
        ? listOverheadPabrik.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;

    let grand =
      parseInt(totalFS) +
      parseInt(totalLC) +
      parseInt(totalMP) +
      parseInt(totalBOP);
    setGrandTotal(grand);
  };

  useEffect(() => {
    hitungGrandTotal();
  }, [
    listFactorySupply,
    listLabourCost,
    listMachineProcess,
    listOverheadPabrik,
  ]);

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Sub Tipe Produksi</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Card.Body>
            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="container my-2 px-2">
                    <div className="row">
                      <div className="col">
                        <TextDetail
                          title="Tipe Produksi"
                          text={values.nama_kelompok_tipe_barang_jadi}
                          line={true}
                        />

                        <TextDetail
                          title="Nama Sub Tipe Produksi"
                          text={values.nama_tipe_barang_jadi}
                          line={true}
                        />
                      </div>

                      <div className="col">
                        {/* DESKRIPSI */}
                        <TextDetail
                          title="Deskripsi"
                          text={values.deskripsi}
                          line={true}
                        />
                      </div>
                    </div>

                    <hr />
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Factory Supply</strong>
                    </div>
                    <Table
                      data={listFactorySupply}
                      title="Factory Supply"
                      kode="fs"
                    />

                    <hr />
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Labour Cost MGP</strong>
                    </div>
                    <Table
                      data={listLabourCost}
                      title="Labour Cost MGP"
                      kode="lc"
                    />

                    <hr />
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Machine Process</strong>
                    </div>
                    <Table
                      data={listMachineProcess}
                      title="Machine Process"
                      kode="mp"
                    />

                    <hr />
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Biaya Overhead</strong>
                    </div>
                    <Table
                      data={listOverheadPabrik}
                      title="Biaya Overhead"
                      kode="bop"
                    />

                    <CRUDLayout.Table classname="mx-3">
                      <THead>
                        <Tr className="text-center">
                          <Th>
                            <div className="text-right">Grand Total</div>
                          </Th>
                          <Th style={{ width: '10%' }}>
                            <div className="text-right">
                              {RupiahConvert(String(grandTotal)).detail}
                            </div>
                          </Th>
                        </Tr>
                      </THead>
                    </CRUDLayout.Table>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        )}
      </Card>
    </CRUDLayout>
  );
};
export default UpdateTipeBarangJadi;
