import { Services } from '../../../services';

class BarangJadiBentukApi {
  page(params) {
    return Services.get('/barang_jadi_bentuk/page', { params });
  }
  single(params) {
    return Services.get('/barang_jadi_bentuk/single', { params });
  }
  create(data) {
    return Services.post('/barang_jadi_bentuk', data);
  }
  update(data) {
    return Services.put('/barang_jadi_bentuk', data);
  }
  delete(data) {
    return Services.post('/barang_jadi_bentuk/delete', data);
  }
  show(data) {
    return Services.put('/barang_jadi_bentuk/show', data);
  }
  hide(data) {
    return Services.put('/barang_jadi_bentuk/hide', data);
  }
}

export default new BarangJadiBentukApi();
