import { Services } from '../../../services';

class PeluangApi {
  getSingle(params) {
    return Services.get('/peluang/single', { params });
  }

  getBarangJadi() {
    return Services.get('/registrasi_barang_jadi');
  }

  getAnalisaBarangJadi() {
    return Services.get('registrasi_barang_jadi/dropdown?tipe=barang_jadi');
  }

  update(data) {
    return Services.put('/peluang', data);
  }
}

export default new PeluangApi();
