import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableHardwood } from './TableHardwood';

export const ContainerHardwood = ({ showHistory }) => {
  const { dataHardwood } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableHardwood
        jobOrder
        showTotalLuasVolume
        title="List Hardwood Dari Job Order"
        data={dataHardwood.jobOrder}
      />
      <TableHardwood
        showInputRow
        showTotalLuasVolume
        title="List Permintaan Hardwood"
        data={dataHardwood.permintaan}
      />
      {showHistory && (
        <TableHardwood
          readOnly
          title="History Permintaan Hardwood"
          data={dataHardwood.history}
        />
      )}
    </Row>
  );
};
