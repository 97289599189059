import { Modal } from 'react-bootstrap';
import { ActionButton } from 'components';

export const ModalDeleteBO = ({
  deleteItem,
  setDeleteItem,
  onDeleteSubmit,
}) => {
  return (
    <Modal
      show={!!deleteItem.data}
      onHide={() => setDeleteItem({ index: null, data: null })}
    >
      <Modal.Header closeButton>
        <span className="text-danger">Hapus Biaya Overhead</span>
      </Modal.Header>

      <Modal.Body className="text-center">
        <h5>
          <span>Hapus data dengan nama item: </span>
          <br />
          <b>{deleteItem?.data?.nama_item}</b>
        </h5>

        <small className="text-danger">
          Data yang dihapus tidak dapat dikembalikan!
        </small>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <ActionButton
          variant="outline-secondary"
          className="m-1"
          text="Batal"
          onClick={() => setDeleteItem({ index: null, data: null })}
        />
        <ActionButton
          variant="danger"
          className="m-1"
          text="Hapus Data"
          onClick={onDeleteSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};
