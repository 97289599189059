import { ActionButton, Alert, DataStatus, Table } from 'components';
import { useFormikContext } from 'formik';
import { useIsGuest } from 'hooks';
import { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoEyeOutline,
  IoPencilOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import { DateConvert } from 'utilities';
import { ModalRealisasiProduksi, TableDetailProduksi } from '../../../Section';

const TableAlatMesin = ({
  dataAlatMesin,
  dataHistoryAlatMesin,
  dataRealisasiAlatMesin,
  setDataRealisasiAlatMesin,
  setTab,
}) => {
  const rootFormik = useFormikContext();
  const isGuest = useIsGuest();
  const isReadOnly = !!rootFormik?.values?.isReadOnly;
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'create', // create, update atau delete
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={`${props.className} p-1`}
      style={{
        ...props.style,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  if (isReadOnly) {
    return (
      <TableDetailProduksi
        title="Alat Mesin"
        dataRealisasi={dataRealisasiAlatMesin}
      />
    );
  }

  return (
    <div>
      {/* Alert */}
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Table List Item AlatMesin */}
      <div className="pb-2">
        <b className="p-1">List Item Alat Mesin</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th className="py-2 text-center" style={{ width: 30 }}>
              No.
            </Th>
            <Th className="p-2 text-center" style={{ width: 100 }}>
              Tgl. Transfer
            </Th>
            <Th className="p-2 text-center" style={{ width: 100 }}>
              No. Transfer
            </Th>
            <Th className="p-2 text-center" style={{ minWidth: 300 }}>
              Item Bahan
            </Th>
            <Th className="p-2 text-center" style={{ width: 150 }}>
              Satuan
            </Th>
            <Th className="p-1 text-center" style={{ width: 100 }}>
              Qty. Transfer
            </Th>
            {!isGuest && (
              <Th className="p-0 text-center" style={{ width: 40 }}>
                Aksi
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {dataAlatMesin.length > 0 ? (
            dataAlatMesin.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  {val.tgl_transfer
                    ? DateConvert(new Date(val.tgl_transfer)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_transfer}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan}</Td>
                <Td className="text-right">{val.qty_transfer}</Td>
                {!isGuest && (
                  <Td>
                    <ActionButton
                      size="sm"
                      className="col"
                      disable={
                        !!dataRealisasiAlatMesin.find(
                          (data) => data?.id_transfer === val.id_transfer
                        )
                      }
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({
                          show: true,
                          type: 'create',
                        });
                      }}
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </Td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Table List Realisasi Item Alat Mesin */}
      <>
        <div className="mt-3 pb-2">
          <b className="p-1">Realisasi Alat Mesin</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <Th className="py-2 text-center" style={{ width: 30 }}>
                No.
              </Th>
              <Th className="p-2 text-center" style={{ minWidth: 300 }}>
                Item Bahan
              </Th>
              <Th className="p-2 text-center" style={{ width: 150 }}>
                Satuan
              </Th>
              <Th className="p-1 text-center" style={{ width: 100 }}>
                Qty. Realisasi
              </Th>
              {!isGuest && (
                <Th className="p-0 text-center" style={{ width: 70 }}>
                  Aksi
                </Th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataRealisasiAlatMesin.length > 0 ? (
              dataRealisasiAlatMesin.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.satuan}</Td>
                  <Td className="text-right">{val.qty_realisasi}</Td>
                  {!isGuest && (
                    <Td>
                      <ButtonGroup size="sm">
                        <ActionButton
                          variant="success"
                          text={<IoPencilOutline />}
                          onClick={() => {
                            setProcessedData({ ...val, index });
                            setModalConfig({
                              show: true,
                              type: 'update',
                            });
                          }}
                        />
                        <ActionButton
                          variant="danger"
                          text={<IoTrashBinOutline />}
                          onClick={() => {
                            setProcessedData({ ...val, index });
                            setModalConfig({
                              show: true,
                              type: 'delete',
                            });
                          }}
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>

      {/* Table List History Item Alat Mesin */}

      <>
        <div className="mt-3 pb-2">
          <b className="p-1">History Realisasi Alat Mesin</b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <Th className="py-2 text-center" style={{ width: 30 }}>
                No.
              </Th>
              <Th className="p-2 text-center" style={{ width: 100 }}>
                Tgl. Realisasi
              </Th>
              <Th className="p-2 text-center" style={{ width: 100 }}>
                No. Realisasi
              </Th>
              <Th className="p-2 text-center" style={{ minWidth: 300 }}>
                Item Bahan
              </Th>
              <Th className="p-2 text-center" style={{ width: 150 }}>
                Satuan
              </Th>
              <Th className="p-1 text-center" style={{ width: 100 }}>
                Qty. Realisasi
              </Th>
              {!isGuest && (
                <Th className="p-0 text-center" style={{ width: 40 }}>
                  Aksi
                </Th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataHistoryAlatMesin.length > 0 ? (
              dataHistoryAlatMesin.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    {val.tgl_realisasi
                      ? DateConvert(new Date(val.tgl_realisasi)).defaultDMY
                      : '-'}
                  </Td>
                  <Td>{val.no_realisasi}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.satuan}</Td>
                  <Td className="text-right">{val.qty_realisasi}</Td>
                  {!isGuest && (
                    <Td>
                      <ActionButton
                        variant="primary"
                        size="sm"
                        text={<IoEyeOutline />}
                        onClick={() => {
                          setProcessedData({ ...val, index });
                          setModalConfig({
                            show: true,
                            type: 'detail',
                          });
                        }}
                      />
                    </Td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>

      {/* Modal Realisasi */}
      <ModalRealisasiProduksi
        tabKey="alat_mesin"
        title="Alat Mesin"
        processedData={processedData}
        dataRealisasi={dataRealisasiAlatMesin}
        modalConfig={modalConfig}
        setAlertConfig={setAlertConfig}
        setDataRealisasi={setDataRealisasiAlatMesin}
        setModalConfig={setModalConfig}
        setProcessedData={setProcessedData}
      />
    </div>
  );
};

export default TableAlatMesin;
