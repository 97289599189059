import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerBO, ContainerBODetail } from '../BiayaOverhead';

export const TabOverhead = ({ readOnly = false, showHistory = true }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="bo">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="bo">
            <NavTab eventKey="bo" title="Biaya Overhead" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="bo"
              component={
                readOnly ? (
                  <ContainerBODetail />
                ) : (
                  <ContainerBO showHistory={showHistory} />
                )
              }
            />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
