import { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlParser from 'html-react-parser';
import {
  DatePicker,
  Input,
  SelectSearch,
  TextEditor,
  TextArea,
} from 'components';
import TableBarangJadi from './TableBarangJadi';

const TabInfo = ({
  type: TYPE,
  formik,
  listBarangJadi,
  setListBarangJadi,
  dataBarangJadi,
  setDataBarangJadi,
}) => {
  const { values, errors, touched, setFieldValue, setValues } = formik;
  const [textEditorState, setTextEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(values.lain_lain ?? ''))
    )
  );

  const updateEditorState = (value, setFieldValue) => {
    setTextEditorState(value);
    const convertData = convertToRaw(value.getCurrentContent());
    setFieldValue('lain_lain', draftToHtml(convertData));
  };

  // Tampilan jika type TAMBAH / UBAH
  return (
    <div>
      <Row>
        <Col sm>
          <DatePicker
            disabled
            label="Tanggal Peluang"
            name="tgl_peluang"
            selected={values.tgl_peluang ? new Date(values.tgl_peluang) : ''}
            error={errors.tgl_peluang && touched.tgl_peluang && true}
            errorText={errors.tgl_peluang}
          />
        </Col>
        <Col sm>
          <Input
            readOnly
            label="No Peluang"
            type="text"
            name="no_peluang"
            value={values?.no_peluang ?? ''}
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <SelectSearch
            isDisabled={true}
            label="Customer"
            defaultValue={{
              value: values.id_customer,
              label: values.nama_customer,
            }}
            error={errors.id_customer && touched.id_customer && true}
            errorText={
              errors.id_customer && touched.id_customer && errors.id_customer
            }
          />

          <SelectSearch
            label="Proyek"
            isDisabled={true}
            defaultValue={{
              value: values.id_proyek,
              label: values.nama_proyek,
            }}
            error={errors.id_proyek && touched.id_proyek && true}
            errorText={
              errors.id_proyek && touched.id_proyek && errors.id_proyek
            }
          />

          <SelectSearch
            isDisabled={true}
            label="Peringkat Peluang"
            defaultValue={{
              value: values.id_peringkat_peluang,
              label: values.nama_peringkat_peluang,
            }}
            error={
              errors.id_peringkat_peluang &&
              touched.id_peringkat_peluang &&
              true
            }
            errorText={
              errors.id_peringkat_peluang &&
              touched.id_peringkat_peluang &&
              errors.id_peringkat_peluang
            }
          />

          <Input readOnly label="ATT" type="text" value={values.att} />

          <div className="row">
            <div className="col-md-6">
              <DatePicker
                disabled
                label="Target Penawaran"
                name="tgl_selesai"
                selected={
                  values.tgl_selesai ? new Date(values.tgl_selesai) : ''
                }
                error={errors.tgl_selesai && touched.tgl_selesai && true}
                errorText={errors.tgl_selesai}
              />
            </div>
            <div className="col-md-6">
              <DatePicker
                disabled
                label="Realisasi Penawaran"
                name="realisasi_penawaran"
                selected={
                  values.realisasi_penawaran
                    ? new Date(values.realisasi_penawaran)
                    : ''
                }
                error={
                  errors.realisasi_penawaran &&
                  touched.realisasi_penawaran &&
                  true
                }
                errorText={errors.realisasi_penawaran}
              />
            </div>
          </div>

          <Input
            readOnly
            label="Schedule Kebutuhan Proyek (Total Hari)"
            type="number"
            value={values.schedule_kebutuhan_proyek}
          />

          <TextArea
            disabled
            label="Keterangan Pengiriman"
            placeholder="Keterengan Pengiriman"
            value={values.keterangan_pengiriman}
            error={
              errors.keterangan_pengiriman &&
              touched.keterangan_pengiriman &&
              true
            }
            errorText={errors.keterangan_pengiriman}
          />

          <TextArea
            disabled
            label="Lokasi Pengiriman"
            placeholder="Lokasi Pengiriman"
            value={values.lokasi_pengiriman}
            error={
              errors.lokasi_pengiriman && touched.lokasi_pengiriman && true
            }
            errorText={errors.lokasi_pengiriman}
          />

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              disabled
              type="checkbox"
              label="Instalasi"
              checked={values.instalasi}
            />
          </Form.Group>
        </Col>

        <Col>
          <TextEditor
            readOnly
            label="Informasi Lainya"
            placeholder="Tuliskan informasi lainnya"
            editorState={textEditorState}
            onEditorStateChange={(val) => {
              updateEditorState(val, setFieldValue, 'lain_lain');
            }}
            editorHeight={480}
            error={errors.lain_lain && touched.lain_lain && true}
            errorText={
              errors.lain_lain && touched.lain_lain && errors.lain_lain
            }
          />
          <Input
            readOnly
            label="Link Referensi"
            placeholder="https://"
            value={values.link_referensi}
            error={Boolean(errors.link_referensi && touched.link_referensi)}
            errorText={
              Boolean(errors.link_referensi && touched.link_referensi) &&
              errors.link_referensi
            }
          />
        </Col>
      </Row>
      <hr />

      <TableBarangJadi
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        listBarangJadi={listBarangJadi}
        setListBarangJadi={setListBarangJadi}
      />
    </div>
  );
};

export default TabInfo;
