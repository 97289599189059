import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { PeluangApi } from 'api';
import { useSessionStorage } from 'hooks';

export const DropdownAnalisaBarangJadiFilterContext = createContext();

export const DropdownAnalisaBarangJadiFilterContextProvider = ({
  children,
}) => {
  const FETCH_INTERVAL_MINUTES = 2 * 60 * 1000; // 2 MINUTES
  const [triggerFetch, setTriggerFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastFetch, setLastFetch] = useSessionStorage(
    'last_fetch_dropdown_analisa_filter',
    null
  );
  const [dropdown, setDropdown] = useSessionStorage(
    'dropdown_analisa_barang_jadi_filter',
    { barangjadi_peluang: [] }
  );

  const isRequireToRefetch = lastFetch
    ? Boolean(
        new Date()?.getTime() - new Date(lastFetch).getTime() >
          FETCH_INTERVAL_MINUTES
      )
    : true;

  const getDropdown = () => {
    setLoading(true);

    axios
      .all([PeluangApi.getAnalisaBarangJadi()])
      .then(
        axios.spread((resBarangJadiPeluang) => {
          const barangjadi_peluang =
            resBarangJadiPeluang.data.data.map((val) =>
              Object({
                ...val,
                value: val.id_item_buaso,
                label: val.nama_item,
              })
            ) ?? [];

          const dataDropdown = { barangjadi_peluang };

          setDropdown(dataDropdown);
          setLastFetch(new Date());
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (triggerFetch && isRequireToRefetch) {
      getDropdown();
    }

    return () => {
      setDropdown({
        barangjadi_peluang: [],
      });

      setLoading(false);
    };
  }, [triggerFetch]);

  return (
    <DropdownAnalisaBarangJadiFilterContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownAnalisaBarangJadiFilterContext.Provider>
  );
};

export const withDropdownAnalisaBarangJadiFilterContextProvider =
  (Component) => (props) => {
    return (
      <DropdownAnalisaBarangJadiFilterContextProvider>
        <Component {...props} />
      </DropdownAnalisaBarangJadiFilterContextProvider>
    );
  };
