import { createContext, useState } from 'react';

export const RAPContext = createContext();

export const RAPContextProvider = ({ children }) => {
  const [indexAnalisa, setIndexAnalisa] = useState('');
  const [type, setType] = useState('');
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);

  return (
    <RAPContext.Provider
      value={{
        indexAnalisa,
        type,
        dataAnalisaBarangJadi,
        setIndexAnalisa,
        setType,
        setDataAnalisaBarangJadi,
      }}
    >
      {children}
    </RAPContext.Provider>
  );
};
