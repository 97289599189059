import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  Input,
  SelectSearch,
  TBody,
  Td,
  TdFixed,
  TextArea,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { Formik } from 'formik';
import { RupiahConvert } from 'utilities';
import { FinishingBarangJadiApi } from 'api';

const EditFinishingBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dataBp, setDataBp] = useState([]);
  const [dataSc, setDataSc] = useState([]);
  const [dataLc, setDataLc] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [tableBp, setTableBp] = useState([]);
  const [tableSc, setTableSc] = useState([]);
  const [tableLc, setTableLc] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const getData = (conf) => {
    setIsLoading(conf.loading);

    FinishingBarangJadiApi.getDetail(id)
      .then((detail) => {
        setDataDetail(detail.data.data);
        setTableBp(
          detail.data.data.detail.bp.map((val) =>
            Object({ ...val, tipe: 'bp' })
          )
        );
        setTableSc(
          detail.data.data.detail.sc.map((val) =>
            Object({ ...val, tipe: 'sc' })
          )
        );
        setTableLc(
          detail.data.data.detail.lc.map((val) =>
            Object({ ...val, tipe: 'lc' })
          )
        );
      })

      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getDropdown = () => {
    Axios.all([
      FinishingBarangJadiApi.getItemBahanPenunjang(),
      FinishingBarangJadiApi.getItemSubkon(),
      FinishingBarangJadiApi.getItemLabourCost(),
    ])
      .then(
        Axios.spread((bp, sc, lc) => {
          setDataBp(bp.data.data);
          setDataSc(sc.data.data);
          setDataLc(lc.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Dropdown gagal dimuat',
        });
        setShowAlert(true);
      });
  };

  useEffect(() => {
    setNavbarTitle('Tipe Finishing');
    getData({ loading: true });
    getDropdown();

    return () => setIsLoading(false);
  }, [setNavbarTitle]);

  const ItemDetail = ({ dropdownData, kode }) => {
    const [satuan, setSatuan] = useState('');

    const formInitialValues = {
      kode_item_bahan: '',
      id_item_bahan: '',
      nama_item_bahan: '',
      nama_satuan: '',
      harga_satuan: '0',
      sub_total: '0',
      qty: 1,
      koefisien: 1,
      tipe: kode,
    };

    const formValidationSchema = Yup.object().shape({
      id_item_bahan: Yup.string().required('Masukan Item Bahan Penunjang'),
      qty: Yup.string().required('Masukan Qty'),
    });

    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        id_finishing_barang_jadi: dataDetail.id_finishing_barang_jadi,
      };
      FinishingBarangJadiApi.simpanDetail(finalValues)
        .then(() => {
          setAlertConfig({
            variant: 'primary',
            text: `Tambah data berhasil!`,
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setShowAlert(true);
          getData({ loading: false });
        });
    };

    const handleSubTotal = (qty, harga_satuan, koefisien, setFieldValue) => {
      let sub_total = parseFloat(qty) * harga_satuan * parseFloat(koefisien);
      setFieldValue('sub_total', Math.round(sub_total));
    };

    return (
      <>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-row justify-content-between align-items-end mb-1">
                <div style={{ minWidth: '250px' }}>
                  <SelectSearch
                    label={`Item ${
                      kode === 'bp'
                        ? 'Bahan Penunjang'
                        : kode === 'sc'
                        ? 'Subkon'
                        : 'Labour Cost'
                    }`}
                    name="id_item_buaso"
                    option={dropdownData.map((val) => {
                      return {
                        value: val.id_item_buaso,
                        label: `${val.kode_item_bahan} - ${val.nama_item}`,
                        nama_satuan: val.nama_satuan,
                        harga_satuan: val.harga_satuan,
                        kode_item_bahan: val.kode_item_bahan,
                      };
                    })}
                    placeholder="Pilih Item"
                    onChange={(val) => {
                      setFieldValue('id_item_bahan', val.value);
                      setFieldValue('nama_item_bahan', val.label);
                      setFieldValue('nama_satuan', val.nama_satuan);
                      setFieldValue('kode_item_bahan', val.kode_item_bahan);
                      setFieldValue('harga_satuan', val.harga_satuan ?? 0);
                      setSatuan(val.nama_satuan);

                      handleSubTotal(
                        values.qty,
                        val.harga_satuan,
                        values.koefisien,
                        setFieldValue
                      );
                    }}
                    error={
                      errors.id_item_bahan && touched.id_item_bahan && true
                    }
                    errorText={
                      errors.id_item_bahan &&
                      touched.id_item_bahan &&
                      errors.id_item_bahan
                    }
                  />
                </div>
                <Input
                  style={{ maxWidth: '80px' }}
                  label="Qty"
                  type="number"
                  name="qty"
                  min={1}
                  step={0.1}
                  value={values.qty}
                  onChange={(val) => {
                    setFieldValue('qty', val.target.value);
                    handleSubTotal(
                      val.target.value,
                      values.harga_satuan,
                      values.koefisien,
                      setFieldValue
                    );
                  }}
                  error={errors.qty && touched.qty && true}
                  errorText={errors.qty}
                />

                <Input
                  style={{ maxWidth: '100px' }}
                  label="Unit"
                  type="text"
                  name="nama_satuan"
                  value={satuan}
                  readOnly={true}
                  onChange={handleChange}
                />
                <Input
                  style={{ maxWidth: '150px' }}
                  label="Unit Price"
                  type="text"
                  name="harga_satuan"
                  value={
                    RupiahConvert(String(parseInt(values.harga_satuan))).detail
                  }
                  readOnly={true}
                  onChange={handleChange}
                />
                <Input
                  style={{ maxWidth: '80px' }}
                  label="Koefisien"
                  type="number"
                  name="koefisien"
                  min={1}
                  value={values.koefisien}
                  step={0.1}
                  onChange={(val) => {
                    setFieldValue('koefisien', val.target.value);
                    handleSubTotal(
                      values.qty,
                      values.harga_satuan,
                      val.target.value,
                      setFieldValue
                    );
                  }}
                  error={errors.koefisien && touched.koefisien && true}
                  errorText={errors.koefisien}
                />

                <Input
                  style={{ maxWidth: '150px' }}
                  label="Sub Total Price"
                  type="text"
                  name="harga_satuan"
                  value={RupiahConvert(String(values.sub_total)).detail}
                  readOnly={true}
                  onChange={handleChange}
                />

                <ActionButton
                  style={{ marginBottom: '0.5rem' }}
                  text="Tambah Ke List"
                  onClick={handleSubmit}
                  className="mt-4 px-2"
                  size="sm"
                />
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  };

  const TableDetail = ({ dataTable, kode }) => {
    const toCommas = (val) => {
      return val.toString().replace(/\./g, ',');
    };
    const deleteDataHandler = (val) => {
      let deleteValue = {};
      if (val.tipe === 'bp') {
        deleteValue = {
          tipe: val.tipe,
          id_finishing_barang_jadi_bp: val.id_finishing_barang_jadi_bp,
        };
      }
      if (val.tipe === 'lc') {
        deleteValue = {
          tipe: val.tipe,
          id_finishing_barang_jadi_lc: val.id_finishing_barang_jadi_lc,
        };
      }
      if (val.tipe === 'sc') {
        deleteValue = {
          tipe: val.tipe,
          id_finishing_barang_jadi_sc: val.id_finishing_barang_jadi_sc,
        };
      }

      FinishingBarangJadiApi.deleteDetail(deleteValue)
        .then(() =>
          setAlertConfig({
            variant: 'primary',
            text: `Data Berhasil di Hapus`,
          })
        )
        .catch(() =>
          setAlertConfig({
            variant: 'danger',
            text: `Data Gagal di Hapus`,
          })
        )
        .finally(() => {
          getData({ loading: false });
          setShowAlert(true);
        });
    };

    const SubTotal = () =>
      dataTable?.reduce((sum, obj) => sum + parseInt(obj?.sub_total ?? 0), 0);

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <ThFixed>Qty</ThFixed>
              <ThFixed>Unit</ThFixed>
              <Th>Unit Price</Th>
              <ThFixed>Koefisien</ThFixed>
              <Th>Total</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => {
                let totalOrder =
                  parseFloat(val.harga_satuan) * parseFloat(val.qty);
                let sub_total = totalOrder * parseFloat(val.koefisien ?? 0);
                val.sub_total = parseInt(sub_total);
                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item_bahan}</TdFixed>
                    <Td>{val.nama_item}</Td>
                    <TdFixed>{toCommas(parseFloat(val.qty))}</TdFixed>
                    <Td>{val.nama_satuan}</Td>
                    <Td width={150} textRight>
                      {RupiahConvert(String(parseInt(val.harga_satuan))).detail}
                    </Td>
                    <TdFixed>
                      {toCommas(parseFloat(val.koefisien ?? 0))}
                    </TdFixed>
                    <Td width={150} textRight>
                      {RupiahConvert(String(val.sub_total)).detail}
                    </Td>
                    <TdFixed>
                      <div className="d-flex justify-content-center">
                        <DeleteButton
                          onClick={() => {
                            deleteDataHandler(val);
                          }}
                        />
                      </div>
                    </TdFixed>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan="9">
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
            {dataTable.length > 0 ? (
              <Tr>
                <Td textRight colSpan={7}>
                  <strong>{`Sub Total ${
                    kode === 'bp'
                      ? 'Bahan Penunjang'
                      : kode === 'sc'
                      ? 'Subcon'
                      : 'Labour Cost'
                  }`}</strong>
                </Td>
                <Td textRight>{RupiahConvert(SubTotal().toString()).detail}</Td>
                <Td></Td>
              </Tr>
            ) : (
              <div />
            )}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  const formInitialValues = {
    id_finishing_barang_jadi: dataDetail.id_finishing_barang_jadi,
    nama_finishing_barang_jadi: dataDetail.nama_finishing_barang_jadi,
    deskripsi: dataDetail.deskripsi,
  };

  const formValidationSchema = Yup.object().shape({
    nama_finishing_barang_jadi: Yup.string().required(
      'Masukan Nama Finsihing Barang Jadi'
    ),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      detail: [...tableBp, ...tableSc, ...tableLc],
    };

    FinishingBarangJadiApi.update(finalValues)
      .then(() => {
        history.push('/master/finishing-barang-jadi', {
          registerAlertConfig: {
            variant: 'primary',
            text: 'Tambah data berhasil!',
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: `Tambah data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        setShowAlert(true);
      });
  };

  console.log({
    tableBp,
    tableSc,
    tableLc,
  });

  const getGrandTotal = () =>
    [...tableBp]
      .concat(tableSc, tableLc)
      .reduce((sum, obj) => sum + Math.round(obj.sub_total ?? 0), 0);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Update Data Tipe Finishing</b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="text"
                        label="Nama Tipe Finishing"
                        placeholder="Tipe finishing"
                        name="nama_finishing_barang_jadi"
                        onChange={handleChange}
                        value={values.nama_finishing_barang_jadi}
                        error={
                          errors.nama_finishing_barang_jadi &&
                          touched.nama_finishing_barang_jadi &&
                          true
                        }
                        errorText={errors.nama_finishing_barang_jadi}
                      />
                    </Col>

                    <Col md={6}>
                      <TextArea
                        type="text"
                        label="Deskripsi"
                        placeholder="Masukan deskripsi"
                        rows={4}
                        name="deskripsi"
                        value={values.deskripsi}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                  <hr />

                  <ItemDetail kode="bp" dropdownData={dataBp} />
                  <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                    <strong>List Item Bahan Penunjang</strong>
                  </div>
                  <TableDetail dataTable={tableBp} kode="bp" />
                  <hr />

                  <ItemDetail kode="sc" dropdownData={dataSc} />
                  <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                    <strong>List Item Subkon</strong>
                  </div>
                  <TableDetail dataTable={tableSc} kode="sc" />
                  <hr />

                  <ItemDetail kode="lc" dropdownData={dataLc} />
                  <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                    <strong>List Item Labour Cost</strong>
                  </div>

                  <TableDetail dataTable={tableLc} kode="lc" />

                  <CRUDLayout.Table>
                    <Th>
                      <div className="text-right">Grand Total</div>
                    </Th>
                    <Th style={{ width: '10%' }}>
                      <div className="text-right">
                        {RupiahConvert(String(getGrandTotal())).detail}
                      </div>
                    </Th>
                  </CRUDLayout.Table>

                  <div className="d-flex justify-content-end mt-3">
                    <ActionButton
                      type="submit"
                      className="btn btn-success text-white px-3"
                      size="md"
                      text="Update"
                    />
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default EditFinishingBarangJadi;
