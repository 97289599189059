import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';

const AddButton = ({
  onClick,
  tooltipText,
  tooltipPlacement,
  type,
  className,
}) => {
  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={<Tooltip>{tooltipText ? tooltipText : 'Tambah data'}</Tooltip>}
    >
      <Button
        type={type}
        variant="primary"
        size="sm"
        className={`m-1 ${className}`}
        onClick={onClick}
      >
        <AiOutlinePlus style={{ fontSize: '.9rem' }} />
      </Button>
    </OverlayTrigger>
  );
};

export default AddButton;
