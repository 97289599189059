import { useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownPermintaanProduksiContext } from '../Context';

export const ModalFilterJobOrder = ({ show, setShow, filter, setFilter }) => {
  const { dropdownProyek, dropdownCustomer, dropdownJobOrder } = useContext(
    DropdownPermintaanProduksiContext
  );
  const defaultDropdown = { value: null, label: 'Semua' };

  const optItems = [defaultDropdown].concat(dropdownJobOrder?.data ?? []);
  const optProyek = [defaultDropdown].concat(dropdownProyek?.data ?? []);
  const optCustomer = [defaultDropdown].concat(dropdownCustomer?.data ?? []);

  const initialValues = {
    tgl_rap_start: filter?.tgl_rap_start,
    tgl_rap_end: filter?.tgl_rap_end,
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    id_customer: filter?.id_customer,
    id_proyek: filter?.id_proyek,
    id_barang_jadi: filter?.id_barang_jadi,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_rap_start: undefined,
      tgl_rap_end: undefined,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      id_customer: undefined,
      id_proyek: undefined,
      id_barang_jadi: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. RAP"
            placeholderText="Pilih Tanggal RAP"
            startDate={
              values.tgl_rap_start ? new Date(values.tgl_rap_start) : ''
            }
            endDate={values.tgl_rap_end ? new Date(values.tgl_rap_end) : ''}
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_rap_start', startDate);
              setFieldValue('tgl_rap_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Customer"
            placeholder="Pilih Customer"
            loading={false}
            option={optCustomer}
            defaultValue={
              values.id_customer
                ? optCustomer?.find((v) => values.id_customer === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_customer', value)}
          />

          <SelectSearch
            label="Proyek"
            placeholder="Pilih Proyek"
            option={optProyek}
            defaultValue={
              values.id_proyek
                ? optProyek?.find((v) => values.id_proyek === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_proyek', value)}
          />

          <DatePicker
            selectsRange
            label="Tgl. Job Order"
            placeholderText="Pilih Tanggal Job Order"
            startDate={
              values.tgl_job_order_start
                ? new Date(values.tgl_job_order_start)
                : ''
            }
            endDate={
              values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_job_order_start', startDate);
              setFieldValue('tgl_job_order_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Job Order"
            placeholder="Pilih Job Order"
            loading={false}
            option={optItems}
            defaultValue={
              values.id_barang_jadi
                ? optItems?.find((v) => values.id_barang_jadi === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_barang_jadi', value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
