import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  DataStatus,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  SelectMonth,
  Tr,
  Th,
  Td,
} from 'components';
import _ from 'lodash';
import { ProgressProduksiApi } from 'api';
import { PageNumber, DateConvert, RupiahConvert } from 'utilities';

const ProgressProduksi = ({ setNavbarTitle }) => {
  const today = new Date();
  const history = useHistory();
  const style = { minWidth: 200 };
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const mapDetailShouldNotEmpty = (data) => {
    // map detail prop should not empty
    return data?.map((val) => {
      if (!val?.detail?.length) return { ...val, detail: [{}] };

      return val;
    });
  };

  const getInitialData = () => {
    setIsLoading(true);
    setIsSearching(false);

    const query =
      dateSelected.month.toString() === '13'
        ? {
            q: searchKey,
            page: page,
            per_page: dataLength,
            tahun: dateSelected.year,
          }
        : {
            q: searchKey,
            page: page,
            per_page: dataLength,
            bulan: dateSelected.month,
            tahun: dateSelected.year,
          };

    ProgressProduksiApi.getPage(query)
      .then((res) => {
        setData(mapDetailShouldNotEmpty(res.data.data));
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: err.response.data.message,
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Progress Produksi');
    getInitialData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    page,
    dataLength,
    searchKey,
    dateSelected.month,
    dateSelected.year,
  ]);

  const Select = (props) => (
    <select
      {...props}
      className={`custom-select custom-select ${props.className}`}
    >
      {props.children}
    </select>
  );

  const TableContent = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed rowSpan={2}>No.</ThFixed>
            <ThFixed rowSpan={2}>Informasi Peluang</ThFixed>
            <Th rowSpan={2} style={style}>
              Customer
            </Th>
            <Th rowSpan={2} style={style}>
              Proyek
            </Th>
            <ThFixed rowSpan={2}>Informasi SPK</ThFixed>
            <ThFixed rowSpan={2}>Informasi RAP</ThFixed>
            <ThFixed rowSpan={2}>Informasi Job Order</ThFixed>
            <Th rowSpan={2} style={style}>
              Barang Jadi
            </Th>
            <ThFixed colSpan={2}>Time Schedule</ThFixed>
            <ThFixed rowSpan={2} style={style}>
              Nilai Order
            </ThFixed>
            <ThFixed colSpan={4}>Progress Produksi s/d Per Ini </ThFixed>
            <ThFixed rowSpan={2}>Status</ThFixed>
            <ThFixed rowSpan={2}>Detail</ThFixed>
          </Tr>
          <Tr>
            <Th>Start </Th>
            <Th>Finish</Th>

            <Th>Qty. Job Order</Th>
            <Th>Qty. Terealisasi</Th>
            <Th>%</Th>
            <Th style={style}>Rp</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.map((val, index) =>
            val?.detail?.map((item, indexDetail) => (
              <Tr key={indexDetail}>
                {indexDetail === 0 && (
                  <>
                    <TdFixed rowSpan={val.detail.length ?? 1}>
                      {PageNumber(page, dataLength, index)}
                    </TdFixed>

                    <Td rowSpan={val.detail.length ?? 1}>
                      <div>
                        {DateConvert(new Date(val.tgl_peluang)).defaultDMY}
                      </div>
                      <div>{val.no_peluang}</div>
                    </Td>

                    <Td rowSpan={val.detail.length ?? 1}>
                      {val.nama_customer}
                    </Td>

                    <Td rowSpan={val.detail.length ?? 1}>{val.nama_proyek}</Td>

                    <Td rowSpan={val.detail.length ?? 1}>
                      <div>
                        {val.tgl_spk
                          ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_spk ?? '-'}</div>
                    </Td>

                    <Td rowSpan={val.detail.length ?? 1}>
                      <div>
                        {val.tgl_rap
                          ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_rap ?? '-'}</div>
                    </Td>
                  </>
                )}

                <Td>
                  <div>
                    {item.tgl_job_order
                      ? DateConvert(new Date(item.tgl_job_order)).defaultDMY
                      : '-'}
                  </div>
                  <div>{item.no_job_order ?? '-'}</div>
                </Td>

                <Td>{item.nama_item_barang_jadi ?? '-'}</Td>

                <Td>
                  {item.tgl_mulai_jo
                    ? DateConvert(new Date(item.tgl_mulai_jo)).defaultDMY
                    : '-'}
                </Td>

                <Td>
                  {item.tgl_selesai_jo
                    ? DateConvert(new Date(item.tgl_selesai_jo)).defaultDMY
                    : '-'}
                </Td>

                <Td textRight>
                  {
                    RupiahConvert(String(Math.round(item.nilai_order ?? 0)))
                      .detail
                  }
                </Td>

                <Td textRight>{parseFloat(item.qty_job_order ?? 0)}</Td>

                <Td textRight>{parseFloat(item.qty_realisasi ?? 0)}</Td>

                <Td textRight>{parseFloat(item.presentase ?? 0)}%</Td>

                <Td textRight>
                  {RupiahConvert(String(Math.round(item.rp ?? 0))).detail}
                </Td>

                <Td textCenter className="text-uppercase">
                  {item.status ?? '-'}
                </Td>

                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      size="sm"
                      className="text-nowrap"
                      text="Detail"
                      onClick={() =>
                        history.push(`/laporan/progress-produksi/log`, {
                          no_peluang: val.no_peluang,
                          baseline: 1,
                        })
                      }
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))
          )}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setPage(1);
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });
              }}
            >
              <option value={13}>Semua Bulan</option>
            </SelectMonth>

            <Select
              className="m-1"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setPage(1);
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });
              }}
            >
              {_.range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </Select>
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data?.length ? (
        <TableContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default ProgressProduksi;
