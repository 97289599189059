import { useContext } from 'react';
import { Table, Td, TdFixed, Th, ThFixed } from 'components';
import { RupiahConvert } from 'utilities';
import { PermintaanProduksiContext } from '../Context';

import { calcSubtotalHW } from './Hardwood';
import { calcSubtotalPW } from './Plywood';
import { calcSubtotalFS } from './FactorySupply';
import { calcSubtotalBPF } from './BahanPenunjangFinishing';
import { calcSubtotalLC } from './LabourCost';
import { calcSubtotalLCF } from './LabourCostFinishing';
import { calcSubtotalMP } from './MachineProcess';
import { calcSubtotalSF } from './SubconFinishing';
import { calcSubtotalBO } from './BiayaOverhead';

export const SummarySection = () => {
  const {
    dataHardwood,
    dataPlywood,
    dataFS,
    dataBPF,
    dataLC,
    dataLCF,
    dataSF,
    dataMP,
    dataBO,
  } = useContext(PermintaanProduksiContext);

  const summaryBiaya = [
    {
      grup_biaya: 'Permintaan Bahan',
      sub_grup_biaya: 'Hardwood',
      total: dataHardwood?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalHW(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Bahan',
      sub_grup_biaya: 'Plywood',
      total: dataPlywood?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalPW(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Bahan',
      sub_grup_biaya: 'Factory Supply',
      total: dataFS?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalFS(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Bahan',
      sub_grup_biaya: 'Bahan Penunjang Finishing',
      total: dataBPF?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalBPF(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Upah',
      sub_grup_biaya: 'Labour Cost',
      total: dataLC?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalLC(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Upah',
      sub_grup_biaya: 'Labour Cost Finishing',
      total: dataLCF?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalLCF(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Alat Mesin',
      sub_grup_biaya: 'Machine Process',
      total: dataMP?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalMP(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Subkon',
      sub_grup_biaya: 'Subkon Finishing',
      total: dataSF?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalSF(curr),
        0
      ),
    },
    {
      grup_biaya: 'Permintaan Overhead',
      sub_grup_biaya: 'Biaya Overhead',
      total: dataBO?.permintaan?.reduce(
        (acc, curr) => acc + calcSubtotalBO(curr),
        0
      ),
    },
  ];

  const grandTotal = summaryBiaya?.reduce(
    (acc, sum) => acc + parseFloat(sum.total ?? 0),
    0
  );

  return (
    <div className="my-2">
      <div className="mt-3" style={{ fontSize: '.9rem' }}>
        Summary Biaya
      </div>
      <Table className="mt-1 bordered">
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th>Grup Biaya</Th>
            <Th>Sub Grup Biaya</Th>
            <Th>Total Price</Th>
          </tr>
        </thead>

        <tbody>
          {summaryBiaya.map((val, index) => (
            <tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>{val.grup_biaya}</Td>
              <Td>{val.sub_grup_biaya}</Td>
              <Td className="text-right">
                {RupiahConvert(String(Math.round(val.total ?? 0))).detail}
              </Td>
            </tr>
          ))}

          <tr>
            <Td colSpan="3" className="text-right">
              <b>Grand Total</b>
            </Td>
            <Td className="text-right">
              {RupiahConvert(String(Math.round(grandTotal))).detail}
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
