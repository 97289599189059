import { Fragment, useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Table,
  THead,
  Tr,
  Td,
  Th,
  ThFixed,
  TBody,
  DataStatus,
} from 'components';

import { PermintaanProduksiContext } from '../../Context';
import { calcSubtotalLCF, InputLCF } from './InputLCF';
import { RowItemLCF } from './RowItemLCF';
import { ModalDeleteLCF } from './ModalDelete';

export const TableLCF = ({
  jobOrder = false,
  showInputRow = false,
  showTotalPrice = false,
  showStatusPermintaan = false,
  readOnly = false,
  title = '',
  data = [],
}) => {
  const { setDataLCF } = useContext(PermintaanProduksiContext);

  const [updateItem, setUpdateItem] = useState({
    index: null,
    data: null,
  });

  const [deleteItem, setDeleteItem] = useState({
    index: null,
    data: null,
  });

  const onClickJobOrder = (itemJobOrder) => {
    setDataLCF((prev) => ({
      ...prev,
      permintaan: [itemJobOrder, ...prev.permintaan],
    }));
  };

  const onUpdate = (index, data) => setUpdateItem({ index, data });

  const onUpdateCancel = () => setUpdateItem({ index: null, data: null });

  const onDelete = (index, data) => setDeleteItem({ index, data });

  const onCreateSubmit = (values) =>
    setDataLCF((prev) => ({
      ...prev,
      permintaan: [values, ...prev.permintaan],
    }));

  const onUpdateSubmit = (values) => {
    const copyData = [...data];
    copyData[updateItem.index] = values;

    setUpdateItem({ index: null, data: null });
    setDataLCF((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const onDeleteSubmit = () => {
    const copyData = [...data];
    copyData.splice(deleteItem.index, 1);

    setDeleteItem({ index: null, data: null });
    setDataLCF((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const reduceSubTotal = parseFloat(
    data.reduce(
      (subTotal, values) => Math.round(subTotal + calcSubtotalLCF(values)),
      0
    )
  );

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        {title}
      </div>
      <div className="table-responsive">
        <Table>
          <THead className="bg-light">
            <Tr>
              <ThFixed className="text-nowrap">No.</ThFixed>
              <Th width={300}>Uraian Pekerjaan</Th>
              <ThFixed className="text-nowrap">Qty.</ThFixed>
              <ThFixed width={150}>Satuan</ThFixed>
              <ThFixed className="text-nowrap">Unit Price (Rp)</ThFixed>
              <ThFixed className="text-nowrap">Konst.</ThFixed>
              <ThFixed className="text-nowrap">Sub Total Price (Rp)</ThFixed>

              {showStatusPermintaan ? (
                <ThFixed rowSpan={2}>Status Permintaan</ThFixed>
              ) : null}

              {readOnly ? null : <ThFixed>Aksi</ThFixed>}
            </Tr>
          </THead>

          <TBody>
            {showInputRow ? (
              <InputLCF type="create" onSubmit={onCreateSubmit} />
            ) : null}

            {data?.length ? (
              data?.map((val, index) =>
                updateItem?.index === index ? (
                  <InputLCF
                    key={index}
                    type="update"
                    data={updateItem.data}
                    onSubmit={onUpdateSubmit}
                    onCancel={onUpdateCancel}
                  />
                ) : (
                  <RowItemLCF
                    key={index}
                    index={index}
                    data={val}
                    jobOrder={jobOrder}
                    readOnly={readOnly}
                    onUpdate={onUpdate}
                    onClickJobOrder={onClickJobOrder}
                    onDelete={onDelete}
                    showStatusPermintaan={showStatusPermintaan}
                  />
                )
              )
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}

            {data?.length && showTotalPrice ? (
              <Tr>
                <Td textRight colSpan={6}>
                  Total Price
                </Td>
                <Td textRight>
                  <NumberFormat
                    value={reduceSubTotal}
                    displayType="text"
                    prefix="Rp"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                  />
                </Td>
                {readOnly ? null : <Td />}
              </Tr>
            ) : null}
          </TBody>
        </Table>
      </div>

      <ModalDeleteLCF
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        onDeleteSubmit={onDeleteSubmit}
      />
    </Fragment>
  );
};
