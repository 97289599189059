import { useEffect, useState, useContext } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import htmlParser from 'html-react-parser';
import FileSaver from 'file-saver';
import Axios from 'axios';
import { useIsGuest } from 'hooks';
import { AnalisaBarangJadiApi } from 'api';
import {
  Alert,
  Approval,
  BackButton,
  DataStatus,
  ExportButton,
} from 'components';
import ModalFormBarangJadi from '../FormSaveAsBarangJadi';
import FilesUpload from './FilesUpload';

import {
  TableListFNBP,
  TableListFNLC,
  TableListFNSC,
  TableListHardwood,
  TableListPlywood,
  TableListSummary,
  TableListTPBOP,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
} from './Table';
import { sortUrutanItem } from '../../Utilities/sortUrutanItem';
import {
  withDropdownAnalisaBarangJadiContextProvider,
  DropdownAnalisaBarangJadiContext,
} from '../../Context';

const FormAnalisaBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { isApprove = false } = location?.state;
  const { id } = useParams();
  const isGuest = useIsGuest();

  const { dropdown, selectData } = useContext(DropdownAnalisaBarangJadiContext);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState({});
  const [dataBahanBaku, setDataBahanBaku] = useState({});
  const [dataBahanPenunjang, setDataBahanPenunjang] = useState({});
  const [dataFinishing, setDataFinishing] = useState({});
  const [dataOnSite, setDataOnSite] = useState({});
  const [filesUpload, setFilesUpload] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([AnalisaBarangJadiApi.getSingle({ id_barang_jadi: id })])
      .then(
        Axios.spread((value) => {
          const dataValue = value.data.data;
          setDataAnalisaBarangJadi(value.data.data);
          setFilesUpload(value?.data?.data?.gambar ?? []);
          setDataBahanBaku({
            analisa_hardwood: dataValue.analisa_hardwood,
            analisa_plywood: dataValue.analisa_plywood,
          });
          setDataBahanPenunjang({
            analisa_fin_tp_fs: dataValue.analisa_fin_tp_fs,
            analisa_fin_tp_lc: dataValue.analisa_fin_tp_lc,
            analisa_fin_tp_mp: dataValue.analisa_fin_tp_mp,
            analisa_fin_tp_bop: dataValue.analisa_fin_tp_bop,
          });
          setDataFinishing({
            analisa_fin_fn_bp: dataValue.analisa_fin_fn_bp,
            analisa_fin_fn_sc: dataValue.analisa_fin_fn_sc,
            analisa_fin_fn_lc: dataValue.analisa_fin_fn_lc,
          });
        })
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle('Analisa Barang Jadi');
    getInitialData();

    return () => {};
  }, []);

  const InfoSection = () => {
    const [tabs, setTabs] = useState('informasi'); //State untuk menampung tabs yang aktif

    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    );

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div style={{ height: '400px', overflowY: 'auto' }}>{value}</div>
      </div>
    );

    const InfoItemLink = ({ title, value, text }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank" rel="noreferrer">
            {text}
          </a>
        </b>
      </div>
    );

    const tabsConfig = [
      {
        tab: 'informasi',
        label: 'Informasi Umum',
        component: () => (
          <Row>
            <Col>
              <InfoItem
                title="Kode Barang Jadi"
                value={
                  dataAnalisaBarangJadi.kode_item
                    ? dataAnalisaBarangJadi.kode_item
                    : '-'
                }
              />
              <InfoItem
                title="Nama Barang Jadi"
                value={
                  dataAnalisaBarangJadi.nama_item
                    ? dataAnalisaBarangJadi.nama_item
                    : '-'
                }
              />
              <InfoItem
                title="Sumber Barang Jadi"
                value={
                  dataAnalisaBarangJadi.nama_kelompok
                    ? dataAnalisaBarangJadi.nama_kelompok
                    : '-'
                }
              />
              <InfoItem
                title="Satuan Jual"
                value={
                  dataAnalisaBarangJadi.nama_satuan
                    ? dataAnalisaBarangJadi.nama_satuan
                    : '-'
                }
              />
              {dataAnalisaBarangJadi.link_referensi ? (
                <InfoItemLink
                  title="Link Referensi"
                  value={dataAnalisaBarangJadi.link_referensi}
                  text="Lihat Referensi"
                />
              ) : (
                <InfoItem title="Link Referensi" value="-" />
              )}
            </Col>
            <Col>
              <InfoItemUraian
                title="Uraian"
                value={
                  dataAnalisaBarangJadi.uraian
                    ? htmlParser(dataAnalisaBarangJadi.uraian)
                    : '-'
                }
              />
            </Col>
          </Row>
        ),
      },
      {
        tab: 'gambar',
        label: 'Gambar',
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="images"
          />
        ),
      },
      {
        tab: 'file',
        label: 'File',
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="documents"
          />
        ),
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        <div className="mb-2 d-flex justify-content-between align-items-end">
          <h6 className="font-weight-bold text-uppercase">{`Detail Data ${
            dataAnalisaBarangJadi?.nama_item ?? ''
          }`}</h6>
          <BackButton onClick={() => history.goBack()} />
        </div>
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  const TableSection = () => {
    const [tabs, setTabs] = useState('bahan_baku'); //State untuk menampung tabs yang aktif
    const [isModalSaveAs, setIsModalSaveAs] = useState({ show: false });
    const [dataHardwood, setDataHardwood] = useState([]);
    const [dataPlywood, setDataPlywood] = useState([]);
    const [dataTPFS, setDataTPFS] = useState([]);
    const [dataTPLC, setDataTPLC] = useState([]);
    const [dataTPMP, setDataTPMP] = useState([]);
    const [dataTPBOP, setDataTPBOP] = useState([]);
    const [dataFNBP, setDataFNBP] = useState([]);
    const [dataFNSC, setDataFNSC] = useState([]);
    const [dataFNLC, setDataFNLC] = useState([]);
    const [dataMPOS, setDataMPOS] = useState([]);
    const [dataLCOS, setDataLCOS] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
      variable: false,
    });
    const [prevFormState, setPrevFormState] = useState({
      // state menyimpan form value input item hardwood & plywood
      hardwood: {},
      plywood: {},
    });

    const getUniqueTipeFinishing = () => {
      // fungsi mengambil id tipe finishing yang unique
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let uniqueTipeFinishing = []; // menyimpan obj unique id tipe finishing

      // cari unique id tipe finishing
      listBahanBaku?.map((item) => {
        if (
          item?.id_finishing_barang_jadi !== '0' &&
          Boolean(!uniqueTipeFinishing.includes(item?.id_finishing_barang_jadi))
        ) {
          uniqueTipeFinishing.push(item?.id_finishing_barang_jadi);
        }
      });

      uniqueTipeFinishing = uniqueTipeFinishing?.map((item) => {
        return dropdown.tipe_finishing.find(
          (val) => val?.id_finishing_barang_jadi === item
        );
      });

      return uniqueTipeFinishing;
    };

    const getTotalLuasTipeFinishing = () => {
      // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunakan sbg konst pada tab 3)
      const uniqueTipeFinishing = getUniqueTipeFinishing();
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      let totalLuasTipeFinishing = []; // menyimpan total luas tiap tipe finishing

      // hitung total luas tiap tipe finishing
      uniqueTipeFinishing?.map((val) => {
        const totalLuas = listBahanBaku
          ?.filter(
            (item) =>
              item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi
          )
          .reduce(
            (acc, { total_luas }) => acc + parseFloat(total_luas ?? 0),
            0
          );
        totalLuasTipeFinishing.push({
          ...val,
          total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6),
        });
      });
      return totalLuasTipeFinishing;
    };

    const getTotalUnit = () => {
      // fungsi mengambil total unit/volume pada list bahan baku (plywood&hardwood) (digunakan sbg konst pada tab 2)
      const listBahanBaku = [...dataHardwood, ...dataPlywood];
      const totalUnit = listBahanBaku.reduce(
        (total, { total_unit }) => total + parseFloat(total_unit ?? 0),
        0
      );

      return parseFloat(totalUnit).toPrecision(6);
    };

    const filterDataHardwood = () => {
      let finalData = [];
      const data = dataBahanBaku?.analisa_hardwood;
      const sorting = sortUrutanItem(data);

      sorting?.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          id_jenis_kayu: val.id_jenis_kayu,
          nama_jenis_kayu: val.nama_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
          id_part_kayu: val.id_part_kayu,
          nama_part_kayu: val.nama_part_kayu,
        });
      });

      setDataHardwood(finalData);
    };

    const filterDataPlywood = () => {
      let finalData = [];
      const data = dataBahanBaku?.analisa_plywood;
      const sorting = sortUrutanItem(data);

      sorting?.map((val) => {
        finalData.push({
          id_barang_jadi: id,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_final: val.qty_final ?? 0,
          t_final: val.t_final ?? 0,
          w_final: val.w_final ?? 0,
          l_final: val.l_final ?? 0,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.harga_satuan,
          konstanta: val.koefisien,
          kode_item_bahan: val.kode_item_bahan,
          nama_satuan: val.nama_satuan,
          id_item_buaso: val.id_plywood,
          nama_item: val.nama_plywood,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          nama_tipe_sisi: val.nama_tipe_sisi,
        });
      });

      setDataPlywood(finalData);
    };

    const filterDataTipeBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_barang_jadi: val.id_barang_jadi,
              id_item_buaso: val.id_item_bahan,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              id_analisa_barang_jadi_tipe_fs:
                val.id_analisa_barang_jadi_tipe_fs,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataTPFS = filterData(dataBahanPenunjang?.analisa_fin_tp_fs);
      const newDataTPLC = filterData(dataBahanPenunjang?.analisa_fin_tp_lc);
      const newDataTPMP = filterData(dataBahanPenunjang?.analisa_fin_tp_mp);
      const newDataTPBOP = filterData(dataBahanPenunjang?.analisa_fin_tp_bop);
      const newDataLCOS = filterData(dataOnSite?.analisa_fin_os_lc);
      const newDataMPOS = filterData(dataOnSite?.analisa_fin_os_mp);

      setDataTPFS(newDataTPFS);
      setDataTPLC(newDataTPLC);
      setDataTPMP(newDataTPMP);
      setDataTPBOP(newDataTPBOP);
      setDataLCOS(newDataLCOS);
      setDataMPOS(newDataMPOS);
    };

    const filterDataFinishingBarangJadi = () => {
      const filterData = (data) => {
        const newData = [];
        data &&
          data.length > 0 &&
          data.map((val, index) => {
            newData.push({
              id_analisa_barang_jadi_tipe_fs:
                val.id_analisa_barang_jadi_tipe_fs,
              id_barang_jadi: val.id_barang_jadi,
              id_item_bahan: val.id_item_bahan,
              id_finishing_barang_jadi: val.id_finishing_barang_jadi,
              qty: val.qty,
              unit_price: val.harga_satuan,
              konstanta: val.koefisien,
              kode_item_bahan: val.kode_item_bahan,
              nama_item: val.nama_item,
              nama_satuan: val.nama_satuan,
              nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
              urutan_item: index + 1,
            });
          });
        return newData;
      };

      const newDataFNBP = filterData(dataFinishing?.analisa_fin_fn_bp);
      const newDataFNSC = filterData(dataFinishing?.analisa_fin_fn_sc);
      const newDataFNLC = filterData(dataFinishing?.analisa_fin_fn_lc);

      setDataFNBP(newDataFNBP);
      setDataFNSC(newDataFNSC);
      setDataFNLC(newDataFNLC);
    };

    const mappingHardwood = () => {
      const final = [];

      dataHardwood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          is_header: val.is_header,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_jenis_kayu: val.id_jenis_kayu,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
          id_part_kayu: val.id_part_kayu,
        });
      });

      return final;
    };

    const mappingPlywood = () => {
      const final = [];

      dataPlywood.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          urutan_item: val.urutan_item,
          id_deskripsi: val.id_deskripsi,
          deskripsi: val.deskripsi,
          is_header: val.is_header,
          qty_final: val.qty_final,
          t_final: val.t_final,
          w_final: val.w_final,
          l_final: val.l_final,
          qty_raw: val.qty_raw,
          t_raw: val.t_raw,
          w_raw: val.w_raw,
          l_raw: val.l_raw,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_item_buaso: val.id_item_buaso,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_tipe_sisi: val.id_tipe_sisi,
        });
      });

      return final;
    };

    const mappingTP = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_analisa_barang_jadi_tipe_fs: val.id_analisa_barang_jadi_tipe_fs,
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
          id_analisa_barang_jadi_tipe_fs: val.id_analisa_barang_jadi_tipe_fs,
        });
      });

      return final;
    };

    const getGrandTotal = () => {
      const uniqueTipe = getUniqueTipeFinishing().map(
        (val) => val?.id_finishing_barang_jadi
      );
      let totalHardwood = 0;
      let totalPlywood = 0;
      let totalTPFS = 0;
      let totalTPLC = 0;
      let totalTPMP = 0;
      let totalTPBOP = 0;
      let totalFNBP = 0;
      let totalFNSC = 0;
      let totalFNLC = 0;
      let totalLCOS = 0;
      let totalMPOS = 0;

      const totalHW = dataHardwood.reduce((prev, val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
            1000000
        ).toFixed(6);

        const totalUnit =
          parseFloat(countTotalUnit) < 0.000001
            ? '0.000001'
            : parseFloat(countTotalUnit);
        const totalPrice = Math.round(
          parseFloat(totalUnit) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta)
        );
        const checkPrice = val.is_header ? 0 : totalPrice;
        totalHardwood = parseInt(checkPrice);

        return prev + totalHardwood;
      }, 0);

      dataPlywood.map((val) => {
        const total = Math.round(
          parseFloat(val.qty_raw).toFixed(6) *
            parseInt(val.unit_price ?? 0) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalPlywood = parseInt(totalPlywood) + parseInt(total || 0);
      });

      dataTPFS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPFS = parseInt(totalTPFS) + parseInt(total);
      });

      dataTPLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPLC = parseInt(totalTPLC) + parseInt(total);
      });

      dataTPMP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPMP = parseInt(totalTPMP) + parseInt(total);
      });

      dataTPBOP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
      });

      dataFNBP.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNBP = parseInt(totalFNBP) + parseInt(total);
        }
      });

      dataFNSC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNSC = parseInt(totalFNSC) + parseInt(total);
        }
      });

      dataFNLC.map((val) => {
        if (
          val.id_finishing_barang_jadi !== '0' &&
          uniqueTipe.includes(val.id_finishing_barang_jadi)
        ) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
              parseInt(val.unit_price) *
              parseFloat(val.konstanta).toFixed(6)
          );
          totalFNLC = parseInt(totalFNLC) + parseInt(total);
        }
      });

      dataLCOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalLCOS = parseInt(totalLCOS) + parseInt(total);
      });

      dataMPOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalMPOS = parseInt(totalMPOS) + parseInt(total);
      });

      const totalFinishing =
        parseInt(totalFNBP) + parseInt(totalFNSC) + parseInt(totalFNLC);

      const grandTotal =
        parseInt(totalHW) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFinishing);

      const summaryBiaya = [
        { kode: 'hw', nama_biaya: 'Hardwood', subtotal: parseInt(totalHW) },
        { kode: 'pw', nama_biaya: 'Plywood', subtotal: parseInt(totalPlywood) },
        {
          kode: 'fs',
          nama_biaya: 'Factory Supply',
          subtotal: parseInt(totalTPFS),
        },
        {
          kode: 'lc',
          nama_biaya: 'Labour Cost',
          subtotal: parseInt(totalTPLC),
        },
        {
          kode: 'mp',
          nama_biaya: 'Machine Process',
          subtotal: parseInt(totalTPMP),
        },
        {
          kode: 'bop',
          nama_biaya: 'Overhead Pabrik',
          subtotal: parseInt(totalTPBOP),
        },
        {
          kode: 'fin',
          nama_biaya: 'Finishing',
          subtotal: parseInt(totalFinishing),
        },
      ];

      return summaryBiaya;
      // return RupiahConvert(grandTotal.toString()).detail;
    };

    const submitDataHandler = ({ id_barang_jadi, isSaveAs }) => {
      setIsSubmitting(true);

      const analisaHardwood = mappingHardwood();
      const analisaPlywood = mappingPlywood();
      const analisaFinTpFs = mappingTP(dataTPFS);
      const analisaFinTpLc = mappingTP(dataTPLC);
      const analisaFinTpMp = mappingTP(dataTPMP);
      const analisaFinTpBop = mappingTP(dataTPBOP);
      const analisaFinFnBp = mappingFN(dataFNBP);
      const analisaFinFnSc = mappingFN(dataFNSC);
      const analisaFinFnLc = mappingFN(dataFNLC);
      const analisaFinOsLc = mappingTP(dataLCOS);
      const analisaFinOsMp = mappingTP(dataMPOS);

      const finalValues = {
        id_barang_jadi: id_barang_jadi ?? id,
        save_as: isSaveAs ?? false,
        analisa_hardwood: analisaHardwood,
        analisa_plywood: analisaPlywood,
        analisa_fin_tp_fs: analisaFinTpFs,
        analisa_fin_tp_lc: analisaFinTpLc,
        analisa_fin_tp_mp: analisaFinTpMp,
        analisa_fin_tp_bop: analisaFinTpBop,
        analisa_fin_fn_bp: analisaFinFnBp,
        analisa_fin_fn_sc: analisaFinFnSc,
        analisa_fin_fn_lc: analisaFinFnLc,
        analisa_fin_os_mp: analisaFinOsMp,
        analisa_fin_os_lc: analisaFinOsLc,
      };

      AnalisaBarangJadiApi.save(finalValues)
        .then(() =>
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          })
        )
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal disimpan!',
          })
        )
        .finally(() =>
          history.push('/transaksi/analisa-barang-jadi', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil simpan!',
            },
          })
        );
    };

    useEffect(() => {
      filterDataHardwood();
      filterDataPlywood();
      filterDataTipeBarangJadi();
      filterDataFinishingBarangJadi();
    }, []);

    const SelectKelompokTipeBarangJadi = () => {
      const totalVolume = getTotalUnit();
      return (
        <div className="d-flex justify-content-end align-items-end mb-3">
          {/* Total Volume Section */}
          <div className="col-md-12">
            <small className="pl-2 mt-1">Total Volume</small>
            <br />
            <div className="pl-2 mt-1 mr-2 font-weight-bold">
              {`${parseFloat(totalVolume).toPrecision()} (m3)`}
            </div>
          </div>
        </div>
      );
    };

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'bahan_baku',
        label: 'Analisa Bahan Baku',
        component: () => (
          <>
            <TableListHardwood
              dataHardwood={dataHardwood}
              setDataHardwood={setDataHardwood}
              dropdownTipeSisi={dropdown.tipe_sisi}
              dropdownTipeFinishing={dropdown.tipe_finishing}
              dropdownSpekKayu={dropdown.spek_kayu}
              dropdownDeskripsi={dropdown.deskripsi}
              dropdownPart={dropdown.part_kayu}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
            <TableListPlywood
              dataPlywood={dataPlywood}
              setDataPlywood={setDataPlywood}
              dropdownTipeSisi={dropdown.tipe_sisi}
              dropdownTipeFinishing={dropdown.tipe_finishing}
              dropdownSpekPlywood={dropdown.spek_plywood}
              dropdownDeskripsi={dropdown.deskripsi}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
          </>
        ),
      },
      {
        tab: 'penunjang',
        label: 'Analisa Penunjang Produksi',
        component: () => {
          let totalVolume = getTotalUnit();
          return (
            <>
              <SelectKelompokTipeBarangJadi />
              {loading.tipe ? (
                <div style={{ height: '800px' }}>
                  <DataStatus
                    loading={true}
                    text="Memuat data penunjang produksi"
                  />
                </div>
              ) : (
                <>
                  <TableListTPFS
                    dataSelectTPFS={selectData.tpfs}
                    dataTPFS={dataTPFS}
                    setDataTPFS={setDataTPFS}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPLC
                    dataSelectTPLC={selectData.tplc}
                    dataTPLC={dataTPLC}
                    setDataTPLC={setDataTPLC}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPMP
                    dataSelectTPMP={selectData.tpmp}
                    dataTPMP={dataTPMP}
                    setDataTPMP={setDataTPMP}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPBOP
                    dataSelectTPBOP={selectData.tpbop}
                    dataTPBOP={dataTPBOP}
                    setDataTPBOP={setDataTPBOP}
                    defaultKonst={totalVolume}
                  />
                </>
              )}
            </>
          );
        },
      },
      {
        tab: 'finishing',
        label: 'Analisa Finishing',
        component: () => {
          let totalLuasTipeFinishing = getTotalLuasTipeFinishing();
          return (
            <>
              {loading.finishing ? (
                <DataStatus loading={loading.finishing} text="Memuat data" />
              ) : totalLuasTipeFinishing.length > 0 ? (
                totalLuasTipeFinishing.map((val) => {
                  return (
                    <div className="mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="pl-2 font-weight-bold text-uppercase">
                          {val.nama_finishing_barang_jadi}
                        </h5>
                        <div className="d-flex justify-content-center align-items-start mx-2">
                          <div className="mr-3">
                            <small className="pl-2">{`Total Luas Permukaan`}</small>
                            <br />
                            <span className="pl-2 font-weight-bold">{`${parseFloat(
                              val.total_luas_tipe_finishing
                            ).toPrecision()} (m2)`}</span>
                          </div>
                        </div>
                      </div>
                      <TableListFNBP
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNBP={selectData.fnbp}
                        dataFNBP={dataFNBP}
                        setDataFNBP={setDataFNBP}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <TableListFNSC
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNSC={selectData.fnsc}
                        dataFNSC={dataFNSC}
                        setDataFNSC={setDataFNSC}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <TableListFNLC
                        id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                        dataSelectFNLC={selectData.fnlc}
                        dataFNLC={dataFNLC}
                        setDataFNLC={setDataFNLC}
                        defaultKonst={val.total_luas_tipe_finishing}
                      />
                      <hr />
                    </div>
                  );
                })
              ) : (
                <DataStatus text="Data Tipe Finishing Bahan Baku diperlukan" />
              )}
            </>
          );
        },
      },
    ];

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault();
      setTabs(newPage);
    };

    const exportButtonHandler = () => {
      AnalisaBarangJadiApi.exportAnalisaBarangJadi({ id_barang_jadi: id })
        .then((res) => {
          const path = res.data.data;
          const fileName = path.split('/').pop();
          FileSaver.saveAs(path, fileName);
        })
        .catch((err) =>
          window.alert(
            `Tidak dapat mengunduh file ${err.response.data.message}`
          )
        );
    };

    useEffect(() => {
      setTabs(tabsConfig[0].tab);
    }, []);

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    );

    return (
      <>
        {/* Card Analisa Barang Jadi */}
        <Row className="d-flex justify-content-center align-items-end">
          <Col>
            <h6 className="font-weight-bold text-uppercase">
              Data Analisa Barang Jadi
            </h6>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <h6 className="font-weight-bold text-uppercase">
              {isApprove && <ExportButton onClick={exportButtonHandler} />}
            </h6>
          </Col>
        </Row>
        <Card className="mb-4">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === tabs && <Component key={index} />
            )}
          </Card.Body>
        </Card>

        {/* Card Summary Biaya */}
        <h6 className="font-weight-bold text-uppercase">Summary Biaya</h6>
        <Card>
          <Card.Body>
            <TableListSummary getGrandTotal={getGrandTotal} />
          </Card.Body>
        </Card>

        {!isGuest && <div className="col d-flex justify-content-end p-0"></div>}

        {isModalSaveAs.show && (
          <ModalFormBarangJadi
            ID={id}
            modalConfig={isModalSaveAs}
            setModalConfig={setIsModalSaveAs}
            submitDataHandler={submitDataHandler}
          />
        )}
      </>
    );
  };

  if (isPageLoading) {
    return <DataStatus loading={true} text="Memuat data . . ." />;
  } else if (isFetchingFailed) {
    return <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />;
  }

  return (
    <>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {!isPageLoading && !isFetchingFailed && <InfoSection />}

      {/* Table Section */}
      {!isPageLoading && !isFetchingFailed && (
        <TableSection
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
      )}

      {!isPageLoading && !isFetchingFailed && (
        <Approval data={dataAnalisaBarangJadi} />
      )}
    </>
  );
};

export default withDropdownAnalisaBarangJadiContextProvider(
  FormAnalisaBarangJadi
);
