import { Fragment, useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Table, THead, Tr, Td, ThFixed, TBody, DataStatus } from 'components';

import { PermintaanProduksiContext } from '../../Context';
import { kalkulasiLuas, kalkulasiVolume } from '../../Helpers';
import { calcSubtotalPW, InputPlywood } from './InputPlywood';
import { RowItemPlywood } from './RowItemPlywood';
import { ModalDeletePW } from './ModalDelete';

export const TablePlywood = ({
  jobOrder = false,
  showInputRow = false,
  showTotalLuasVolume = false,
  showStatusPermintaan = false,
  readOnly = false,
  title = '',
  data = [],
}) => {
  const { setDataPlywood } = useContext(PermintaanProduksiContext);

  const [updateItem, setUpdateItem] = useState({
    index: null,
    data: null,
  });

  const [deleteItem, setDeleteItem] = useState({
    index: null,
    data: null,
  });

  const onClickJobOrder = (itemJobOrder) => {
    setDataPlywood((prev) => ({
      ...prev,
      permintaan: [itemJobOrder, ...prev.permintaan],
    }));
  };

  const onUpdate = (index, data) => setUpdateItem({ index, data });

  const onUpdateCancel = () => setUpdateItem({ index: null, data: null });

  const onDelete = (index, data) => setDeleteItem({ index, data });

  const onCreateSubmit = (values) =>
    setDataPlywood((prev) => ({
      ...prev,
      permintaan: [values, ...prev.permintaan],
    }));

  const onUpdateSubmit = (values) => {
    const copyData = [...data];
    copyData[updateItem.index] = values;

    setUpdateItem({ index: null, data: null });
    setDataPlywood((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const onDeleteSubmit = () => {
    const copyData = [...data];
    copyData.splice(deleteItem.index, 1);

    setDeleteItem({ index: null, data: null });
    setDataPlywood((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const reduceTotalLuas = parseFloat(
    data.reduce(
      (luas, values) =>
        luas +
        kalkulasiLuas({
          id_tipe_sisi: values.id_tipe_sisi,
          qty: values.qty_raw,
          t: values.t_raw,
          w: values.w_raw,
          l: values.l_raw,
        }),
      0
    )
  ).toFixed(6);

  const reduceTotalVolume = parseFloat(
    data.reduce(
      (volume, values) =>
        volume +
        kalkulasiVolume({
          qty_raw: values.qty_raw,
          t_raw: values.t_raw,
          w_raw: values.w_raw,
          l_raw: values.l_raw,
        }),
      0
    )
  ).toFixed(6);

  const reduceSubTotal = parseFloat(
    data.reduce(
      (subTotal, values) => Math.round(subTotal + calcSubtotalPW(values)),
      0
    )
  );

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        {title}
      </div>
      <div className="table-responsive">
        <Table>
          <THead className="bg-light">
            <Tr>
              <ThFixed rowSpan={2} className="text-nowrap">
                No.
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Deskripsi
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Spesifikasi Kayu
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Tipe Finishing
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Tipe Sisi
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Qty. Final
              </ThFixed>
              <ThFixed colSpan={3} className="text-nowrap">
                Ukuran Final (cm)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Qty. Raw
              </ThFixed>
              <ThFixed colSpan={3}>Ukuran Raw (cm)</ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Luas (m2)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Volume (m3)
              </ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Unit Price
              </ThFixed>
              <ThFixed rowSpan={2}>Konst.</ThFixed>
              <ThFixed rowSpan={2} className="text-nowrap">
                Sub Total Price (Rp)
              </ThFixed>
              {showStatusPermintaan ? (
                <ThFixed rowSpan={2}>Status Permintaan</ThFixed>
              ) : null}
              {readOnly ? null : <ThFixed rowSpan={2}>Aksi</ThFixed>}
            </Tr>

            <Tr>
              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>

              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>
            </Tr>
          </THead>

          <TBody>
            {showInputRow ? (
              <InputPlywood type="create" onSubmit={onCreateSubmit} />
            ) : null}

            {data?.length ? (
              data?.map((val, index) =>
                updateItem?.index === index ? (
                  <InputPlywood
                    key={index}
                    type="update"
                    data={updateItem.data}
                    onSubmit={onUpdateSubmit}
                    onCancel={onUpdateCancel}
                  />
                ) : (
                  <RowItemPlywood
                    key={index}
                    index={index}
                    data={val}
                    jobOrder={jobOrder}
                    readOnly={readOnly}
                    showStatusPermintaan={showStatusPermintaan}
                    onUpdate={onUpdate}
                    onClickJobOrder={onClickJobOrder}
                    onDelete={onDelete}
                  />
                )
              )
            ) : (
              <Tr>
                <Td colSpan={19}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}

            {data?.length && showTotalLuasVolume ? (
              <Tr>
                <Td textRight colSpan={13}>
                  Total Luas dan Volume
                </Td>
                <Td textRight>{reduceTotalLuas}</Td>
                <Td textRight>{reduceTotalVolume}</Td>
                <Td textRight colSpan={2}>
                  Total Price
                </Td>
                <Td textRight>
                  <NumberFormat
                    value={reduceSubTotal}
                    displayType="text"
                    prefix="Rp"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                  />
                </Td>
                {readOnly && jobOrder ? null : <Td />}
              </Tr>
            ) : null}
          </TBody>
        </Table>
      </div>

      <ModalDeletePW
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        onDeleteSubmit={onDeleteSubmit}
      />
    </Fragment>
  );
};
