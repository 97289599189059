import { Services } from '../../../services';

class BarangJadiUkuranApi {
  page(params) {
    return Services.get('/barang_jadi_ukuran/page', { params });
  }
  single(params) {
    return Services.get('/barang_jadi_ukuran/single', { params });
  }
  create(data) {
    return Services.post('/barang_jadi_ukuran', data);
  }
  update(data) {
    return Services.put('/barang_jadi_ukuran', data);
  }
  delete(data) {
    return Services.post('/barang_jadi_ukuran/delete', data);
  }
  show(data) {
    return Services.put('/barang_jadi_ukuran/show', data);
  }
  hide(data) {
    return Services.put('/barang_jadi_ukuran/hide', data);
  }
}

export default new BarangJadiUkuranApi();
