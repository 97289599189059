import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoEyeOutline } from 'react-icons/io5';
import { Row, Col } from 'react-bootstrap';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { RealisasiProduksiApi } from 'api';

const RealisasiProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataRealisasiProduksi, setDataRealisasiProduksi] = useState([]);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_jo_awal: undefined,
    tgl_jo_akhir: undefined,
    nama_customer: undefined,
    no_spk: undefined,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RealisasiProduksiApi.get({
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
    })
      .then(({ data }) => {
        setDataRealisasiProduksi(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    history.replace(location?.pathname, { alert: undefined });
    setAlertConfig({ ...alertConfig, show: false });
  };

  useEffect(() => {
    setNavbarTitle('Realisasi Produksi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, pagination.q, pagination.page, pagination.per_page]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th className="p-0" style={{ width: 30 }}>
              Aksi
            </Th>
            <ThFixed>Informasi Realisasi Produksi</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th className="p-0" style={{ minWidth: 200 }}>
              Customer
            </Th>
            <Th className="p-0" style={{ minWidth: 200 }}>
              Barang Jadi
            </Th>
            <Th className="p-0" style={{ width: 100 }}>
              Qty. Job Order
            </Th>
            <Th className="p-0" style={{ width: 150 }}>
              Qty. yang Telah Terealisasi
            </Th>
          </Tr>
        </THead>
        <TBody>
          {dataRealisasiProduksi.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>
              <Td>
                <ActionButton
                  size="sm"
                  onClick={() =>
                    history.push({
                      pathname:
                        '/transaksi/realisasi-produksi/detail/' +
                        val.id_realisasi_produksi,
                      state: {
                        dataJo: val,
                      },
                    })
                  }
                >
                  <IoEyeOutline />
                </ActionButton>
              </Td>
              <TdFixed>
                <div>
                  {' '}
                  {val.tgl_realisasi_produksi
                    ? DateConvert(new Date(val.tgl_realisasi_produksi))
                        .defaultDMY
                    : '-'}{' '}
                </div>
                <div>{val?.no_realisasi_produksi ?? '-'}</div>
              </TdFixed>
              <TdFixed>
                <div>
                  {' '}
                  {val.tgl_job_order
                    ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                    : '-'}{' '}
                </div>
                <div>{val?.no_job_order ?? '-'}</div>
              </TdFixed>
              <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
              <Td>{val.nama_barang_jadi ? val.nama_barang_jadi : '-'}</Td>
              <Td>
                {val?.qty_rap
                  ? `${parseFloat(val.qty_rap)} ${val.kode_satuan}`
                  : '-'}
              </Td>
              <Td>
                {val?.qty_realisasi
                  ? `${parseFloat(val.qty_realisasi)} ${val.kode_satuan}`
                  : '-'}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!dataRealisasiProduksi || dataRealisasiProduksi.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="p-2">
          <b>List Data Realisasi Produksi</b>
        </div>
        <DataTable />
        {Boolean(dataRealisasiProduksi?.length > 0) && (
          <Pagination
            dataLength={pagination.per_page}
            dataNumber={
              pagination.page * pagination.per_page - pagination.per_page + 1
            }
            dataPage={
              pagination.dataCount < pagination.per_page
                ? pagination.dataCount
                : pagination.page * pagination.per_page
            }
            dataCount={pagination.dataCount}
            currentPage={pagination.page}
            totalPage={pagination.total_page}
            onPaginationChange={({ selected }) =>
              setPagination((prev) => ({
                ...prev,
                page: selected + 1,
              }))
            }
            onDataLengthChange={(e) =>
              setPagination((prev) => ({
                ...prev,
                page: 1,
                per_page: e.target.value,
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(
                  (e) =>
                    setPagination((prv) => ({
                      ...prv,
                      q: e.target.value,
                      page: 1,
                    })),
                  1500
                )}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push({
                pathname: '/transaksi/realisasi-produksi/job-order',
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={Boolean(alertConfig?.text?.length)}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default RealisasiProduksi;
