import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerMP, ContainerMPDetail } from '../MachineProcess';

export const TabAlatMesin = ({ readOnly = false, showHistory = true }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="alat">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="alat">
            <NavTab eventKey="alat" title="Alat Mesin" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="alat"
              component={
                readOnly ? (
                  <ContainerMPDetail />
                ) : (
                  <ContainerMP showHistory={showHistory} />
                )
              }
            />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
