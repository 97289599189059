import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';

const ModalFilterSPKRAP = ({ dropdown, show, setShow, filter, setFilter }) => {
  const initialValues = {
    tgl_spk_awal: filter.tgl_spk_awal,
    tgl_spk_akhir: filter.tgl_spk_akhir,
    tgl_selesai_start: filter.tgl_selesai_start,
    tgl_selesai_end: filter.tgl_selesai_end,
    id_customer: filter.id_customer,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...values, ...prev, filter: false }));
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_spk_awal: undefined,
      tgl_spk_akhir: undefined,
      tgl_selesai_start: undefined,
      tgl_selesai_end: undefined,
      id_customer: undefined,
    }));
    setShow(false);
  };
  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ values, setValues, handleSubmit }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. SPK"
            placeholderText="Pilih tanggal SPK"
            monthsShown={2}
            startDate={values.tgl_spk_awal ? new Date(values.tgl_spk_awal) : ''}
            endDate={values.tgl_spk_akhir ? new Date(values.tgl_spk_akhir) : ''}
            onChange={(dates) => {
              const [start, end] = dates;
              const tgl_spk_awal = start ? DateConvert(start).default : null;
              const tgl_spk_akhir = end ? DateConvert(end).default : null;

              setValues((prev) => ({ ...prev, tgl_spk_awal, tgl_spk_akhir }));
            }}
          />

          <DatePicker
            selectsRange
            label="Tgl. Selesai"
            placeholderText="Pilih tanggal selesai"
            startDate={
              values.tgl_selesai_start ? new Date(values.tgl_selesai_start) : ''
            }
            endDate={
              values.tgl_selesai_end ? new Date(values.tgl_selesai_end) : ''
            }
            monthsShown={2}
            onChange={(dates) => {
              const [start, end] = dates;
              const tgl_selesai_start = start
                ? DateConvert(start).default
                : null;
              const tgl_selesai_end = end ? DateConvert(end).default : null;

              setValues((prev) => ({
                ...prev,
                tgl_selesai_start,
                tgl_selesai_end,
              }));
            }}
          />

          <SelectSearch
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown?.customer?.find(
              (item) => item.value === values.id_customer
            )}
            option={dropdown?.customer}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, id_customer: e.value }))
            }
          />
        </FilterModal>
      )}
    </Formik>
  );
};

export default ModalFilterSPKRAP;
