import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { Input, ActionButton, BackButton, DataStatus, Alert } from 'components';
import { TabSection } from '../Section';
import { JobOrderApi } from 'api';
import { DateConvert, RupiahConvert } from 'utilities';
import { InfoSPK, TableSPK } from '../SPKSection';
import { multiplyByQtyRap } from '../Section/multiplyByQtyRap';

const AddJobOrder = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [analisaHardwood, setAnalisaHardwood] = useState([]);
  const [analisaPlyWood, setAnalisaPlyWood] = useState([]);
  const [analisaFs, setAnalisaFs] = useState([]);
  const [analisaLc, setAnalisaLc] = useState([]);
  const [analisaMp, setAnalisaMp] = useState([]);
  const [analisaBop, setAnalisaBop] = useState([]);
  const [analisaBp, setAnalisaBp] = useState([]);
  const [analisaSc, setAnalisaSc] = useState([]);
  const [analisaFnLc, setAnalisaFnLc] = useState([]);
  const [dataUrainLabourCost, setdataUrainLabourCost] = useState([]);
  const [dataUraianLabourCostFinishing, setdataUraianLabourCostFinishing] =
    useState([]);
  const [dataUraianSubcontFinishing, setdataUraianSubcontFinishing] = useState(
    []
  );
  const [modalSPKConfig, setModalSPKConfig] = useState({
    show: false,
    id_spk: '',
  });
  const [noBaru, setNoBaru] = useState('');

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const history = useHistory();
  const { id } = useParams();

  // GET PAGE LIST RAP
  const getInitialData = () => {
    setIsLoading(true);

    JobOrderApi.getSingleRap({ id_rap_detail: id })
      .then(({ data }) => {
        setData(data.data);
        setAnalisaHardwood(data.data.analisa.analisa_hardwood);
        setAnalisaPlyWood(data.data.analisa.analisa_plywood);
        setAnalisaFs(data.data.analisa.analisa_fin_tp_fs);
        setAnalisaLc(data.data.analisa.analisa_fin_tp_lc);
        setAnalisaMp(data.data.analisa.analisa_fin_tp_mp);
        setAnalisaBop(data.data.analisa.analisa_fin_tp_bop);
        setAnalisaBp(data.data.analisa.analisa_fin_fn_bp);
        setAnalisaBop(data.data.analisa.analisa_fin_tp_bop);
        setAnalisaBp(data.data.analisa.analisa_fin_fn_bp);
        setAnalisaSc(data.data.analisa.analisa_fin_fn_sc);
        setAnalisaFnLc(data.data.analisa.analisa_fin_fn_lc);
        setdataUrainLabourCost(data.data.analisa.uraian_labour_cost);
        setdataUraianLabourCostFinishing(
          data.data.analisa.uraian_labour_cost_finishing
        );
        setdataUraianSubcontFinishing(
          data.data.analisa.uraian_subkon_finishing
        );
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getNoBaru = (tgl) => {
    JobOrderApi.getNo({ tanggal: tgl }).then(({ data }) => {
      setNoBaru(data.data);
    });
  };

  const ModalSPK = () => {
    const [fetchSPKStatus, setFetchSPKStatus] = useState({
      loading: true,
      success: false,
      alert: false,
    });
    const [dataSPK, setdataSPK] = useState({});

    const getDataSPK = (id) => {
      JobOrderApi.getSingleSPK({ id_spk: id })
        .then((data) => setdataSPK(data.data.data))
        .catch((err) => {})
        .finally(() => setFetchSPKStatus({ loading: false, success: true }));
    };

    useEffect(() => {
      if (modalSPKConfig.show) {
        getDataSPK(modalSPKConfig.id_spk);
      } else {
        setModalSPKConfig({ loading: false, success: false });
      }
    }, []);

    return (
      <Modal
        size="xl"
        show={modalSPKConfig.show}
        onHide={() =>
          setModalSPKConfig({
            ...modalSPKConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>Detail Data SPK</Modal.Header>
        <Modal.Body>
          {fetchSPKStatus.loading ? (
            <DataStatus loading={true} text="Memuat Data" />
          ) : (
            <>
              <InfoSPK dataSPK={dataSPK} />
              <TableSPK dataSPK={dataSPK} dataPrelim={dataSPK.detail_prelim} />
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle('Tambah Job Order');
    return () => {};
  }, []);

  const getGrandTotal = () => {
    let totalHardwood = 0;
    let totalPlywood = 0;
    let totalTPFS = 0;
    let totalTPLC = 0;
    let totalTPMP = 0;
    let totalTPBOP = 0;
    let totalFNBP = 0;
    let totalFNSC = 0;
    let totalFNLC = 0;

    analisaHardwood.map((val) => {
      const countTotalUnit = parseFloat(
        (parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.t_raw).toFixed(6) *
          parseFloat(val.w_raw).toFixed(6) *
          parseFloat(val.l_raw).toFixed(6)) /
          1000000
      ).toFixed(6);
      const totalUnit =
        parseFloat(countTotalUnit) < parseFloat(0.01)
          ? parseFloat(0.01)
          : parseFloat(countTotalUnit);
      const totalPrice = parseInt(
        parseFloat(totalUnit) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien)
      );
      const checkPrice = val.is_header ? 0 : totalPrice;
      totalHardwood = parseInt(parseInt(totalHardwood) + parseInt(checkPrice));
    });

    analisaPlyWood.map((val) => {
      const countTotalUnit = parseFloat(
        (parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.t_raw).toFixed(6) *
          parseFloat(val.w_raw).toFixed(6) *
          parseFloat(val.l_raw).toFixed(6)) /
          1000000
      ).toFixed(6);
      const totalUnit =
        parseFloat(countTotalUnit) < parseFloat(0.01)
          ? parseFloat(0.01)
          : parseFloat(countTotalUnit);
      const totalPrice = parseInt(
        parseFloat(totalUnit) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien)
      );
      const checkPrice = val.is_header ? 0 : totalPrice;
      totalPlywood = parseInt(parseInt(totalPlywood) + parseInt(checkPrice));
    });

    analisaFs.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalTPFS = parseInt(totalTPFS) + parseInt(total);
    });

    analisaLc.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalTPLC = parseInt(totalTPLC) + parseInt(total);
    });

    analisaMp.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalTPMP = parseInt(totalTPMP) + parseInt(total);
    });

    analisaBop.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
    });

    analisaBp.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalFNBP = parseInt(totalFNBP) + parseInt(total);
    });

    analisaSc.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalFNSC = parseInt(totalFNSC) + parseInt(total);
    });

    analisaFnLc.map((val) => {
      const total = parseInt(
        parseFloat(val.qty).toFixed(6) *
          parseInt(val.harga_satuan) *
          parseFloat(val.koefisien).toFixed(6)
      );
      totalFNLC = parseInt(totalFNLC) + parseInt(total);
    });

    const grandTotal =
      parseInt(totalHardwood) +
      parseInt(totalPlywood) +
      parseInt(totalTPFS) +
      parseInt(totalTPLC) +
      parseInt(totalTPMP) +
      parseInt(totalTPBOP) +
      parseInt(totalFNBP) +
      parseInt(totalFNSC) +
      parseInt(totalFNLC);

    return RupiahConvert(grandTotal.toString()).detail;
  };

  const HeadView = ({ title, text, link, onClick }) => (
    <>
      <div className="d-flex">
        <div style={{ width: 150 }}>{title}</div>
        <div>:</div>
        <div
          className={link ? 'text-primary ml-3' : 'ml-3'}
          style={{ cursor: link ? 'pointer' : 'default' }}
          onClick={onClick}
        >
          {text}
        </div>
      </div>
    </>
  );

  const GrandTotalSection = () => (
    <div className="p-2 bg-white text-right border">
      <b className="text-nowrap pr-3" style={{ fontSize: '14px' }}>
        Grand Total : {getGrandTotal()}
      </b>
    </div>
  );

  const formInitialValues = {
    id_rap_detail: id,
    tgl_job_order: '',
    tgl_mulai_jo: '',
    tgl_selesai_jo: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_job_order: Yup.string().required('Masukan Tanggal Job Order'),
    tgl_mulai_jo: Yup.string().required('Masukan Tanggal Mulai Job Order'),
    tgl_selesai_jo: Yup.string().required('Masukan Tanggal Selesai Job Order'),
  });

  const formSubmitHandler = (values) => {
    JobOrderApi.save(values)
      .then((res) => {
        setAlertConfig({
          variant: 'primary',
          text: `Tambah data berhasil! `,
        });
      })
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: `Tambah data gagal! `,
          show: true,
        });
      })
      .finally(() =>
        history.push('/transaksi/job-order', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil simpan!',
          },
        })
      );
  };

  return (
    <div className="mb-5">
      {isLoading ? (
        <div>
          <DataStatus text="Memuat Data" loading={true} />
        </div>
      ) : data !== undefined ? (
        <>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <b>Tambah Job Order</b>
                    <BackButton onClick={() => history.goBack()} />
                  </Card.Header>
                  <Card.Body>
                    <Alert
                      show={alertConfig.show}
                      variant={alertConfig.variant}
                      text={alertConfig.text}
                      showCloseButton={true}
                      onClose={() => {
                        setAlertConfig({
                          ...alertConfig,
                          show: false,
                        });
                      }}
                    />
                    <Row>
                      <Col>
                        <HeadView
                          title="Tgl. RAP"
                          text={
                            data.tgl_rap
                              ? DateConvert(new Date(data.tgl_rap)).detail
                              : '-'
                          }
                        />
                        <HeadView
                          title="No. RAP"
                          text={data.no_rap ? data.no_rap : '-'}
                        />
                        <HeadView
                          title="Customer"
                          text={data.nama_customer ? data.nama_customer : '-'}
                        />
                        <HeadView
                          title="ATT"
                          text={data.att ? data.att : '-'}
                        />
                        <HeadView
                          title="Tgl. Selesai Peluang"
                          text={
                            data.tgl_selesai
                              ? DateConvert(new Date(data.tgl_selesai)).detail
                              : '-'
                          }
                        />
                      </Col>
                      <Col>
                        <HeadView
                          title="Tgl. Selesai Barang Jadi"
                          text={
                            data.tgl_spk
                              ? DateConvert(new Date(data.tgl_spk)).detail
                              : '-'
                          }
                        />
                        <HeadView
                          title="No. SPK"
                          text={data.no_spk ? data.no_spk : '-'}
                          link
                          onClick={() =>
                            setModalSPKConfig({
                              id_spk: data.id_spk,
                              show: true,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                      <Col lg="3">
                        <Input
                          label="Tgl. Job Order"
                          type="date"
                          name="tgl_job_order"
                          value={values.tgl_job_order}
                          onChange={(e) => {
                            getNoBaru(e.target.value);
                            setFieldValue('tgl_job_order', e.target.value);
                          }}
                          error={
                            errors.tgl_job_order &&
                            touched.tgl_job_order &&
                            true
                          }
                          errorText={
                            errors.tgl_job_order &&
                            touched.tgl_job_order &&
                            errors.tgl_job_order
                          }
                        />
                      </Col>
                      <Col lg="3">
                        <Input
                          label="No. Job Order"
                          type="text"
                          readOnly={true}
                          name="no_job_order"
                          placeholder="Pilih Tanggal Terlebih Dahulu"
                          value={noBaru}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col lg="3">
                        <Input
                          label="Tgl. Mulai"
                          type="date"
                          name="tgl_mulai_jo"
                          value={values.tgl_mulai_jo}
                          onChange={(e) => {
                            setFieldValue('tgl_mulai_jo', e.target.value);
                          }}
                          error={
                            errors.tgl_mulai_jo && touched.tgl_mulai_jo && true
                          }
                          errorText={
                            errors.tgl_mulai_jo &&
                            touched.tgl_mulai_jo &&
                            errors.tgl_mulai_jo
                          }
                        />
                      </Col>
                      <Col lg="3">
                        <Input
                          label="Tgl. Selesai"
                          type="date"
                          name="tgl_selesai_jo"
                          value={values.tgl_selesai_jo}
                          onChange={(e) => {
                            setFieldValue('tgl_selesai_jo', e.target.value);
                          }}
                          error={
                            errors.tgl_selesai_jo &&
                            touched.tgl_selesai_jo &&
                            true
                          }
                          errorText={
                            errors.tgl_selesai_jo &&
                            touched.tgl_selesai_jo &&
                            errors.tgl_selesai_jo
                          }
                        />
                      </Col>
                    </Row>
                    <HeadView
                      title="Kode Barang Jadi"
                      text={data.kode_barang_jadi ? data.kode_barang_jadi : '-'}
                    />
                    <HeadView
                      title="Nama Barang Jadi"
                      text={data.nama_barang_jadi ? data.nama_barang_jadi : '-'}
                    />
                    <HeadView
                      title="Qty."
                      text={
                        data.qty_rap
                          ? data.qty_rap + ` ${data.nama_satuan ?? ''}`
                          : '-'
                      }
                    />
                  </Card.Body>
                </Card>
                <div>
                  <h6 className="px-2 mt-5">
                    <b>Analisa Barang Jadi</b>
                  </h6>
                  <TabSection
                    qtyRap={data?.qty_rap}
                    dataHardwood={analisaHardwood}
                    dataPlywood={analisaPlyWood}
                    dataTPFS={analisaFs}
                    dataTPLC={analisaLc}
                    dataTPMP={analisaMp}
                    dataTPBOP={analisaBop}
                    dataFNBP={analisaBp}
                    dataFNSC={analisaSc}
                    dataFNLC={analisaFnLc}
                    dataUraianLabourCost={dataUrainLabourCost}
                    dataUraianLabourCostFinishing={
                      dataUraianLabourCostFinishing
                    }
                    dataUraianSubcontFinishing={dataUraianSubcontFinishing}
                  />
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      text="Simpan Job Order"
                      type="submit"
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <div>
          <DataStatus text="Data Tidak Ada" />
        </div>
      )}
      {modalSPKConfig.show && <ModalSPK />}
    </div>
  );
};

export default AddJobOrder;
