import { useContext } from 'react';
import {
  RealisasiProduksiContext,
  withRealisasiProduksiProvider,
} from '../../Contexts';
import { TableRealisasiSubkon } from './Table';

const TabRealisasiSubkon = () => {
  const { dataItem, dataRealisasi, dataHistory } = useContext(
    RealisasiProduksiContext
  );

  return (
    <div>
      <TableRealisasiSubkon
        dataSubkon={dataItem.subkon}
        dataRealisasiSubkon={dataRealisasi.subkon}
        dataHistorySubkon={dataHistory.subkon}
      />
    </div>
  );
};

export default TabRealisasiSubkon;
