import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableFS } from './TableFS';

export const ContainerFS = ({ showHistory }) => {
  const { dataFS } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableFS
        jobOrder
        showTotalPrice
        title="List Factory Supply Dari Job Order"
        data={dataFS.jobOrder}
      />
      <TableFS
        showInputRow
        showTotalPrice
        title="List Permintaan Factory Supply"
        data={dataFS.permintaan}
      />
      {showHistory && (
        <TableFS
          readOnly
          title="History Permintaan Factory Supply"
          data={dataFS.history}
        />
      )}
    </Row>
  );
};
