export const sortUrutanItem = (data) =>
  data?.sort((a, b) => {
    const urutanItemA = parseInt(a);
    const urutanItemB = parseInt(b);

    if (urutanItemA < urutanItemB) return -1;
    if (urutanItemA > urutanItemB) return 1;

    return 0;
  });
