import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { RupiahConvert } from 'utilities';
import { FinishingBarangJadiApi } from 'api';

const DetailFinshingBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dataDetail, setDataDetail] = useState({});
  const [tableBp, setTableBp] = useState([]);
  const [tableSc, setTableSc] = useState([]);
  const [tableLc, setTableLc] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const getData = () => {
    setIsLoading(true);

    FinishingBarangJadiApi.getDetail(id)
      .then((detail) => {
        setDataDetail(detail.data.data);

        setTableBp(detail.data.data.detail.bp);
        setTableSc(detail.data.data.detail.sc);
        setTableLc(detail.data.data.detail.lc);
      })

      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const ShowDataDo = ({ title, text, line }) => (
    <div className="mb-2">
      <small
        className="text-secondary"
        style={{ opacity: 0.8, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : '-'}
      </div>
      {line && <hr className="my-2" />}
    </div>
  );

  const TableDetail = ({ dataTable, kode }) => {
    const toCommas = (val) => {
      return val.toString().replace(/\./g, ',');
    };

    const SubTotal = () => {
      let total = 0;

      dataTable.forEach((val) => {
        const subTotal = parseInt(val.harga_satuan) * parseFloat(val.qty);
        total = total + subTotal;
      });

      return total;
    };

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <ThFixed>Qty</ThFixed>
              <ThFixed>Unit</ThFixed>
              <Th>Unit Price</Th>
              <ThFixed>Koefisien</ThFixed>
              <Th>Total</Th>
            </Tr>
          </THead>
          <TBody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => {
                let totalOrder =
                  parseFloat(val.harga_satuan) * parseFloat(val.qty);
                let sub_total = totalOrder * parseFloat(val.koefisien ?? 0);
                val.sub_total = parseInt(sub_total);
                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item_bahan}</TdFixed>
                    <Td>{val.nama_item}</Td>
                    <TdFixed>{toCommas(parseFloat(val.qty))}</TdFixed>
                    <Td>{val.nama_satuan}</Td>
                    <Td width={150} textRight>
                      {RupiahConvert(String(parseInt(val.harga_satuan))).detail}
                    </Td>
                    <TdFixed>
                      {toCommas(parseFloat(val.koefisien ?? 0))}
                    </TdFixed>
                    <Td width={150} textRight>
                      {RupiahConvert(String(val.sub_total)).detail}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan="8">
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
            {dataTable.length > 0 ? (
              <Tr>
                <Td textRight colSpan={7}>
                  <strong>{`Sub Total ${
                    kode === 'bp'
                      ? 'Bahan Penunjang'
                      : kode === 'sc'
                      ? 'Subcon'
                      : 'Labour Cost'
                  }`}</strong>
                </Td>
                <Td textRight>{RupiahConvert(SubTotal().toString()).detail}</Td>
              </Tr>
            ) : (
              <div />
            )}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  const getGrandTotal = () =>
    [...tableBp]
      .concat(tableLc, tableSc)
      .reduce(
        (sum, obj) =>
          sum +
          Math.round(
            parseFloat(obj.harga_satuan ?? 0) * parseFloat(obj.qty ?? 0)
          ),
        0
      );

  useEffect(() => {
    setNavbarTitle('Tipe Finishing');

    getData();
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Data Tipe Finishing</b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>

      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : (
          <>
            <Row>
              <Col md={6}>
                <ShowDataDo
                  title="Nama Tipe Finishing"
                  text={dataDetail.nama_finishing_barang_jadi}
                  line={true}
                />
              </Col>
              <Col md={6}>
                <ShowDataDo
                  title="Deskripsi"
                  text={dataDetail.deskripsi}
                  line={true}
                />
              </Col>
            </Row>
            <div style={{ fontSize: 14 }} className="mb-1 mt-3">
              <strong>List Item Bahan Penunjang</strong>
            </div>
            <TableDetail dataTable={tableBp} kode="bp" />

            <hr />
            <div style={{ fontSize: 14 }} className="mb-1 mt-3">
              <strong>List Item Subkon</strong>
            </div>
            <TableDetail dataTable={tableSc} kode="sc" />

            <hr />
            <div style={{ fontSize: 14 }} className="mb-1 mt-3">
              <strong>List Item Labour Cost</strong>
            </div>

            <TableDetail dataTable={tableLc} kode="lc" />

            <CRUDLayout.Table>
              <Th>
                <div className="text-right">Grand Total</div>
              </Th>
              <Th>
                <div className="text-right">
                  {RupiahConvert(String(getGrandTotal())).detail}
                </div>
              </Th>
            </CRUDLayout.Table>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailFinshingBarangJadi;
