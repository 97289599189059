import { createContext } from 'react';
import { useQuery } from 'react-query';
import { AnalisaBarangJadiApi, PermintaanProduksiApi, RAPApi } from 'api';
import { useQueryDropdown } from 'config/useQueryDropdown';

export const DropdownPermintaanProduksiContext = createContext();

export const DropdownPermintaanProduksiContextProvider = ({ children }) => {
  const dropdownDeskripsi = useQuery(
    ['dropdown_deskripsi', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownDeskripsi().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_deskripsi_bahan_baku,
            label: v.nama_deskripsi_bahan_baku,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownPartKayu = useQuery(
    ['dropdown_part_kayu', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownPart().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_part_kayu,
            label: v.nama_part_kayu,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTipeSisi = useQuery(
    ['dropdown_tipe_sisi', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeSisi().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_tipe_sisi,
            label: v.nama_tipe_sisi,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTipeFinishing = useQuery(
    ['dropdown_tipe_finishing', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeFinishing().then((res) => {
        const tanpaFinishing = [
          {
            id_finishing_barang_jadi: '0',
            nama_finishing_barang_jadi: 'Tanpa Finishing',
          },
        ];

        return tanpaFinishing.concat(res.data.data ?? []).map((v) => ({
          ...v,
          value: v.id_finishing_barang_jadi,
          label: v.nama_finishing_barang_jadi,
        }));
      }),
    useQueryDropdown
  );

  const dropdownSpekKayu = useQuery(
    ['dropdown_spek_kayu', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownSpekKayu().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_jenis_kayu,
            label: v.nama_jenis_kayu,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownSpekPlywood = useQuery(
    ['dropdown_spek_plywood', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownSpekPlywood().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: v.nama_item,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTipeProduksi = useQuery(
    ['dropdown_tipe_produksi', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({
        tipe: 'tipe_prd',
      }).then((res) => res.data.data ?? []),
    useQueryDropdown
  );

  const dropdownBentuk = useQuery(
    ['dropdown_bentuk', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: 'bentuk' }).then(
        (res) => res.data.data ?? []
      ),
    useQueryDropdown
  );

  const dropdownUkuran = useQuery(
    ['dropdown_ukuran', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: 'ukuran' }).then(
        (res) => res.data.data ?? []
      ),
    useQueryDropdown
  );

  const dropdownSatuan = useQuery(
    ['dropdown_unit', 'permintaan_produksi'],
    () =>
      RAPApi.getUnit().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_satuan,
            label: v.nama_satuan,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTPFS = useQuery(
    ['dropdown_tpfs', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'fs' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: `${v.kode_item_bahan} | ${v.nama_item}`,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTPLC = useQuery(
    ['dropdown_tplc', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'lc' }).then(
        (res) => res.data.data ?? []
      ),
    useQueryDropdown
  );

  const dropdownTPMP = useQuery(
    ['dropdown_tpmp', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'mp' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: `${v.kode_item_bahan} | ${v.nama_item}`,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownTPBOP = useQuery(
    ['dropdown_tpbop', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'bop' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_item_buaso,
            label: `${v.kode_item_bahan} | ${v.nama_item}`,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownFNBP = useQuery(
    ['dropdown_fnbp', 'permintaan_produksi'],
    () =>
      AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'bp' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: `${v.kode_item_bahan} | ${v.nama_item}`,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownProyek = useQuery(
    ['dropdown_proyek', 'permintaan_produksi'],
    () =>
      PermintaanProduksiApi.getDropdownFilter({ tipe: 'proyek' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_proyek,
            label: v.nama_proyek,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownCustomer = useQuery(
    ['dropdown_customer', 'permintaan_produksi'],
    () =>
      PermintaanProduksiApi.getDropdownFilter({ tipe: 'customer' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_customer,
            label: v.nama_customer,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownJobOrder = useQuery(
    ['dropdown_job_order', 'permintaan_produksi'],
    () =>
      PermintaanProduksiApi.getDropdownJobOrder().then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_item_buaso,
            label: v.nama_item ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownApproval = [
    { value: 'PEN', label: 'PENDING' },
    { value: 'APP', label: 'APPROVE' },
    { value: 'REV', label: 'REVISE' },
    { value: 'REJ', label: 'REJECT' },
    { value: 'VER', label: 'VERIFIED' },
  ];

  return (
    <DropdownPermintaanProduksiContext.Provider
      value={{
        dropdownDeskripsi,
        dropdownPartKayu,
        dropdownTipeSisi,
        dropdownTipeFinishing,
        dropdownSpekKayu,
        dropdownSpekPlywood,
        dropdownTipeProduksi,
        dropdownBentuk,
        dropdownUkuran,
        dropdownSatuan,
        dropdownTPFS,
        dropdownTPLC,
        dropdownTPMP,
        dropdownTPBOP,
        dropdownFNBP,
        dropdownProyek,
        dropdownCustomer,
        dropdownJobOrder,
        dropdownApproval,
      }}
    >
      {children}
    </DropdownPermintaanProduksiContext.Provider>
  );
};

export const withDropdownPermintaanProduksiContextProvider =
  (Component) => (props) => {
    return (
      <DropdownPermintaanProduksiContextProvider>
        <Component {...props} />
      </DropdownPermintaanProduksiContextProvider>
    );
  };
