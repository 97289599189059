import React, { useState } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

const TabInfoPeluang = ({ dataInfoPeluang, dataTablePeluang }) => {
  const InfoItemModal = ({ label, text, className, style, width }) => (
    <div className={`d-flex align-items-top ${className}`} style={style}>
      <div style={{ width: width ? width : 150, fontSize: 14 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14 }}>{text}</div>
    </div>
  );
  const ShowData = ({ title, text, line }) => (
    <div>
      <small
        className="text-secondary"
        style={{ opacity: 0.9, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div className="text-dark">
        <div style={{ fontSize: 14 }} className="text-break">
          {text ? text : '-'}
        </div>
      </div>

      {line && <hr className="my-2" />}
    </div>
  );

  return (
    <div>
      <Row>
        <Col md>
          <InfoItemModal
            label="Tgl. Peluang"
            text={
              dataInfoPeluang.tgl_peluang
                ? DateConvert(new Date(dataInfoPeluang.tgl_peluang)).detail
                : '-'
            }
          />
          <InfoItemModal
            label="No. Peluang"
            text={dataInfoPeluang.no_peluang ?? '-'}
          />
          <InfoItemModal
            label="Target Penawaran"
            text={
              dataInfoPeluang.tgl_selesai
                ? DateConvert(new Date(dataInfoPeluang.tgl_peluang)).detail
                : '-'
            }
          />
        </Col>
        <Col md>
          <InfoItemModal
            label="Peringkat Peluang"
            text={dataInfoPeluang.nama_peringkat_peluang ?? '-'}
          />
          <InfoItemModal label="ATT" text={dataInfoPeluang.att ?? '-'} />
          <InfoItemModal
            label="Realisasi Penawaran"
            text={
              dataInfoPeluang.realisasi_penawaran
                ? DateConvert(new Date(dataInfoPeluang.realisasi_penawaran))
                    .detail
                : '-'
            }
          />
        </Col>
      </Row>
      <hr />
      <ShowData
        title="Customer"
        text={dataInfoPeluang.nama_customer ?? '-'}
        line={true}
      />
      <ShowData
        title="Proyek"
        text={dataInfoPeluang.nama_proyek ?? '-'}
        line={true}
      />
      <ShowData
        title="Schedule Kebutuhan Proyek"
        text={dataInfoPeluang.schedule_kebutuhan_proyek ?? '-'}
        line={true}
      />
      <ShowData
        title="Link Referensi"
        text={dataInfoPeluang.link_referensi ?? '-'}
        line={true}
      />

      <div className="my-2">
        <b>Barang Jadi</b>
      </div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th style={{ maxWidth: 80 }} className="py-2">
              Kode Barang Jadi
            </Th>
            <Th style={{ minWidth: 200 }}>Nama Barang Jadi</Th>
            <Th>Jenis Barang Jadi</Th>
            <Th>Satuan</Th>
            <Th>Qty</Th>
          </tr>
        </thead>
        <tbody>
          {dataTablePeluang && dataTablePeluang.length > 0 ? (
            dataTablePeluang.map((val, index) => (
              <tr>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.nama_jenis}</Td>
                <Td>{val.nama_satuan_jual}</Td>
                <Td textRight>{val.qty}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={6}>
                <DataStatus text="Tidak data data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TabInfoPeluang;
