import { Fragment, useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
  ReadButton,
  ApprovalStatusButton,
  FilterButton,
  UpdateButton,
} from 'components';
import { useSessionStorage } from 'hooks';
import { DateConvert, TableNumber } from 'utilities';
import { PermintaanProduksiApi } from 'api';
import {
  ItemPermintaanCollapse,
  ModalFilterPermintaanProduksi,
} from './Component';
import { withDropdownPermintaanProduksiContextProvider } from './Context';

const PermintaanProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPermintaanProduksi, setDataPermintaanProduksi] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_permintaan_produksi_start: undefined,
    tgl_permintaan_produksi_end: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    id_barang_jadi: undefined,
    status_approval: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanProduksiApi.get({
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_permintaan_produksi_start: pagination.tgl_permintaan_produksi_start,
      tgl_permintaan_produksi_end: pagination.tgl_permintaan_produksi_end,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      id_barang_jadi: pagination.id_barang_jadi,
      status_approval: pagination.status_approval,
    })
      .then(({ data }) => {
        setDataPermintaanProduksi(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch(() =>
        setAlert({
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert({
        text: locationState.alert.text,
        variant: locationState.alert.variant,
      });
    }
  };

  const dismissAlert = () => {
    setAlert({ text: '', variant: 'primary' });
    history.replace(location?.pathname, { alert: undefined });
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Produksi');
    getInitialData();
    checkAlert();

    return () => setIsPageLoading(false);
  }, [
    pagination.page,
    pagination.per_page,
    pagination.q,
    pagination.tgl_permintaan_produksi_start,
    pagination.tgl_permintaan_produksi_end,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.id_barang_jadi,
    pagination.status_approval,
  ]);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-1">
            <Col className="d-flex flex-row justify-content-start align-items-center">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(
                  (e) =>
                    setPagination((prv) => ({
                      ...prv,
                      q: e.target.value,
                      page: 1,
                    })),
                  1500
                )}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/transaksi/permintaan-produksi/list-job-order')
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={!!alert.text.length}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={dismissAlert}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPermintaanProduksi?.length ? (
        <TableContent
          data={dataPermintaanProduksi}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterPermintaanProduksi
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

const TableContent = ({ data = [], pagination, setPagination, history }) => {
  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        List Data Permintaan Produksi
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th>Item Job Order</Th>
            <Th>Item Permintaan Produksi</Th>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.map((val, index) => (
            <Tr key={val.id_permintaan_produksi}>
              <TdFixed className="text-center">
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>

              <Td>
                <ButtonGroup>
                  <ReadButton
                    size="sm"
                    className="m-0"
                    onClick={() =>
                      history.push(
                        `/transaksi/permintaan-produksi/detail/${val.id_permintaan_produksi}`
                      )
                    }
                  />

                  {val.status_approval === 'REV' && (
                    <UpdateButton
                      size="sm"
                      className="m-0"
                      onClick={() =>
                        history.push(
                          `/transaksi/permintaan-produksi/ubah/${val.id_permintaan_produksi}`
                        )
                      }
                    />
                  )}
                </ButtonGroup>
              </Td>

              <Td>
                <div>
                  {val.tgl_permintaan_produksi
                    ? DateConvert(new Date(val.tgl_permintaan_produksi))
                        .defaultDMY
                    : ''}
                </div>
                <div>{val.no_permintaan_produksi} </div>
              </Td>

              <Td>
                <div>
                  {val.tgl_job_order
                    ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_job_order}</div>
              </Td>

              <Td>
                {val.nama_barang_jadi}
                {val.qty_rap ? parseFloat(val.qty_rap) : ''} {val.nama_satuan}
              </Td>

              <Td>
                <ItemPermintaanCollapse data={val.detail ?? []} />
              </Td>

              <Td>
                <ApprovalStatusButton
                  status={val.status_approval}
                  defaultStatus="PEN"
                />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>

      {data?.length ? (
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      ) : null}
    </Fragment>
  );
};

export default withDropdownPermintaanProduksiContextProvider(
  PermintaanProduksiList
);
