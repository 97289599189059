import { memo, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { DateConvert } from 'utilities';
import { RealisasiProduksiApi } from 'api';
import {
  Input,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
} from 'components';

export const InfoSection = memo(({ readOnly = false, dataInfo }) => {
  const { values, setValues, errors, touched, setFieldValue } =
    useFormikContext();

  const {
    tgl_job_order,
    no_job_order,
    nama_customer,
    kode_barang_jadi,
    nama_barang_jadi,
    qty_rap,
    qty_realisasi_sum,
    nama_proyek,
    tgl_realisasi_produksi,
    no_realisasi_produksi,
  } = dataInfo;

  const onTanggalChangeHandler = (date) => {
    RealisasiProduksiApi.getNomor({ tanggal: date })
      .then((data) => {
        const no = data?.data?.data;
        setValues((prev) => ({
          ...prev,
          tgl_realisasi_produksi: date,
          no_realisasi_produksi: no,
        }));
      })
      .catch(() => {
        alert('Tanggal gagal dimuat');
        setValues({
          tgl_realisasi_produksi: '',
          no_realisasi_produksi: '',
        });
      });
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 10);

    return convert;
  };

  useEffect(() => {
    onTanggalChangeHandler(DateConvert(new Date()).default);
  }, []);

  return (
    <>
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="Tgl. Job Order"
            text={DateConvert(new Date(tgl_job_order)).detail}
          />
          <InfoItemHorizontal label="No. Job Order" text={no_job_order} />
          <InfoItemHorizontal label="Proyek" text={nama_proyek} />
          <InfoItemHorizontal label="Customer" text={nama_customer} />
        </Col>
        <Col md>
          <InfoItemHorizontal
            label="Kode Barang Jadi"
            text={kode_barang_jadi}
          />
          <InfoItemHorizontal
            label="Item Barang Jadi"
            text={nama_barang_jadi}
          />
          <InfoItemHorizontal
            label="Qty. Job Order"
            text={parseFloat(qty_rap ?? 0)}
          />
          <InfoItemHorizontal
            label="Qty. yang Terealisasi"
            text={parseFloat(qty_realisasi_sum ?? 0)}
          />
        </Col>
      </Row>

      <hr />

      {readOnly ? (
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Realisasi"
              text={
                tgl_realisasi_produksi
                  ? DateConvert(new Date(tgl_realisasi_produksi)).detail
                  : ''
              }
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Realisasi Produksi"
              text={no_realisasi_produksi ?? ''}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <DatePicker
              label="Tgl. Realisasi Produksi"
              placeholderText="Masukan tgl realisasi produksi"
              selected={
                values?.tgl_realisasi_produksi
                  ? new Date(values.tgl_realisasi_produksi)
                  : ''
              }
              onChange={(date) => {
                const newDate = DateConvert(new Date(date)).default;
                onTanggalChangeHandler(newDate);
              }}
              error={
                !!(
                  errors.tgl_realisasi_produksi &&
                  touched.tgl_realisasi_produksi
                )
              }
              errorText={
                !!(
                  errors.tgl_realisasi_produksi &&
                  touched.tgl_realisasi_produksi
                ) && errors.tgl_realisasi_produksi
              }
            />
          </Col>

          <Col>
            <Input
              readOnly
              label="No. Realisasi Produksi"
              placeholder="Pilih tgl untuk menentukan nomor"
              value={values.no_realisasi_produksi}
              error={
                !!(
                  errors.tgl_realisasi_produksi &&
                  touched.tgl_realisasi_produksi
                )
              }
              errorText={
                !!(
                  errors.tgl_realisasi_produksi &&
                  touched.tgl_realisasi_produksi
                ) && errors.tgl_realisasi_produksi
              }
            />
          </Col>

          <Col>
            <Input
              readOnly={Boolean(qty_realisasi_sum >= qty_rap)}
              label="Qty. Barang Jadi Yang Telah Selesai Diproduksi*"
              placeholder="Masukkan Qty. barang jadi yang selesai diproduksi"
              value={values.qty_realisasi}
              onChange={(e) => {
                const value = decimalConvert(e.target.value);
                setFieldValue('qty_realisasi', value);
              }}
              error={!!(errors.qty_realisasi && touched.qty_realisasi)}
              errorText={
                !!(errors.qty_realisasi && touched.qty_realisasi) &&
                errors.qty_realisasi
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
});
