import { DateConvert } from 'utilities';

export const mapSubmitValues = (data) => {
  console.log('receive', data);

  const finalValues = {
    id_permintaan_produksi: data.id_permintaan_produksi,
    id_job_order: data.id_job_order,
    tgl_permintaan_produksi: DateConvert(data.tgl_permintaan_produksi).default,
  };

  finalValues.hardwood = data?.dataHardwood?.permintaan?.map((hw) => ({
    id_jenis_kayu: hw.id_jenis_kayu,
    id_finishing_barang_jadi: hw.id_finishing_barang_jadi ?? '0',
    id_tipe_sisi: hw.id_tipe_sisi,
    id_part_kayu: hw.id_part_kayu,
    id_deskripsi: hw.id_deskripsi,
    deskripsi: hw.deskripsi,
    qty_raw: hw.qty_raw,
    t_raw: hw.t_raw,
    w_raw: hw.w_raw,
    l_raw: hw.l_raw,
    qty_final: hw.qty_final,
    t_final: hw.t_final,
    w_final: hw.w_final,
    l_final: hw.l_final,
    unit_price: hw.unit_price,
    konstanta: hw.konstanta,
  }));

  finalValues.plywood = data?.dataPlywood?.permintaan?.map((pw) => ({
    id_item_buaso: pw.id_item_buaso,
    id_finishing_barang_jadi: pw.id_finishing_barang_jadi ?? '0',
    id_tipe_sisi: pw.id_tipe_sisi,
    id_deskripsi: pw.id_deskripsi,
    deskripsi: pw.deskripsi,
    qty_raw: pw.qty_raw,
    t_raw: pw.t_raw,
    w_raw: pw.w_raw,
    l_raw: pw.l_raw,
    qty_final: pw.qty_final,
    t_final: pw.t_final,
    w_final: pw.w_final,
    l_final: pw.l_final,
    unit_price: pw.unit_price,
    konstanta: pw.konstanta,
  }));

  finalValues.factory_supply = data?.dataFS?.permintaan?.map((fs) => ({
    id_item_buaso: fs.id_item_buaso,
    qty: fs.qty,
    unit_price: fs.unit_price,
    konstanta: fs.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.bahan_penunjang = data?.dataBPF?.permintaan?.map((bpf) => ({
    id_item_buaso: bpf.id_item_buaso,
    qty: bpf.qty,
    unit_price: bpf.unit_price,
    konstanta: bpf.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.labour_cost = data?.dataLC?.permintaan?.map((lc) => ({
    uraian_pekerjaan: lc.uraian,
    qty: lc.qty,
    id_satuan: lc.id_satuan,
    unit_price: lc.unit_price,
    konstanta: lc.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.labour_cost_finishing = data?.dataLCF?.permintaan?.map((lcf) => ({
    uraian_pekerjaan: lcf.uraian,
    qty: lcf.qty,
    id_satuan: lcf.id_satuan,
    unit_price: lcf.unit_price,
    konstanta: lcf.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.machine_process = data?.dataMP?.permintaan?.map((mp) => ({
    id_item_aset: mp.id_item_buaso,
    qty: mp.qty,
    unit_price: mp.unit_price,
    konstanta: mp.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.subcon_finishing = data?.dataSF?.permintaan?.map((sf) => ({
    uraian_pekerjaan: sf.uraian,
    qty: sf.qty,
    id_satuan: sf.id_satuan,
    unit_price: sf.unit_price,
    konstanta: sf.konstanta,
    // status_permintaan: 'waiting',
  }));

  finalValues.biaya_overhead = data?.dataBO?.permintaan?.map((bo) => ({
    id_item_buaso: bo.id_item_buaso,
    qty: bo.qty,
    unit_price: bo.unit_price,
    konstanta: bo.konstanta,
    // status_permintaan: 'waiting',
  }));

  return finalValues;
};
