import React from 'react';
import { Table } from 'react-bootstrap';
import { RupiahConvert } from 'utilities';
import htmlParser from 'html-react-parser';

const TableSPK = ({ dataSPK, dataListSPK }) => {
  console.log({ dataListSPK });

  // Menangani perhitungan grand total prelim
  const getGrandTotalPrelim = dataSPK?.prelim?.reduce((prev, current) => {
    const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
    const newHarga = current.harga ? parseFloat(current.harga) : 0;
    const newQtyDurasi = current.qty_durasi
      ? parseFloat(current.qty_durasi)
      : 0;
    const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
    const subTotal = newQtyItem * newHarga;
    const total = subTotal;

    return prev + total;
  }, 0);

  // Menangani perhitungan grand total tabel
  const getGrandTotal = () => {
    const grandTotal = dataListSPK.reduce((prev, current) => {
      const count =
        parseInt(current.rounded || 0) * parseFloat(current.qty_rab || 0);
      const total = prev + count;

      return total;
    }, 0);

    return grandTotal + getGrandTotalPrelim;
  };

  const getGrandTotalAfterDiskon = () => {
    const total = getGrandTotal();
    const diskon = dataSPK.diskon ?? 0;
    const countDiskon = (diskon * total) / 100;
    const afterDiskon = parseInt(total) - parseInt(countDiskon);

    return { after_diskon: afterDiskon, total_diskon: countDiskon };
  };

  // Menangani perhitungan grand total setelah ppn
  const getGrandTotalAfterPPN = () => {
    const total = getGrandTotalAfterDiskon().after_diskon;
    const ppn = dataSPK.ppn ?? 0;
    const countPPN = (ppn * total) / 100;
    const afterPPN = parseInt(total) + parseInt(countPPN);

    return { after_ppn: afterPPN, total_ppn: countPPN };
  };

  // Menangani perhitungan grand total setelah ppn
  const getGrandTotalAfterDP = () => {
    const total = getGrandTotalAfterPPN().after_ppn;
    const dp = dataSPK.uang_muka ?? 0;
    const countDP = (dp * total) / 100;
    const afterDP = parseInt(total) - parseInt(countDP);

    return { after_dp: afterDP, total_dp: countDP };
  };

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        padding: 4,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  // Menampilkan data pada tabel
  const TableContent = ({ index, val }) => {
    // Menangani perhitungan total
    const getTotal = () => {
      const qty = parseFloat(val.qty_rab || 0);
      const price = parseInt(val.rounded || 0);
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr>
        <Td className="text-center">{index + 1}</Td>
        <Td className="px-1">{val.kode_item}</Td>
        <Td className="px-1">{val.nama_item}</Td>
        <Td className="px-1 text-right">{parseFloat(val.qty_rab || 0)}</Td>
        <Td className="px-1">{val.nama_satuan}</Td>
        <Td className="px-1">{htmlParser(val.uraian ?? '<p></p>')}</Td>
        <Td style={{ width: '160px' }} className="px-1 text-right">
          {val.rounded
            ? RupiahConvert(parseInt(val.rounded).toString()).detail
            : ''}
        </Td>
        <Td style={{ width: '160px' }} className="px-1 text-right">
          {getTotal()}
        </Td>
      </tr>
    );
  };

  // Menampilkan status ketika tidak ada data
  if (!dataListSPK || dataListSPK.length < 1) {
    return (
      <>
        <div className="px-1 py-2">
          <b>List Item SPK</b>
        </div>
        <Table bordered>
          <thead className="bg-light">
            <tr>
              <Th rowSpan={2} className="text-center" style={{ width: 30 }}>
                No
              </Th>
              <Th rowSpan={2} className="text-center" style={{ width: 80 }}>
                Kode Item Barang Jadi
              </Th>
              <Th rowSpan={2} className="text-center">
                Nama Item Barang Jadi
              </Th>
              <Th colSpan={2} className="text-center">
                Volume
              </Th>
              <Th rowSpan={2} className="text-center">
                Uraian
              </Th>
              <Th rowSpan={2} className="text-center">
                Harga Rate
              </Th>
              <Th rowSpan={2} className="text-center">
                Sub Total Rate
              </Th>
            </tr>
            <tr>
              <Th className="text-center">Qty</Th>
              <Th className="text-center">Unit</Th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-light">
              <td colSpan={8}>
                <div className="py-3 text-center">
                  <b>Tidak ada data</b>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  }

  // Menampilkan keseluruhan data & fitur tabel
  return (
    <>
      <div className="px-1 py-2">
        <b>List Item SPK</b>
      </div>
      <Table bordered>
        <thead className="bg-light">
          <tr>
            <Th rowSpan={2} className="text-center" style={{ width: 30 }}>
              No
            </Th>
            <Th rowSpan={2} className="text-center" style={{ width: 80 }}>
              Kode Item Barang Jadi
            </Th>
            <Th rowSpan={2} className="text-center">
              Nama Item Barang Jadi
            </Th>
            <Th colSpan={2} className="text-center">
              Volume
            </Th>
            <Th rowSpan={2} className="text-center">
              Uraian
            </Th>
            <Th rowSpan={2} className="text-center">
              Harga Rate
            </Th>
            <Th rowSpan={2} className="text-center">
              Sub Total Rate
            </Th>
          </tr>
          <tr>
            <Th className="text-center">Qty</Th>
            <Th className="text-center">Unit</Th>
          </tr>
        </thead>

        <tbody>
          {dataListSPK.map((val, index) => {
            // Tampilkan data kedalam tabel
            return <TableContent key={index} index={index} val={val} />;
          })}

          {/* Row Prelim */}
          <tr>
            <Td className="text-center">
              {dataListSPK.length > 0 ? dataListSPK.length + 1 : 1}
            </Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">PRELIM</Td>
            <Td className="px-1 text-right">1</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
            </Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
            </Td>
          </tr>
        </tbody>

        <tfoot>
          <tr className="bg-light">
            <Th colSpan={7} className="p-1 pr-2 text-right">
              Total
            </Th>
            <Th className="text-right">
              {RupiahConvert(parseInt(getGrandTotal()).toString()).detail}
            </Th>
          </tr>

          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              Diskon ({parseFloat(dataSPK.diskon).toPrecision()} %)
            </Th>
            <Th className="text-right" style={{ padding: 2 }}>
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDiskon().total_diskon).toString()
                ).detail
              }
            </Th>
          </tr>

          <tr className="bg-light">
            <Th colSpan={7} className="p-1 pr-2 text-right">
              Total Setelah Diskon
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDiskon().after_diskon).toString()
                ).detail
              }
            </Th>
          </tr>

          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              PPN ({parseFloat(dataSPK.ppn).toPrecision()} %)
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterPPN().total_ppn).toString()
                ).detail
              }
            </Th>
          </tr>

          <tr className="bg-light">
            <Th colSpan={7} className="p-1 pr-2 text-right">
              Total Setelah Pajak
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterPPN().after_ppn).toString()
                ).detail
              }
            </Th>
          </tr>

          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              Uang Muka ({parseFloat(dataSPK.uang_muka).toPrecision()} %)
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDP().total_dp).toString()
                ).detail
              }
            </Th>
          </tr>

          <tr className="bg-light">
            <Th colSpan={7} className="p-1 pr-2 text-right">
              Sisa
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDP().after_dp).toString()
                ).detail
              }
            </Th>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default TableSPK;
