import HTMLReactParser from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { ButtonGroup, Modal } from 'react-bootstrap';
import {
  IoAddOutline,
  IoEyeOutline,
  IoPencilOutline,
  IoReaderOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import { RealisasiProduksiApi } from 'api';
import { ActionButton, Alert, DataStatus, Table } from 'components';
import { Formik, useFormikContext } from 'formik';
import { useIsGuest } from 'hooks';
import { DateConvert, RupiahConvert } from 'utilities';
import { ModalOpname, TableDetailOpname } from '../../../Section';
import { calculationHandler } from '../../../Utils';
import { TableItemKontrak } from '../Table';
import { RealisasiProduksiContext } from '../../../Contexts';

const TableLabourCostFinishing = ({
  dataLabourCostFinishing,
  dataRealisasiLabourCostFinishing,
  dataHistoryLabourCostFinishing,
}) => {
  const rootFormik = useFormikContext();
  const isGuest = useIsGuest();
  const isReadOnly = !!rootFormik?.values?.isReadOnly;
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'create', // create, update atau delete
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={`${props.className} p-1`}
      style={{
        ...props.style,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  const ModalRealisasi = () => {
    const { setDataRealisasi } = useContext(RealisasiProduksiContext);
    const ModalForm = () => {
      const [dataDetailOpnameUpah, setDataDetailOpnameUpah] = useState([]);
      const [dataOpnameUpah, setDataOpnameUpah] = useState({});
      const [fetchStatus, setFetchStatus] = useState({
        loading: true,
        success: false,
      });
      const formInitialValues = {
        id_kontrak_upah: processedData?.id_kontrak_upah ?? '',
        tgl_realisasi: rootFormik?.values?.tgl_realisasi_produksi ?? '',
        ref: 'RLC',
        nama_vendor: processedData?.nama_vendor ?? '',
        nama_proyek: rootFormik?.values?.nama_proyek ?? '',
        nama_barang_jadi: rootFormik?.values?.nama_barang_jadi ?? '',
      };

      const formSubmitHandler = (values) => {
        const realisasiDataProduksi = () => {
          setDataRealisasi((prev) => ({
            ...prev,
            lcf: [...prev.lcf, { ...values, rincian: dataDetailOpnameUpah }],
          }));

          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil direalisasi!',
          });

          setModalConfig({
            ...modalConfig,
            show: false,
          });

          setProcessedData({});
        };

        const updateDataProduksi = () => {
          setDataRealisasi((prev) => ({
            ...prev,
            lcf: prev.lcf.toSpliced(processedData.index, 1, {
              ...values,
              rincian: dataDetailOpnameUpah,
            }),
          }));

          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil diubah!',
          });

          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setProcessedData({});
        };

        modalConfig.type === 'create'
          ? realisasiDataProduksi()
          : updateDataProduksi();
      };

      const getDetailOpname = () => {
        if (modalConfig.type === 'create' || modalConfig.type === 'detail') {
          RealisasiProduksiApi.getOpnameUpah({
            id_kontrak_upah: processedData?.id_kontrak_upah,
            id_realisasi_produksi_upah:
              modalConfig.type === 'detail'
                ? processedData?.id_realisasi_produksi_upah
                : undefined,
          })
            .then((data) => {
              const detail = data?.data?.data?.detail
                ? data?.data?.data?.detail.map((val) => ({
                    ...val,
                    persentase_periode_ini:
                      modalConfig.type === 'detail'
                        ? val.persentase_periode_ini
                        : 0,
                    nominal_periode_ini:
                      modalConfig.type === 'detail'
                        ? val.nominal_periode_ini
                        : 0,
                  }))
                : [];
              setDataDetailOpnameUpah(detail);
              setDataOpnameUpah(data?.data?.data);
              setFetchStatus({
                loading: false,
                success: true,
              });
            })
            .catch(() => {
              setFetchStatus({
                loading: false,
                success: true,
              });
            });
        } else {
          setDataDetailOpnameUpah(processedData.rincian);
          setFetchStatus({
            loading: false,
            success: true,
          });
        }
      };

      useEffect(() => {
        getDetailOpname();
      }, []);

      return (
        <Formik
          initialValues={formInitialValues}
          // validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => {
            return (
              <ModalOpname
                formik={formik}
                fetchStatus={fetchStatus}
                modalConfig={modalConfig}
                data={dataDetailOpnameUpah}
                setData={setDataDetailOpnameUpah}
                dataInfo={dataOpnameUpah}
              />
            );
          }}
        </Formik>
      );
    };

    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);

        setDataRealisasi((prev) => ({
          ...prev,
          lcf: prev.lcf.toSpliced(processedData.index, 1),
        }));

        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });

        setIsDeleting(false);

        setProcessedData({});

        setModalConfig({
          ...modalConfig,
          show: false,
        });
      };

      return (
        <Modal.Body className="text-center">
          <p>
            Hapus data dengan rincian Pekerjaan: <br />
            <div className="d-flex justify-content-center">
              <b className="text-left">
                {processedData?.rincian
                  ? processedData.rincian.map((val) => (
                      <span className="d-block"> - {val.uraian}</span>
                    ))
                  : '-'}
              </b>{' '}
              <br />
            </div>
            <small className="text-danger">
              Data yang dihapus tidak dapat dikembalikan!
            </small>
          </p>
          <div>
            <ActionButton
              variant="outline-dark"
              text="Batal"
              className="m-1"
              onClick={() => {
                setModalConfig({
                  ...modalConfig,
                  show: false,
                });
                setProcessedData({});
              }}
            />
            <ActionButton
              variant="danger"
              text="Hapus Data"
              className="m-1"
              onClick={deleteDataHandler}
              loading={isDeleting}
            />
          </div>
        </Modal.Body>
      );
    };

    const ModalConfirm = () => {
      const [dataKontrak, setDataKontrak] = useState({});
      const [fetchStatus, setFetchStatus] = useState({
        loading: true,
        success: false,
      });

      const getDetailOpname = () => {
        RealisasiProduksiApi.getOpnameUpah({
          id_kontrak_upah: processedData?.id_kontrak_upah,
        })
          .then((data) => {
            const detail = data?.data?.data;
            setDataKontrak(detail);
            setFetchStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchStatus({
              loading: false,
              success: true,
            });
          });
      };

      useEffect(() => {
        getDetailOpname();
      }, []);

      if (fetchStatus.loading || !fetchStatus.success) {
        return (
          <Modal.Body>
            <DataStatus
              loading={fetchStatus.loading}
              text={
                fetchStatus.loading ? 'Memuat data . . .' : 'Data gagal dimuat!'
              }
            />
          </Modal.Body>
        );
      }

      return (
        <Modal.Body>
          <div className="border p-3 mb-3">
            {HTMLReactParser(dataKontrak.teks_kontrak_part_1)}
          </div>
          <div>
            <TableItemKontrak
              readOnly
              dataInfo={dataKontrak}
              dataItem={
                dataKontrak?.detail
                  ? dataKontrak.detail.map((val) => ({
                      uraian: val?.uraian ?? '',
                      qty: parseFloat(val?.qty ?? 0),
                      kode_satuan: val?.kode_satuan ?? '',
                      unit_price: parseFloat(val?.unit_price ?? 0),
                      konstanta: parseFloat(val?.konstanta ?? 0),
                    }))
                  : []
              }
            />
          </div>
          <div className="border p-3 mb-3">
            {HTMLReactParser(dataKontrak.teks_kontrak_part_2)}
          </div>
          <div className="border p-3 mb-3">
            {HTMLReactParser(dataKontrak.teks_kontrak_part_3)}
          </div>
        </Modal.Body>
      );
    };

    return (
      <Modal
        show={modalConfig.show}
        size={modalConfig.type === 'delete' ? 'md' : 'xl'}
        onHide={() => {
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setProcessedData({});
        }}
      >
        <Modal.Header closeButton>
          <b>
            {modalConfig.type === 'detail'
              ? 'Detail Labour Cost Finishing '
              : modalConfig.type === 'create'
              ? 'Tambah Labour Cost Finishing '
              : modalConfig.type === 'update'
              ? 'Ubah Labour Cost Finishing '
              : modalConfig.type === 'kontrak'
              ? 'Kontrak Labour Cost Finishing '
              : modalConfig.type === 'delete' && 'Hapus Labour Cost Finishing '}
          </b>
        </Modal.Header>
        {modalConfig.type === 'delete' ? (
          <ModalDelete />
        ) : modalConfig.type === 'kontrak' ? (
          <ModalConfirm />
        ) : (
          <ModalForm />
        )}
      </Modal>
    );
  };

  if (isReadOnly) {
    return (
      <TableDetailOpname
        title="Labour Cost Finishing"
        type="upah"
        dataRealisasi={dataRealisasiLabourCostFinishing}
      />
    );
  }

  return (
    <div>
      {/* Alert */}
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Table List Item Labour Cost Finishing  */}
      <div className="pb-2">
        <b className="p-1">Kontrak Labour Cost Finishing</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th className="py-2 text-center" style={{ width: 30 }}>
              No.
            </Th>
            <Th className="py-2 text-center" style={{ width: 100 }}>
              Tgl. Kontrak
            </Th>
            <Th className="py-2 text-center" style={{ width: 100 }}>
              No. Kontrak
            </Th>
            <Th className="p-2 text-center" style={{ minWidth: 200 }}>
              Vendor
            </Th>
            <Th className="p-2 text-center" style={{ minWidth: 200 }}>
              Rincian Pekerjaan
            </Th>
            {!isGuest && (
              <Th className="p-0 text-center" style={{ width: 40 }}>
                Aksi
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {dataLabourCostFinishing.length > 0 ? (
            dataLabourCostFinishing.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  {val.tgl_kontrak_upah
                    ? DateConvert(new Date(val.tgl_kontrak_upah)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_kontrak_upah}</Td>
                <Td>{val.nama_vendor}</Td>
                <Td>
                  {val?.rincian?.map((val, i) => (
                    <span className="d-block" key={i}>
                      - {val?.uraian ?? ''}
                    </span>
                  ))}
                </Td>
                <Td>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      variant="warning"
                      className="text-white"
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({
                          show: true,
                          type: 'kontrak',
                        });
                      }}
                    >
                      <IoReaderOutline />
                    </ActionButton>
                    {!isGuest && (
                      <ActionButton
                        size="sm"
                        onClick={() => {
                          setProcessedData(val);
                          setModalConfig({
                            show: true,
                            type: 'create',
                          });
                        }}
                        disable={dataRealisasiLabourCostFinishing.find(
                          (fil) => fil.id_kontrak_upah === val.id_kontrak_upah
                        )}
                      >
                        <IoAddOutline />
                      </ActionButton>
                    )}
                  </ButtonGroup>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Table List Item Opname Labour Cost Finishing  */}
      <>
        <div className="pb-2">
          <b className="p-1">Opname Labour Cost Finishing </b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <Th className="py-2 text-center" style={{ width: 30 }}>
                No.
              </Th>
              <Th className="text-center" style={{ minWidth: 200 }}>
                Vendor
              </Th>
              <Th className="text-center" style={{ minWidth: 200 }}>
                Rincian Pekerjaan
              </Th>
              <Th className="text-center" style={{ width: 160 }}>
                Total Upah Kerja
              </Th>
              <Th className="text-center" style={{ width: 160 }}>
                Pembayaran Periode Ini
              </Th>
              {!isGuest && (
                <Th className="p-0 text-center" style={{ width: 70 }}>
                  Aksi
                </Th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataRealisasiLabourCostFinishing.length > 0 ? (
              dataRealisasiLabourCostFinishing.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val?.nama_vendor ?? ''}</Td>
                  <Td>
                    {val?.rincian?.map((val, i) => (
                      <span className="d-block" key={i}>
                        - {val?.uraian ?? ''}
                      </span>
                    ))}
                  </Td>
                  <Td className="text-right">
                    {val?.rincian
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalAllPeriod(val.rincian)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {val?.rincian
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalCurrentPeriod(val.rincian)
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  {!isGuest && (
                    <Td>
                      <ButtonGroup size="sm">
                        <ActionButton
                          variant="success"
                          text={<IoPencilOutline />}
                          onClick={() => {
                            setProcessedData({ ...val, index });
                            setModalConfig({
                              show: true,
                              type: 'update',
                            });
                          }}
                        />
                        <ActionButton
                          variant="danger"
                          text={<IoTrashBinOutline />}
                          onClick={() => {
                            setProcessedData({ ...val, index });
                            setModalConfig({
                              show: true,
                              type: 'delete',
                            });
                          }}
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
      <>
        {/* Table List History Item Labour Cost Finishing  */}
        <div className="mt-3 pb-2">
          <b className="p-1">History Opname Labour Cost Finishing </b>
        </div>
        <Table>
          <thead className="bg-light">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>
                No.
              </Th>
              <Th className="text-center" style={{ width: 150 }}>
                Informasi Realisasi Produksi
              </Th>
              <Th className="text-center" style={{ width: 80 }}>
                No. Kontrak
              </Th>
              <Th className="text-center" style={{ width: 150 }}>
                Vendor
              </Th>
              <Th className="text-center" style={{ minWidth: 150 }}>
                Rincian Pekerjaan
              </Th>
              <Th className="text-center" style={{ width: 150 }}>
                Total Upah Kerja
              </Th>
              <Th className="text-center" style={{ width: 150 }}>
                Pembayaran Periode Ini
              </Th>
              <Th className="text-center" style={{ width: 40 }}>
                Aksi
              </Th>
            </tr>
          </thead>
          <tbody>
            {dataHistoryLabourCostFinishing.length > 0 ? (
              dataHistoryLabourCostFinishing.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <span className="d-block">
                      {val?.tgl_realisasi_produksi
                        ? DateConvert(new Date(val.tgl_realisasi_produksi))
                            .defaultDMY
                        : ''}
                    </span>
                    <span className="d-block">
                      {val?.no_realisasi_produksi ?? ''}
                    </span>
                  </Td>
                  <Td>{val?.no_kontrak_upah ?? ''}</Td>
                  <Td>{val?.nama_vendor ?? ''}</Td>
                  <Td>
                    {val?.rincian
                      ? val.rincian.map((val) => (
                          <span className="d-block">- {val.uraian}</span>
                        ))
                      : ''}
                  </Td>
                  <Td className="text-right">
                    {val?.rincian
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotal(val?.rincian ?? [])
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td className="text-right">
                    {val?.rincian
                      ? RupiahConvert(
                          calculationHandler
                            .grandTotalCurrentPeriod(val?.rincian ?? [])
                            .toString()
                        ).detail
                      : RupiahConvert('0').detail}
                  </Td>
                  <Td>
                    <ActionButton
                      variant="primary"
                      size="sm"
                      text={<IoEyeOutline />}
                      onClick={() => {
                        setProcessedData({ ...val, index });
                        setModalConfig({
                          show: true,
                          type: 'detail',
                        });
                      }}
                    />
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </>

      {/* Modal Realisasi */}
      <ModalRealisasi />
    </div>
  );
};

export default TableLabourCostFinishing;
