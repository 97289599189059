import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  IoAddOutline,
  IoTrashOutline,
  IoPencilOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import ReactSelect from 'react-select';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, Alert, InputCurrency } from 'components';
import { RupiahConvert } from 'utilities';

const TableUraianLabourCost = ({
  id_rap_detail,
  id_finishing_barang_jadi,
  dataSelectTPLC,
  dataUraianLabourCost,
  setDataUraianLabourCost,
  defaultKonst,
  dataUnit,
}) => {
  const { id } = useParams();
  const [processedData, setProcessedData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'danger',
  });

  let lastUrutanItem = 1;
  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  const formInitialValues = {
    id_rap_detail: id_rap_detail,
    id_finishing_barang_jadi: id_finishing_barang_jadi,
    uraian: '',
    qty: '',
    id_satuan: '',
    unit_price: '',
    konstanta: 1,
    nama_satuan: '',
  };

  const formValidationSchema = Yup.object().shape({
    uraian: Yup.string().required(),
    qty: Yup.string().required(),
    unit_price: Yup.string().required(),
    konstanta: Yup.string().required(),
    id_satuan: Yup.string().required(),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      //konstanta: parseFloat(values.konstanta) * defaultKonst,
      konstanta: parseFloat(values.konstanta),
      urutan_item: lastUrutanItem,
    };

    setDataUraianLabourCost([...dataUraianLabourCost, finalValues]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    formik;

  const formTotal = Math.round(
    parseFloat(values.qty).toFixed(6) *
      parseFloat(values.unit_price) *
      parseFloat(values.konstanta).toFixed(6)
  );
  let subTotal = 0;

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 10);

    return convert;
  };

  useEffect(() => {
    setSelectedData({});
    return () => {};
  }, [dataSelectTPLC]);

  const SelectSearch = (props) => (
    <ReactSelect
      {...props}
      classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
      noOptionsMessage={() => 'Tidak ada data'}
      menuShouldBlockScroll={true}
      menuPosition="fixed"
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
        }),
      }}
    />
  );

  const PageModal = () => {
    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);
        setTimeout(() => {
          const newData = dataUraianLabourCost.filter(
            (val) => val.urutan_item !== processedData.urutan_item
          );
          setDataUraianLabourCost(newData);
          setIsDeleting(false);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil dihapus!',
          });
        }, 200);
      };

      useEffect(() => {
        return () => {
          setIsDeleting(false);
        };
      }, []);

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan nama item bahan: </span>
            <br />
            <b>{processedData.nama_item}</b>
          </h5>
          <small className="text-danger">
            Data yang dihapus tidak dapat dikembalikan!
          </small>
        </>
      );

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      );

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <span className="text-danger">Hapus Data Labour Cost</span>
        </Modal.Header>
        <ModalDelete />
      </Modal>
    );
  };

  const ListDataTable = ({
    index,
    val,
    total,
    setProcessedData,
    setModalConfig,
  }) => {
    return (
      <tr key={index}>
        <td style={tableStyling} className="px-2">
          {index + 1}
        </td>
        <td style={tableStyling} className="px-2">
          {val.uraian}
        </td>
        <td style={tableStyling} className="text-right">
          {val.qty ? parseFloat(val.qty).toPrecision() : '0'}
        </td>
        <td style={tableStyling} className="px-2">
          {val.nama_satuan}
        </td>
        <td style={tableStyling} className="text-right">
          <NumberFormat
            value={parseFloat(val.unit_price)}
            displayType="text"
            prefix="Rp"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
          />
        </td>
        <td style={tableStyling} className="text-right">
          {val.konstanta ? parseFloat(val.konstanta).toPrecision() : '0'}
        </td>
        <td style={tableStyling} className="text-right">
          {RupiahConvert(total.toString()).detail}
        </td>
        <td
          className="d-flex justify-content-center align-items-center btn-group px-1"
          style={tableStyling}
        >
          <ActionButton
            size="sm"
            variant="success"
            text={<IoPencilOutline />}
            onClick={() => setEditedData(val)}
          />
          <ActionButton
            size="sm"
            variant="danger"
            text={<IoTrashOutline />}
            onClick={() => {
              setProcessedData(val);
              setModalConfig({
                show: true,
                type: 'danger',
              });
            }}
          />
        </td>
      </tr>
    );
  };

  const EditDataTable = ({
    index,
    val,
    dataUraianLabourCost,
    setDataUraianLabourCost,
  }) => {
    const [editSelectedData, setEditSelectedData] = useState(val);

    const formEditInitialValues = {
      id_rap_detail: id_rap_detail,
      id_finishing_barang_jadi: id_finishing_barang_jadi,
      qty: val.qty,
      unit_price: editSelectedData.harga_satuan
        ? parseFloat(editSelectedData.harga_satuan)
        : val.unit_price,
      konstanta: val.konstanta,
      nama_satuan: editSelectedData.nama_satuan
        ? editSelectedData.nama_satuan
        : '',
      urutan_item: val.urutan_item,
      uraian: val.uraian,
      id_satuan: val.id_satuan,
    };

    const formEditValidationSchema = Yup.object().shape({
      uraian: Yup.string().required(),
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
      id_satuan: Yup.string().required(),
    });

    const formEditSubmitHandler = (values) => {
      const finalValue = dataUraianLabourCost.map((data) => {
        if (values.urutan_item === data.urutan_item) {
          return values;
        } else {
          return data;
        }
      });

      setDataUraianLabourCost(finalValue);
      setEditedData({});
      setAlertConfig({
        show: true,
        variant: 'primary',
        text: 'Data berhasil diubah!',
      });
    };

    const editFormik = useFormik({
      enableReinitialize: true,
      initialValues: formEditInitialValues,
      validationSchema: formEditValidationSchema,
      onSubmit: formEditSubmitHandler,
    });

    const {
      values,
      errors,
      touched,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = editFormik;

    const formTotal = Math.round(
      parseFloat(values.qty).toFixed(6) *
        parseFloat(values.unit_price) *
        parseFloat(values.konstanta).toFixed(6)
    );

    return (
      <tr key={index}>
        <td style={tableStyling} className="px-2">
          {index + 1}
        </td>
        {/* <td style={tableStyling} className="px-2">{values.kode_item_bahan}</td> */}
        <td style={{ ...tableStyling, Width: '100px' }}>
          <input
            name="uraian"
            placeholder="Masukkan uraian disini"
            className={`form-control form-control-sm ${
              errors.uraian && touched.uraian ? 'is-invalid' : ''
            }`}
            value={values.uraian}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue('uraian', value);
            }}
          />
        </td>
        <td style={{ ...tableStyling, width: '100px' }}>
          <input
            name="qty"
            placeholder="Qty"
            className={`form-control form-control-sm ${
              errors.qty && touched.qty ? 'is-invalid' : ''
            }`}
            value={values.qty}
            onChange={(e) => {
              const value = e.target.value;
              const convert = decimalConvert(value);
              setFieldValue('qty', convert);
            }}
          />
        </td>
        <td style={{ tableStyling, width: '100px' }} className="px-2">
          <SelectSearch
            placeholder="Unit"
            defaultValue={
              values.id_satuan
                ? {
                    value: values.id_satuan,
                    label: dataUnit.find(
                      (val) => val.id_satuan === values.id_satuan
                    ).nama_satuan,
                  }
                : ''
            }
            options={dataUnit.map((val) => {
              return {
                value: val.id_satuan,
                label: val.nama_satuan,
                data: val,
              };
            })}
            onChange={(val) => {
              setSelectedData(val.data);
              setFieldValue('id_satuan', val.value);
              setFieldValue('nama_satuan', val.label);
            }}
            error={errors.id_satuan && touched.id_satuan && true}
          />
        </td>
        <td style={{ ...tableStyling, width: '170px' }}>
          <InputCurrency
            noMargin
            wrapperClassName="mb-0"
            className={`form-control form-control-sm ${
              errors.unit_price ? 'is-invalid' : ''
            }`}
            value={parseFloat(values?.unit_price)}
            onChange={(e) => setFieldValue('unit_price', e)}
          />
        </td>
        <td style={{ ...tableStyling, width: '70px' }}>
          <input
            name="konstanta"
            placeholder="Konst"
            className={`form-control form-control-sm ${
              errors.konstanta && touched.konstanta ? 'is-invalid' : ''
            }`}
            value={values.konstanta}
            onChange={(e) => {
              const value = e.target.value;
              const convert = decimalConvert(value);
              setFieldValue('konstanta', convert);
            }}
          />
        </td>
        <td style={{ tableStyling, width: '170px' }}>
          {formTotal
            ? RupiahConvert(formTotal.toString()).detail
            : RupiahConvert('0').detail}
        </td>
        <td style={tableStyling}>
          <div className="d-flex justify-content-around align-items-center btn-group px-1">
            <ActionButton
              size="sm"
              variant="outline-success"
              text={<IoCheckmarkOutline />}
              onClick={handleSubmit}
            />
            <ActionButton
              size="sm"
              variant="outline-danger"
              text={<IoCloseOutline />}
              onClick={() => setEditedData({})}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="px-2 pb-2 mt-3">
        <b>List Item Uraian Subkon Finishing</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <div className="table-responsive">
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center">
            <tr>
              <th
                className="align-middle p-2"
                style={{ tableStyling, width: 20 }}
              >
                No.
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Item Labour Cost
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Qty
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Unit Price (Rp)
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Konst.
              </th>
              <th className="align-middle p-2" style={tableStyling}>
                Total Price (Rp)
              </th>
              <th
                className="align-middle p-2"
                style={{ ...tableStyling, width: '80px' }}
              >
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              {/* <td style={tableStyling} className="px-2">{values.kode_item_bahan}</td> */}
              <td style={{ ...tableStyling, Width: '100px' }}>
                <input
                  name="uraian"
                  placeholder="Masukkan uraian disini"
                  className={`form-control form-control-sm ${
                    errors.uraian && touched.uraian ? 'is-invalid' : ''
                  }`}
                  value={values.uraian}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldValue('uraian', value);
                  }}
                />
              </td>
              <td style={{ ...tableStyling, width: '100px' }}>
                <input
                  name="qty"
                  placeholder="Qty"
                  className={`form-control form-control-sm ${
                    errors.qty && touched.qty ? 'is-invalid' : ''
                  }`}
                  value={values.qty}
                  onChange={(e) => {
                    const value = e.target.value;
                    const convert = decimalConvert(value);
                    setFieldValue('qty', convert);
                  }}
                />
              </td>
              <td style={{ tableStyling, width: '100px' }} className="px-2">
                <SelectSearch
                  placeholder="Unit"
                  defaultValue={
                    values.id_satuan
                      ? {
                          value: values.id_satuan,
                          label: dataUnit.find(
                            (val) => val.id_satuan === values.id_satuan
                          ).nama_satuan,
                        }
                      : ''
                  }
                  options={dataUnit.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                      data: val,
                    };
                  })}
                  onChange={(val) => {
                    setSelectedData(val.data);
                    setFieldValue('id_satuan', val.value);
                    setFieldValue('nama_satuan', val.label);
                  }}
                  error={errors.id_satuan && touched.id_satuan && true}
                />
              </td>
              <td style={{ ...tableStyling, width: '170px' }}>
                <InputCurrency
                  noMargin
                  wrapperClassName="mb-0"
                  className={`form-control form-control-sm ${
                    errors.unit_price ? 'is-invalid' : ''
                  }`}
                  value={parseFloat(values?.unit_price)}
                  onChange={(e) => setFieldValue('unit_price', e)}
                />
              </td>
              <td style={{ ...tableStyling, width: '70px' }}>
                <input
                  name="konstanta"
                  placeholder="Konst"
                  className={`form-control form-control-sm ${
                    errors.konstanta && touched.konstanta ? 'is-invalid' : ''
                  }`}
                  value={values.konstanta}
                  onChange={(e) => {
                    const value = e.target.value;
                    const convert = decimalConvert(value);
                    setFieldValue('konstanta', convert);
                  }}
                />
              </td>
              <td style={{ tableStyling, width: '170px' }}>
                {formTotal
                  ? RupiahConvert(formTotal.toString()).detail
                  : RupiahConvert('0').detail}
              </td>
              <td style={tableStyling}>
                <div className="px-1">
                  <ActionButton
                    size="sm btn-block"
                    text={<IoAddOutline />}
                    onClick={handleSubmit}
                  />
                </div>
              </td>
            </tr>
            {dataUraianLabourCost
              .filter(
                (item) =>
                  item.id_finishing_barang_jadi === id_finishing_barang_jadi
              )
              .map((val, index) => {
                const total = Math.round(
                  parseFloat(val.qty).toFixed(6) *
                    parseFloat(val.unit_price) *
                    parseFloat(val.konstanta).toFixed(6)
                );
                const lastItem = parseInt(val.urutan_item)
                  ? parseInt(val.urutan_item) + 1
                  : 1;
                lastUrutanItem = lastItem;
                subTotal = parseInt(subTotal) + parseInt(total);

                return editedData === val ? (
                  <EditDataTable
                    index={index}
                    val={val}
                    dataUraianLabourCost={dataUraianLabourCost}
                    setDataUraianLabourCost={setDataUraianLabourCost}
                  />
                ) : (
                  <ListDataTable
                    index={index}
                    val={val}
                    total={total}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    setProcessedData={setProcessedData}
                    setModalConfig={setModalConfig}
                  />
                );
              })}
            <tr>
              <td colSpan={6} className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td className="text-right" style={tableStyling}>
                <b>{RupiahConvert(subTotal.toString()).detail}</b>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <PageModal />
    </>
  );
};

export default TableUraianLabourCost;
