import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  CreateButton,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  Td,
  Th,
  ThFixed,
} from 'components';
import { TransferSisaProduksiApi } from 'api';
import { DateConvert, TableNumber } from 'utilities';
import {
  ItemSisaProduksiCollapse,
  ModalFilterTransferSisaProduksi,
} from './Components';
import { withDropdownTransferSisaProduksiContextProvider } from './Contexts';
import { useSessionStorage } from 'hooks';

const TransferSisaProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    tgl_realisasi_produksi_start: undefined,
    tgl_realisasi_produksi_end: undefined,
    tgl_transfer_sisa_produksi_start: undefined,
    tgl_transfer_sisa_produksi_end: undefined,
    id_barang_jadi: undefined,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_realisasi_produksi_start: pagination.tgl_realisasi_produksi_start,
      tgl_realisasi_produksi_end: pagination.tgl_realisasi_produksi_end,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      id_barang_jadi: pagination.id_barang_jadi,
      tgl_transfer_sisa_produksi_start:
        pagination.tgl_transfer_sisa_produksi_start,
      tgl_transfer_sisa_produksi_end: pagination.tgl_transfer_sisa_produksi_end,
    };

    TransferSisaProduksiApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination({
          ...pagination,
          data_count: data.data_count,
          total_page: data.total_page,
        });
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Data gagal dimuat ${err.response.data.message}`,
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState.alert);
    }
  };

  const dismissAlert = () => {
    history.replace(location?.pathname, { alert: undefined });
    setAlertConfig({ ...alertConfig, show: false });
  };

  useEffect(() => {
    setNavbarTitle('Transfer Sisa Produksi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.tgl_realisasi_produksi_start,
    pagination.tgl_realisasi_produksi_end,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.tgl_transfer_sisa_produksi_start,
    pagination.tgl_transfer_sisa_produksi_end,
    pagination.id_barang_jadi,
  ]);

  const TableContent = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mt-2" style={{ fontSize: '.9rem' }}>
          List Data Transfer Sisa Produksi
        </div>
        <Table>
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Informasi Job Order</Th>
              <Th>Item Job Order</Th>
              <Th>Informasi Realisasi Produksi</Th>
              <Th>Informasi Transfer Sisa Produksi</Th>
              <Th>Item Sisa Produksi</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>
                <Td textCenter>
                  <ActionButton
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/transfer-sisa-produksi/detail/${val.id_transfer_sisa_produksi}`
                      )
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>
                </Td>
                <Td>
                  <div>
                    {val.tgl_job_order
                      ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_job_order ?? '-'}</div>
                </Td>
                <Td>{val.nama_barang_jadi ?? '-'}</Td>
                <Td>
                  <div>
                    {val.tgl_realisasi_produksi
                      ? DateConvert(new Date(val.tgl_realisasi_produksi))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_realisasi_produksi ?? '-'}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_transfer_sisa_produksi
                      ? DateConvert(new Date(val.tgl_transfer_sisa_produksi))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_transfer_sisa_produksi ?? '-'}</div>
                </Td>
                <Td>
                  <ItemSisaProduksiCollapse data={val.detail ?? []} />
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <>
      <Row className="mb-1">
        <Col className="d-flex flex-row justify-space-between align-items-center">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(
              (e) =>
                setPagination((prv) => ({
                  ...prv,
                  q: e.target.value,
                  page: 1,
                })),
              1500
            )}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter((prev) => !prev)}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <CreateButton
            onClick={() =>
              history.push(
                '/transaksi/transfer-sisa-produksi/realisasi-produksi'
              )
            }
          />
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent />
      )}

      <ModalFilterTransferSisaProduksi
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownTransferSisaProduksiContextProvider(
  TransferSisaProduksiList
);
