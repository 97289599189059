import { ButtonGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { IoArrowDown } from 'react-icons/io5';
import { Tr, Td, UpdateButton, DeleteButton, ActionButton } from 'components';
import { kalkulasiLuas, kalkulasiVolume } from '../../Helpers';
import { calcSubtotalHW } from './InputHardwood';

export const RowItemHardwood = ({
  index,
  data = {},
  jobOrder,
  readOnly,
  showStatusPermintaan,
  onUpdate,
  onClickJobOrder,
  onDelete,
}) => {
  const toFloatFixed = (value) => (
    <NumberFormat
      value={parseFloat(value)}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={6}
    />
  );

  return (
    <Tr>
      <Td textCenter>{index + 1} </Td>
      <Td className="text-nowrap"> {data.deskripsi} </Td>
      <Td className="text-nowrap"> {data.nama_jenis_kayu} </Td>
      <Td className="text-nowrap"> {data.nama_part_kayu} </Td>
      <Td className="text-nowrap">
        {data.nama_finishing_barang_jadi ?? 'Tanpa Finishing'}
      </Td>
      <Td className="text-nowrap"> {data.nama_tipe_sisi} </Td>
      <Td textRight> {toFloatFixed(data.qty_final)} </Td>
      <Td textRight> {toFloatFixed(data.t_final)} </Td>
      <Td textRight> {toFloatFixed(data.w_final)} </Td>
      <Td textRight> {toFloatFixed(data.l_final)} </Td>
      <Td textRight> {toFloatFixed(data.qty_raw)} </Td>
      <Td textRight> {toFloatFixed(data.t_raw)} </Td>
      <Td textRight> {toFloatFixed(data.w_raw)} </Td>
      <Td textRight> {toFloatFixed(data.l_raw)} </Td>
      <Td textRight>
        {kalkulasiLuas({
          id_tipe_sisi: data.id_tipe_sisi,
          qty: data.qty_final,
          t: data.t_final,
          w: data.w_final,
          l: data.l_final,
        })}
      </Td>

      <Td textRight>
        {kalkulasiVolume({
          qty_raw: data.qty_raw,
          t_raw: data.t_raw,
          w_raw: data.w_raw,
          l_raw: data.l_raw,
        })}
      </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(data.unit_price)}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>
      <Td textRight> {toFloatFixed(data.konstanta)} </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(calcSubtotalHW(data))}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>

      {showStatusPermintaan ? (
        <Td className="text-uppercase text-center">{data.status_permintaan ?? 'Waiting'}</Td>
      ) : null}

      {readOnly ? null : jobOrder ? (
        <Td>
          <ActionButton
            size="sm"
            className="m-1"
            onClick={() => onClickJobOrder(data)}
          >
            <IoArrowDown />
          </ActionButton>
        </Td>
      ) : (
        <Td>
          <ButtonGroup>
            <UpdateButton
              className="m-0"
              onClick={() => onUpdate(index, data)}
            />
            <DeleteButton
              className="m-0"
              onClick={() => onDelete(index, data)}
            />
          </ButtonGroup>
        </Td>
      )}
    </Tr>
  );
};
