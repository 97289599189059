import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableBPF } from './TableBPF';

export const ContainerBPFDetail = () => {
  const { dataBPF } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableBPF
        jobOrder
        readOnly
        showTotalPrice
        title="List Bahan Penunjang Finishing Dari Job Order"
        data={dataBPF.jobOrder}
      />
      <TableBPF
        readOnly
        showTotalPrice
        showStatusPermintaan
        title="List Permintaan Bahan Penunjang Finishing"
        data={dataBPF.permintaan}
      />
    </Row>
  );
};
