import { Modal } from 'react-bootstrap';
import {
  IoCheckmarkCircleSharp,
  IoCloseCircle,
  IoWarningSharp,
} from 'react-icons/io5';
import { ActionButton } from 'components';

export const ModalAlert = ({
  modalStatus,
  onModalClose,
  onErrorClose,
  onSuccessClose,
  onSubmit,
  isSubmitting,
}) => {
  const { show, type, title, message } = modalStatus;

  const warning = {
    title: title ?? 'Perhatian!',
    message: message ?? 'Apakah anda yakin?',
    icon: <IoWarningSharp size={80} color="#c93636" />,
    submitButton: {
      onClick: onSubmit ?? null,
      text: 'Simpan',
      variant: 'primary',
    },

    closeButton: {
      onClick: onErrorClose ?? null,
      text: 'Batal',
      variant: 'danger',
    },
  };

  const error = {
    title: title ?? 'Gagal!',
    message: message ?? 'Terjadi keselahan!',
    icon: <IoCloseCircle size={80} color="#c93636" />,
    closeButton: {
      onClick: onErrorClose ?? null,
      text: 'Ok',
      variant: 'primary',
    },
  };

  const success = {
    title: title ?? 'Berhasil!',
    message: message ?? 'Berhasil!',
    icon: <IoCheckmarkCircleSharp size={80} color="#30b090" />,
    closeButton: {
      onClick: onSuccessClose ?? null,
      text: 'Ok',
      variant: 'primary',
    },
  };

  const config =
    type === 'success' ? success : type === 'warning' ? warning : error;

  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header closeButton>{config.title}</Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div>{config.icon}</div>
        <small className="text-center font-weight-bold">{config.message}</small>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-center align-items-center">
        {config?.submitButton?.onClick && (
          <ActionButton
            loading={isSubmitting}
            className="m-1"
            text={config?.submitButton?.text}
            onClick={config?.submitButton?.onClick}
          />
        )}

        {config?.closeButton?.onClick && (
          <ActionButton
            disable={isSubmitting}
            text={config.closeButton.text}
            className="px-4"
            variant={config.closeButton.variant}
            onClick={config?.closeButton?.onClick}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
