import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableLCF } from './TableLCF';

export const ContainerLCFDetail = () => {
  const { dataLCF } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableLCF
        jobOrder
        readOnly
        showTotalPrice
        title="List Labour Cost Finishing Dari Job Order"
        data={dataLCF.jobOrder}
      />
      <TableLCF
        readOnly
        showTotalPrice
        showStatusPermintaan
        title="List Permintaan Labour Cost Finishing"
        data={dataLCF.permintaan}
      />
    </Row>
  );
};
