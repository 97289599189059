import { Services } from '../../../services';

class MutasiBarangJadiApi {
  page(params) {
    return Services.get('/mutasi_barang_jadi/page', { params });
  }

  single(params) {
    return Services.get('/mutasi_barang_jadi/single', { params });
  }

  dropdown(params) {
    return Services.get('/mutasi_barang_jadi/dropdown', { params });
  }

  mutasi_barang(params) {
    return Services.get('/mutasi_barang_jadi/mutasi_barang', { params });
  }

  no_baru(params) {
    return Services.get('/mutasi_barang_jadi/no_baru', { params });
  }

  create(value) {
    return Services.post('/mutasi_barang_jadi', value);
  }

  update(value) {
    return Services.put('/mutasi_barang_jadi', value);
  }
}

export default new MutasiBarangJadiApi();
