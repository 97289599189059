import { createContext } from 'react';
import { useQuery } from 'react-query';
import { TransferSisaProduksiApi } from 'api';
import { useQueryDropdown } from 'config/useQueryDropdown';

export const DropdownTransferSisaProduksiContext = createContext();

export const DropdownTransferSisaProduksiContextProvider = ({ children }) => {
  const dropdownDimensi = [
    { value: '0', label: 'Non Dimensi' },
    { value: '1', label: '1 Dimensi' },
    { value: '2', label: '2 Dimensi' },
    { value: '3', label: '3 Dimensi' },
  ];

  const dropdownBuaso = useQuery(
    ['dropdown_buaso', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'buaso' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_buaso,
            label: `${v.kode_buaso} - ${v.nama_buaso}`,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownJobOrder = useQuery(
    ['dropdown_job_order', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'barang_jadi' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_item_buaso,
            label: `${v.kode_item} - ${v.nama_item}` ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownKelompok = useQuery(
    ['dropdown_kelompok', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'kelompok' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_kelompok,
            label: v.nama_kelompok ?? '',
            id_buaso: v.id_buaso,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownJenis = useQuery(
    ['dropdown_jenis', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'jenis' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_jenis,
            label: v.nama_jenis ?? '',
            id_kelompok: v.id_kelompok,
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownPabrikan = useQuery(
    ['dropdown_pabrikan', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.bahan_dropdown({ tipe: 'pabrikan' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_pabrikan,
            label: v.nama_pabrikan ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownSatuan = useQuery(
    ['dropdown_satuan', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'satuan' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_satuan,
            label: v.nama_satuan ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownGudang = useQuery(
    ['dropdown_gudang', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'gudang' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_gudang,
            label: v.nama_gudang ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownCustomer = useQuery(
    ['dropdown_customer', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'customer' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_customer,
            label: v.nama_customer ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  const dropdownProyek = useQuery(
    ['dropdown_proyek', 'transfer_sisa_produksi'],
    () =>
      TransferSisaProduksiApi.dropdown({ tipe: 'proyek' }).then(
        (res) =>
          res?.data?.data?.map((v) => ({
            value: v.id_proyek,
            label: v.nama_proyek ?? '',
          })) ?? []
      ),
    useQueryDropdown
  );

  return (
    <DropdownTransferSisaProduksiContext.Provider
      value={{
        dropdownDimensi,
        dropdownBuaso,
        dropdownKelompok,
        dropdownJenis,
        dropdownPabrikan,
        dropdownSatuan,
        dropdownGudang,
        dropdownJobOrder,
        dropdownCustomer,
        dropdownProyek,
      }}
    >
      {children}
    </DropdownTransferSisaProduksiContext.Provider>
  );
};

export const withDropdownTransferSisaProduksiContextProvider =
  (Component) => (props) => {
    return (
      <DropdownTransferSisaProduksiContextProvider>
        <Component {...props} />
      </DropdownTransferSisaProduksiContextProvider>
    );
  };
