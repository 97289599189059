import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import {
  Alert,
  CreateButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  DeleteModal,
  InputSearch,
  Pagination,
  ReadButton,
  Switch,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from 'components';
import { PageNumber } from 'utilities';
import { FinishingBarangJadiApi } from 'api';

const FinishingBarangJadi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const title = 'Tipe Finishing';
  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;

  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [isDeleteData, setIsDeleteData] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [deleteData, setDeleteData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([FinishingBarangJadiApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // fungsi show/ hide
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_finishing_barang_jadi: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? FinishingBarangJadiApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => {
            setShowAlert(true);
            getData();
          })
      : FinishingBarangJadiApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => {
            setShowAlert(true);
            getData();
          });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getData();
    if (registerAlertConfig) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const values = {
      id_finishing_barang_jadi: deleteData.id_finishing_barang_jadi,
    };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      setBtnLoading(true);

      FinishingBarangJadiApi.delete(values)
        .then(() => {
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! ${err.response.data.message}`,
          });
        })
        .finally(() => {
          setIsDeleteData(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Finishing : {deleteData.nama_finishing_barang_jadi}</div>
      </DeleteModal>
    );
  };

  const TableSection = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Nama Tipe Finishing</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() => {
                      history.push(
                        `/master/finishing-barang-jadi/detail/${val.id_finishing_barang_jadi}`,
                        {}
                      );
                    }}
                  />
                  <UpdateButton
                    onClick={() => {
                      history.push(
                        `/master/finishing-barang-jadi/update/${val.id_finishing_barang_jadi}`
                      );
                    }}
                  />
                  <DeleteButton
                    onClick={() => {
                      setDeleteData(val);
                      setIsDeleteData(true);
                    }}
                  />
                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() =>
                      changeDataStatus(
                        val.is_hidden,
                        val.id_finishing_barang_jadi
                      )
                    }
                  />
                </div>
              </TdFixed>
              <Td>{val.nama_finishing_barang_jadi}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row mb-1">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push(`/master/finishing-barang-jadi/tambah`, {});
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false);
          history.replace('/master/finishing-barang-jadi', {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data?.length ? (
        <TableSection />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <HapusModal />
      <DeleteModal />
    </CRUDLayout>
  );
};

export default FinishingBarangJadi;
