import React, { useState, useEffect, useContext } from 'react';
import { Modal, Card, Nav } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DataStatus, ActionButton } from 'components';
import { PeluangApi } from 'api';
import { TabInfo, TabGambar, TabFile } from './Tabs';

const FormPeluang = ({
  id_peluang,
  modalConfig,
  setModalConfig,
  setAlertConfig,
  getFilteredAnalisaBarangJadi,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [listBarangJadi, setListBarangJadi] = useState([]);
  const [defaultTabs, setDefaultTabs] = useState('info');
  const tabsConfig = [
    {
      tab: 'info',
      label: 'Informasi Umum',
      component: ({ ...rest }) => <TabInfo {...rest} />,
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: ({ ...rest }) => <TabGambar {...rest} />,
    },
    {
      tab: 'file',
      label: 'File',
      component: ({ ...rest }) => <TabFile {...rest} />,
    },
  ];

  const onTabsChangeHandler = (e, tabs) => {
    e.preventDefault();
    setDefaultTabs(tabs);
  };

  const checkTypeAndStoreFile = (data) => {
    let gambar = [];
    let file = [];

    data.map((val) => {
      const map = {
        link: val.path_gambar,
        id: val.id_peluang_gambar,
        nama: '',
        data: null,
      };

      const generalFileType = map.link.split('.').pop();
      const fileName = map.link.split('/').pop();

      // Check apakah data adalah gambar
      if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
        return gambar.push({ ...map, nama: fileName });
      }

      return file.push({ ...map, nama: fileName });
    });

    setDataGambar(gambar);
    setDataFile(file);
  };

  const getData = () => {
    setIsLoading(true);

    PeluangApi.getSingle({ id_peluang })
      .then((data) => {
        const dataInfo = data?.data?.data ?? {};

        checkTypeAndStoreFile(dataInfo?.gambar ?? []);
        setDataInfo(dataInfo);
        setListBarangJadi(
          dataInfo?.detail?.map((val) =>
            Object({ ...val, nama_satuan: val.nama_satuan_jual })
          ) ?? []
        );
      })
      .catch(() => window.alert('Tidak dapat memuat data!'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => id_peluang && getData(), [id_peluang]);

  const formInitialValues = {
    id_peluang: dataInfo.id_peluang,
    att: dataInfo.att,
    tgl_peluang: dataInfo?.tgl_peluang ?? '',
    no_peluang: dataInfo?.no_peluang ?? '',
    id_customer: dataInfo?.id_customer ?? '',
    nama_customer: dataInfo.nama_customer,
    id_proyek: dataInfo?.id_proyek ?? '',
    nama_proyek: dataInfo.nama_proyek,
    id_peringkat_peluang: dataInfo?.id_peringkat_peluang ?? '',
    nama_peringkat_peluang: dataInfo.nama_peringkat_peluang,
    tgl_selesai: dataInfo?.tgl_selesai ?? '',
    realisasi_penawaran: dataInfo?.realisasi_penawaran ?? '',
    keterangan_pengiriman: dataInfo?.keterangan_pengiriman ?? '',
    instalasi: dataInfo?.instalasi ?? false,
    lokasi_pengiriman: dataInfo?.lokasi_pengiriman ?? '',
    lain_lain: dataInfo?.lain_lain ?? '',
    schedule_kebutuhan_proyek: dataInfo?.schedule_kebutuhan_proyek ?? '',
    link_referensi: dataInfo?.link_referensi ?? '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_peluang: Yup.date().required('Tanggal Peluang wajib diisi'),
    id_customer: Yup.string().required('Customer wajib diisi'),
    id_proyek: Yup.string().required('Proyek wajib diisi'),
    id_peringkat_peluang: Yup.string().required(
      'Peringkat Peluang wajib diisi'
    ),
    tgl_selesai: Yup.date().required('Tanggal Selesai wajib diisi'),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    const finalValues = {
      id_peluang,
      ...values,
      detail: listBarangJadi,
    };

    PeluangApi.update(finalValues)
      .then(() =>
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        })
      )
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Ubah data gagal! (${err?.response?.data?.message ?? ''})`,
        })
      )
      .finally(() => {
        setModalConfig({ show: false });
        getFilteredAnalisaBarangJadi(id_peluang);
      });
  };

  return (
    <Modal
      size="xl"
      scrollable={true}
      show={modalConfig.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">
          <h6 className="mb-0">Tambah Data Barang Jadi</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Header>
            {/* Tab Header */}
            <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
              {tabsConfig.map((val, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    // ref={tabsRef}
                    href={`#${val.tab}`}
                    onClick={(e) => onTabsChangeHandler(e, val.tab)}
                  >
                    {val.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {isLoading ? (
              <DataStatus loading={isLoading} text="Memuat Data" />
            ) : (
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    {tabsConfig.map(
                      ({ tab, component: Component }, index) =>
                        tab === defaultTabs && (
                          <Component
                            key={index}
                            formik={formik}
                            dataGambar={dataGambar}
                            dataFile={dataFile}
                            listBarangJadi={listBarangJadi}
                            setListBarangJadi={setListBarangJadi}
                            setDataGambar={setDataGambar}
                            setDataFile={setDataFile}
                          />
                        )
                    )}
                    <hr />
                    {/* Submit Button */}
                    <div className="text-right">
                      <ActionButton
                        type="submit"
                        text="Simpan Peluang"
                        loading={formik.isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};
export default FormPeluang;
