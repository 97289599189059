import { Services } from '../../../services';

class PartKayuApi {
  getPage(params) {
    return Services.get('/deskripsi_bahan_baku/page/', { params });
  }
  getSingle(params) {
    return Services.get('/deskripsi_bahan_baku/single', { params });
  }
  create(data) {
    return Services.post('/deskripsi_bahan_baku', data);
  }
  update(data) {
    return Services.put('/deskripsi_bahan_baku', data);
  }
  delete(id_deskripsi_bahan_baku) {
    return Services.post(
      '/deskripsi_bahan_baku/delete',
      id_deskripsi_bahan_baku
    );
  }
}

export default new PartKayuApi();
