import { Services } from '../../../services';

class JenisBarangJadiApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/jenis_barang_jadi/page?page=${page}&per_page=${dataLength}&q=${key}&nama_kelompok=["Barang%20Jadi","Barang%20Jadi%20Siap%20Jual"]`
    );
  }

  getKelompokItem() {
    return Services.get('/registrasi_barang_jadi/kelompok');
  }

  create(data) {
    return Services.post('/jenis_barang_jadi', data);
  }

  search(key) {
    return Services.get(
      `/jenis_barang_jadi/page?q=${key}&nama_kelompok=["Barang%20Jadi","Barang%20Jadi%20Siap%20Jual"]`
    );
  }

  update(data) {
    return Services.post('/jenis_barang_jadi/update', data);
  }

  hide(data) {
    return Services.post('/jenis_barang_jadi/hide', data);
  }

  show(data) {
    return Services.post('/jenis_barang_jadi/show', data);
  }

  delete(data) {
    return Services.post('/jenis_barang_jadi/delete', data);
  }
}

export default new JenisBarangJadiApi();
