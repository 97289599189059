import { Fragment, useContext, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Table,
  THead,
  Tr,
  Td,
  Th,
  ThFixed,
  TBody,
  DataStatus,
} from 'components';
import { PermintaanProduksiContext } from '../../Context';
import { calcSubtotalFS, InputFS } from './InputFS';
import { RowItemFS } from './RowItemFS';
import { ModalDeleteFS } from './ModalDelete';

export const TableFS = ({
  jobOrder = false,
  showInputRow = false,
  showTotalPrice = false,
  showStatusPermintaan = false,
  readOnly = false,
  title = '',
  data = [],
}) => {
  const { setDataFS } = useContext(PermintaanProduksiContext);

  const [updateItem, setUpdateItem] = useState({
    index: null,
    data: null,
  });

  const [deleteItem, setDeleteItem] = useState({
    index: null,
    data: null,
  });

  const onClickJobOrder = (itemJobOrder) => {
    setDataFS((prev) => ({
      ...prev,
      permintaan: [itemJobOrder, ...prev.permintaan],
    }));
  };

  const onUpdate = (index, data) => setUpdateItem({ index, data });

  const onUpdateCancel = () => setUpdateItem({ index: null, data: null });

  const onDelete = (index, data) => setDeleteItem({ index, data });

  const onCreateSubmit = (values) =>
    setDataFS((prev) => ({
      ...prev,
      permintaan: [values, ...prev.permintaan],
    }));

  const onUpdateSubmit = (values) => {
    const copyData = [...data];
    copyData[updateItem.index] = values;

    setUpdateItem({ index: null, data: null });
    setDataFS((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const onDeleteSubmit = () => {
    const copyData = [...data];
    copyData.splice(deleteItem.index, 1);

    setDeleteItem({ index: null, data: null });
    setDataFS((prev) => ({
      ...prev,
      permintaan: copyData,
    }));
  };

  const reduceSubTotal = parseFloat(
    data.reduce(
      (subTotal, values) => Math.round(subTotal + calcSubtotalFS(values)),
      0
    )
  );

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        {title}
      </div>
      <div className="table-responsive">
        <Table>
          <THead className="bg-light">
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed className="text-nowrap">Kode Item</ThFixed>
              <Th>Nama Item</Th>
              <ThFixed className="text-nowrap">Qty.</ThFixed>
              <ThFixed>Satuan</ThFixed>
              <ThFixed className="text-nowrap">Unit Price (Rp)</ThFixed>
              <ThFixed className="text-nowrap">Konst.</ThFixed>
              <ThFixed className="text-nowrap">Sub Total Price (Rp)</ThFixed>
              {showStatusPermintaan ? (
                <ThFixed>Status Permintaan </ThFixed>
              ) : null}
              {readOnly ? null : <ThFixed>Aksi</ThFixed>}
            </Tr>
          </THead>

          <TBody>
            {showInputRow ? (
              <InputFS type="create" onSubmit={onCreateSubmit} />
            ) : null}

            {data?.length ? (
              data?.map((val, index) =>
                updateItem?.index === index ? (
                  <InputFS
                    key={index}
                    type="update"
                    data={updateItem.data}
                    onSubmit={onUpdateSubmit}
                    onCancel={onUpdateCancel}
                  />
                ) : (
                  <RowItemFS
                    key={index}
                    index={index}
                    data={val}
                    jobOrder={jobOrder}
                    readOnly={readOnly}
                    onUpdate={onUpdate}
                    onClickJobOrder={onClickJobOrder}
                    onDelete={onDelete}
                    showStatusPermintaan={showStatusPermintaan}
                  />
                )
              )
            ) : (
              <Tr>
                <Td colSpan={9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}

            {data?.length && showTotalPrice ? (
              <Tr>
                <Td textRight colSpan={7}>
                  Total Price
                </Td>
                <Td textRight>
                  <NumberFormat
                    value={reduceSubTotal}
                    displayType="text"
                    prefix="Rp"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                  />
                </Td>
                {readOnly ? null : <Td />}
              </Tr>
            ) : null}
          </TBody>
        </Table>
      </div>

      <ModalDeleteFS
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        onDeleteSubmit={onDeleteSubmit}
      />
    </Fragment>
  );
};
