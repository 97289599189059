import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DataStatus, InfoItemVertical, Approval } from 'components';
import { DateConvert } from 'utilities';
import { MutasiBarangJadiApi } from 'api';

const LoadingOrFail = ({ fetchStatus }) => {
  const { loading } = fetchStatus;

  if (loading) {
    return <DataStatus loading={loading} text="Memuat data . . . " />;
  }
  return <DataStatus text="Data gagal dimuat!" />;
};

export const ModalDetailMutasiBarangJadi = ({ modal, setModal, title }) => {
  const { id_mutasi_antar_gudang } = modal?.data;
  const [data, setData] = useState({});
  const [fetchStatus, setFetchStatus] = useState({
    loading: true,
    success: false,
  });

  useEffect(() => {
    if (id_mutasi_antar_gudang) {
      setFetchStatus((prev) => ({ ...prev, loading: true }));

      MutasiBarangJadiApi.single({ id_mutasi_antar_gudang })
        .then((res) => {
          setData(res.data.data);
          setFetchStatus({ loading: false, success: true });
        })
        .catch(() => setFetchStatus({ loading: false, success: false }));
    }

    return () => setData({});
  }, [id_mutasi_antar_gudang]);

  return (
    <Modal
      size="lg"
      show={modal.show && modal.tipe === 'detail'}
      onHide={() => setModal({ ...modal, show: false, tipe: '' })}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="mb-0 text-capitalize">
            {modal.tipe} Data {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {fetchStatus.loading || !fetchStatus.success ? (
          <LoadingOrFail fetchStatus={fetchStatus} />
        ) : (
          <>
            <InfoItemVertical
              label="Tgl. Mutasi"
              text={
                data?.tgl_mutasi_antar_gudang
                  ? DateConvert(new Date(data?.tgl_mutasi_antar_gudang)).detail
                  : '-'
              }
            />

            <InfoItemVertical
              label="No. Mutasi"
              text={data?.no_mutasi_antar_gudang ?? '-'}
            />

            <InfoItemVertical
              label="No. Job Order"
              text={data?.no_ref_transaksi ?? '-'}
            />

            <InfoItemVertical
              label="Item Bahan"
              text={
                data?.nama_item
                  ? `${data?.nama_satuan ? `(${data.nama_satuan}) ` : ''}${
                      data.nama_item
                    }`
                  : '-'
              }
            />

            <InfoItemVertical
              label="Gudang Asal"
              text={data?.nama_gudang_asal ?? '-'}
            />

            <InfoItemVertical
              label="Gudang Tujuan"
              text={data?.nama_gudang_tujuan ?? '-'}
            />

            <InfoItemVertical
              label="Qty. Mutasi"
              text={data?.qty_mutasi ?? '-'}
            />

            <InfoItemVertical
              label="Catatan Mutasi"
              text={data?.catatan_mutasi ?? '-'}
            />

            {data?.stakeholder?.length > 1 && (
              <>
                <hr />
                <Approval data={data} />
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
