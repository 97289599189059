import React, { useState, useEffect, useContext, memo } from 'react';
import { Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCheckmark,
  IoCloseOutline,
} from 'react-icons/io5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, SelectSearch, Input, ActionButton } from 'components';
import { RupiahConvert } from 'utilities';
import { RAPContext } from '../RAPContext';
import AnalisaBarangJadiRAP from '../AnalisaBarangJadiRAP';
import AnalisaBarangJadiRAPDetail from '../AnalisaBarangJadiRAPDetail';

const TableBarangJadi = ({
  dataBarangJadi,
  setDataBarangJadi,
  dataSelectAnalisaBarangJadi,
}) => {
  const {
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    setIndexAnalisa,
    type: TYPE,
  } = useContext(RAPContext);
  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });

  const FormSection = ({ dataBarangJadi, setDataBarangJadi }) => {
    const [loading, setLoading] = useState({ barangJadi: false });

    const formInitialValues = {
      id_jenis: '',
      nama_jenis: '',
      id_barang_jadi: '',
      nama_item: '',
      kode_item: '',
      qty: dataBarangJadi.qty_rab ?? '0',
      satuan: '',
      harga_satuan: '0',
      ref_uid: '',
    };

    const formValidationSchema = Yup.object().shape({
      id_barang_jadi: Yup.string().required(),
      qty: Yup.string().required(),
    });

    const formSubmitHandler = (values) => {
      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil ditambah!',
        });
        setDataBarangJadi([...dataBarangJadi, values]);
      }, 300);
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const { values, errors, touched, setFieldValue, handleSubmit } = formik;

    return (
      <tr>
        <td />
        <td colSpan="3" className="p-0 align-middle">
          <SelectSearch
            noMargin
            placeholder="Pilih barang jadi"
            defaultValue={
              values.id_barang_jadi
                ? { value: values.id_barang_jadi, label: values.nama_item }
                : ''
            }
            option={dataSelectAnalisaBarangJadi?.map((val) => {
              return {
                value: val.id_barang_jadi,
                label: `${val.kode_item} | ${val.nama_item}`,
                kode_item: val.kode_item,
                nama_item: val.nama_item,
                satuan: val.nama_satuan,
                harga_satuan: val.total_analisa,
              };
            })}
            onChange={(val) => {
              setFieldValue('id_barang_jadi', val.value);
              setFieldValue('nama_item', val.nama_item);
              setFieldValue('kode_item', val.kode_item);
              setFieldValue('satuan', val.satuan);
              setFieldValue('harga_satuan', val.harga_satuan);
            }}
            loading={loading.barangJadi}
            error={errors.id_barang_jadi && touched.id_barang_jadi && true}
          />
        </td>
        <td width="100px" className="p-0 align-middle">
          <input
            type="text"
            name="qty"
            placeholder="Masukan qty"
            className={`form-control form-control-sm ${
              errors.qty ? 'is-invalid' : ''
            }`}
            value={values.qty}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue('qty', value);
            }}
          />
        </td>
        <td className="text-right">{values.satuan ?? ''}</td>
        <td className="text-right">
          {RupiahConvert(parseInt(values.harga_satuan ?? 0).toString()).detail}
        </td>
        <td className="text-right">
          {
            RupiahConvert(
              parseInt(
                parseInt(values.qty ?? 0) *
                  parseInt(values.harga_satuan ?? 0) || 0
              ).toString()
            ).detail
          }
        </td>
        {TYPE !== 'DETAIL' && (
          <td className="p-0">
            <div className="m-1">
              <ActionButton
                size="sm btn-block"
                text={<IoAddOutline />}
                onClick={handleSubmit}
              />
            </div>
          </td>
        )}
      </tr>
    );
  };

  const TableSection = () => {
    const generateTotal = () => {
      const grandTotal = dataBarangJadi.reduce((prevValue, currentValue) => {
        const checkQty = currentValue?.qty ? parseFloat(currentValue.qty) : '0';
        const checkSatuan = currentValue?.harga_satuan
          ? parseInt(currentValue.harga_satuan)
          : '0';
        const subTotal = parseInt(checkQty * checkSatuan);
        const total = parseInt(prevValue + subTotal);

        return total;
      }, 0);

      return RupiahConvert(grandTotal.toString()).detail;
    };

    const DataTable = ({ val, index }) => {
      const checkQty = val?.qty ? parseFloat(val.qty) : '0';
      const checkSatuan = val?.harga_satuan ? parseInt(val.harga_satuan) : '0';
      const subTotal = parseInt(checkQty * checkSatuan);

      const StatusAnalisaButton = ({ is_edit = false }) => {
        return (
          <ActionButton
            disable
            variant={is_edit ? 'outline-success' : 'outline-danger'}
          >
            {is_edit ? (
              <IoCheckmark fontSize={12} />
            ) : (
              <IoCloseOutline fontSize={12} />
            )}
          </ActionButton>
        );
      };

      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>{val.kode_item}</td>
          <td>
            <OverlayTrigger
              overlay={
                <Tooltip>Klik untuk melihat & mengelola barang jadi</Tooltip>
              }
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIndexAnalisa(index);
                  setProcessedData({
                    ...val,
                    index: index,
                  });
                  setModalConfig({
                    show: true,
                    type: 'analisa',
                    title: (
                      <span className="text-primary">
                        Analisa Barang Jadi RAP
                      </span>
                    ),
                  });
                }}
              >
                {val.nama_item}
              </a>
            </OverlayTrigger>
          </td>
          <td className="text-center">
            <StatusAnalisaButton is_edit={val.is_edit} />
          </td>
          <td>{val.qty}</td>
          <td>{val.satuan}</td>
          <td className="text-right">
            {RupiahConvert(String(val?.harga_satuan ?? 0)).detail}
          </td>
          <td className="text-right">
            {RupiahConvert(String(subTotal ?? 0)).detail}
          </td>
          {TYPE !== 'DETAIL' && (
            <td>
              <div className="btn-group p-0">
                <ActionButton
                  size="sm"
                  text={<IoOptionsOutline />}
                  tooltip={true}
                  tooltipText="Detail & kelola barang jadi"
                  onClick={() => {
                    setIndexAnalisa(index);
                    setProcessedData({
                      ...val,
                      index: index,
                    });
                    setModalConfig({
                      show: true,
                      type: 'analisa',
                      title: (
                        <span className="text-primary">
                          Analisa Barang Jadi RAP
                        </span>
                      ),
                    });
                  }}
                />
                <ActionButton
                  size="sm"
                  variant="success"
                  text={<IoPencilOutline />}
                  tooltip={true}
                  tooltipText="Ubah data"
                  onClick={() => {
                    setProcessedData({
                      ...val,
                      index: index,
                    });
                    setModalConfig({
                      show: true,
                      type: 'edit',
                      title: <span className="text-success">Ubah Data</span>,
                    });
                  }}
                />
                <ActionButton
                  size="sm"
                  variant="danger"
                  text={<IoTrashOutline />}
                  tooltip={true}
                  tooltipText="Hapus data"
                  onClick={() => {
                    setProcessedData({
                      ...val,
                      index: index,
                    });
                    setModalConfig({
                      show: true,
                      type: 'delete',
                      title: <span className="text-danger">Hapus Data</span>,
                    });
                  }}
                />
              </div>
            </td>
          )}
        </tr>
      );
    };

    return (
      <>
        <div className="py-2 p-1">
          <b>List Item RAP</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <table
          className="table table-sm table-bordered"
          style={{ fontSize: '14px' }}
        >
          <thead className="text-center bg-light">
            <tr>
              <th className="align-middle" style={{ width: '10px' }}>
                No.
              </th>
              <th className="align-middle" style={{ width: '100px' }}>
                Kode Barang Jadi
              </th>
              <th className="align-middle" style={{ width: '300px' }}>
                Barang Jadi
              </th>
              <th style={{ width: '10px' }} className="align-middle">
                Status Analisa
              </th>
              <th className="align-middle">Qty</th>
              <th className="align-middle">Satuan</th>
              <th className="align-middle">Harga Satuan</th>
              <th className="align-middle">Sub Total</th>
              {TYPE !== 'DETAIL' && (
                <th className="align-middle" style={{ width: '50px' }}>
                  Aksi
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {/* INPUT FORM */}
            {TYPE !== 'DETAIL' && (
              <FormSection
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
              />
            )}

            {dataBarangJadi?.map((val, index) =>
              val !== null ? <DataTable index={index} val={val} /> : null
            )}
            <tr className="bg-light">
              <td colSpan={6} className="text-right">
                <b>Total :</b>
              </td>
              <td className="text-right text-nowrap">
                <b>{generateTotal()}</b>
              </td>
              {TYPE !== 'DETAIL' && <td></td>}
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const EditModal = () => {
    const [loading, setLoading] = useState({ barangJadi: false });

    const formInitialValues = {
      nama_jenis: processedData.nama_jenis,
      id_barang_jadi: processedData.id_barang_jadi,
      nama_item: processedData.nama_item,
      kode_item: processedData.kode_item,
      qty: processedData.qty,
      satuan: processedData.satuan,
      harga_satuan: processedData.harga_satuan,
      ref_uid: processedData.ref_uid,
    };

    const formValidationSchema = Yup.object().shape({
      id_barang_jadi: Yup.string().required(),
      qty: Yup.string().required(),
    });

    const formSubmitHandler = (values) => {
      const updateIndex = processedData.index;
      const finalData = dataBarangJadi.map((val, index) =>
        index.toString() === updateIndex.toString() ? values : val
      );

      setTimeout(() => {
        setDataBarangJadi(finalData);
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });
      }, 300);
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const {
      values,
      errors,
      touched,
      isSubmitting,
      setFieldValue,
      handleSubmit,
    } = formik;

    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, '');
      const convert = newValue.substring(0, 5);

      return convert;
    };
    return (
      <>
        <Modal.Body>
          <SelectSearch
            label="Barang Jadi"
            placeholder="Pilih barang jadi"
            defaultValue={
              values.id_barang_jadi
                ? { value: values.id_barang_jadi, label: values.nama_item }
                : ''
            }
            option={dataSelectAnalisaBarangJadi?.map((val) => {
              return {
                value: val.id_barang_jadi,
                label: val.nama_item,
                kode_item: val.kode_item,
                satuan: val.nama_satuan,
                harga_satuan: val.total_analisa,
              };
            })}
            onChange={(val) => {
              setFieldValue('id_barang_jadi', val.value);
              setFieldValue('nama_item', val.label);
              setFieldValue('kode_item', val.kode_item);
              setFieldValue('satuan', val.satuan);
              setFieldValue('harga_satuan', val.harga_satuan);
              setFieldValue('ref_uid', '');
            }}
            loading={loading.barangJadi}
            error={errors.id_barang_jadi && touched.id_barang_jadi && true}
          />
          <Row>
            <Col>
              <Input
                label="Qty"
                placeholder="Qty"
                value={values.qty}
                onChange={(e) => {
                  const value = e.target.value;
                  const convert = decimalConvert(value);
                  setFieldValue('qty', convert);
                }}
                error={errors.qty && touched.qty && true}
              />
            </Col>
            <Col>
              <Input
                label="Satuan"
                placeholder="Satuan"
                value={values.satuan}
                readOnly
              />
            </Col>
          </Row>
          <Input
            label="Harga Satuan"
            placeholder="Harga satuan"
            value={
              values.harga_satuan
                ? RupiahConvert(values.harga_satuan?.toString()).detail
                : ''
            }
            readOnly
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant="success"
            text="Ubah Data"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );
  };

  const DeleteModal = () => {
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteDataHandler = () => {
      const index = processedData.index;

      setIsDeleting(true);

      const finalData = dataBarangJadi.map((val, i) => {
        if (i === index) {
          return null;
        }

        return val;
      });

      const finalDataAnalisa = dataAnalisaBarangJadi.map((val, i) => {
        if (i === index) {
          return null;
        }

        return val;
      });

      setTimeout(() => {
        setDataBarangJadi(finalData);
        setDataAnalisaBarangJadi(finalDataAnalisa);

        setModalConfig({
          ...modalConfig,
          show: false,
        });
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil dihapus!',
        });
        setIsDeleting(false);
      }, 300);
    };

    return (
      <>
        <Modal.Body className="text-center">
          <h5>
            <span>Hapus data dengan nama item bahan: </span>
            <br />
            <b>{processedData.nama_item}</b>
          </h5>
          <small className="text-danger">
            Data yang dihapus tidak dapat dikembalikan!
          </small>
          <div className="d-flex justify-content-center mt-3">
            <ActionButton
              variant="outline-secondary"
              className="m-1"
              text="Batal"
              onClick={() =>
                setModalConfig({
                  show: false,
                  type: '',
                  title: '',
                })
              }
            />
            <ActionButton
              variant="danger"
              className="m-1"
              text="Hapus Data"
              loading={isDeleting}
              onClick={deleteDataHandler}
            />
          </div>
        </Modal.Body>
      </>
    );
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <TableSection
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
      />

      {/* Modal Analisa Barang Jadi */}
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalConfig.type === 'analisa' ? (
            TYPE === 'DETAIL' ? (
              <AnalisaBarangJadiRAPDetail
                processedData={processedData}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                alertConfig={alertConfig}
                modalConfig={modalConfig}
                setAlertConfig={setAlertConfig}
                setModalConfig={setModalConfig}
              />
            ) : (
              <AnalisaBarangJadiRAP
                processedData={processedData}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                alertConfig={alertConfig}
                modalConfig={modalConfig}
                setAlertConfig={setAlertConfig}
                setModalConfig={setModalConfig}
              />
            )
          ) : modalConfig.type === 'edit' ? (
            <EditModal />
          ) : (
            <DeleteModal />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(TableBarangJadi);
