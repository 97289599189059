import { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import ReactSelect from 'react-select';
import {
  IoPencilOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoTrashOutline,
  IoAdd,
} from 'react-icons/io5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Input,
  Table,
  Th,
  ThFixed,
  Td,
  DeleteModal,
} from 'components';
import { RupiahConvert } from 'utilities';

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, '');
  const convert = newValue.substring(0, 10);

  return convert;
};

const sumTotal = ({ qty, price, konstanta }) =>
  parseInt(
    parseFloat(qty ?? 0) * parseFloat(price ?? 0) * parseFloat(konstanta ?? 0)
  );

const sumGrandTotal = (data) =>
  data.reduce(
    (prev, current) =>
      prev +
      sumTotal({
        qty: current.qty,
        price: current.unit_price,
        konstanta: current.konstanta,
      }),
    0
  );

const SelectSearch = (props) => {
  if (props.loading) {
    return (
      <ReactSelect
        {...props}
        classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
        noOptionsMessage={() => 'Tidak ada data'}
        placeholder="Memuat data . . ."
        menuShouldBlockScroll={true}
        menuPosition="fixed"
        isDisabled={true}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: 28,
            maxHeight: 31,
            fontSize: 14,
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 5,
            margin: 0,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
            paddingLeft: 5,
            paddingRight: 5,
          }),
          menu: (base) => ({
            ...base,
            fontSize: 13,
          }),
        }}
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
      noOptionsMessage={() => 'Tidak ada data'}
      menuShouldBlockScroll={true}
      menuPosition="fixed"
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
          zIndex: 9999,
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
    />
  );
};

const TableRow = ({
  index,
  data,
  dataDropdownItem,
  dataDropdownSatuan,
  entireData,
  setEntireData,
  action = 'view',
  modalConfig,
  setModalConfig,
  dropdownLoading,
  readOnly,
}) => {
  const [currentAction, setCurrentAction] = useState(action);
  const formInitialValues = {
    id_uraian: '',
    uraian: '',
    qty: 0,
    id_satuan: '',
    kode_satuan: '',
    unit_price: 0,
    konstanta: 1,
  };

  const formValidationSchema = Yup.object().shape({
    id_uraian: Yup.string().required(),
    qty: Yup.string().required(),
    id_satuan: Yup.string().required(),
    kode_satuan: Yup.string().required(),
    unit_price: Yup.string().required(),
    konstanta: Yup.string().required(),
  });

  const formSubmitHandler = (values, { resetForm }) => {
    if (currentAction === 'update') {
      let newData = [...entireData];
      newData[index] = values;

      setEntireData(newData);
      setCurrentAction('view');
    } else {
      setEntireData([...entireData, { ...values }]);

      resetForm();
    }
  };

  const { values, errors, touched, setValues, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

  useEffect(() => {
    setValues({
      id_uraian: data?.id_uraian ?? '',
      uraian: data?.uraian ?? '',
      qty: parseFloat(data?.qty ?? 0),
      id_satuan: data?.id_satuan ?? null,
      kode_satuan: data?.kode_satuan ?? '',
      unit_price: parseFloat(data?.unit_price ?? 0),
      konstanta: parseFloat(data?.konstanta ?? 1),
    });
  }, [currentAction]);

  if (currentAction === 'view') {
    return (
      <>
        <Td>{index + 1}</Td>
        <Td>{data.uraian}</Td>
        <Td textRight>{data.qty}</Td>
        <Td>{data.kode_satuan}</Td>
        <Td textRight>
          {RupiahConvert(data?.unit_price?.toString() ?? '0').detail}
        </Td>
        <Td textRight>{data.konstanta}</Td>
        <Td textRight>
          {
            RupiahConvert(
              sumTotal({
                qty: data.qty,
                price: data.unit_price,
                konstanta: data.konstanta,
              }).toString()
            ).detail
          }
        </Td>
        {!readOnly && (
          <Td>
            <ButtonGroup size="sm">
              <ActionButton
                variant="success"
                onClick={() => setCurrentAction('update')}
              >
                <IoPencilOutline />
              </ActionButton>
              <ActionButton
                variant="danger"
                onClick={() =>
                  setModalConfig({
                    show: true,
                    index: index,
                  })
                }
              >
                <IoTrashOutline />
              </ActionButton>
            </ButtonGroup>
          </Td>
        )}
      </>
    );
  }

  return (
    <>
      {/* No */}
      <Td>{action === 'create' ? '' : index + 1}</Td>

      {/* Item Pekerjaan */}
      <Td className="p-0">
        <SelectSearch
          key={values.id_uraian}
          placeholder="Pilih item"
          defaultValue={
            values.id_uraian
              ? dataDropdownItem?.find((val) => val.value === values.id_uraian)
              : ''
          }
          options={dataDropdownItem}
          onChange={(val) =>
            setValues({
              ...values,
              id_uraian: val.value,
              uraian: val.label,
              id_satuan: val.id_satuan,
              kode_satuan: val.kode_satuan,
            })
          }
          loading={dropdownLoading}
          error={errors.id_uraian && touched.id_uraian}
        />
      </Td>

      {/* Volume */}
      <Td className="p-0">
        <Input
          noPadding
          value={values?.qty ?? 0}
          onChange={(e) =>
            setFieldValue('qty', decimalConvert(e?.target?.value ?? '0'))
          }
          error={!!errors.qty && !!touched.qty}
        />
      </Td>

      {/* Satuan */}
      <Td className="p-0">
        <SelectSearch
          key={values.id_satuan}
          placeholder="Pilih satuan"
          defaultValue={
            values.id_satuan
              ? dataDropdownSatuan?.find(
                  (val) => val.value === values.id_satuan
                )
              : ''
          }
          options={dataDropdownSatuan}
          onChange={(val) =>
            setValues({
              ...values,
              id_satuan: val.value,
              kode_satuan: val.label,
            })
          }
          loading={dropdownLoading}
          error={Boolean(errors.id_satuan && touched.id_satuan)}
        />
      </Td>

      {/* Harga Satuan */}
      <Td className="p-0">
        <Input
          noPadding
          value={
            RupiahConvert(parseFloat(values?.unit_price ?? 0).toString()).detail
          }
          onChange={(e) => {
            const value = Boolean(RupiahConvert(e.target.value).default)
              ? RupiahConvert(e.target.value).default
              : '0';

            setFieldValue('unit_price', value);
          }}
          error={!!errors.unit_price && !!touched.unit_price}
        />
      </Td>

      {/* Konstanta */}
      <Td className="p-0">
        <Input
          noPadding
          value={values?.konstanta ?? 0}
          onChange={(e) =>
            setFieldValue('konstanta', decimalConvert(e?.target?.value ?? '0'))
          }
          error={!!errors.konstanta && !!touched.konstanta}
        />
      </Td>

      {/* Jumlah */}
      <Td textRight>
        {
          RupiahConvert(
            sumTotal({
              qty: values.qty,
              price: values.unit_price,
              konstanta: values.konstanta,
            }).toString()
          ).detail
        }
      </Td>

      {/* Aksi */}
      {!readOnly && (
        <Td>
          {currentAction === 'create' ? (
            <ActionButton className="col" size="sm" onClick={handleSubmit}>
              <IoAdd />
            </ActionButton>
          ) : (
            <ButtonGroup size="sm">
              <ActionButton variant="outline-success" onClick={handleSubmit}>
                <IoCheckmarkOutline />
              </ActionButton>
              <ActionButton
                variant="outline-danger"
                onClick={() => setCurrentAction('view')}
              >
                <IoCloseOutline />
              </ActionButton>
            </ButtonGroup>
          )}
        </Td>
      )}
    </>
  );
};

export const TableItemKontrak = ({
  dataItem,
  setDataItem,
  dataInfo,
  readOnly,
}) => {
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [dataItemDefault, setDataItemDefault] = useState([]);
  const [dataDropdownSatuan, setDataDropdownSatuan] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    index: {},
  });

  const sumPPN = () => {
    const total = sumGrandTotal(dataItem);
    const totalPPN = parseInt(parseFloat(dataInfo.ppn / 1000) * total);
    const totalSetelahPPN = totalPPN + total;

    return {
      totalPPN: parseInt(totalPPN),
      totalSetelahPPN: parseInt(totalSetelahPPN),
    };
  };

  useEffect(() => {
    setDataItemDefault(dataItem);

    return () => {
      setDropdownLoading(true);
    };
  }, []);

  return (
    <>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th style={{ minWidth: 200 }}>Item Pekerjaan</Th>
            <Th style={{ width: 80 }}>Volume</Th>
            <Th style={{ minWidth: 100 }}>Satuan</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 80 }}>Konstanta</Th>
            <Th style={{ width: 150 }}>Jumlah</Th>
            {!readOnly && <ThFixed>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {!readOnly && (
            <TableRow
              action="create"
              data={{}}
              dataDropdownItem={dataItemDefault.map((val) => ({
                label: val.uraian,
                value: val.id_uraian,
                id_satuan: val.id_satuan,
                kode_satuan: val.kode_satuan,
              }))}
              dataDropdownSatuan={dataDropdownSatuan}
              entireData={dataItem}
              setEntireData={setDataItem}
              modalConfig={modalConfig}
              setModalConfig={setModalConfig}
              dropdownLoading={dropdownLoading}
            />
          )}
          {dataItem.map((val, index) => (
            <tr key={index}>
              <TableRow
                index={index}
                data={val}
                dataDropdownItem={dataItemDefault.map((val) => ({
                  label: val.uraian,
                  value: val.id_uraian,
                  id_satuan: val.id_satuan,
                  kode_satuan: val.kode_satuan,
                }))}
                dataDropdownSatuan={dataDropdownSatuan}
                entireData={dataItem}
                setEntireData={setDataItem}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig}
                dropdownLoading={dropdownLoading}
                readOnly={readOnly}
              />
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <Td textRight colSpan={6}>
              <b>TOTAL HARGA</b>
            </Td>
            <Td textRight>
              <b>{RupiahConvert(sumGrandTotal(dataItem).toString()).detail}</b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>
          <tr>
            <Td textRight colSpan={6}>
              <b>PPN ({parseFloat(dataInfo.ppn).toPrecision()} %)</b>
            </Td>
            <Td textRight>
              <b>{RupiahConvert(sumPPN().totalPPN.toString()).detail}</b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>
          <tr>
            <Td textRight colSpan={6}>
              <b>TOTAL HARGA SETELAH PPN</b>
            </Td>
            <Td textRight>
              <b>{RupiahConvert(sumPPN().totalSetelahPPN.toString()).detail}</b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>
        </tfoot>
      </Table>

      <DeleteModal
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false, index: {} })}
        onConfirm={() => {
          const newData = dataItem.filter((_v, i) => i !== modalConfig.index);
          setDataItem(newData);
          setModalConfig({ show: false, index: '' });
        }}
      />
    </>
  );
};
