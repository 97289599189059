import React, { useState, useContext } from 'react';
import { Table, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { RupiahConvert } from 'utilities';
import { RAPContext } from '../RAPContext';
import AnalisaBarangJadiRAPDetail from '../AnalisaBarangJadiRAPDetail';
import htmlParser from 'html-react-parser';
import AnalisaBarangJadiSPK from '../AnalisaBarangJadiSPK';

const TableListSPK = ({
  dataSPK,
  dataItemSPK,
  dataItemPrelim,
  dataBarangJadi,
  setDataBarangJadi,
}) => {
  const {
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    setIndexAnalisa,
    type: TYPE,
  } = useContext(RAPContext);

  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const ppn = dataSPK.ppn ?? 0;
  const diskon = dataSPK.diskon ?? 0;
  const uang_muka = dataSPK.uang_muka ?? 0;

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        padding: 7,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  const getTotal = (qty, harga) => {
    const checkQty = qty ?? 0;
    const checkHarga = harga ?? 0;
    const total = parseFloat(checkQty) * parseInt(checkHarga);

    return parseInt(total);
  };

  const getTotalPrelim = dataItemPrelim.reduce((prev, current) => {
    const checkQty = current.qty_analisa ?? 0;
    const checkHarga = current.unit_price ?? 0;
    const total = parseFloat(checkQty) * parseInt(checkHarga);

    return parseInt(total + prev);
  }, 0);

  const getGrandTotal = () => {
    const total = dataItemSPK.reduce((prev, current) => {
      const checkQty = current.qty_spk ?? current.qty_rab;
      const checkHarga = current.harga_rate ?? current.rounded;
      const total = parseFloat(checkQty) * parseInt(checkHarga);

      return parseInt(total + prev);
    }, 0);
    const totalPrelim = dataItemPrelim.reduce((prev, current) => {
      const checkQty = current.qty_analisa ?? 0;
      const checkHarga = current.unit_price ?? 0;
      const total = parseFloat(checkQty) * parseInt(checkHarga);

      return parseInt(total + prev);
    }, 0);
    const totalWithPrelim = total + totalPrelim;
    return totalWithPrelim;
  };
  const getGrandTotalAfterDiskon = () => {
    const grandTotal = getGrandTotal();
    const afterDiskon = Math.round((grandTotal / 100) * diskon);
    const totalAfterDiskon = parseInt(grandTotal) - afterDiskon;

    return { after_diskon: afterDiskon, total: totalAfterDiskon };
  };

  const getGrandTotalAfterPPN = () => {
    const grandTotal = getGrandTotalAfterDiskon().total;
    const afterPPN = parseFloat(grandTotal / 100) * parseFloat(ppn);
    const totalAfterPPN = parseInt(grandTotal) + parseInt(afterPPN);

    return { after_ppn: afterPPN, total: totalAfterPPN };
  };

  const getGrandTotalAfterDP = () => {
    const grandTotal = getGrandTotalAfterPPN().total;
    const afterDP = (grandTotal / 100) * ppn;
    const totalAfterDP = parseInt(grandTotal) - parseInt(afterDP);

    return { after_DP: afterDP, total: totalAfterDP };
  };

  return (
    <div className="pb-3">
      <div className="p-1">
        <b>List Item SPK</b>
      </div>
      <Table bordered>
        <thead className="bg-light">
          <tr>
            <Th className="text-center" rowSpan={2} style={{ width: 30 }}>
              No.
            </Th>
            <Th className="text-center p-0" rowSpan={2} style={{ width: 100 }}>
              Kode Item Barang Jadi
            </Th>
            <Th className="text-center" rowSpan={2}>
              Nama Item Barang Jadi
            </Th>
            <Th className="text-center p-0" colSpan={2}>
              Volume
            </Th>
            <Th className="text-center" rowSpan={2}>
              Uraian
            </Th>
            <Th className="text-center" rowSpan={2}>
              Harga Rate
            </Th>
            <Th className="text-center" rowSpan={2}>
              Sub Total Rate
            </Th>
          </tr>
          <tr>
            <Th className="text-center p-0">Qty</Th>
            <Th className="text-center p-0">Unit</Th>
          </tr>
        </thead>
        <tbody>
          {dataItemSPK.map((val, index) => (
            <tr key={index}>
              <Td className="text-center">{index + 1}</Td>
              <Td className="px-1">{val.kode_item ?? '-'}</Td>
              <Td className="px-1">
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      Klik untuk melihat & mengelola barang jadi
                    </Tooltip>
                  }
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIndexAnalisa(index);
                      setProcessedData({
                        ...val,
                        index: index,
                      });
                      setModalConfig({
                        show: true,
                        type: 'analisa',
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi RAP
                          </span>
                        ),
                      });
                    }}
                  >
                    {val.nama_item}
                  </a>
                </OverlayTrigger>
              </Td>
              <Td className="px-1 text-right">
                {val.qty_spk ? val.qty_spk : val.qty_rab ? val.qty_rab : '-'}
              </Td>
              <Td className="px-1">{val.nama_satuan ?? '-'}</Td>
              <Td className="px-1">{htmlParser(val.uraian ?? '<p></p>')}</Td>
              <Td style={{ width: '160px' }} className="px-1 text-right">
                {val.harga_rate
                  ? RupiahConvert(parseInt(val.harga_rate).toString()).detail
                  : val.rounded
                  ? RupiahConvert(parseInt(val.rounded).toString()).detail
                  : 'Rp. 0'}
              </Td>
              <Td style={{ width: '160px' }} className="px-1 text-right">
                {
                  RupiahConvert(getTotal(val.qty_rab, val.rounded).toString())
                    .detail
                }
              </Td>
            </tr>
          ))}
          <tr>
            <Td className="text-center">1</Td>
            <Td className="px-1" colSpan="2">
              Prelim
            </Td>
            <Td className="px-1 text-right">1</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">-</Td>
            <Td style={{ width: '160px' }} className="px-1 text-right">
              {RupiahConvert(parseInt(getTotalPrelim).toString()).detail}
            </Td>
            <Td style={{ width: '160px' }} className="px-1 text-right">
              {RupiahConvert(parseInt(getTotalPrelim).toString()).detail}
            </Td>
          </tr>
        </tbody>
        <tfoot>
          <tr className="bg-light">
            <Th colSpan={7} className="text-right p-1">
              Total
            </Th>
            <Th className="text-right p-1">
              {RupiahConvert(getGrandTotal().toString()).detail}
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="text-right p-1">
              Diskon ({parseFloat(diskon).toPrecision()} %)
            </Th>
            <Th className="text-right p-1">
              {
                RupiahConvert(
                  getGrandTotalAfterDiskon().after_diskon.toString()
                ).detail
              }
            </Th>
          </tr>
          <tr className="bg-light">
            <Th colSpan={7} className="text-right p-1">
              Total Setelah Diskon
            </Th>
            <Th className="text-right p-1">
              {
                RupiahConvert(getGrandTotalAfterDiskon().total.toString())
                  .detail
              }
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="text-right p-1">
              PPN ({parseFloat(ppn).toPrecision()} %)
            </Th>
            <Th className="text-right p-1">
              {
                RupiahConvert(
                  String(parseInt(getGrandTotalAfterPPN().after_ppn))
                ).detail
              }
            </Th>
          </tr>
          <tr className="bg-light">
            <Th colSpan={7} className="text-right p-1">
              Total Setelah Pajak
            </Th>
            <Th className="text-right p-1">
              {RupiahConvert(getGrandTotalAfterPPN().total.toString()).detail}
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="text-right p-1">
              Uang Muka ({parseFloat(ppn).toPrecision()} %)
            </Th>
            <Th className="text-right p-1">
              {
                RupiahConvert(String(parseInt(getGrandTotalAfterDP().after_DP)))
                  .detail
              }
            </Th>
          </tr>
          <tr className="bg-light">
            <Th colSpan={7} className="text-right p-1">
              Sisa
            </Th>
            <Th className="text-right p-1">
              {RupiahConvert(getGrandTotalAfterDP().total.toString()).detail}
            </Th>
          </tr>
        </tfoot>
      </Table>
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AnalisaBarangJadiSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
            type="add"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TableListSPK;
