import { useContext, useState } from 'react';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import {
  ActionButton,
  DataStatus,
  DeleteButton,
  Table,
  Td,
  Th,
  ThFixed,
  UpdateButton,
} from 'components';
import {
  ModalFormItemBarang,
  ModalFormTransferSisaProduksi,
} from '../Components';
import {
  TransferSisaProduksiContext,
  withDropdownTransferSisaProduksiContextProvider,
} from '../Contexts';
import { RupiahConvert } from 'utilities';

export const TableDataTransferSisaProduksi =
  withDropdownTransferSisaProduksiContextProvider(({ action = 'detail' }) => {
    const { listTransferSisa, setListTransferSisa } = useContext(
      TransferSisaProduksiContext
    );
    const [modal, setModal] = useState({
      show: false,
      type: 'sisa', // sisa | barang
      action: 'create', // create | update
      data: null,
    });

    return (
      <>
        <Row
          className="mb-2 mt-5 d-flex justify-space-between align-items-center"
          style={{ fontSize: '.9rem' }}
        >
          <Col>Data Transfer Sisa Produksi</Col>
          {action === 'form' && (
            <Col className="d-flex justify-content-end">
              <ActionButton
                size="sm"
                text="Tambah Data"
                onClick={() =>
                  setModal({
                    show: true,
                    type: 'sisa',
                    action: 'create',
                    data: null,
                  })
                }
              />
            </Col>
          )}
        </Row>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <Th>Item Sisa Produksi</Th>
              <Th>Qty. Transfer</Th>
              <Th>Gudang Tujuan</Th>
              <Th>Nilai Persediaan Per Satuan</Th>
              {action === 'form' && <Th width={10}>Aksi</Th>}
            </tr>
          </thead>
          <tbody>
            {listTransferSisa?.length ? (
              listTransferSisa.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>
                    {val.qty_transfer ?? 0} {val.nama_satuan}
                  </Td>
                  <Td>{val.nama_gudang_tujuan}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(String(parseFloat(val.nilai_persediaan)))
                        .detail
                    }
                  </Td>
                  {action === 'form' && (
                    <Td>
                      <ButtonGroup>
                        <UpdateButton
                          className="m-0"
                          onClick={() =>
                            setModal({
                              show: true,
                              type: 'sisa',
                              action: 'update',
                              data: { ...val, index },
                            })
                          }
                        />
                        <DeleteButton
                          className="m-0"
                          onClick={() =>
                            setListTransferSisa((prev) =>
                              prev.toSpliced(index, 1)
                            )
                          }
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={6}>
                  <DataStatus text="Tidak ada data " />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>

        {modal.show && modal.type === 'sisa' && (
          <ModalFormTransferSisaProduksi
            data={modal.data}
            action={modal.action}
            show={modal.show && modal.type === 'sisa'}
            onHide={() => setModal({ show: false, type: 'sisa', data: null })}
            onClickShowFormBarang={() =>
              setModal({ show: true, type: 'barang' })
            }
          />
        )}

        <ModalFormItemBarang
          show={modal.show && modal.type === 'barang'}
          onHide={() => setModal({ show: true, type: 'sisa' })}
        />
      </>
    );
  });
