import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ActionButton, InputCurrency, SelectSearch, Td, Tr } from 'components';
import { DropdownPermintaanProduksiContext } from '../../Context';
import { kalkulasiLuas, kalkulasiVolume } from '../../Helpers';

export const InputPlywood = ({ type = 'create', data, onSubmit, onCancel }) => {
  const {
    dropdownDeskripsi,
    dropdownSpekPlywood,
    dropdownTipeFinishing,
    dropdownTipeSisi,
  } = useContext(DropdownPermintaanProduksiContext);
  const isCreate = type === 'create';
  const width = 70;

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        setValues,
        setFieldValue,
        handleSubmit,
      }) => {
        const totalVolume = kalkulasiVolume({
          qty_raw: values.qty_raw,
          t_raw: values.t_raw,
          w_raw: values.w_raw,
          l_raw: values.l_raw,
        });

        return (
          <Tr>
            <Td></Td>

            <Td>
              <SelectSearch
                placeholder="Pilih Deskripsi"
                width={180}
                name="id_deskripsi"
                option={dropdownDeskripsi?.data ?? []}
                loading={dropdownDeskripsi.isLoading}
                defaultValue={dropdownDeskripsi?.data?.find(
                  ({ id_deskripsi_bahan_baku }) =>
                    values.id_deskripsi === id_deskripsi_bahan_baku
                )}
                onChange={(val) =>
                  setValues((prev) => ({
                    ...prev,
                    id_deskripsi: val.id_deskripsi_bahan_baku,
                    deskripsi: val.nama_deskripsi_bahan_baku,
                  }))
                }
                error={errors.id_deskripsi && touched.id_deskripsi}
              />
            </Td>

            <Td>
              <SelectSearch
                placeholder="Pilih Spesifikasi Kayu"
                width={180}
                name="id_item_buaso"
                option={dropdownSpekPlywood?.data ?? []}
                loading={dropdownSpekPlywood?.isLoading}
                defaultValue={dropdownSpekPlywood?.data?.find(
                  ({ id_item_buaso }) => values.id_item_buaso === id_item_buaso
                )}
                onChange={async ({ id_item_buaso, nama_item }) =>
                  setValues((prev) => ({
                    ...prev,
                    id_item_buaso,
                    nama_item,
                  }))
                }
                error={errors.id_item_buaso && touched.id_item_buaso}
              />
            </Td>

            <Td>
              <SelectSearch
                placeholder="Pilih Tipe Finishing "
                width={180}
                name="id_finishing_barang_jadi"
                option={dropdownTipeFinishing?.data ?? []}
                loading={dropdownTipeFinishing?.isLoading}
                defaultValue={dropdownTipeFinishing?.data?.find(
                  ({ id_finishing_barang_jadi }) =>
                    values.id_finishing_barang_jadi === id_finishing_barang_jadi
                )}
                onChange={({
                  id_finishing_barang_jadi,
                  nama_finishing_barang_jadi,
                }) =>
                  setValues((prev) => ({
                    ...prev,
                    id_finishing_barang_jadi,
                    nama_finishing_barang_jadi,
                  }))
                }
                error={
                  errors.id_finishing_barang_jadi &&
                  touched.id_finishing_barang_jadi
                }
              />
            </Td>

            <Td>
              <SelectSearch
                placeholder="Pilih Tipe Sisi"
                width={180}
                name="id_tipe_sisi"
                option={dropdownTipeSisi?.data ?? []}
                loading={dropdownTipeSisi?.isLoading}
                defaultValue={dropdownTipeSisi?.data?.find(
                  ({ id_tipe_sisi }) => values.id_tipe_sisi === id_tipe_sisi
                )}
                onChange={({ id_tipe_sisi, nama_tipe_sisi }) =>
                  setValues((prev) => ({
                    ...prev,
                    id_tipe_sisi,
                    nama_tipe_sisi,
                  }))
                }
                error={errors.id_tipe_sisi && touched.id_tipe_sisi}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="qty_final"
                value={values.qty_final}
                onChange={(value) => setFieldValue('qty_final', value)}
                error={errors.qty_final && touched.qty_final}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="t_final"
                value={values.t_final}
                onChange={(value) => setFieldValue('t_final', value)}
                error={errors.t_final && touched.t_final}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="w_final"
                value={values.w_final}
                onChange={(value) => setFieldValue('w_final', value)}
                error={errors.w_final && touched.w_final}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="l_final"
                value={values.l_final}
                onChange={(value) => setFieldValue('l_final', value)}
                error={errors.l_final && touched.l_final}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="qty_raw"
                value={values.qty_raw}
                onChange={(value) => setFieldValue('qty_raw', value)}
                error={errors.qty_raw && touched.qty_raw}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="t_raw"
                value={values.t_raw}
                onChange={(value) => setFieldValue('t_raw', value)}
                error={errors.t_raw && touched.t_raw}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="w_raw"
                value={values.w_raw}
                onChange={(value) => setFieldValue('w_raw', value)}
                error={errors.w_raw && touched.w_raw}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="l_raw"
                value={values.l_raw}
                onChange={(value) => setFieldValue('l_raw', value)}
                error={errors.l_raw && touched.l_raw}
              />
            </Td>

            <Td>
              <InputCurrency
                readOnly
                prefix=""
                style={{ width }}
                className="text-right"
                name="luas"
                value={kalkulasiLuas({
                  id_tipe_sisi: values.id_tipe_sisi,
                  qty: values.qty_raw,
                  t: values.t_raw,
                  w: values.w_raw,
                  l: values.l_raw,
                })}
              />
            </Td>

            <Td>
              <InputCurrency
                readOnly
                prefix=""
                style={{ width }}
                className="text-right"
                decimalScale={6}
                name="volume"
                value={totalVolume}
              />
            </Td>

            <Td>
              <InputCurrency
                style={{ width: 150 }}
                className="text-right"
                name="unit_price"
                value={values.unit_price}
                onChange={(value) => setFieldValue('unit_price', value)}
                error={errors.unit_price && touched.unit_price}
              />
            </Td>

            <Td>
              <InputCurrency
                prefix=""
                style={{ width }}
                className="text-right"
                name="konstanta"
                value={values.konstanta}
                onChange={(value) => setFieldValue('konstanta', value)}
                error={errors.konstanta && touched.konstanta}
              />
            </Td>

            <Td>
              <InputCurrency
                readOnly
                style={{ width: 150 }}
                className="text-right"
                name="subtotal"
                value={calcSubtotalPW(values)}
              />
            </Td>

            <Td>
              {isCreate ? (
                <ActionButton
                  size="sm"
                  variant="primary"
                  className="btn-block"
                  text={<IoAddOutline />}
                  onClick={handleSubmit}
                />
              ) : (
                <ButtonGroup>
                  <ActionButton
                    size="sm"
                    variant="outline-success"
                    text={<IoCheckmarkOutline />}
                    onClick={handleSubmit}
                  />
                  <ActionButton
                    size="sm"
                    variant="outline-danger"
                    text={<IoCloseOutline />}
                    onClick={onCancel}
                  />
                </ButtonGroup>
              )}
            </Td>
          </Tr>
        );
      }}
    </Formik>
  );
};

const formInitialValues = (data) => ({
  id_deskripsi: data?.id_deskripsi ?? '',
  deskripsi: data?.deskripsi ?? '',
  id_item_buaso: data?.id_item_buaso ?? '',
  nama_item: data?.nama_item ?? '',
  id_finishing_barang_jadi: data?.id_finishing_barang_jadi ?? '',
  nama_finishing_barang_jadi:
    data?.nama_finishing_barang_jadi ?? 'Tanpa Finishing',
  id_tipe_sisi: data?.id_tipe_sisi ?? '',
  nama_tipe_sisi: data?.nama_tipe_sisi ?? '',
  unit_price: data?.unit_price ?? 0,
  konstanta: parseFloat(data?.konstanta ?? 1),

  qty_raw: parseFloat(data?.qty_raw ?? 0),
  t_raw: parseFloat(data?.t_raw ?? 0),
  w_raw: parseFloat(data?.w_raw ?? 0),
  l_raw: parseFloat(data?.l_raw ?? 0),

  qty_final: parseFloat(data?.qty_final ?? 0),
  t_final: parseFloat(data?.t_final ?? 0),
  w_final: parseFloat(data?.w_final ?? 0),
  l_final: parseFloat(data?.l_final ?? 0),
});

const validationSchema = yup.object().shape({
  id_deskripsi: yup.string().required(),
  id_item_buaso: yup.string().required(),
  id_finishing_barang_jadi: yup.string().required(),
  id_tipe_sisi: yup.string().required(),
  qty_raw: yup.string().required(),
  t_raw: yup.string().required(),
  w_raw: yup.string().required(),
  l_raw: yup.string().required(),
  qty_final: yup.string().required(),
  t_final: yup.string().required(),
  w_final: yup.string().required(),
  l_final: yup.string().required(),
  unit_price: yup.string().required(),
  konstanta: yup.string().required(),
});

export const calcSubtotalPW = (data) =>
  parseFloat(data.qty_raw) * parseFloat(data.unit_price);
