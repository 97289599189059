import { ButtonGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { IoArrowDown } from 'react-icons/io5';
import { Tr, Td, UpdateButton, DeleteButton, ActionButton } from 'components';
import { calcSubtotalLCF } from './InputLCF';

export const RowItemLCF = ({
  index,
  data = {},
  jobOrder,
  readOnly,
  showStatusPermintaan,
  onUpdate,
  onClickJobOrder,
  onDelete,
}) => {
  const toFloatFixed = (value) => (
    <NumberFormat
      value={parseFloat(value)}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={6}
    />
  );

  return (
    <Tr>
      <Td textCenter>{index + 1} </Td>
      <Td className="text-nowrap"> {data.uraian} </Td>
      <Td textRight> {toFloatFixed(data.qty)} </Td>
      <Td className="text-nowrap"> {data.nama_satuan} </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(data.unit_price)}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>
      <Td textRight> {toFloatFixed(data.konstanta)} </Td>
      <Td textRight>
        <NumberFormat
          value={parseFloat(calcSubtotalLCF(data))}
          displayType="text"
          prefix="Rp"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
        />
      </Td>

      {showStatusPermintaan ? (
        <Td className="text-uppercase text-center">
          {data.status_permintaan ?? 'Waiting'}
        </Td>
      ) : null}

      {readOnly ? null : jobOrder ? (
        <Td>
          <ActionButton
            size="sm"
            className="m-1"
            onClick={() => onClickJobOrder(data)}
          >
            <IoArrowDown />
          </ActionButton>
        </Td>
      ) : (
        <Td>
          <ButtonGroup>
            <UpdateButton
              className="m-0"
              onClick={() => onUpdate(index, data)}
            />
            <DeleteButton
              className="m-0"
              onClick={() => onDelete(index, data)}
            />
          </ButtonGroup>
        </Td>
      )}
    </Tr>
  );
};
