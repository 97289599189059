import { useContext, useEffect, useState } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { useSessionStorage } from 'hooks';
import { ActionButton, BackButton, DataStatus } from 'components';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { RealisasiProduksiApi } from 'api';
import {
  TabRealisasiAlatMesin,
  TabRealisasiBahan,
  TabRealisasiOverhead,
  TabRealisasiSubkon,
  TabRealisasiUpah,
} from './Tabs';
import {
  mappingDataAlatMesin,
  mappingDataHistoryAlatMesin,
  mappingDataHistoryOverhead,
  mappingDataOverhead,
  mapDataSubmitBahan,
  mapDataSubmitUpah,
  mapDataSubmitAlatMesin,
  mapDataSubmitSubkon,
  mapDataSubmitOverhead,
  responseFilterBahan,
  responseFilterBahanHistory,
  responseFilterUpah,
  responseFilterUpahHistory,
} from './Utils';
import {
  InfoSection,
  ModalAlertEmptyRealisasi,
  NavTab,
  TabPane,
} from './Components';
import {
  RealisasiProduksiContext,
  withRealisasiProduksiProvider,
} from './Contexts';

const RealisasiProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [tab, setTab] = useSessionStorage('tab_realisasi_root', 'bahan');
  const [rawDataRealisasi, setRawDataRealisasi] = useState({});
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [modal, setModal] = useState({
    show: false,
    message: '',
  });
  const { setDataItem, dataRealisasi, setDataHistory } = useContext(
    RealisasiProduksiContext
  );

  const formInitialValues = {
    id_job_order: rawDataRealisasi?.id_job_order,
    no_realisasi_produksi: '',
    tgl_realisasi_produksi: '',
    qty_realisasi: '0',
    nama_proyek: rawDataRealisasi?.nama_proyek ?? '',
    nama_barang_jadi: rawDataRealisasi?.nama_barang_jadi,
  };

  const checkSubmit = ({ bahan, upah, alat_mesin, subkon, overhead }) => {
    let emptyRealisasi = [];

    if (!bahan.length) {
      emptyRealisasi.push('Bahan');
    }

    if (!upah.length) {
      emptyRealisasi.push('Upah');
    }

    if (!alat_mesin.length) {
      emptyRealisasi.push('Alat Mesin');
    }

    if (!subkon.length) {
      emptyRealisasi.push('Subkon');
    }

    if (!overhead.length) {
      emptyRealisasi.push('Overhead');
    }

    if (emptyRealisasi.length === 5) {
      throw new Error(emptyRealisasi.join(', '));
    }
  };

  const formValidationSchema = Yup.object().shape({
    no_realisasi_produksi: Yup.string().required(
      'Pilih tgl untuk menentukan nomor'
    ),
    tgl_realisasi_produksi: Yup.string().required('Tanggal tidak boleh kosong'),
    qty_realisasi: Yup.string().required('Qty. selesai produksi dibutuhkan'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const bahan = [
      mapDataSubmitBahan(dataRealisasi.hardwood, 'RHW'),
      mapDataSubmitBahan(dataRealisasi.plywood, 'RPW'),
      mapDataSubmitBahan(dataRealisasi.fs, 'RFS'),
      mapDataSubmitBahan(dataRealisasi.bp, 'RPF'),
    ].flat();

    const upah = [
      mapDataSubmitUpah(dataRealisasi.lc, 'RLC'),
      mapDataSubmitUpah(dataRealisasi.lcf, 'RLF'),
    ].flat();

    const alat_mesin = mapDataSubmitAlatMesin(
      dataRealisasi.alat_mesin,
      'RMP',
      values.tgl_realisasi_produksi
    );

    const subkon = mapDataSubmitSubkon(dataRealisasi.subkon, 'RSF');

    const overhead = mapDataSubmitOverhead(
      dataRealisasi.overhead,
      'ROP',
      values.tgl_realisasi_produksi
    );

    const finalData = {
      tgl_realisasi_produksi: values?.tgl_realisasi_produksi,
      no_realisasi_produksi: values?.no_realisasi_produksi,
      qty_realisasi: values?.qty_realisasi ?? 0,
      id_job_order: values?.id_job_order,
      bahan,
      upah,
      alat_mesin,
      subkon,
      overhead,
    };

    try {
      checkSubmit({ bahan, upah, alat_mesin, subkon, overhead });

      RealisasiProduksiApi.save(finalData).then(() => {
        history.push('/transaksi/realisasi-produksi', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil simpan!',
          },
        });
      });
    } catch (error) {
      setModal({
        show: true,
        message: error.message.toString(),
      });
      setSubmitting(false);
    }
  };

  // Check apakah ada data yang di pass dari list page
  const checkData = new Promise((resolve, reject) => {
    const data = location?.state?.dataJo;
    if (data) {
      return resolve(data);
    }

    return reject('Tidak ada data');
  });

  const getDataRealisasi = (id_job_order) => {
    Axios.all([
      RealisasiProduksiApi.getRealisasiBahan({ id_job_order }),
      RealisasiProduksiApi.getHistoryRealisasiBahan({ id_job_order }),
      RealisasiProduksiApi.getRealisasiUpah({ id_job_order }),
      RealisasiProduksiApi.getHistoryRealisasiUpah({ id_job_order }),
      RealisasiProduksiApi.getRealisasiSubkon({ id_job_order }),
      RealisasiProduksiApi.getHistoryRealisasiSubkon({ id_job_order }),
      RealisasiProduksiApi.getRealisasiAlatMesin({ id_job_order }),
      RealisasiProduksiApi.getHistoryRealisasiAlatMesin({ id_job_order }),
      RealisasiProduksiApi.getRealisasiOverhead({ id_job_order }),
      RealisasiProduksiApi.getHistoryRealisasiOverhead({ id_job_order }),
    ])
      .then(
        Axios.spread(
          (
            bahanRes,
            historyBahanRes,
            upahRes,
            historyUpahRes,
            subkonRes,
            historySubkonRes,
            alatRes,
            historyAlatRes,
            overheadRes,
            historyOverheadRes
          ) => {
            // bahan
            const { hw, pw, fs, bpf } = responseFilterBahan(
              bahanRes.data.data ?? []
            );
            const { hwHistory, pwHistory, fsHistory, bpfHistory } =
              responseFilterBahanHistory(historyBahanRes.data.data ?? []);

            // upah
            const { lc, lcf } = responseFilterUpah(upahRes.data.data ?? []);
            const { lcHistory, lcfHistory } = responseFilterUpahHistory(
              historyUpahRes.data.data ?? []
            );

            // subkon
            const subkon = subkonRes.data?.data;
            const subkonHistory = historySubkonRes.data?.data;

            // alat mesin
            const alat_mesin = mappingDataAlatMesin(alatRes?.data?.data ?? []);
            const alatHistory = mappingDataHistoryAlatMesin(
              historyAlatRes?.data?.data
            );

            // overhead
            const overhead = mappingDataOverhead(overheadRes?.data?.data ?? []);
            const overheadHistory = mappingDataHistoryOverhead(
              historyOverheadRes?.data?.data
            );

            setDataItem((prev) => ({
              ...prev,
              hardwood: hw,
              plywood: pw,
              bp: bpf,
              fs,
              lc,
              lcf,
              subkon,
              alat_mesin,
              overhead,
            }));

            setDataHistory({
              hardwood: hwHistory,
              plywood: pwHistory,
              fs: fsHistory,
              bp: bpfHistory,
              lc: lcHistory,
              lcf: lcfHistory,
              subkon: subkonHistory,
              alat_mesin: alatHistory,
              overhead: overheadHistory,
            });

            setFetchingStatus({
              loading: false,
              success: true,
            });
          }
        )
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  // Menangani fetching data ketika halaman di render
  const getInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    checkData
      .then((data) => {
        setRawDataRealisasi(data);
        getDataRealisasi(data.id_job_order);
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const tabsConfig = [
    {
      tab: 'bahan',
      label: 'Realisasi Bahan',
      component: () => <TabRealisasiBahan />,
    },
    {
      tab: 'upah',
      label: 'Realisasi Upah',
      component: () => <TabRealisasiUpah />,
    },
    {
      tab: 'subkon',
      label: 'Realisasi Subkon',
      component: () => <TabRealisasiSubkon />,
    },
    {
      tab: 'alatMesin',
      label: 'Realisasi Alat Mesin',
      component: () => <TabRealisasiAlatMesin />,
    },
    {
      tab: 'overhead',
      label: 'Realisasi Overhead',
      component: () => <TabRealisasiOverhead />,
    },
  ];

  useEffect(() => {
    setNavbarTitle('Realisasi Produksi');
    getInitialData();

    return () => {
      sessionStorage.removeItem('tab_realisasi_root');
      sessionStorage.removeItem('tab_realisasi_bahan');
      sessionStorage.removeItem('tab_realisasi_upah');
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {(formik) => {
        return (
          <>
            <Card className="mb-4">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <b>Realisasi Produksi</b>
                <BackButton onClick={() => history.goBack()} />
              </Card.Header>

              <Card.Body>
                {fetchingStatus.loading ? (
                  <DataStatus loading={true} text="Memuat data . . ." />
                ) : fetchingStatus.success ? (
                  <InfoSection dataInfo={rawDataRealisasi} formik={formik} />
                ) : (
                  <DataStatus text="Data gagal dimuat!" />
                )}
              </Card.Body>
            </Card>

            {!fetchingStatus.loading && fetchingStatus.success && (
              <Card>
                <Tab.Container defaultActiveKey={tab}>
                  <Card.Header>
                    <Nav variant="tabs" defaultActiveKey={tab}>
                      {tabsConfig.map((val) => (
                        <NavTab
                          eventKey={val.tab}
                          title={val.label}
                          onClick={() => setTab(val.tab)}
                        />
                      ))}
                    </Nav>
                  </Card.Header>

                  <Card.Body>
                    <Tab.Content>
                      {tabsConfig.map(({ tab, component: Component }) => (
                        <TabPane eventKey={tab} component={<Component />} />
                      ))}
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>
            )}
            {!fetchingStatus.loading && fetchingStatus.success && (
              <div className="text-right mt-3 mb-5">
                <ActionButton
                  text="Simpan"
                  style={{ minWidth: 100 }}
                  loading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                />
              </div>
            )}

            <ModalAlertEmptyRealisasi
              show={modal.show}
              message={modal.message}
              onModalClose={() => setModal({ show: false, message: '' })}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default withRealisasiProduksiProvider(RealisasiProduksi);
