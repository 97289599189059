import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { Input, ActionButton, BackButton, DataStatus, Alert } from 'components';
import { TabSection } from '../Section';
import { JobOrderApi } from 'api';
import { DateConvert, RupiahConvert } from 'utilities';
import { InfoSPK, TableSPK } from '../SPKSection';
import { multiplyByQtyRap } from '../Section/multiplyByQtyRap';

const UpdateJobOrder = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(false);

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [analisaHardwood, setAnalisaHardwood] = useState([]);
  const [analisaPlyWood, setAnalisaPlyWood] = useState([]);
  const [analisaFs, setAnalisaFs] = useState([]);
  const [analisaLc, setAnalisaLc] = useState([]);
  const [analisaMp, setAnalisaMp] = useState([]);
  const [analisaBop, setAnalisaBop] = useState([]);
  const [analisaBp, setAnalisaBp] = useState([]);
  const [analisaSc, setAnalisaSc] = useState([]);
  const [analisaFnLc, setAnalisaFnLc] = useState([]);
  const [dataUrainLabourCost, setdataUrainLabourCost] = useState([]);
  const [dataUraianLabourCostFinishing, setdataUraianLabourCostFinishing] =
    useState([]);
  const [dataUraianSubcontFinishing, setdataUraianSubcontFinishing] = useState(
    []
  );
  const [modalSPKConfig, setModalSPKConfig] = useState({
    show: false,
    id_spk: '',
  });

  const [noBaru, setNoBaru] = useState('');

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const history = useHistory();
  const { id } = useParams();

  // GET PAGE LIST RAP
  const getInitialData = () => {
    setIsLoading(true);

    JobOrderApi.getSingle({ id_job_order: id })
      .then(({ data }) => {
        const qtyRap = parseFloat(data.data.qty_rap ?? 0);
        setData(data.data);
        setAnalisaHardwood(data.data.analisa.analisa_hardwood);
        setAnalisaPlyWood(data.data.analisa.analisa_plywood);
        setAnalisaFs(data.data.analisa.analisa_fin_tp_fs);
        setAnalisaLc(data.data.analisa.analisa_fin_tp_lc);
        setAnalisaMp(data.data.analisa.analisa_fin_tp_mp);
        setAnalisaBop(data.data.analisa.analisa_fin_tp_bop);
        setAnalisaBp(data.data.analisa.analisa_fin_fn_bp);
        setAnalisaBop(data.data.analisa.analisa_fin_tp_bop);
        setAnalisaBp(data.data.analisa.analisa_fin_fn_bp);
        setAnalisaSc(data.data.analisa.analisa_fin_fn_sc);
        setAnalisaFnLc(data.data.analisa.analisa_fin_fn_lc);
        setdataUrainLabourCost(data.data.analisa.uraian_labour_cost);
        setdataUraianLabourCostFinishing(
          data.data.analisa.uraian_labour_cost_finishing
        );
        setdataUraianSubcontFinishing(
          data.data.analisa.uraian_subkon_finishing
        );
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getNoBaru = (tgl) => {
    JobOrderApi.getNo({ tanggal: tgl }).then(({ data }) => {
      setNoBaru(data.data);
    });
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle('Ubah Job Order');
    return () => {};
  }, []);

  const ModalSPK = () => {
    const [fetchSPKStatus, setFetchSPKStatus] = useState({
      loading: true,
      success: false,
      alert: false,
    });
    const [dataSPK, setdataSPK] = useState({});

    const getDataSPK = (id) => {
      JobOrderApi.getSingleSPK({ id_spk: id })
        .then((data) => setdataSPK(data.data.data))
        .catch((err) => {})
        .finally(() => setFetchSPKStatus({ loading: false, success: true }));
    };

    useEffect(() => {
      if (modalSPKConfig.show) {
        getDataSPK(modalSPKConfig.id_spk);
      } else {
        setModalSPKConfig({ loading: false, success: false });
      }
    }, []);

    return (
      <Modal
        size="xl"
        show={modalSPKConfig.show}
        onHide={() =>
          setModalSPKConfig({
            ...modalSPKConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>Detail Data SPK</Modal.Header>
        <Modal.Body>
          {fetchSPKStatus.loading ? (
            <DataStatus loading={true} text="Memuat Data" />
          ) : (
            <>
              <InfoSPK dataSPK={dataSPK} />
              <TableSPK dataSPK={dataSPK} dataPrelim={dataSPK.detail_prelim} />
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const HeadView = ({ title, text, link, onClick }) => (
    <>
      <div className="d-flex">
        <div style={{ width: 150 }}>{title}</div>
        <div>:</div>
        <div
          className={link ? 'text-primary ml-3' : 'ml-3'}
          style={{ cursor: link ? 'pointer' : 'default' }}
          onClick={onClick}
        >
          {text}
        </div>
      </div>
    </>
  );

  const formInitialValues = {
    id_job_order: id,
    id_rap_detail: data.id_rap_detail,
    tgl_job_order: data.tgl_job_order,
    no_job_order: data.no_job_order,
    tgl_mulai_jo: data.tgl_mulai_jo,
    tgl_selesai_jo: data.tgl_selesai_jo,
  };

  const formValidationSchema = Yup.object().shape({
    tgl_job_order: Yup.string().required('Masukan Tanggal Job Order'),
  });

  const formSubmitHandler = (values) => {
    JobOrderApi.update(values)
      .then((res) => {
        setAlertConfig({
          variant: 'primary',
          text: `Ubah data berhasil! `,
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: 'danger',
          text: `Ubah data gagal! `,
          show: true,
        });
      })
      .finally(() =>
        history.push('/transaksi/job-order', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil simpan!',
          },
        })
      );
  };

  return (
    <div className="mb-5">
      {isLoading ? (
        <DataStatus text="Memuat Data" loading={true} />
      ) : data !== undefined ? (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <b>Ubah Job Order</b>
                  <BackButton onClick={() => history.goBack()} />
                </Card.Header>
                <Card.Body>
                  <Alert
                    show={alertConfig.show}
                    variant={alertConfig.variant}
                    text={alertConfig.text}
                    showCloseButton={true}
                    onClose={() => {
                      setAlertConfig({
                        ...alertConfig,
                        show: false,
                      });
                    }}
                  />
                  <Row>
                    <Col>
                      <HeadView
                        title="Tgl. RAP"
                        text={
                          data.tgl_rap
                            ? DateConvert(new Date(data.tgl_rap)).detail
                            : '-'
                        }
                      />
                      <HeadView
                        title="No. RAP"
                        text={data.no_rap ? data.no_rap : '-'}
                      />
                      <HeadView
                        title="Customer"
                        text={data.nama_customer ? data.nama_customer : '-'}
                      />
                      <HeadView title="ATT" text={data.att ? data.att : '-'} />
                      <HeadView
                        title="Tgl. Selesai"
                        text={
                          data.tgl_selesai
                            ? DateConvert(new Date(data.tgl_selesai)).detail
                            : '-'
                        }
                      />
                    </Col>
                    <Col>
                      <HeadView
                        title="Tgl. Selesai Barang Jadi"
                        text={
                          data.tgl_spk
                            ? DateConvert(new Date(data.tgl_spk)).detail
                            : '-'
                        }
                      />
                      <HeadView
                        title="No. SPK"
                        text={data.no_spk ? data.no_spk : '-'}
                        link
                        onClick={() =>
                          setModalSPKConfig({ id_spk: data.id_spk, show: true })
                        }
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-2">
                    <Col lg="3">
                      <Input
                        label="Tgl. Job Order"
                        type="date"
                        name="tgl_job_order"
                        value={values.tgl_job_order}
                        onChange={(e) => {
                          getNoBaru(e.target.value);
                          setFieldValue('tgl_job_order', e.target.value);
                        }}
                        error={
                          errors.tgl_job_order && touched.tgl_job_order && true
                        }
                        errorText={
                          errors.tgl_job_order &&
                          touched.tgl_job_order &&
                          errors.tgl_job_order
                        }
                      />
                    </Col>
                    <Col lg="3">
                      <Input
                        label="No. Job Order"
                        type="text"
                        readOnly={true}
                        name="no_job_order"
                        placeholder="Pilih Tanggal Terlebih Dahulu"
                        value={values.no_job_order}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col lg="3">
                      <Input
                        label="Tgl. Mulai"
                        type="date"
                        name="tgl_mulai_jo"
                        value={values.tgl_mulai_jo}
                        onChange={(e) => {
                          setFieldValue('tgl_mulai_jo', e.target.value);
                        }}
                        error={
                          errors.tgl_mulai_jo && touched.tgl_mulai_jo && true
                        }
                        errorText={
                          errors.tgl_mulai_jo &&
                          touched.tgl_mulai_jo &&
                          errors.tgl_mulai_jo
                        }
                      />
                    </Col>
                    <Col lg="3">
                      <Input
                        label="Tgl. Selesai"
                        type="date"
                        name="tgl_selesai_jo"
                        value={values.tgl_selesai_jo}
                        onChange={(e) => {
                          setFieldValue('tgl_selesai_jo', e.target.value);
                        }}
                        error={
                          errors.tgl_selesai_jo &&
                          touched.tgl_selesai_jo &&
                          true
                        }
                        errorText={
                          errors.tgl_selesai_jo &&
                          touched.tgl_selesai_jo &&
                          errors.tgl_selesai_jo
                        }
                      />
                    </Col>
                  </Row>
                  <HeadView
                    title="Kode Barang Jadi"
                    text={data.kode_barang_jadi ? data.kode_barang_jadi : '-'}
                  />
                  <HeadView
                    title="Nama Barang Jadi"
                    text={data.nama_barang_jadi ? data.nama_barang_jadi : '-'}
                  />
                  <HeadView
                    title="Qty."
                    text={
                      data.qty_rap
                        ? data.qty_rap + ` ${data.nama_satuan ?? ''}`
                        : '-'
                    }
                  />
                </Card.Body>
              </Card>
              <div>
                <h6 className="px-2 mt-5">
                  <b>Analisa Barang Jadi</b>
                </h6>
                <TabSection
                  dataHardwood={analisaHardwood}
                  dataPlywood={analisaPlyWood}
                  dataTPFS={analisaFs}
                  dataTPLC={analisaLc}
                  dataTPMP={analisaMp}
                  dataTPBOP={analisaBop}
                  dataFNBP={analisaBp}
                  dataFNSC={analisaSc}
                  dataFNLC={analisaFnLc}
                  dataUraianLabourCost={dataUrainLabourCost}
                  dataUraianLabourCostFinishing={dataUraianLabourCostFinishing}
                  dataUraianSubcontFinishing={dataUraianSubcontFinishing}
                />
                <div className="d-flex justify-content-end">
                  <ActionButton
                    text="Simpan Job Order"
                    variant="success"
                    type="submit"
                    loading={isSubmitting}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <DataStatus text="Data Tidak Ada" />
      )}
      {modalSPKConfig.show && <ModalSPK />}
    </div>
  );
};

export default UpdateJobOrder;
