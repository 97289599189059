import { mappingDataBahan, mappingDataHistoryBahan } from './mappingHandler';

export const responseFilterBahan = (bahan = []) => {
  const hw = mappingDataBahan(
    bahan?.filter((val) => val?.ref_bahan === 'bhw') ?? []
  );

  const pw = mappingDataBahan(
    bahan?.filter((val) => val?.ref_bahan === 'bpw') ?? []
  );

  const fs = mappingDataBahan(
    bahan?.filter((val) => val?.ref_bahan === 'bfs') ?? []
  );

  const bpf = mappingDataBahan(
    bahan?.filter((val) => val?.ref_bahan === 'bpf') ?? []
  );

  return {
    hw,
    pw,
    fs,
    bpf,
  };
};

export const responseFilterBahanHistory = (bahan = []) => {
  const hwHistory = mappingDataHistoryBahan(
    bahan?.filter((val) => val?.ref === 'RHW') ?? []
  );

  const pwHistory = mappingDataHistoryBahan(
    bahan?.filter((val) => val?.ref === 'RPW') ?? []
  );

  const fsHistory = mappingDataHistoryBahan(
    bahan?.filter((val) => val?.ref === 'RFS') ?? []
  );

  const bpfHistory = mappingDataHistoryBahan(
    bahan?.filter((val) => val?.ref === 'RPF') ?? []
  );

  return {
    hwHistory,
    pwHistory,
    fsHistory,
    bpfHistory,
  };
};

export const responseFilterUpah = (upah = []) => {
  const lc = upah?.filter((val) => val?.ref_kontrak_upah === 'klc') ?? [];
  const lcf = upah?.filter((val) => val?.ref_kontrak_upah === 'klf') ?? [];

  return {
    lc,
    lcf,
  };
};

export const responseFilterUpahHistory = (upah = []) => {
  const lcHistory = upah?.filter((val) => val?.ref === 'RLC') ?? [];
  const lcfHistory = upah?.filter((val) => val?.ref === 'RLF') ?? [];

  return {
    lcHistory,
    lcfHistory,
  };
};
