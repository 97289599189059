import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TablePlywood } from './TablePlywood';

export const ContainerPlywood = ({ showHistory }) => {
  const { dataPlywood } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TablePlywood
        jobOrder
        showTotalLuasVolume
        title="List Plywood Dari Job Order"
        data={dataPlywood.jobOrder}
      />
      <TablePlywood
        showInputRow
        showTotalLuasVolume
        title="List Permintaan Plywood"
        data={dataPlywood.permintaan}
      />

      {showHistory && (
        <TablePlywood
          readOnly
          title="History Permintaan Plywood"
          data={dataPlywood.history}
        />
      )}
    </Row>
  );
};
