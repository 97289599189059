import { Card, Tab, Nav } from 'react-bootstrap';
import { NavTab, TabPane } from './TabNavigations';
import { ContainerHardwood, ContainerHardwoodDetail } from '../Hardwood';
import { ContainerPlywood, ContainerPlywoodDetail } from '../Plywood';
import { ContainerFS, ContainerFSDetail } from '../FactorySupply';
import { ContainerBPF, ContainerBPFDetail } from '../BahanPenunjangFinishing';

export const TabBahan = ({ readOnly = false, showHistory = true }) => {
  return (
    <Card className="mt-4">
      <Tab.Container defaultActiveKey="hardwood">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey="hardwood">
            <NavTab eventKey="hardwood" title="Hardwood" />
            <NavTab eventKey="plywood" title="Plywood" />
            <NavTab eventKey="factory" title="Factory Supply" />
            <NavTab eventKey="penunjang" title="Bahan Penunjang Finishing" />
          </Nav>
        </Card.Header>

        <Card.Body>
          <Tab.Content>
            <TabPane
              eventKey="hardwood"
              component={
                readOnly ? (
                  <ContainerHardwoodDetail />
                ) : (
                  <ContainerHardwood showHistory={showHistory} />
                )
              }
            />
            <TabPane
              eventKey="plywood"
              component={
                readOnly ? (
                  <ContainerPlywoodDetail />
                ) : (
                  <ContainerPlywood showHistory={showHistory} />
                )
              }
            />
            <TabPane
              eventKey="factory"
              component={
                readOnly ? (
                  <ContainerFSDetail />
                ) : (
                  <ContainerFS showHistory={showHistory} />
                )
              }
            />
            <TabPane
              eventKey="penunjang"
              component={
                readOnly ? (
                  <ContainerBPFDetail />
                ) : (
                  <ContainerBPF showHistory={showHistory} />
                )
              }
            />
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  );
};
