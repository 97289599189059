// HALAMAN UPDATE TIPE BARANG JADI
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Td,
  Th,
  BackButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  SelectSearch,
  TextArea,
  UpdateModal,
} from '../../../../components';
import { RupiahConvert, DateConvert } from '../../../../utilities';
import { TipeBarangJadiApi } from '../../../../api';
const UpdateTipeBarangJadi = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  let { id_tipe_barang_jadi } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [updateData, setUpdateData] = useState([]);

  // STATE MENAMPUNG DATA
  const [data, setData] = useState({});

  const [listTipeBarangJadi, setListTipeBarangJadi] = useState([]);
  const [listFactorySupply, setListFactorySupply] = useState([]);
  const [listLabourCost, setListLabourCost] = useState([]);
  const [listMachineProcess, setListMachineProcess] = useState([]);
  const [listOverheadPabrik, setListOverheadPabrik] = useState([]);

  const [dropdownFS, setDropdownFS] = useState([]);
  const [dropdownLC, setDropdownLC] = useState([]);
  const [dropdownMP, setDropdownMP] = useState([]);
  const [dropdownBOP, setDropdownBOP] = useState([]);

  const [grandTotal, setGrandTotal] = useState(0);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = (loading = true) => {
    setIsLoading(loading);
    Axios.all([
      TipeBarangJadiApi.getSingle(id_tipe_barang_jadi),
      TipeBarangJadiApi.getDropdownKelompokTipeBarangJadi(),
      TipeBarangJadiApi.getDropdownItem('fs'),
      TipeBarangJadiApi.getDropdownItem('lc'),
      TipeBarangJadiApi.getDropdownItem('mp'),
      TipeBarangJadiApi.getDropdownItem('bop'),
    ])
      .then(
        Axios.spread((res, kelompok_dropdown, fs, lc, mp, bop) => {
          setData(res.data.data);
          setDropdownFS(fs.data.data);
          setDropdownLC(lc.data.data);
          setDropdownMP(mp.data.data);
          setDropdownBOP(bop.data.data);

          setListFactorySupply(res.data.data.detail.fs);
          setListLabourCost(res.data.data.detail.lc);
          setListMachineProcess(res.data.data.detail.mp);
          setListOverheadPabrik(res.data.data.detail.bop);
          setListTipeBarangJadi(kelompok_dropdown.data.data);
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(true);
    setNavbarTitle('Update Data Tipe Barang Jadi'); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // TABLE COMPONENT
  const Table = (props) => {
    const toCommas = (val) => {
      return val.toString().replace(/\./g, ',');
    };
    useEffect(() => {
      hitungGrandTotal();
    }, []);

    const handleDeleteDetail = (val) => {
      let finalValues = {
        tipe: props.kode,
      };
      finalValues[`id_tipe_barang_jadi_${props.kode}`] =
        val[`id_tipe_barang_jadi_${props.kode}`];

      TipeBarangJadiApi.deleteDetailTipeBarangJadi(finalValues)
        .then((res) => {
          setAlertConfig({
            variant: 'primary',
            text: `Hapus data berhasil! `,
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // SHOW ALERT
          getData(false);
          setShowAlert(true);
        });
    };
    return (
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Item</ThFixed>
            <Th>Nama Item</Th>
            <ThFixed>Qty</ThFixed>
            <ThFixed>Unit </ThFixed>
            <Th>Unit Price</Th>
            <ThFixed>Konstanta</ThFixed>
            <Th>Sub Total Price</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {props.data.length > 0 ? (
            props.data?.map((val, index) => {
              // TAMBAH KEY sub_total PADA ELEMENT BARANG
              let totalOrder =
                parseFloat(val.harga_satuan) * parseFloat(val.qty);
              let sub_total = totalOrder * parseFloat(val.koefisien);
              val.sub_total = parseInt(sub_total);
              return (
                <Tr key={props.kode + index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item_bahan}</TdFixed>
                  <Td>{val.nama_item_bahan ?? val.label}</Td>
                  <Td textRight>{toCommas(parseFloat(val.qty))}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td width={150} textRight>
                    {RupiahConvert(String(parseInt(val.harga_satuan))).detail}
                  </Td>
                  <TdFixed>{toCommas(parseFloat(val.koefisien))}</TdFixed>
                  <Td width={150} textRight>
                    {RupiahConvert(String(val.sub_total)).detail}
                  </Td>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      {/* <UpdateButton
                                                    onClick={() => {
                                                        setIsUpdateForm(true)
                                                        setUpdateData({ kode: props.kode, ...val })
                                                    }}
                                                /> */}
                      <DeleteButton
                        onClick={() => {
                          handleDeleteDetail(val);
                        }}
                      />
                    </div>
                  </TdFixed>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan="9">
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {props.data.length > 0 ? (
            <Tr>
              <Td textRight colSpan="7">
                <strong>Total {props.title}</strong>
              </Td>
              <Td textRight>
                <strong>
                  {
                    RupiahConvert(
                      String(
                        props.data.reduce(
                          (sum, { sub_total }) => sum + parseInt(sub_total),
                          0
                        )
                      )
                    ).detail
                  }
                </strong>
              </Td>
              <Td></Td>
            </Tr>
          ) : (
            <div />
          )}
        </TBody>
      </CRUDLayout.Table>
    );
  };

  const PilihItem = (props) => {
    const [item, setItem] = useState({});
    const [qty, setQty] = useState(1);
    const [koefisien, setKoefisien] = useState('1');
    const [satuan, setSatuan] = useState('');
    const [hargaSatuan, setHargaSatuan] = useState('0');
    const [subTotal, setSubTotal] = useState('0');

    const [dropdownData, setDropdownData] = useState([]);

    const getDataDropdown = () => {
      switch (props.kode) {
        case 'fs':
          setDropdownData([...dropdownFS]);
          break;
        case 'lc':
          setDropdownData([...dropdownLC]);
          break;
        case 'mp':
          setDropdownData([...dropdownMP]);
          break;
        default:
          setDropdownData([...dropdownBOP]);
          break;
      }
    };

    const handleSubmit = () => {
      // CHECK IF item & qty ISN'T EMPTY
      if (!(Object.entries(item).length === 0) && qty != 0) {
        const finalValues = {
          id_tipe_barang_jadi: data.id_tipe_barang_jadi,
          ...item,
          qty,
          koefisien,
          sub_total: subTotal,
        };
        TipeBarangJadiApi.simpanDetailTipeBarangjadi(finalValues)
          .then((res) => {
            setAlertConfig({
              variant: 'primary',
              text: `Tambah data berhasil!`,
            });
          })
          .catch((err) => {
            // ERROR ALERT
            setAlertConfig({
              variant: 'danger',
              text: `Tambah data gagal! (${err.response.data.message})`,
            });
          })
          .finally(() => {
            // SHOW ALERT
            getData(false);
            setShowAlert(true);
          });

        setSubTotal(0);
        setQty(0);
        setKoefisien(1);
        setHargaSatuan(0);
      } else {
      }
    };

    useEffect(() => {
      let sub_total = parseFloat(qty) * hargaSatuan * parseFloat(koefisien);
      setSubTotal(Math.round(sub_total));
    }, [qty, hargaSatuan, koefisien]);

    return (
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-between align-items-end mb-1">
          <div style={{ minWidth: '250px' }}>
            <SelectSearch
              label={`Pilih Item ${props.title}`}
              name="item"
              placeholder="Pilih Item"
              onChange={(val) => {
                setItem(val);
                setSatuan(val.nama_satuan);
                setHargaSatuan(val.harga_satuan ?? 0);
              }}
              onFocus={getDataDropdown}
              option={dropdownData.map((val) => {
                return {
                  value: val.id_item_buaso,
                  label: val.nama_item,
                  tipe: props.kode,
                  id_item_buaso: val.id_item_buaso,
                  nama_satuan: val.nama_satuan,
                  harga_satuan: val.harga_satuan ?? 0,
                };
              })} //option harus berupa value dan label
              defaultValue=""
            />
          </div>

          <Input
            style={{ maxWidth: '80px' }}
            label="Qty"
            type="number"
            name="qty"
            value={qty}
            onChange={(val) => setQty(val.target.value)}
            min={1}
            step={0.1}
            placeholder="Qty"
          />

          <Input
            style={{ maxWidth: '100px' }}
            label="Unit"
            type="text"
            name="satuan"
            value={satuan}
            placeholder="Unit"
            readOnly={true}
          />

          <Input
            style={{ maxWidth: '150px' }}
            label="Unit Price"
            type="text"
            value={RupiahConvert(String(parseInt(hargaSatuan))).detail}
            name="harga_satuan"
            placeholder="Unit Price"
            readOnly={true}
          />

          <Input
            style={{ maxWidth: '80px' }}
            label="Konstanta"
            type="number"
            name="koefisien"
            value={koefisien}
            onChange={(val) => setKoefisien(val.target.value)}
            min={1}
            step={0.1}
            placeholder="Koefisien"
          />

          <Input
            style={{ maxWidth: '150px' }}
            label="Sub Total Price"
            type="text"
            value={RupiahConvert(String(subTotal)).detail}
            name="sub_total"
            placeholder="Sub Total Price"
            readOnly={true}
          />

          <ActionButton
            size="sm"
            style={{ marginBottom: '0.5rem' }}
            onClick={handleSubmit}
            variant="primary"
            text="Tambah Ke List"
            className="text-white"
          />
        </div>
      </form>
    );
  };

  // MODAL UBAH COMPONENT
  const EditItemModal = () => {
    const [dropdownData, setDropdownData] = useState([]);

    const getDataDropdown = () => {
      switch (updateData.kode) {
        case 'fs':
          setDropdownData([...dropdownFS]);
          break;
        case 'lc':
          setDropdownData([...dropdownLC]);
          break;
        case 'mp':
          setDropdownData([...dropdownMP]);
          break;
        default:
          setDropdownData([...dropdownBOP]);
          break;
      }
    };

    const formInitialValues = {
      id_item_buaso: updateData.id_item_buaso,
      nama_satuan: updateData.nama_satuan,
      harga_satuan: updateData.harga_satuan,
      qty: updateData.qty,
      tipe: updateData.kode,
      kode_item_bahan: '',
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      let finalValues = {
        tipe: values.tipe,
        qty: values.qty,
      };
      finalValues[`id_tipe_barang_jadi_${values.tipe}`] =
        updateData[`id_tipe_barang_jadi_${values.tipe}`];

      TipeBarangJadiApi.simpanQtyDetailTipeBarangjadi(finalValues)
        .then((res) => {
          setAlertConfig({
            variant: 'primary',
            text: `Ubah data berhasil!`,
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // SHOW ALERT
          setShowAlert(true);
          setIsUpdateForm(false);
          getData(false);
        });
    };

    return (
      <UpdateModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={`Item ${
          updateData.kode == 'fs'
            ? 'Factory Supply'
            : updateData.kode == 'lc'
            ? 'Labour Cost'
            : updateData.kode == 'mp'
            ? 'Machine Process'
            : 'Biaya Overhead'
        }`}
      >
        <Formik initialValues={formInitialValues} onSubmit={formSubmitHandler}>
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Nama Item"
                  name="id_item_buaso"
                  placeholder="Pilih Nama Item"
                  onFocus={getDataDropdown}
                  isDisabled={true}
                  onChange={(val) => {
                    setFieldValue('id_item_buaso', val.value);
                    setFieldValue('kode_item_bahan', val.kode_item_bahan);
                    setFieldValue('label', val.label);
                    setFieldValue('nama_satuan', val.nama_satuan);
                    setFieldValue('harga_satuan', val.harga_satuan);
                  }}
                  option={dropdownData.map((val) => {
                    return {
                      value: val.id_item_buaso,
                      label: val.nama_item,
                      id_item_buaso: val.id_item_buaso,
                      nama_satuan: val.nama_satuan,
                      harga_satuan: val.harga_satuan ?? 5000,
                      kode_item_bahan: val.kode_item_bahan,
                    };
                  })} //option harus berupa value dan label
                  defaultValue={{
                    label: updateData.nama_item_bahan,
                    value: values.id_item_buaso,
                  }}
                  error={errors.id_item_buaso && touched.id_item_buaso && true}
                  errorText={
                    errors.id_item_buaso &&
                    touched.id_item_buaso &&
                    errors.id_item_buaso
                  }
                />

                <Input
                  label="Satuan"
                  type="text"
                  name="nama_satuan"
                  value={values.nama_satuan}
                  placeholder="Satuan"
                  readOnly={true}
                />

                <Input
                  label="Harga Satuan"
                  type="text"
                  name="harga_satuan"
                  value={values.harga_satuan}
                  placeholder="Harga Satuan"
                  readOnly={true}
                />

                <Input
                  label="Qty"
                  type="number"
                  name="qty"
                  value={values.qty}
                  onChange={handleChange}
                  min={1}
                  placeholder="Qty"
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  const formInitialValues = {
    id_kelompok_tipe_barang_jadi: data.id_kelompok_tipe_barang_jadi,
    nama_kelompok_tipe_barang_jadi: data.nama_kelompok_tipe_barang_jadi,
    nama_tipe_barang_jadi: data.nama_tipe_barang_jadi,
    deskripsi: data.deskripsi,
  };

  // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_kelompok_tipe_barang_jadi: Yup.string().required(
      'Pilih Kelompok Tipe Barang'
    ),
    nama_tipe_barang_jadi: Yup.string().required(
      'Masukkan Nama Tipe Barang Jadi'
    ),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    // SEND UPDATED DATA TO SERVER
    const finalValues = {
      id_tipe_barang_jadi: data.id_tipe_barang_jadi,
      ...values,
    };
    TipeBarangJadiApi.update(finalValues)
      .then((res) => {
        history.push('/master/tipe-barang-jadi', {
          registerAlertConfig: {
            variant: 'primary',
            text: 'Ubah data berhasil!',
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: 'danger',
          text: `Update data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        // SHOW ALERT
        setShowAlert(true);
      });
  };

  const hitungGrandTotal = () => {
    let totalFS =
      listFactorySupply.length > 0
        ? listFactorySupply.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalMP =
      listMachineProcess.length > 0
        ? listMachineProcess.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalLC =
      listLabourCost.length > 0
        ? listLabourCost.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;
    let totalBOP =
      listOverheadPabrik.length > 0
        ? listOverheadPabrik.reduce(
            (sum, obj) => sum + parseInt(obj?.sub_total ?? 0),
            0
          )
        : 0;

    let grand =
      parseInt(totalFS) +
      parseInt(totalLC) +
      parseInt(totalMP) +
      parseInt(totalBOP);
    setGrandTotal(grand);
  };

  useEffect(() => {
    hitungGrandTotal();
  }, [
    listFactorySupply,
    listLabourCost,
    listMachineProcess,
    listOverheadPabrik,
  ]);

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data Sub Tipe Produksi</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Card.Body>
            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="container my-2 px-2">
                    <div className="row">
                      <div className="col">
                        <SelectSearch
                          label="Tipe Produksi"
                          name="id_kelompok_tipe_barang_jadi"
                          placeholder="Pilih tipe produksi"
                          onChange={(val) =>
                            setFieldValue(
                              'id_kelompok_tipe_barang_jadi',
                              val.value
                            )
                          }
                          option={listTipeBarangJadi.map((val) => {
                            return {
                              value: val.id_kelompok_tipe_barang_jadi,
                              label: val.nama_kelompok_barang_jadi,
                            };
                          })} //option harus berupa value dan label
                          defaultValue={{
                            value: values.id_kelompok_tipe_barang_jadi,
                            label: values.nama_kelompok_tipe_barang_jadi,
                          }}
                          error={
                            errors.id_kelompok_tipe_barang_jadi &&
                            touched.id_kelompok_tipe_barang_jadi &&
                            true
                          }
                          errorText={
                            errors.id_kelompok_tipe_barang_jadi &&
                            touched.id_kelompok_tipe_barang_jadi &&
                            errors.id_kelompok_tipe_barang_jadi
                          }
                        />

                        <Input
                          label="Nama Sub Tipe Produksi"
                          type="text"
                          name="nama_tipe_barang_jadi"
                          placeholder="Nama sub tipe produksi"
                          value={values.nama_tipe_barang_jadi}
                          onChange={handleChange}
                          error={
                            errors.nama_tipe_barang_jadi &&
                            touched.nama_tipe_barang_jadi &&
                            true
                          }
                          errorText={errors.nama_tipe_barang_jadi}
                        />
                      </div>

                      <div className="col">
                        {/* DESKRIPSI */}
                        <TextArea
                          label="Deskripsi"
                          placeholder="Deskripsi"
                          value={values.deskripsi}
                          onChange={handleChange}
                          name="deskripsi"
                          rows={4}
                        />
                      </div>
                    </div>

                    <hr />
                    {!isLoading && (
                      <PilihItem
                        title="Factory Supply"
                        kode="fs"
                        dropdownData={dropdownFS}
                      />
                    )}
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Factory Supply</strong>
                    </div>
                    <Table
                      data={listFactorySupply}
                      title="Factory Supply"
                      kode="fs"
                    />

                    <hr />
                    {!isLoading && (
                      <PilihItem
                        title="Labour Cost"
                        kode="lc"
                        dropdownData={dropdownLC}
                      />
                    )}
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Labour Cost MGP</strong>
                    </div>
                    <Table
                      data={listLabourCost}
                      title="Labour Cost MGP"
                      kode="lc"
                    />

                    <hr />
                    {!isLoading && (
                      <PilihItem
                        title="Machine Process"
                        kode="mp"
                        dropdownData={dropdownMP}
                      />
                    )}
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Machine Process</strong>
                    </div>
                    <Table
                      data={listMachineProcess}
                      title="Machine Process"
                      kode="mp"
                    />

                    <hr />
                    {!isLoading && (
                      <PilihItem
                        title="Biaya Overhead"
                        kode="bop"
                        dropdownData={dropdownBOP}
                      />
                    )}
                    <div style={{ fontSize: 14 }} className="mb-1 mt-3">
                      <strong>List Item Biaya Overhead</strong>
                    </div>
                    <Table
                      data={listOverheadPabrik}
                      title="Biaya Overhead"
                      kode="bop"
                    />

                    <CRUDLayout.Table classname="mx-3">
                      <THead>
                        <Tr className="text-center">
                          <Th>
                            <div className="text-right">Grand Total</div>
                          </Th>
                          <Th style={{ width: '10%' }}>
                            <div className="text-right">
                              {RupiahConvert(String(grandTotal)).detail}
                            </div>
                          </Th>
                        </Tr>
                      </THead>
                    </CRUDLayout.Table>

                    <div className="d-flex justify-content-end mt-2">
                      <ActionButton
                        type="submit"
                        className="btn btn-success text-white"
                        size="md"
                        text="Update"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        )}
      </Card>
      {isUpdateForm && <EditItemModal />}
    </CRUDLayout>
  );
};
export default UpdateTipeBarangJadi;
