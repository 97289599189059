import { Services } from '../../../services';

class RAPApi {
  // RAP
  get(params) {
    return Services.get('/rap/page', { params });
  }

  dropdownFilter(params) {
    return Services.get('/rap/dropdown', { params });
  }

  getSPK(params) {
    return Services.get('/rap/list_spk', { params });
  }

  getNomor(params) {
    return Services.get('/rap/no_baru', { params });
  }

  getSingle(params) {
    return Services.get('/rap/single', { params });
  }

  getSingleSPK(params) {
    return Services.get('/rap/detail_spk', { params });
  }

  getDropdownAnalisa(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_analisa', {
      params,
    });
  }

  save(data) {
    return Services.post('/rap', data);
  }

  // Analisa Barang Jadi RAP
  getSingleAnalisaRAP(params) {
    return Services.get('/rap/analisa_single', { params });
  }

  getSingleAnalisaSPK(params) {
    return Services.get('/rap/analisa_rab_single', { params });
  }

  getAnalisaSPK(params) {
    return Services.get('/rap/analisa_spk', { params });
  }

  getSingleAnalisaBJ(params) {
    return Services.get('/rap/analisa_barang_jadi_single', { params });
  }

  getKayu(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_kayu', { params });
  }

  getKelompokTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_kelompok_tipe', {
      params,
    });
  }

  getTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_tipe', { params });
  }

  getDropdownTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_tipe', { params });
  }

  getSingleTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_tipe', { params });
  }

  getSingleTemplatePenunjang(params) {
    return Services.get('/analisa_barang_jadi/template', { params });
  }

  getFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_finishing', {
      params,
    });
  }

  getDropdownFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_finishing', {
      params,
    });
  }

  getSingleFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_finishing', {
      params,
    });
  }

  getDropdownDeskripsi() {
    return Services.get('/analisa_barang_jadi/d_deskripsi');
  }

  getDropdownPartKayu() {
    return Services.get('/analisa_barang_jadi/d_part_kayu');
  }

  getDropdownTipeSisi() {
    return Services.get('/analisa_barang_jadi/d_tipe_sisi');
  }

  getDropdownTipeFinishing() {
    return Services.get('/analisa_barang_jadi/d_finishing');
  }

  getDropdownSpekKayu() {
    return Services.get('/analisa_barang_jadi/d_jenis_kayu');
  }

  getDropdownSpekPlywood() {
    return Services.get('/analisa_barang_jadi/d_plywood');
  }

  getHargaKubikasiKayu(params) {
    return Services.get('/analisa_barang_jadi_rap/harga_kubikasi', { params });
  }

  getDropdownTipeProduksi(params) {
    return Services.get('/analisa_barang_jadi/d_tipe_produksi', { params });
  }

  getDropdownBentuk(params) {
    return Services.get('/analisa_barang_jadi/d_bentuk', { params });
  }

  getDropdownUkuran(params) {
    return Services.get('/analisa_barang_jadi/d_ukuran', { params });
  }

  getDropdownItemLabourCost(params) {
    return Services.get('/buaso/dropdown_upah', { params });
  }

  getKelompokPrelim(params) {
    return Services.get('/analisa_barang_jadi_rap/list_kelompok_prelim', {
      params,
    });
  }

  getItemPrelim(params) {
    return Services.get('/analisa_barang_jadi_rap/dropdown_item_prelim', {
      params,
    });
  }

  getTemplateLabourCost(params) {
    return Services.get('/rap/template_labour_cost_on_site', { params });
  }

  getDetailPeluangRAP(params) {
    return Services.get('/rap/detail_peluang', { params });
  }

  getRapExport(params) {
    return Services.get('/rap_report', { params });
  }

  getUnit() {
    return Services.get('/rap/satuan/');
  }
}

export default new RAPApi();
