import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { debounce } from 'lodash';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ApprovalStatusButton,
  ReadButton,
  UpdateButton,
  CreateButton,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  Th,
  Td,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { RAPApi } from 'api';
import ModalFilterRAP from './ModalFilterRAP';

const RAP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [dataRAP, setDataRAP] = useState([]);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_rap_awal: undefined,
    tgl_rap_akhir: undefined,
    tgl_selesai_start: undefined,
    tgl_selesai_end: undefined,
    id_customer: undefined,
    baseline: undefined,
    status_approval: undefined,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState(false);
  const [dropdown, setDropdown] = useState({
    customer: [{ value: null, label: 'Semua' }],
    baseline: [
      { value: null, label: 'Semua' },
      ...Array.from(Array(10).keys()).map((i) => ({
        value: i + 1,
        label: i + 1,
      })),
    ],
    approval: [
      { value: null, label: 'Semua' },
      { value: 'PEN', label: 'PENDING' },
      { value: 'REV', label: 'REVISION' },
      { value: 'VER', label: 'VERIFIED' },
      { value: 'APP', label: 'APPROVED' },
      { value: 'REJ', label: 'REJECTED' },
      { value: 'DRAFT', label: 'DRAFT' },
    ],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    let query = {
      page: pagination.page,
      per_page: pagination.per_page,
      q: pagination.q,

      tgl_rap_awal: pagination.tgl_rap_awal,
      tgl_rap_akhir: pagination.tgl_rap_akhir,
      tgl_selesai_start: pagination.tgl_selesai_start,
      tgl_selesai_end: pagination.tgl_selesai_end,
      id_customer: pagination.id_customer,
      baseline: pagination.baseline,
      status_approval: pagination.status_approval,
    };

    RAPApi.get(query)
      .then(({ data }) => {
        setDataRAP(data.data);
        setPagination({
          ...pagination,
          data_count: data.data_count,
          total_page: data.total_page,
        });
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const fetchDropdownFilter = () => {
    Axios.all([RAPApi.dropdownFilter({ tipe: 'customer' })]).then(
      Axios.spread((resCustomer) => {
        const customer = resCustomer?.data?.data?.map((customer) =>
          Object({ value: customer.id_customer, label: customer.nama_customer })
        );

        setDropdown((prev) => ({
          ...prev,
          customer: [...prev.customer, ...customer],
        }));
      })
    );
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlert({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Produksi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.tgl_rap_awal,
    pagination.tgl_rap_akhir,
    pagination.tgl_selesai_start,
    pagination.tgl_selesai_end,
    pagination.id_customer,
    pagination.baseline,
    pagination.status_approval,
  ]);

  useEffect(() => {
    fetchDropdownFilter();

    return () => setDropdown({});
  }, []);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index}>
                  {brg.nama_item}
                </li>
              );
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : '';
      const convert = data.substring(0, 3);

      if (convert === 'APP') return 'APPROVED';
      if (convert === 'VER') return 'VERIFIED';
      if (convert === 'REV') return 'REVISE';
      if (convert === 'REJ') return 'REJECT';
      if (convert === 'PEN') return 'PENDING';

      return 'DRAFT';
    };

    const checkEditButton = (status) => {
      const stat = checkStatus(status);

      if (
        stat.toUpperCase() === 'REVISE' ||
        stat.toUpperCase() === 'DRAFT' ||
        stat.toUpperCase() === 'APPROVED'
      )
        return true;

      return false;
    };

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi RAP</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>ATT</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <Th>Barang Jadi</Th>
            <ThFixed>Baseline</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataRAP.map((val, index) => (
            <Tr key={index}>
              <Td textCenter>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </Td>
              <Td>
                <div className="d-flex">
                  <ReadButton
                    onClick={() =>
                      history.push('/transaksi/rap/detail/' + val.id_rap)
                    }
                  />

                  {checkEditButton(val.status_approval) && (
                    <UpdateButton
                      onClick={() =>
                        history.push('/transaksi/rap/ubah/' + val.id_rap)
                      }
                    />
                  )}
                </div>
              </Td>
              <Td>
                <div>
                  {val.tgl_rap
                    ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_rap ?? '-'}</div>
              </Td>
              <Td>
                <div>
                  {val.tgl_spk
                    ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_spk ?? '-'}</div>
              </Td>
              <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>{val.att ? val.att : '-'}</Td>
              <Td>
                {val.tgl_selesai
                  ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                  : '-'}
              </Td>
              <Td>
                {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                  <BarangJadiCollapse data={val.barang_jadi} />
                ) : (
                  '-'
                )}
              </Td>
              <Td>{val.baseline ? val.baseline : '-'}</Td>
              <Td>
                <ApprovalStatusButton status={val.status_approval} />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!dataRAP || dataRAP.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mb-1">
          <b>List Data Rancangan Anggaran Produksi (RAP)</b>
        </div>
        <DataTable />
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="d-flex align-items-center mb-2">
            <Col md="10" className="d-flex">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(searchHandler, 1500)}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push('/transaksi/rap/spk')} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      <ModalFilterRAP
        dropdown={dropdown}
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default RAP;
