import { createContext, useState } from 'react';

export const RealisasiProduksiContext = createContext();

export const RealisasiProduksiProvider = ({ children }) => {
  const [dataItem, setDataItem] = useState({
    hardwood: [],
    plywood: [],
    fs: [],
    bp: [],
    lc: [],
    lcf: [],
    subkon: [],
    alat_mesin: [],
    overhead: [],
  });

  const [dataRealisasi, setDataRealisasi] = useState({
    hardwood: [],
    plywood: [],
    fs: [],
    bp: [],
    lc: [],
    lcf: [],
    subkon: [],
    alat_mesin: [],
    overhead: [],
  });

  const [dataHistory, setDataHistory] = useState({
    hardwood: [],
    plywood: [],
    fs: [],
    bp: [],
    lc: [],
    lcf: [],
    subkon: [],
    alat_mesin: [],
    overhead: [],
  });

  const [modalForm, setModalForm] = useState({
    tab: 'sf',
    action: 'create',
    data: null,
    showRegistrasi: false,
    showKontrak: false,
  });

  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);

  return (
    <RealisasiProduksiContext.Provider
      value={{
        dataItem,
        setDataItem,

        dataRealisasi,
        setDataRealisasi,

        dataHistory,
        setDataHistory,

        modalForm,
        setModalForm,

        modalKonfirmasi,
        setModalKonfirmasi,
      }}
    >
      {children}
    </RealisasiProduksiContext.Provider>
  );
};

export const withRealisasiProduksiProvider = (Component) => (props) => {
  return (
    <RealisasiProduksiProvider>
      <Component {...props} />
    </RealisasiProduksiProvider>
  );
};
