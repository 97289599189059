import { useContext } from 'react';
import { Row } from 'react-bootstrap';
import { PermintaanProduksiContext } from '../../Context';
import { TableBO } from './TableBO';

export const ContainerBO = ({ showHistory }) => {
  const { dataBO } = useContext(PermintaanProduksiContext);

  return (
    <Row>
      <TableBO
        jobOrder
        showTotalPrice
        title="List Biaya Overhead Dari Job Order"
        data={dataBO.jobOrder}
      />
      <TableBO
        showInputRow
        showTotalPrice
        title="List Permintaan Biaya Overhead"
        data={dataBO.permintaan}
      />
      {showHistory && (
        <TableBO
          readOnly
          title="History Permintaan Biaya Overhead"
          data={dataBO.history}
        />
      )}
    </Row>
  );
};
