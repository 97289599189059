import { Services } from '../../../services';

class BarangJadiSeriApi {
  page(params) {
    return Services.get('/barang_jadi_seri/page', { params });
  }
  single(params) {
    return Services.get('/barang_jadi_seri/single', { params });
  }
  create(data) {
    return Services.post('/barang_jadi_seri', data);
  }
  update(data) {
    return Services.put('/barang_jadi_seri', data);
  }
  delete(data) {
    return Services.post('/barang_jadi_seri/delete', data);
  }
  show(data) {
    return Services.put('/barang_jadi_seri/show', data);
  }
  hide(data) {
    return Services.put('/barang_jadi_seri/hide', data);
  }
}

export default new BarangJadiSeriApi();
