import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AnalisaBarangJadiApi } from 'api';
import { useSessionStorage } from 'hooks';

export const DropdownAnalisaBarangJadiContext = createContext();

export const DropdownAnalisaBarangJadiContextProvider = ({ children }) => {
  const FETCH_INTERVAL_MINUTES = 2 * 60 * 1000; // 2 MINUTES
  const [triggerFetch, setTriggerFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastFetch, setLastFetch] = useSessionStorage(
    'last_fetch_dropdown_analisa',
    null
  );
  const [dropdown, setDropdown] = useSessionStorage(
    'dropdown_analisa_barang_jadi',
    {
      deskripsi: [],
      part_kayu: [],
      tipe_sisi: [],
      tipe_finishing: [],
      spek_kayu: [],
      spek_plywood: [],
      tipe_produksi: [],
      bentuk: [],
      ukuran: [],
    }
  );
  const [selectData, setSelectData] = useSessionStorage(
    'select_data_analisa_barang_jadi',
    {
      tpfs: [],
      tplc: [],
      tpmp: [],
      tpbop: [],
      fnbp: [],
      fnsc: [],
      fnlc: [],
    }
  );

  const isRequireToRefetch = lastFetch
    ? Boolean(
        new Date()?.getTime() - new Date(lastFetch).getTime() >
          FETCH_INTERVAL_MINUTES
      )
    : true;

  const getDropdown = () => {
    setLoading(true);

    axios
      .all([
        AnalisaBarangJadiApi.getDropdownDeskripsi(),
        AnalisaBarangJadiApi.getDropdownPart(),
        AnalisaBarangJadiApi.getDropdownTipeSisi(),
        AnalisaBarangJadiApi.getDropdownTipeFinishing(),
        AnalisaBarangJadiApi.getDropdownSpekKayu(),
        AnalisaBarangJadiApi.getDropdownSpekPlywood(),
        AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: 'tipe_prd' }),
        AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: 'bentuk' }),
        AnalisaBarangJadiApi.getDropdownAnalisaPenunjang({ tipe: 'ukuran' }),

        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'fs' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'lc' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'mp' }),
        AnalisaBarangJadiApi.getDropdownTipeBarangJadi({ tipe: 'bop' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'bp' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'sc' }),
        AnalisaBarangJadiApi.getDropdownFinishingBarangJadi({ tipe: 'lc' }),
      ])
      .then(
        axios.spread(
          (
            resDeskripsi,
            resPartKayu,
            resTipeSisi,
            resTipeFinishing,
            resSpekKayu,
            resSpekPlywood,
            resTipeProduksi,
            resBentuk,
            resUkuran,
            resTpfs,
            resTplc,
            resTpmp,
            resTpbop,
            resFnbp,
            resFnsc,
            resFnlc
          ) => {
            const deskripsi = resDeskripsi.data.data ?? [];
            const part_kayu = resPartKayu.data.data ?? [];
            const tipe_sisi = resTipeSisi.data.data ?? [];
            const tipe_finishing = resTipeFinishing.data.data ?? [];
            const spek_kayu = resSpekKayu.data.data ?? [];
            const spek_plywood = resSpekPlywood.data.data ?? [];
            const tipe_produksi = resTipeProduksi.data.data ?? [];
            const bentuk = resBentuk.data.data ?? [];
            const ukuran = resUkuran.data.data ?? [];

            const tpfs = resTpfs.data.data ?? [];
            const tpmp = resTpmp.data.data ?? [];
            const tplc = resTplc.data.data ?? [];
            const tpbop = resTpbop.data.data ?? [];
            const fnbp = resFnbp.data.data ?? [];
            const fnsc = resFnsc.data.data ?? [];
            const fnlc = resFnlc.data.data ?? [];

            const dataDropdown = {
              deskripsi,
              part_kayu,
              tipe_sisi,
              tipe_finishing,
              spek_kayu,
              spek_plywood,
              tipe_produksi,
              bentuk,
              ukuran,
            };

            const dataSelect = {
              tpfs,
              tpmp,
              tplc,
              tpbop,
              fnbp,
              fnsc,
              fnlc,
            };

            setDropdown(dataDropdown);
            setSelectData(dataSelect);
            setLastFetch(new Date());
          }
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (triggerFetch && isRequireToRefetch) {
      getDropdown();
    }

    return () => {
      setDropdown({
        deskripsi: [],
        part_kayu: [],
        tipe_sisi: [],
        tipe_finishing: [],
        spek_kayu: [],
        spek_plywood: [],
        tipe_produksi: [],
        bentuk: [],
        ukuran: [],
      });

      setSelectData({
        tpfs: [],
        tplc: [],
        tpmp: [],
        tpbop: [],
        fnbp: [],
        fnsc: [],
        fnlc: [],
      });

      setLoading(false);
    };
  }, [triggerFetch]);

  return (
    <DropdownAnalisaBarangJadiContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        selectData,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownAnalisaBarangJadiContext.Provider>
  );
};

export const withDropdownAnalisaBarangJadiContextProvider =
  (Component) => (props) => {
    return (
      <DropdownAnalisaBarangJadiContextProvider>
        <Component {...props} />
      </DropdownAnalisaBarangJadiContextProvider>
    );
  };
