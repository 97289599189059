import { AnalisaBarangJadiApi } from 'api';

export const getHargaKubikasi = async ({
  id_jenis_kayu,
  id_part_kayu,
  t_raw,
  w_raw,
}) => {
  if (id_jenis_kayu && id_part_kayu && t_raw && w_raw) {
    let harga = '0';

    await AnalisaBarangJadiApi.getHargaKubikasiKayu({
      id_jenis_kayu: id_jenis_kayu,
      id_part_kayu: id_part_kayu,
      t: t_raw,
      w: w_raw,
    })
      .then((res) => {
        harga = parseFloat(res?.data?.data?.harga_rata_rata_per_kubik ?? 0);
      })
      .catch(() => (harga = '0'));

    return harga;
  }
};
