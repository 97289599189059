import React, { useState, useEffect } from 'react';
import { Card, Nav } from 'react-bootstrap';
import { DataStatus } from 'components';
import { JobOrderApi } from 'api';
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableUraianLabourCost,
  TableUraianLabourCostFinishing,
  TableUraianSubcontFinishing,
  TableListSummary,
} from '../Section';

const TabSection = ({
  dataHardwood,
  dataPlywood,
  dataTPFS,
  dataTPLC,
  dataTPMP,
  dataTPBOP,
  dataFNBP,
  dataFNSC,
  dataFNLC,
  dataUraianLabourCost,
  dataUraianLabourCostFinishing,
  dataUraianSubcontFinishing,
}) => {
  const [dataTipeFinishing, setDataTipeFinishing] = useState([]);
  const [tabs, setTabs] = useState('bahan_baku');
  const [loading, setLoading] = useState({
    tipeFinishing: false,
  });

  const getUniqueTipeFinishing = () => {
    // fungsi mengambil id tipe finishing yang unique
    const listBahanBaku = [...dataHardwood, ...dataPlywood];
    let uniqueTipeFinishing = []; // menyimpan obj unique id tipe finishing

    // cari unique id tipe finishing
    listBahanBaku?.map((item) => {
      if (
        item?.id_finishing_barang_jadi !== '0' &&
        Boolean(!uniqueTipeFinishing.includes(item?.id_finishing_barang_jadi))
      ) {
        uniqueTipeFinishing.push(item?.id_finishing_barang_jadi);
      }
    });

    uniqueTipeFinishing = uniqueTipeFinishing?.map((item) => {
      return dataTipeFinishing.find(
        (val) => val?.id_finishing_barang_jadi === item
      );
    });

    return uniqueTipeFinishing;
  };

  const getTotalLuasTipeFinishing = () => {
    // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunakan sbg konst pada tab 3)
    const uniqueTipeFinishing = getUniqueTipeFinishing();
    const listBahanBaku = [...dataHardwood, ...dataPlywood];
    let totalLuasTipeFinishing = []; // menyimpan total luas tiap tipe finishing

    // hitung total luas tiap tipe finishing
    uniqueTipeFinishing?.map((val) => {
      const totalLuas = listBahanBaku
        ?.filter(
          (item) =>
            item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi
        )
        .reduce((acc, { total_luas }) => acc + parseFloat(total_luas ?? 0), 0);
      totalLuasTipeFinishing.push({
        ...val,
        total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6),
      });
    });
    return totalLuasTipeFinishing;
  };

  const getInitialData = async () => {
    setLoading({ ...loading, tipeFinishing: true });

    return await JobOrderApi.getDropdownTipeFinishing()
      .then((res) => {
        const data = res?.data?.data ?? [];
        setDataTipeFinishing(data);
      })
      .catch(() => window.alert('Gagal memuat data finishing!'))
      .finally(() => setLoading({ ...loading, tipeFinishing: false }));
  };

  const getGrandTotal = () => {
    const uniqueTipe = getUniqueTipeFinishing().map(
      (val) => val?.id_finishing_barang_jadi
    );
    let totalHardwood = 0;
    let totalPlywood = 0;
    let totalTPFS = 0;
    let totalTPLC = 0;
    let totalTPMP = 0;
    let totalTPBOP = 0;
    let totalFNBP = 0;
    let totalFNSC = 0;
    let totalFNLC = 0;
    let totalUraianLC = 0;
    let totalUraianLCF = 0;
    let totalUraianSF = 0;

    const totalHW = dataHardwood.reduce((prev, val) => {
      const countTotalUnit = parseFloat(
        (parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.t_raw).toFixed(6) *
          parseFloat(val.w_raw).toFixed(6) *
          parseFloat(val.l_raw).toFixed(6)) /
          1000000
      ).toFixed(6);

      const totalUnit =
        parseFloat(countTotalUnit) < 0.000001
          ? '0.000001'
          : parseFloat(countTotalUnit);
      const totalPrice = Math.round(
        parseFloat(totalUnit) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta)
      );
      const checkPrice = val.is_header ? 0 : totalPrice;
      totalHardwood = parseInt(checkPrice);

      return prev + totalHardwood;
    }, 0);

    dataPlywood.map((val) => {
      const total = Math.round(
        parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.unit_price ?? 0) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalPlywood = parseInt(totalPlywood) + parseInt(total || 0);
    });

    dataTPFS.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPFS = parseInt(totalTPFS) + parseInt(total);
    });

    dataTPLC.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPLC = parseInt(totalTPLC) + parseInt(total);
    });

    dataTPMP.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPMP = parseInt(totalTPMP) + parseInt(total);
    });

    dataTPBOP.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalTPBOP = parseInt(totalTPBOP) + parseInt(total);
    });

    dataFNBP.map((val) => {
      if (
        val.id_finishing_barang_jadi !== '0' &&
        uniqueTipe.includes(val.id_finishing_barang_jadi)
      ) {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseFloat(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNBP = parseInt(totalFNBP) + parseInt(total);
      }
    });

    dataFNSC.map((val) => {
      if (
        val.id_finishing_barang_jadi !== '0' &&
        uniqueTipe.includes(val.id_finishing_barang_jadi)
      ) {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseFloat(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNSC = parseInt(totalFNSC) + parseInt(total);
      }
    });

    dataFNLC.map((val) => {
      if (
        val.id_finishing_barang_jadi !== '0' &&
        uniqueTipe.includes(val.id_finishing_barang_jadi)
      ) {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
            parseFloat(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
        );
        totalFNLC = parseInt(totalFNLC) + parseInt(total);
      }
    });

    dataUraianLabourCost.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalUraianLC = parseInt(totalUraianLC) + parseInt(total);
    });

    dataUraianLabourCostFinishing.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalUraianLCF = parseInt(totalUraianLCF) + parseInt(total);
    });

    dataUraianSubcontFinishing.map((val) => {
      const total = Math.round(
        parseFloat(val.qty).toFixed(6) *
          parseFloat(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
      );
      totalUraianSF = parseInt(totalUraianSF) + parseInt(total);
    });

    const totalFinishing =
      parseInt(totalFNBP) + parseInt(totalUraianSF) + parseInt(totalUraianLCF);

    const grandTotal =
      parseInt(totalHW) +
      parseInt(totalPlywood) +
      parseInt(totalTPFS) +
      parseInt(totalTPLC) +
      parseInt(totalTPMP) +
      parseInt(totalTPBOP) +
      parseInt(totalFinishing);

    const summaryBiaya = [
      { kode: 'hw', nama_biaya: 'Hardwood', subtotal: parseInt(totalHW) },
      { kode: 'pw', nama_biaya: 'Plywood', subtotal: parseInt(totalPlywood) },
      {
        kode: 'fs',
        nama_biaya: 'Factory Supply',
        subtotal: parseInt(totalTPFS),
      },
      { kode: 'lc', nama_biaya: 'Labour Cost', subtotal: parseInt(totalTPLC) },
      {
        kode: 'mp',
        nama_biaya: 'Machine Process',
        subtotal: parseInt(totalTPMP),
      },
      {
        kode: 'bop',
        nama_biaya: 'Overhead Pabrik',
        subtotal: parseInt(totalTPBOP),
      },
      {
        kode: 'fin',
        nama_biaya: 'Finishing',
        subtotal: parseInt(totalFinishing),
      },
      {
        kode: 'ulc',
        nama_biaya: 'Uraian Labour Cost',
        subtotal: parseInt(totalUraianLC),
      },
      {
        kode: 'ulcf',
        nama_biaya: 'Uraian Labour Cost Finishing',
        subtotal: parseInt(totalUraianLCF),
      },
      {
        kode: 'usf',
        nama_biaya: 'Uraian Subcont Finishing',
        subtotal: parseInt(totalUraianSF),
      },
    ];

    return summaryBiaya;
    // return RupiahConvert(grandTotal.toString()).detail
  };

  const getGrandTotalPrice = () => {
    const data = getGrandTotal();

    const totalBahanBaku = data
      .filter((item) => item.kode === 'hw' || item.kode === 'pw')
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalPenunjangProduksi = data
      .filter(
        (item) =>
          item.kode === 'fs' ||
          item.kode === 'lc' ||
          item.kode === 'mp' ||
          item.kode === 'bop'
      )
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalFinishing = data
      .filter((item) => item.kode === 'fin')
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    return totalBahanBaku + totalPenunjangProduksi + totalFinishing;
  };

  const tabsConfig = [
    {
      tab: 'bahan_baku',
      label: 'Analisa Bahan Baku',
      component: () => (
        <>
          <TableListHardwood dataHardwood={dataHardwood} />
          <TableListPlywood dataPlywood={dataPlywood} />
        </>
      ),
    },
    {
      tab: 'penunjang',
      label: 'Analisa Penunjang Produksi',
      component: () => {
        return (
          <>
            {loading.tipe ? (
              <div style={{ height: '800px' }}>
                <DataStatus
                  loading={true}
                  text="Memuat data penunjang produksi"
                />
              </div>
            ) : (
              <>
                <TableListTPFS dataTPFS={dataTPFS} />
                <TableListTPLC dataTPLC={dataTPLC} />
                <TableUraianLabourCost
                  dataUraianLabourCost={dataUraianLabourCost}
                />
                <TableListTPMP dataTPMP={dataTPMP} />
                <TableListTPBOP dataTPBOP={dataTPBOP} />
              </>
            )}
          </>
        );
      },
    },
    {
      tab: 'finishing',
      label: 'Analisa Finishing',
      component: () => {
        const totalLuasTipeFinishing = getTotalLuasTipeFinishing() ?? [];

        return (
          <>
            {loading.tipeFinishing ? (
              <DataStatus loading text="Memuat data . . ." />
            ) : totalLuasTipeFinishing.length > 0 ? (
              totalLuasTipeFinishing.map((val) => {
                return (
                  <div className="mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="pl-2 font-weight-bold text-uppercase">
                        {val.nama_finishing_barang_jadi}
                      </h5>
                    </div>
                    <TableListFNBP
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      defaultKonst={val.total_luas_tipe_finishing}
                      dataFNBP={dataFNBP}
                    />
                    <TableListFNSC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      defaultKonst={val.total_luas_tipe_finishing}
                      dataFNSC={dataFNSC}
                    />
                    <TableUraianSubcontFinishing
                      dataUraianLabourCost={dataUraianSubcontFinishing}
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                    />
                    <TableListFNLC
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                      defaultKonst={val.total_luas_tipe_finishing}
                      dataFNLC={dataFNLC}
                    />
                    <TableUraianLabourCostFinishing
                      dataUraianLabourCost={dataUraianLabourCostFinishing}
                      id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                    />
                  </div>
                );
              })
            ) : (
              <DataStatus text="Tidak ada data tipe finishing" />
            )}
          </>
        );
      },
    },
  ];

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <>
      {/* Card Analisa Barang Jadi */}
      <Card className="mb-4">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <TabsNav key={index} tab={val.tab} label={val.label} />
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            ({ tab, component: Component }, index) =>
              tab === tabs && <Component key={index} />
          )}
        </Card.Body>
      </Card>

      {/* Card Summary Biaya */}
      <h6 className="font-weight-bold px-2 mt-5">Summary Biaya</h6>
      <Card className="mb-4">
        <Card.Body>
          <TableListSummary getGrandTotal={getGrandTotal} />
        </Card.Body>
      </Card>
    </>
  );
};

export default TabSection;
