import { Modal } from 'react-bootstrap';
import { IoWarning } from 'react-icons/io5';
import { ActionButton } from 'components';

export const AlertModal = ({ alertModal, setAlertModal }) => {
  return (
    <Modal
      closeButton
      show={alertModal.show}
      onHide={() => setAlertModal((prev) => ({ ...prev, show: false }))}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="mb-0 text-capitalize">Warning</h6>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <IoWarning size={80} color="red" className="mb-3" />
        <p className="text-capitalize">{alertModal?.message}</p>
        <ActionButton
          variant="primary"
          text="Tutup"
          className="m-1 px-4"
          onClick={() => setAlertModal((prev) => ({ ...prev, show: false }))}
        />
      </Modal.Body>
    </Modal>
  );
};
