import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { DatePicker, InfoItemHorizontal, Input } from 'components';
import { DateConvert } from 'utilities';
import { TransferSisaProduksiApi } from 'api';

export const InfoSection = ({ action = 'form' }) => {
  const { values, touched, errors, setValues } = useFormikContext();

  const getNomor = (date) => {
    const tanggal = DateConvert(new Date(date)).default;
    TransferSisaProduksiApi.no_baru({ tanggal })
      .then((res) => {
        setValues((prev) => ({
          ...prev,
          tgl_transfer_sisa_produksi: date,
          no_transfer_sisa_produksi: res.data.data,
        }));
      })
      .catch((err) =>
        window.alert(`Gagal memuat nomor ${err.response.data.message ?? ''}`)
      );
  };

  return (
    <>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        Detail Data Realisasi Produksi
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="6">
              <InfoItemHorizontal
                label="Customer"
                text={values?.nama_customer}
              />
              <InfoItemHorizontal label="Proyek" text={values?.nama_proyek} />
              <InfoItemHorizontal label="No. SPK" text={values?.no_spk} />
              <InfoItemHorizontal label="No. RAP" text={values?.no_rap} />
            </Col>
            <Col lg="6">
              <InfoItemHorizontal
                label="No. Job Order"
                text={values?.no_job_order}
              />
              <InfoItemHorizontal
                label="Item Job Order"
                text={values?.nama_barang_jadi}
              />
              <InfoItemHorizontal
                label="Tgl. Realisasi Produksi "
                text={
                  values?.tgl_realisasi_produksi
                    ? DateConvert(new Date(values?.tgl_realisasi_produksi))
                        .detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Realisasi Produksi"
                text={values?.no_realisasi_produksi ?? '-'}
              />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col lg="3" md="6">
              {action === 'form' ? (
                <DatePicker
                  label="Tgl. Transfer Sisa Produksi"
                  selected={values?.tgl_transfer_sisa_produksi}
                  onChange={(date) => getNomor(date)}
                  error={
                    errors.tgl_transfer_sisa_produksi &&
                    touched.tgl_transfer_sisa_produksi
                  }
                  errorText={errors.tgl_transfer_sisa_produksi}
                />
              ) : (
                <InfoItemHorizontal
                  label="Tgl. Transfer Sisa Produksi"
                  text={
                    values?.tgl_transfer_sisa_produksi
                      ? DateConvert(new Date(values.tgl_transfer_sisa_produksi))
                          .detail
                      : '-'
                  }
                />
              )}
            </Col>

            <Col lg="3" md="6" className="offset-lg-3">
              {action === 'form' ? (
                <Input
                  disabled
                  label="No. Transfer Sisa Produksi"
                  value={values?.no_transfer_sisa_produksi}
                />
              ) : (
                <InfoItemHorizontal
                  label="No. Transfer Sisa Produksi"
                  text={values?.no_transfer_sisa_produksi ?? '-'}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
