import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSPK = ({ dataSPK }) => {
  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? 'text-primary' : ''}
        style={{ cursor: link ? 'pointer' : 'default' }}
      >
        {value}
      </td>
    </tr>
  );

  const ShowData = ({ title, text, line }) => (
    <div>
      <small
        className="text-secondary"
        style={{ opacity: 0.9, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div className="text-dark">
        <div style={{ fontSize: 14 }} className="text-break">
          {text ? text : '-'}
        </div>
      </div>

      {line && <hr className="my-2" />}
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  dataSPK.tgl_penawaran
                    ? DateConvert(new Date(dataSPK.tgl_penawaran)).detail
                    : '-'
                }
              />
              <InfoItem title="No. Penawaran" value={dataSPK.no_penawaran} />
              <InfoItem
                title="No. Peluang"
                value={dataSPK?.no_peluang ?? '-'}
              />
            </tbody>
          </table>
        </Col>

        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem title="Customer" value={dataSPK.nama_customer} />
              <InfoItem title="ATT" value={dataSPK.att} />
              <InfoItem
                title="Realisasi Penawaran"
                value={
                  dataSPK.tgl_selesai
                    ? DateConvert(new Date(dataSPK.tgl_selesai)).detail
                    : '-'
                }
              />
              <InfoItem title="Proyek" value={dataSPK.nama_proyek ?? '-'} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <ShowData title="Tgl. SPK" text={dataSPK.tgl_spk} line />
          <ShowData title="No. SPK" text={dataSPK.no_spk} line />
        </Col>
        <Col>
          <ShowData
            title="Tgl. Selesai Barang Jadi"
            text={dataSPK.tgl_selesai_spk}
            line
          />
          <ShowData
            title="Segment Pasar"
            text={dataSPK.nama_segmentasi_pasar}
            line
          />
        </Col>
      </Row>
    </>
  );
};

export default InfoSPK;
