/* eslint-disable */

import {
  IoCashOutline,
  IoDocumentTextOutline,
  IoServerOutline,
  IoSpeedometerOutline,
} from 'react-icons/io5';

// IMPORT LOGO CORE
import Logo from '../assets/image/LogoSadhana.png';

// PAGE
import {
  // Tipe Produksi
  BarangJadiTipeProduksi,

  // Bentuk
  BarangJadiBentuk,

  // Ukuran
  BarangJadiUkuran,

  // Seri
  BarangJadiSeri,

  // Barang Jadi
  RegistrasiBarangJadi,
  TambahRegistrasiBarangJadi,
  UpdateRegistrasiBarangJadi,
  DetailRegistrasiBarangJadi,

  // Tipe Finishing
  FinishingBarangJadi,
  TambahFinishingBarangJadi,
  EditFinishingBarangJadi,
  DetailFinishingBarangJadi,

  // Deskripsi Bahan Baku
  DeskripsiBahanBaku,

  // Template Penunjang Produksi
  ListTemplatePenunjangProduksi,
  TambahTemplatePenunjangProduksi,
  UbahTemplatePenunjangProduksi,
  DetailTemplatePenunjangProduksi,

  // Sumber Barang Jadi
  Kelompok,

  // Analisa Barang Jadi
  AnalisaBarangJadi,
  FormAnalisaBarangJadi,
  FormAnalisaBarangJadiDetail,

  // Filter Analisa Barang Jadi
  AnalisaBarangJadiFilter,

  // Rencana Anggaran Produksi
  ListRAP,
  ListSPKRAP,
  TambahRAP,
  UbahRAP,
  DetailRAP,

  // Job Order
  JobOrder,
  ListRap,
  AddJobOrder,
  UpdateJobOrder,
  DetailJobOrder,

  // Permintaan Produksi
  PermintaanProduksiList,
  PermintaanProduksiJobOrderList,
  PermintaanProduksiTambah,
  PermintaanProduksiUbah,
  PermintaanProduksiDetail,

  // Realisasi Produksi
  ListJobOrderRealisasiProduksi,
  ListRealisasiProduksi,
  RealisasiProduksi,
  DetailRealisasiProduksi,

  // Transfer Sisa Produksi
  TransferSisaProduksiList,
  PreformTransferSisaProduksiList,
  TambahTransferSisaProduksi,
  DetailTransferSisaProduksi,

  // Close Job Order
  AddCloseJobOrder,
  CloseJobOrder,
  DetailCloseJobOrder,

  // Mutasi Barang Jadi
  MutasiBarangJadi,

  // Progress Produksi
  LogProgressProduksi,
  ProgressProduksi,

  // Tipe Barang Jadi | Sub Tipe Produksi
  TambahTipeBarangJadi,
  TipeBarangJadi,
  UpdateTipeBarangJadi,
  DetailTipeBarangJadi,

  // Jenis Barang Jadi
  JenisBarangJadi,
} from '../pages/Produksi';

export default {
  LOGO: Logo,
  MODUL: 'Produksi',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'PRD'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: [
        'SUPA',
        'PRD',
        'PRD_MAS_KYU',
        'PRD_MAS_JBJ',
        'PRD_MAS_BRJ',
        'PRD_MAS_TP',
        'PRD_MAS_STP',
        'PRD_MAS_TF',
        'PRD_MAS_TPP',
        'PRD_MAS_SBJ',
      ],
      menu: [
        {
          text: 'Tipe Produksi',
          link: '/master/barang-jadi-tipe-produksi',
          hak: ['SUPA', 'PRD_MAS_BJT'],
        },
        {
          text: 'Bentuk',
          link: '/master/barang-jadi-bentuk',
          hak: ['SUPA', 'PRD_MAS_BJB'],
        },
        {
          text: 'Ukuran',
          link: '/master/barang-jadi-ukuran',
          hak: ['SUPA', 'PRD_MAS_BJU'],
        },
        {
          text: 'Seri',
          link: '/master/barang-jadi-seri',
          hak: ['SUPA', 'PRD_MAS_BJS'],
        },
        {
          text: 'Barang Jadi',
          link: '/master/registrasi-barang-jadi',
          hak: ['SUPA', 'PRD_MAS_BRJ'],
        },
        {
          text: 'Tipe Finishing',
          link: '/master/finishing-barang-jadi',
          hak: ['SUPA', 'PRD_MAS_TF'],
        },
        {
          text: 'Deskripsi Bahan Baku',
          link: '/master/deskripsi-bahan-baku',
          hak: ['SUPA', 'PRD_MAS_DBB'],
        },
        {
          text: 'Template Penunjang Produksi',
          link: '/master/template-penunjang-produksi',
          hak: ['SUPA', 'PRD_MAS_TPP'],
        },
        {
          text: 'Sumber Barang Jadi',
          link: '/master/sumber-barang-jadi',
          hak: ['SUPA', 'PRD_MAS_SBJ'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: [
        'SUPA',
        'PRD',
        'PRD_TRN_JO',
        'PRD_TRN_PPR',
        'PRD_TRN_RAP',
        'PRD_TRN_REP',
        'PRD_MAS_BRJ',
      ],
      menu: [
        {
          text: 'Analisa Barang Jadi',
          link: '/transaksi/analisa-barang-jadi',
          hak: ['SUPA', 'PRD_TRN_ANB'],
        },
        {
          text: 'Filter Analisa Barang Jadi',
          link: '/transaksi/filter-analisa-barang-jadi',
          hak: ['SUPA', 'PRD_TRN_ANB'],
        },
        {
          text: 'Rencana Anggaran Produksi',
          link: '/transaksi/rap',
          hak: ['SUPA', 'PRD_TRN_RAP'],
        },
        {
          text: 'Job Order',
          link: '/transaksi/job-order',
          hak: ['SUPA', 'PRD_TRN_JO'],
        },
        {
          text: 'Permintaan Produksi',
          link: '/transaksi/permintaan-produksi',
          hak: ['SUPA', 'PRD_TRN_PPR'],
        },
        {
          text: 'Realisasi Produksi',
          link: '/transaksi/realisasi-produksi',
          hak: ['SUPA', 'PRD_TRN_REP'],
        },
        {
          text: 'Transfer Sisa Produksi',
          link: '/transaksi/transfer-sisa-produksi',
          hak: ['SUPA', 'PRD_TRN_TSP'],
        },
        {
          text: 'Close Job Order',
          link: '/transaksi/close-job-order',
          hak: ['SUPA', 'PRD_TRN_CLJ'],
        },
        {
          text: 'Mutasi Barang Jadi',
          link: '/transaksi/mutasi-barang-jadi',
          hak: ['SUPA', 'PRD_TRN_MBJ'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['SUPA', 'PRD'],
      menu: [
        {
          text: 'Progress Produksi',
          link: '/laporan/progress-produksi',
          hak: ['SUPA', 'PRD_RPT_LPP'],
        },
      ],
    },
  ],

  ROUTES: [
    // MASTER
    {
      exact: true,
      path: '/master/registrasi-barang-jadi',
      page: RegistrasiBarangJadi,
      hak: ['SUPA', 'PRD_MAS_BRJ'],
    },
    {
      exact: true,
      path: '/master/registrasi-barang-jadi/tambah',
      page: TambahRegistrasiBarangJadi,
      hak: ['SUPA', 'PRD_MAS_BRJ'],
    },
    {
      exact: true,
      path: '/master/registrasi-barang-jadi/update/:id_item_buaso',
      page: UpdateRegistrasiBarangJadi,
      hak: ['SUPA', 'PRD_MAS_BRJ'],
    },
    {
      exact: true,
      path: '/master/registrasi-barang-jadi/detail/:id_item_buaso',
      page: DetailRegistrasiBarangJadi,
      hak: ['SUPA', 'PRD_MAS_BRJ'],
    },
    {
      exact: true,
      path: '/master/deskripsi-bahan-baku',
      page: DeskripsiBahanBaku,
      hak: ['SUPA', 'PRD_MAS_TP'],
    },
    // Template Penunjang Produksi
    {
      exact: true,
      path: '/master/template-penunjang-produksi',
      page: ListTemplatePenunjangProduksi,
      hak: ['SUPA', 'PRD_MAS_TPP'],
    },
    {
      exact: true,
      path: '/master/template-penunjang-produksi/tambah',
      page: TambahTemplatePenunjangProduksi,
      hak: ['SUPA', 'PRD_MAS_TPP'],
    },
    {
      exact: true,
      path: '/master/template-penunjang-produksi/detail/:id',
      page: DetailTemplatePenunjangProduksi,
      hak: ['SUPA', 'PRD_MAS_TPP'],
    },
    {
      exact: true,
      path: '/master/template-penunjang-produksi/ubah/:id',
      page: UbahTemplatePenunjangProduksi,
      hak: ['SUPA', 'PRD_MAS_TPP'],
    },

    // Sumber Barang Jadi
    {
      exact: true,
      path: '/master/sumber-barang-jadi',
      page: Kelompok,
      hak: ['SUPA', 'PRD_MAS_SBJ'],
    },

    {
      exact: true,
      path: '/master/barang-jadi-tipe-produksi',
      page: BarangJadiTipeProduksi,
      hak: ['SUPA', 'PRD_MAS_BJT'],
    },
    {
      exact: true,
      path: '/master/barang-jadi-bentuk',
      page: BarangJadiBentuk,
      hak: ['SUPA', 'PRD_MAS_BJB'],
    },
    {
      exact: true,
      path: '/master/barang-jadi-ukuran',
      page: BarangJadiUkuran,
      hak: ['SUPA', 'PRD_MAS_BJU'],
    },
    {
      exact: true,
      path: '/master/barang-jadi-seri',
      page: BarangJadiSeri,
      hak: ['SUPA', 'PRD_MAS_BJS'],
    },
    // /MASTER

    // TIPE BARANG JADI
    {
      exact: true,
      path: '/master/tipe-barang-jadi',
      page: TipeBarangJadi,
      hak: ['SUPA', 'PRD_MAS_STP'],
    },
    {
      exact: true,
      path: '/master/tipe-barang-jadi/tambah',
      page: TambahTipeBarangJadi,
      hak: ['SUPA', 'PRD_MAS_STP'],
    },

    {
      exact: true,
      path: '/master/tipe-barang-jadi/update/:id_tipe_barang_jadi',
      page: UpdateTipeBarangJadi,
      hak: ['SUPA', 'PRD_MAS_STP'],
    },
    {
      exact: true,
      path: '/master/tipe-barang-jadi/detail/:id_tipe_barang_jadi',
      page: DetailTipeBarangJadi,
      hak: ['SUPA', 'PRD_MAS_STP'],
    },

    // Finishing barang jadi
    {
      exact: true,
      path: '/master/finishing-barang-jadi',
      page: FinishingBarangJadi,
      hak: ['SUPA', 'PRD_MAS_TF'],
    },
    {
      exact: true,
      path: '/master/finishing-barang-jadi/tambah',
      page: TambahFinishingBarangJadi,
      hak: ['SUPA', 'PRD_MAS_TF'],
    },
    {
      exact: true,
      path: '/master/finishing-barang-jadi/update/:id',
      page: EditFinishingBarangJadi,
      hak: ['SUPA', 'PRD_MAS_TF'],
    },
    {
      exact: true,
      path: '/master/finishing-barang-jadi/detail/:id',
      page: DetailFinishingBarangJadi,
      hak: ['SUPA', 'PRD_MAS_TF'],
    },

    // JENIS BARANG JADI
    {
      exact: true,
      path: '/master/jenis-barang-jadi',
      page: JenisBarangJadi,
      hak: ['SUPA', 'PRD_MAS_JBJ'],
    },

    // TRANSAKSI
    // ANALISA BARANG JADI
    {
      exact: true,
      path: '/transaksi/analisa-barang-jadi',
      page: AnalisaBarangJadi,
      hak: ['SUPA', 'PRD_TRN_ANB'],
    },
    {
      exact: true,
      path: '/transaksi/analisa-barang-jadi/form/:id',
      page: FormAnalisaBarangJadi,
      hak: ['SUPA', 'PRD_TRN_ANB'],
    },
    {
      exact: true,
      path: '/transaksi/analisa-barang-jadi/detail/:id',
      page: FormAnalisaBarangJadiDetail,
      hak: ['SUPA', 'PRD_TRN_ANB'],
    },
    {
      exact: true,
      path: '/transaksi/filter-analisa-barang-jadi',
      page: AnalisaBarangJadiFilter,
      hak: ['SUPA', 'PRD_TRN_ANB'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-produksi',
      page: PermintaanProduksiList,
      hak: ['SUPA', 'PRD_TRN_PPR'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-produksi/list-job-order',
      page: PermintaanProduksiJobOrderList,
      hak: ['SUPA', 'PRD_TRN_PPR'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-produksi/tambah/:id_job_order',
      page: PermintaanProduksiTambah,
      hak: ['SUPA', 'PRD_TRN_PPR'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-produksi/ubah/:id_permintaan_produksi',
      page: PermintaanProduksiUbah,
      hak: ['SUPA', 'PRD_TRN_PPR'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-produksi/detail/:id_permintaan_produksi',
      page: PermintaanProduksiDetail,
      hak: ['SUPA', 'PRD_TRN_PPR'],
    },
    {
      exact: true,
      path: '/transaksi/rap',
      page: ListRAP,
      hak: ['SUPA', 'PRD_TRN_RAP'],
    },
    {
      exact: true,
      path: '/transaksi/rap/spk',
      page: ListSPKRAP,
      hak: ['SUPA', 'PRD_TRN_RAP'],
    },
    {
      exact: true,
      path: '/transaksi/rap/detail/:id',
      page: DetailRAP,
      hak: ['SUPA', 'PRD_TRN_RAP'],
    },
    {
      exact: true,
      path: '/transaksi/rap/tambah/:id',
      page: TambahRAP,
      hak: ['SUPA', 'PRD_TRN_RAP'],
    },
    {
      exact: true,
      path: '/transaksi/rap/ubah/:id',
      page: UbahRAP,
      hak: ['SUPA', 'PRD_TRN_RAP'],
    },
    // JOB ORDER
    {
      exact: true,
      path: '/transaksi/job-order',
      page: JobOrder,
      hak: ['SUPA', 'PRD_TRN_JO'],
    },
    {
      exact: true,
      path: '/transaksi/job-order/list-rap',
      page: ListRap,
      hak: ['SUPA', 'PRD_TRN_JO'],
    },
    {
      exact: true,
      path: '/transaksi/job-order/tambah/:id',
      page: AddJobOrder,
      hak: ['SUPA', 'PRD_TRN_JO'],
    },
    {
      exact: true,
      path: '/transaksi/job-order/update/:id',
      page: UpdateJobOrder,
      hak: ['SUPA', 'PRD_TRN_JO'],
    },
    {
      exact: true,
      path: '/transaksi/job-order/detail/:id',
      page: DetailJobOrder,
      hak: ['SUPA', 'PRD_TRN_JO'],
    },

    // REALISASI PRODUKSI
    {
      exact: true,
      path: '/transaksi/realisasi-produksi',
      page: ListRealisasiProduksi,
      hak: ['SUPA', 'PRD_TRN_REP'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-produksi/job-order',
      page: ListJobOrderRealisasiProduksi,
      hak: ['SUPA', 'PRD_TRN_REP'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-produksi/realisasi/:id',
      page: RealisasiProduksi,
      hak: ['SUPA', 'PRD_TRN_REP'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-produksi/detail/:id',
      page: DetailRealisasiProduksi,
      hak: ['SUPA', 'PRD_TRN_REP'],
    },

    // Transfer Sisa Produksi
    {
      exact: true,
      path: '/transaksi/transfer-sisa-produksi',
      page: TransferSisaProduksiList,
      hak: ['SUPA', 'PRD_TRN_TSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-sisa-produksi/realisasi-produksi',
      page: PreformTransferSisaProduksiList,
      hak: ['SUPA', 'PRD_TRN_TSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-sisa-produksi/tambah/:id',
      page: TambahTransferSisaProduksi,
      hak: ['SUPA', 'PRD_TRN_TSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-sisa-produksi/detail/:id',
      page: DetailTransferSisaProduksi,
      hak: ['SUPA', 'PRD_TRN_TSP'],
    },

    // CLOSE JOB ORDER
    {
      exact: true,
      path: '/transaksi/close-job-order',
      page: CloseJobOrder,
      hak: ['SUPA', 'PRD_TRN_CLJ'],
    },
    {
      exact: true,
      path: '/transaksi/close-job-order/tambah/:id_job_order',
      page: AddCloseJobOrder,
      hak: ['SUPA', 'PRD_TRN_CLJ'],
    },
    {
      exact: true,
      path: '/transaksi/close-job-order/detail/:id_job_order',
      page: DetailCloseJobOrder,
      hak: ['SUPA', 'PRD_TRN_CLJ'],
    },

    // MUTASI BARANG JADI
    {
      exact: true,
      path: '/transaksi/mutasi-barang-jadi',
      page: MutasiBarangJadi,
      hak: ['SUPA', 'PRD_TRN_MBJ'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/laporan/progress-produksi',
      page: ProgressProduksi,
      hak: ['SUPA', 'PRD_RPT_LPP'],
    },
    {
      exact: true,
      path: '/laporan/progress-produksi/log',
      page: LogProgressProduksi,
      hak: ['SUPA', 'PRD_RPT_LPP'],
    },
  ],
};
