import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  AddButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { useSessionStorage } from 'hooks';
import { DateConvert, TableNumber } from 'utilities';
import { PermintaanProduksiApi } from 'api';
import { ModalFilterJobOrder, TickDone } from './Component';
import { withDropdownPermintaanProduksiContextProvider } from './Context';

const PermintaanProduksiJobOrderList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataListJobOrder, setDataListJobOrder] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_rap_start: undefined,
    tgl_rap_end: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    id_customer: undefined,
    id_proyek: undefined,
    id_barang_jadi: undefined,
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanProduksiApi.getListJobOrder({
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_rap_start: pagination.tgl_rap_start,
      tgl_rap_end: pagination.tgl_rap_end,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      id_customer: pagination.id_customer,
      id_proyek: pagination.id_proyek,
      id_barang_jadi: pagination.id_barang_jadi,
    })
      .then(({ data }) => {
        setDataListJobOrder(data.data);
        setPagination((prv) => ({
          ...prv,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig({
        show: locationState.alert.show,
        text: locationState.alert.text,
        variant: locationState.alert.variant,
      });
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Produksi');
    getInitialData();
    checkAlert();

    return () => {};
  }, [
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_rap_start,
    pagination.tgl_rap_end,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.id_customer,
    pagination.id_proyek,
    pagination.id_barang_jadi,
  ]);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col className="d-flex flex-row justify-content-start align-items-center">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(
                  (e) =>
                    setPagination((prv) => ({
                      ...prv,
                      q: e.target.value,
                      page: 1,
                    })),
                  1500
                )}
              />
              <FilterButton
                active={showFilter}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataListJobOrder?.length ? (
        <TableContent
          data={dataListJobOrder}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterJobOrder
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

const TableContent = ({ data = [], pagination, setPagination, history }) => {
  return (
    <>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        List Data Job Order Yang Siap Dibuatkan Permintaan Produksi
      </div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi RAP</ThFixed>
            <Th width={170}>Customer</Th>
            <Th width={170}>Proyek</Th>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th>Item Job Order</Th>
            <ThFixed>Tgl. Pengerjaan Job Order</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">
                {TableNumber(pagination.page, pagination.per_page, index)}
              </TdFixed>
              <Td>
                <div>{DateConvert(new Date(val.tgl_rap)).defaultDMY} </div>
                <div>{val.no_rap}</div>
              </Td>
              <Td>{val.nama_customer}</Td>
              <Td>{val.nama_proyek}</Td>
              <Td>
                <div>{DateConvert(new Date(val.tgl_job_order)).defaultDMY}</div>
                <div>{val.no_job_order} </div>
              </Td>
              <Td>
                <div className="d-flex justify-content-start align-items-start">
                  {val.nama_barang_jadi}{' '}
                  {val.qty_rap ? parseFloat(val.qty_rap) : ''} {val.nama_satuan}
                  {val.has_permintaan && (
                    <span style={{ width: '20px' }}>
                      <TickDone />
                    </span>
                  )}
                </div>
              </Td>

              <Td className="text-nowrap text-center">
                <div className="font-weight-bold">
                  {DateConvert(new Date(val.tgl_mulai_jo)).defaultDMY}
                </div>
                s/d
                <div className="font-weight-bold">
                  {DateConvert(new Date(val.tgl_selesai_jo)).defaultDMY}
                </div>
              </Td>
              <Td>
                <AddButton
                  onClick={() =>
                    history.push(
                      `/transaksi/permintaan-produksi/tambah/${val.id_job_order}`,
                      {
                        id_barang_jadi: val.id_barang_jadi,
                      }
                    )
                  }
                />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>

      {data?.length ? (
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prv) => ({
              ...prv,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPagination((prv) => ({
              ...prv,
              page: 1,
              per_page: e.target.value,
            }))
          }
        />
      ) : null}
    </>
  );
};

export default withDropdownPermintaanProduksiContextProvider(
  PermintaanProduksiJobOrderList
);
