import { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Alert, BackButton, DataStatus } from 'components';
import { TransferSisaProduksiApi } from 'api';
import { InfoSection, TableDataTransferSisaProduksi } from './Components';
import {
  TransferSisaProduksiContext,
  withTransferSisaProduksiContextProvider,
} from './Contexts';

const DetailTransferSisaProduksi = ({ setNavbarTitle }) => {
  const { id: id_transfer_sisa_produksi } = useParams();
  const history = useHistory();
  const { setListTransferSisa } = useContext(TransferSisaProduksiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsLoading(true);

    TransferSisaProduksiApi.single({ id_transfer_sisa_produksi })
      .then((res) => {
        const { detail, ...info } = res.data.data;

        setData(info);
        setListTransferSisa(detail);
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    tgl_transfer_sisa_produksi: data?.tgl_transfer_sisa_produksi ?? '',
    no_transfer_sisa_produksi: data?.no_transfer_sisa_produksi ?? '',
    id_job_order: data?.id_job_order ?? '',
    tgl_job_order: data?.tgl_job_order ?? '',
    no_job_order: data?.no_job_order ?? '',
    no_rap: data?.no_rap ?? '',
    no_spk: data?.no_spk ?? '',
    nama_customer: data?.nama_customer ?? '',
    nama_proyek: data?.nama_proyek ?? '',
    nama_barang_jadi: data?.nama_barang_jadi ?? '',
    qty_rap: data?.qty_rap ?? '',
    nama_satuan: data?.nama_satuan ?? '',
    id_realisasi_produksi: data?.id_realisasi_produksi ?? '',
    tgl_realisasi_produksi: data?.tgl_realisasi_produksi ?? '',
    no_realisasi_produksi: data?.no_realisasi_produksi ?? '',
  };

  useEffect(() => {
    setNavbarTitle('Transfer Sisa Produksi');
    getInitialData();
  }, [setNavbarTitle]);

  return (
    <>
      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      <Row className="d-flex justify-content-end align-items-end">
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Formik enableReinitialize initialValues={initialValues}>
          {() => (
            <>
              <InfoSection action="detail" />
              <TableDataTransferSisaProduksi />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default withTransferSisaProduksiContextProvider(
  DetailTransferSisaProduksi
);
