import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  BackButton,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  Th,
  Td,
} from 'components';
import { DateConvert, PageNumber as TableNumber } from 'utilities';
import { RAPApi } from 'api';
import { DetailSPK } from './Content';
import ModalFilterSPKRAP from './ModalFilterSPKRAP';

const ListSPKRAP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [dataSPKRAP, setDataSPKRAP] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    type: 'detail',
    id: '',
    show: false,
  });
  const [pagination, setPagination] = useState({
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_spk_awal: undefined,
    tgl_spk_akhir: undefined,
    tgl_selesai_start: undefined,
    tgl_selesai_end: undefined,
    id_customer: undefined,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [dropdown, setDropdown] = useState({
    customer: [{ value: null, label: 'Semua' }],
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    let query = {
      page: pagination.page,
      per_page: pagination.per_page,
      q: pagination.q,

      tgl_spk_awal: pagination.tgl_spk_awal,
      tgl_spk_akhir: pagination.tgl_spk_akhir,
      tgl_selesai_start: pagination.tgl_selesai_start,
      tgl_selesai_end: pagination.tgl_selesai_end,
      id_customer: pagination.id_customer,
    };

    RAPApi.getSPK(query)
      .then(({ data }) => {
        setDataSPKRAP(data.data);
        setPagination({
          ...pagination,
          data_count: data.data_count,
          total_page: data.total_page,
        });
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const fetchDropdownFilter = () => {
    Axios.all([RAPApi.dropdownFilter({ tipe: 'customer' })]).then(
      Axios.spread((resCustomer) => {
        const customer = resCustomer?.data?.data?.map((customer) =>
          Object({ value: customer.id_customer, label: customer.nama_customer })
        );

        setDropdown(() => ({
          customer: [{ value: null, label: 'Semua' }].concat(customer),
        }));
      })
    );
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert(locationState.alert);
    }
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Produksi');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.tgl_spk_awal,
    pagination.tgl_spk_akhir,
    pagination.tgl_selesai_start,
    pagination.tgl_selesai_end,
    pagination.id_customer,
  ]);

  useEffect(() => {
    fetchDropdownFilter();

    return () => setDropdown({});
  }, []);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index}>
                  {brg.nama_item}
                </li>
              );
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>ATT</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <Th>Barang Jadi</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataSPKRAP.map((val, index) => (
            <Tr key={index}>
              <Td textCenter>
                {TableNumber(pagination.page, pagination.per_page, index)}
              </Td>
              <Td>
                <div>
                  {val.tgl_spk
                    ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_spk ? val.no_spk : '-'}</div>
              </Td>
              <Td>{val.nama_customer ? val.nama_customer : '-'}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>{val.att ? val.att : '-'}</Td>
              <Td>
                {val.tgl_selesai
                  ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                  : '-'}
              </Td>
              <Td>
                {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                  <BarangJadiCollapse data={val.barang_jadi} />
                ) : (
                  '-'
                )}
              </Td>
              <Td>
                <div className="d-flex">
                  <ReadButton
                    onClick={() =>
                      setModalConfig({
                        id: val.id_spk,
                        type: 'detail',
                        show: true,
                      })
                    }
                  />
                  <ActionButton
                    size="sm"
                    variant="success"
                    className="m-1"
                    text={<IoAddOutline />}
                    onClick={() =>
                      history.push('/transaksi/rap/tambah/' + val.id_spk)
                    }
                    tooltip={true}
                    tooltipText="Register"
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!dataSPKRAP || dataSPKRAP.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mb-1">
          <b>SPK Yang Siap Didaftarkan Menjadi RAP</b>
        </div>
        <DataTable />
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  const ModalSection = () => {
    return (
      <Modal
        scrollable
        size="xl"
        show={modalConfig.show}
        onHide={() => setModalConfig({ id: '', show: false, type: 'detail' })}
      >
        <Modal.Header closeButton>
          <b>Detail SPK</b>
        </Modal.Header>
        <Modal.Body>
          <DetailSPK idSPK={modalConfig.id} />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="d-flex align-items-center mb-2">
            <Col md="10" className="d-flex">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(searchHandler, 1500)}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push('/transaksi/rap')} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {modalConfig.show && modalConfig.type === 'detail' && <ModalSection />}

      <ModalFilterSPKRAP
        dropdown={dropdown}
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default ListSPKRAP;
