import { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import {
  DropdownRealisasiProduksiContext,
  withDropdownRealisasiProduksiProvider,
} from '../../RealisasiProduksi/Contexts';

export const ModalFilterJobOrder = withDropdownRealisasiProduksiProvider(
  ({ show, setShow, filter, setFilter }) => {
    const [dataFilter, setDataFilter] = useState({ ...filter });

    const { tgl_jo_awal, tgl_jo_akhir, nama_customer, no_spk } = dataFilter;

    const { dropdownSPK, dropdownCustomer } = useContext(
      DropdownRealisasiProduksiContext
    );

    const defaultDropdown = { value: null, label: 'Semua' };

    const optSPK = [defaultDropdown].concat(dropdownSPK?.data ?? []);

    const optCustomer = [defaultDropdown].concat(dropdownCustomer?.data ?? []);

    return (
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <b>Filter Data</b>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selectsRange
            label="Tgl. Job Order"
            placeholderText="Pilih tanggal Job Order"
            monthsShown={2}
            startDate={tgl_jo_awal ? new Date(tgl_jo_awal) : ''}
            endDate={tgl_jo_akhir ? new Date(tgl_jo_akhir) : ''}
            onChange={(dates) => {
              const [start, end] = dates;

              const tgl_jo_awal = start ? DateConvert(start).default : null;
              const tgl_jo_akhir = end ? DateConvert(end).default : null;

              setDataFilter((prev) => ({
                ...prev,
                tgl_jo_awal,
                tgl_jo_akhir,
              }));
            }}
          />
          <SelectSearch
            label="Customer"
            placeholder="Pilih customer"
            option={optCustomer ?? []}
            loading={dropdownCustomer?.isLoading}
            defaultValue={optCustomer?.find(
              (item) => item.value === nama_customer
            )}
            onChange={(e) =>
              setDataFilter((prev) => ({ ...prev, nama_customer: e.value }))
            }
          />
          <SelectSearch
            label="No. SPK"
            placeholder="Pilih nomor SPK"
            defaultValue={optSPK.find((item) => item.value === no_spk)}
            option={optSPK ?? []}
            loading={dropdownSPK?.isLoading}
            onChange={(e) =>
              setDataFilter((prev) => ({ ...prev, no_spk: e.value }))
            }
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setFilter((prev) => ({
                ...prev,
                active: false,
                tgl_jo_awal: undefined,
                tgl_jo_akhir: undefined,
                nama_customer: undefined,
                no_spk: undefined,
              }));
              setShow(false);
            }}
          >
            RESET
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setFilter({ ...dataFilter, active: true, page: 1 });
              setShow(false);
            }}
          >
            FILTER
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
