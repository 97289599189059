import { Services } from '../../../services';

class KelompokTipeBarangJadiApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/kelompok_tipe_barang_jadi/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  create(data) {
    return Services.post('/kelompok_tipe_barang_jadi', data);
  }
  update(data) {
    return Services.put('/kelompok_tipe_barang_jadi', data);
  }
  delete(id_kondisi_aset) {
    return Services.post('/kelompok_tipe_barang_jadi/delete', id_kondisi_aset);
  }
  search(key) {
    return Services.get(`/kelompok_tipe_barang_jadi/page?q=${key}`);
  }
  show(id_kelompok_tipe_barang_jadi) {
    return Services.put(
      '/kelompok_tipe_barang_jadi/show',
      id_kelompok_tipe_barang_jadi
    );
  }
  hide(id_kelompok_tipe_barang_jadi) {
    return Services.put(
      '/kelompok_tipe_barang_jadi/hide',
      id_kelompok_tipe_barang_jadi
    );
  }
}

export default new KelompokTipeBarangJadiApi();
