import { Services } from 'services';

class PermintaanProduksiApi {
  get(params) {
    return Services.get('/permintaan_produksi/page', { params });
  }

  getListJobOrder(params) {
    return Services.get('/permintaan_produksi/jo', { params });
  }

  getSingle(params) {
    return Services.get('permintaan_produksi/single_permintaan', { params });
  }

  getSingleJobOrder(params) {
    return Services.get('permintaan_produksi/single_jo', { params });
  }

  getNoBaru(params) {
    return Services.get('permintaan_produksi/no_baru', { params });
  }

  getDropdownFilter(params) {
    return Services.get('permintaan_produksi/dropdown', { params });
  }

  getDropdownJobOrder(params) {
    return Services.get('registrasi_barang_jadi', {
      params,
    });
  }

  create(data) {
    return Services.post('/permintaan_produksi', data);
  }

  update(data) {
    return Services.put('/permintaan_produksi', data);
  }

  getExport(params) {
    return Services.get('/permintaan_produksi_report/barang_jadi', { params });
  }
}

export default new PermintaanProduksiApi();
