import { Services } from '../../../services';

class TransferSisaProduksiApi {
  page(params) {
    return Services.get('/transfer_sisa_produksi/page', { params });
  }

  preform(params) {
    return Services.get('/transfer_sisa_produksi/list_realisasi', { params });
  }

  history(params) {
    return Services.get('/transfer_sisa_produksi/history', { params });
  }

  single(params) {
    return Services.get('/transfer_sisa_produksi/single', { params });
  }

  singleRealisasi(params) {
    return Services.get('/transfer_sisa_produksi/single_realisasi', { params });
  }

  dropdown(params) {
    return Services.get('/transfer_sisa_produksi/dropdown', { params });
  }

  no_baru(params) {
    return Services.get('/transfer_sisa_produksi/no_baru', { params });
  }

  create(value) {
    return Services.post('/transfer_sisa_produksi', value);
  }

  update(value) {
    return Services.put('/transfer_sisa_produksi', value);
  }

  delete(value) {
    return Services.post('/transfer_sisa_produksi/delete', value);
  }

  bahan_no_baru() {
    return Services.get('/transfer_sisa_produksi/bahan_no_baru');
  }

  bahan_dropdown(params) {
    return Services.get('/transfer_sisa_produksi/bahan_dropdown', { params });
  }

  create_bahan(value) {
    return Services.post('/transfer_sisa_produksi/bahan', value);
  }
}

export default new TransferSisaProduksiApi();
